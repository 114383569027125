<!-- tooltip and popover won't display on disabled elements so we have a div wrapper to hold those items in case button is disabled. -->
<div style="display: inline;"
     [ngbTooltip]="( popoverShow ? '' : tooltip )"
     [ngbPopover]="popContent"
     [popoverTitle]="popTitle"
     [placement]="['top', 'top-right', 'top-left', 'auto']"
     [ngClass]="{ 'd-grid' : block }"
     triggers="mouseenter:mouseleave"
     popoverClass="{{popoverClass}}"
     container="body"
     [disablePopover]="!popoverShow">
  <ng-template #popTitle>{{popoverTitle}}</ng-template>
  <ng-template #popContent>{{popoverDescription}}</ng-template>
  <button type="button"
          class="{{classes}}"
          (click)="fireClick($event)"
          [disabled]="( disabled || formButtonDisabled ) ? 'disabled' : null">
    <ib-icon *ngIf="!working"
             icon="{{icon}}"
             animation="{{iconAnimation}}"
             label="{{( label | translate ) || label}}"></ib-icon>
    <ib-icon *ngIf="working"
             icon="{{workingIconToUse}}"
             animation="{{workingIconAnimationToUse}}"
             label="{{( workingLabelToUse | translate ) || workingLabelToUse}}"></ib-icon>
  </button>
</div>

<ib-form-status-error-output *ngIf="displayErrorMessages"
                             [formStatus]="formStatus"
                             classes=""></ib-form-status-error-output>
