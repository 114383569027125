// !!! This is generated code... DO NOT modify this file !!!
import * as m from './ngCoreModels';


/**
 * @name Industry Library Approval
 * @description
 * This contains approval status for an industry library entry.
 */
export class ReportCompilerIndustryLibraryApprovalViewModel {

    /**
     * @name VisibilityId
     * @description 
     * The id of the industry library approval status object.
     * @servertype Int64
     * @type {number}
     */
    VisibilityId: number = 0;

    /**
     * @name AssetId
     * @description 
     * The id of the industry library entry this approval is for.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name Status
     * @description 
     * The status of the approval: Requested, Rejected, Approved, or Unknown.
     * @servertype String
     * @type {string}
     */
    Status: string = "";

    /**
     * @name DeterminedByContactId
     * @description 
     * The id of the contact who determined either approved or rejected.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DeterminedByContactId: number = null;

    /**
     * @name DeterminedDateTime
     * @description 
     * The date and time this was either approved or rejected.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DeterminedDateTime: Date = null;

    /**
     * @name RequestedByContactId
     * @description 
     * A list of contact ids who have requested this to be approved.
     * @servertype long array
     * @type {number[]}
     */
    RequestedByContactId: number[] = [];

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description 
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Industry Library Type Information
 * @description
 * This contains information about an industry library type.
 */
export class ReportCompilerIndustryLibraryTypeInformationModel {

    /**
     * @name IndustryLibraryTypeInformationId
     * @description 
     * The id of the industry library type information object.
     * @servertype Int64
     * @type {number}
     */
    IndustryLibraryTypeInformationId: number = 0;

    /**
     * @name IndustryLibraryType
     * @description 
     * The industry library type.  This is a system defined value that is used for front-end and back-end processing.
     * @servertype String
     * @type {string}
     */
    IndustryLibraryType: string = "";

    /**
     * @name IndustryLibraryPartitionId
     * @description 
     * The partition id that hosts the industry library for this industry library type (if any).
     * @servertype Int64 (nullable)
     * @type {number}
     */
    IndustryLibraryPartitionId: number = 0;

    /**
     * @name Comments
     * @description 
     * Comments for this industry library type information.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Private
     * @description 
     * Flag if this item is private.
     * @servertype Boolean
     * @type {boolean}
     */
    Private: boolean = false;

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description 
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Library Text Reason Suggested
 * @description
 * This contains information about why library text entry was suggested.
 */
export class ReportCompilerLibraryEntryReasonSuggestedModel {

    /**
     * @name KeyCode
     * @description 
     * Key that led to the suggestion.
     * @servertype IB.Web.API.Models.v5.ReportCompilerLibraryEntryReasonSuggestedKey Enum
     * @type {ReportCompilerLibraryEntryReasonSuggestedKey}
     */
    KeyCode: ReportCompilerLibraryEntryReasonSuggestedKey = null;

    /**
     * @name KeyText
     * @description 
     * The key that led to this suggestion.
     * @servertype String
     * @type {string}
     */
    KeyText: string = "";

    /**
     * @name Value
     * @description 
     * The value tied to the key that lead to this suggestion.
     * @servertype String
     * @type {string}
     */
    Value: string = "";

    /**
     * @name Wildcard
     * @description 
     * A wildcard indicator if wildcards may have been a factor in the suggestion.
     * @servertype IB.Web.API.Models.v5.ReportCompilerLibraryEntryReasonSuggestedWildcardType Enum
     * @type {ReportCompilerLibraryEntryReasonSuggestedWildcardType}
     */
    Wildcard: ReportCompilerLibraryEntryReasonSuggestedWildcardType = null;

    /**
     * @name Description
     * @description 
     * A description about why the library text was suggested.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Properties
     * @description 
     * Properties regarding the reason suggested.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name ReportCompilerLibraryEntryReasonSuggestedKey Enum
 * @readonly
 * @enum {number}
 */
export enum ReportCompilerLibraryEntryReasonSuggestedKey {

    /**
     * Unknown
     */
    Unknown = 0,

    /**
     * Other
     */
    Other = 1,

    /**
     * Selection
     */
    Selection = 100,

    /**
     * Selection Property 01
     */
    SelectionProperty01 = 101,

    /**
     * Selection Property 02
     */
    SelectionProperty02 = 102,

    /**
     * Selection Property 03
     */
    SelectionProperty03 = 103,

    /**
     * Selection Property 04
     */
    SelectionProperty04 = 104,

    /**
     * Selection Property 05
     */
    SelectionProperty05 = 105,

    /**
     * Selection Property 06
     */
    SelectionProperty06 = 106,

    /**
     * Selection Property 07
     */
    SelectionProperty07 = 107,

    /**
     * Selection Property 08
     */
    SelectionProperty08 = 108,

    /**
     * Selection Property 09
     */
    SelectionProperty09 = 109,

    /**
     * Selection Property 10
     */
    SelectionProperty10 = 110,

    /**
     * Query
     */
    Query = 201,

    /**
     * Category
     */
    Category = 301,

    /**
     * Group
     */
    Group = 401,

    /**
     * Tags
     */
    Tags = 501

}



/**
 * @name ReportCompilerLibraryEntryReasonSuggestedWildcardType Enum
 * @readonly
 * @enum {number}
 */
export enum ReportCompilerLibraryEntryReasonSuggestedWildcardType {

    /**
     * None
     */
    None = 0,

    /**
     * Case Selection Had Wildcards
     */
    CaseSelectionHadWildcards = 10,

    /**
     * Library Selection Had Wildcards
     */
    LibrarySelectionHadWildcards = 20,

    /**
     * Library Selection All Wildcards
     */
    LibrarySelectionAllWildcards = 30

}



/**
 * @name Report Attachment Document
 * @description
 * This contains information about a document for a report attachment.
 */
export class ReportCompilerReportAttachmentDocumentModel {

    /**
     * @name ReportAttachmentDocumentId
     * @description 
     * The id of the report attachment document.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportAttachmentDocumentId: number = 0;

    /**
     * @name ReportAttachmentConfigurationId
     * @description 
     * The id of the report attachment this document is part of.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportAttachmentConfigurationId: number = 0;

    /**
     * @name CaseId
     * @description 
     * The id of the case this belongs to.
     * @servertype Int64
     * @type {number}
     */
    CaseId: number = 0;

    /**
     * @name AssetId
     * @description 
     * The asset id of the asset library document.
     * @servertype Int64
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name CaseAttachment
     * @description 
     * When true the document is locally attached to the case as opposed to being stored in the document library.
     * @servertype Boolean
     * @type {boolean}
     */
    CaseAttachment: boolean = false;

    /**
     * @name Selected
     * @description 
     * In the context of a file this is true if the library document was selected to be included in the report.
     * @servertype Boolean
     * @type {boolean}
     */
    Selected: boolean = false;

    /**
     * @name AutoSelect
     * @description 
     * When true the library entry is configured to be automatically selected when suggested.  This is true when the library entry
     * priority is greater than zero.
     * @servertype Boolean
     * @type {boolean}
     */
    AutoSelect: boolean = false;

    /**
     * @name DisplayOrder
     * @description 
     * In scenarios where not stored as part of a sorted collection of library document this value holds the order to be applied.
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name InternalDisplayOrder
     * @description 
     * The order to display the library document in when using a display ordered interface.  Note that scoring will typically take
     * precedence over display order and display order may only apply to items that have the same score.
     * @servertype Int32
     * @type {number}
     */
    InternalDisplayOrder: number = 0;

    /**
     * @name ExternalDocumentId
     * @description 
     * The external id of the library document.
     * @servertype String
     * @type {string}
     */
    ExternalDocumentId: string = "";

    /**
     * @name Title
     * @description 
     * The title of the library document.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Description
     * @description 
     * A description for this attachment document.  For attachments that support captions this is used for the caption.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name FriendlyName
     * @description 
     * The friendly name of the library document.
     * @servertype String
     * @type {string}
     */
    FriendlyName: string = "";

    /**
     * @name FileType
     * @servertype String
     * @type {string}
     */
    FileType: string = "";

    /**
     * @name AssetType
     * @servertype String
     * @type {string}
     */
    AssetType: string = "";

    /**
     * @name AssetClass
     * @servertype String
     * @type {string}
     */
    AssetClass: string = "";

    /**
     * @name AssetClassInferred
     * @servertype String
     * @type {string}
     */
    AssetClassInferred: string = "";

    /**
     * @name SizeBytes
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SizeBytes: number = 0;

    /**
     * @name SizeOther
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SizeOther: number = 0;

    /**
     * @name SizeInformation
     * @servertype String
     * @type {string}
     */
    SizeInformation: string = "";

    /**
     * @name Width
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Width: number = 0;

    /**
     * @name Height
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Height: number = 0;

    /**
     * @name ContentCategory
     * @description 
     * The content category provides the ability to mark library entries as referring to a specific document type which can then
     * be referenced in other parts of a report.  For example, knowing that one of the documents reviewed was a weather document
     * can allow another part of the report to reference that document.
     * @servertype String
     * @type {string}
     */
    ContentCategory: string = "";

    /**
     * @name Visibility
     * @servertype String
     * @type {string}
     */
    Visibility: string = "";

    /**
     * @name AuthorLongName
     * @description 
     * The long name of the author of this library document.
     * @servertype String
     * @type {string}
     */
    AuthorLongName: string = "";

    /**
     * @name AuthorShortName
     * @description 
     * The long name of the author of this library document.
     * @servertype String
     * @type {string}
     */
    AuthorShortName: string = "";

    /**
     * @name Tags
     * @description 
     * A list of tags that are part of this library entry.
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name Property01
     * @description 
     * A selection property used for this library document.  This value here and meaning depends on the library group this entry
     * is part of.
     * @servertype String
     * @type {string}
     */
    Property01: string = "";

    /**
     * @name Value01
     * @description 
     * A selection value used for this library document.  The corresponding property gives context to this value and depends on
     * the library group this entry is part of.
     * @servertype String
     * @type {string}
     */
    Value01: string = "";

    /**
     * @name Property02
     * @description 
     * A selection property used for this library document.  This value here and meaning depends on the library group this entry
     * is part of.
     * @servertype String
     * @type {string}
     */
    Property02: string = "";

    /**
     * @name Value02
     * @description 
     * A selection value used for this library document.  The corresponding property gives context to this value and depends on
     * the library group this entry is part of.
     * @servertype String
     * @type {string}
     */
    Value02: string = "";

    /**
     * @name Property03
     * @description 
     * A selection property used for this library document.  This value here and meaning depends on the library group this entry
     * is part of.
     * @servertype String
     * @type {string}
     */
    Property03: string = "";

    /**
     * @name Value03
     * @description 
     * A selection value used for this library document.  The corresponding property gives context to this value and depends on
     * the library group this entry is part of.
     * @servertype String
     * @type {string}
     */
    Value03: string = "";

    /**
     * @name Property04
     * @description 
     * A selection property used for this library document.  This value here and meaning depends on the library group this entry
     * is part of.
     * @servertype String
     * @type {string}
     */
    Property04: string = "";

    /**
     * @name Value04
     * @description 
     * A selection value used for this library document.  The corresponding property gives context to this value and depends on
     * the library group this entry is part of.
     * @servertype String
     * @type {string}
     */
    Value04: string = "";

    /**
     * @name Property05
     * @description 
     * A selection property used for this library document.  This value here and meaning depends on the library group this entry
     * is part of.
     * @servertype String
     * @type {string}
     */
    Property05: string = "";

    /**
     * @name Value05
     * @description 
     * A selection value used for this library document.  The corresponding property gives context to this value and depends on
     * the library group this entry is part of.
     * @servertype String
     * @type {string}
     */
    Value05: string = "";

    /**
     * @name Property06
     * @description 
     * A selection property used for this library document.  This value here and meaning depends on the library group this entry
     * is part of.
     * @servertype String
     * @type {string}
     */
    Property06: string = "";

    /**
     * @name Value06
     * @description 
     * A selection value used for this library document.  The corresponding property gives context to this value and depends on
     * the library group this entry is part of.
     * @servertype String
     * @type {string}
     */
    Value06: string = "";

    /**
     * @name Property07
     * @description 
     * A selection property used for this library document.  This value here and meaning depends on the library group this entry
     * is part of.
     * @servertype String
     * @type {string}
     */
    Property07: string = "";

    /**
     * @name Value07
     * @description 
     * A selection value used for this library document.  The corresponding property gives context to this value and depends on
     * the library group this entry is part of.
     * @servertype String
     * @type {string}
     */
    Value07: string = "";

    /**
     * @name Property08
     * @description 
     * A selection property used for this library document.  This value here and meaning depends on the library group this entry
     * is part of.
     * @servertype String
     * @type {string}
     */
    Property08: string = "";

    /**
     * @name Value08
     * @description 
     * A selection value used for this library document.  The corresponding property gives context to this value and depends on
     * the library group this entry is part of.
     * @servertype String
     * @type {string}
     */
    Value08: string = "";

    /**
     * @name Property09
     * @description 
     * A selection property used for this library document.  This value here and meaning depends on the library group this entry
     * is part of.
     * @servertype String
     * @type {string}
     */
    Property09: string = "";

    /**
     * @name Value09
     * @description 
     * A selection value used for this library document.  The corresponding property gives context to this value and depends on
     * the library group this entry is part of.
     * @servertype String
     * @type {string}
     */
    Value09: string = "";

    /**
     * @name Property10
     * @description 
     * A selection property used for this library document.  This value here and meaning depends on the library group this entry
     * is part of.
     * @servertype String
     * @type {string}
     */
    Property10: string = "";

    /**
     * @name Value10
     * @description 
     * A selection value used for this library document.  The corresponding property gives context to this value and depends on
     * the library group this entry is part of.
     * @servertype String
     * @type {string}
     */
    Value10: string = "";

    /**
     * @name FavoriteId
     * @description 
     * If this library document has been marked as a favorite by the current user this is the id of that favorite entry.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FavoriteId: number = 0;

    /**
     * @name FavoriteRank
     * @description 
     * The ranking assigned to this library document due to its favorite status.
     * @servertype Int32
     * @type {number}
     */
    FavoriteRank: number = 0;

    /**
     * @name MatchRank
     * @description 
     * The ranking assigned to this library document due to the strength of matching search criteria that was provided.
     * @servertype Int32
     * @type {number}
     */
    MatchRank: number = 0;

    /**
     * @name MatchRankReason
     * @description 
     * The reason the specified match rank was used.
     * @servertype String
     * @type {string}
     */
    MatchRankReason: string = "";

    /**
     * @name Score
     * @description 
     * The score for this library document between -100 and 100 based on a combination of match rank and favorite rank.
     * @servertype Int32
     * @type {number}
     */
    Score: number = 0;

    /**
     * @name ScoreCalculation
     * @description 
     * A description as to how the score was calculated.
     * @servertype String
     * @type {string}
     */
    ScoreCalculation: string = "";

    /**
     * @name ScoreCalculationInformation
     * @description 
     * Meta data explaining how the score was calculated.
     * @servertype ReportCompilerLibraryEntryScoreCalculationModel array
     * @type {ReportCompilerLibraryEntryScoreCalculationModel[]}
     */
    ScoreCalculationInformation: ReportCompilerLibraryEntryScoreCalculationModel[] = [];

    /**
     * @name ReasonSuggested
     * @description 
     * A description as to why this library document was suggested.
     * @servertype String
     * @type {string}
     */
    ReasonSuggested: string = "";

    /**
     * @name ReasonSuggestedInformation
     * @description 
     * Meta data explaining why this library document was suggested.
     * @servertype ReportCompilerLibraryEntryReasonSuggestedModel array
     * @type {ReportCompilerLibraryEntryReasonSuggestedModel[]}
     */
    ReasonSuggestedInformation: ReportCompilerLibraryEntryReasonSuggestedModel[] = [];

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description 
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Library Entry Score Calculation
 * @description
 * This contains information about library entry score calculation.
 */
export class ReportCompilerLibraryEntryScoreCalculationModel {

    /**
     * @name Score
     * @description 
     * The score for this item typically in the range -100 to 100.
     * @servertype Int32
     * @type {number}
     */
    Score: number = 0;

    /**
     * @name CategoryCode
     * @description 
     * The score category.
     * @servertype IB.Web.API.Models.v5.ReportCompilerLibraryEntryScoreCategory Enum
     * @type {ReportCompilerLibraryEntryScoreCategory}
     */
    CategoryCode: ReportCompilerLibraryEntryScoreCategory = null;

    /**
     * @name CategoryText
     * @description 
     * The score category.
     * @servertype String
     * @type {string}
     */
    CategoryText: string = "";

    /**
     * @name TypeCode
     * @description 
     * The score type.
     * @servertype IB.Web.API.Models.v5.ReportCompilerLibraryEntryScoreType Enum
     * @type {ReportCompilerLibraryEntryScoreType}
     */
    TypeCode: ReportCompilerLibraryEntryScoreType = null;

    /**
     * @name TypeText
     * @description 
     * The score type.
     * @servertype String
     * @type {string}
     */
    TypeText: string = "";

    /**
     * @name ReasonCode
     * @description 
     * Reason the score was impacted.
     * @servertype IB.Web.API.Models.v5.ReportCompilerLibraryEntryScoreImpactReason Enum
     * @type {ReportCompilerLibraryEntryScoreImpactReason}
     */
    ReasonCode: ReportCompilerLibraryEntryScoreImpactReason = null;

    /**
     * @name ReasonText
     * @description 
     * Reason the score was impacted.
     * @servertype String
     * @type {string}
     */
    ReasonText: string = "";

    /**
     * @name Description
     * @description 
     * A description about how the score was impacted.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name MaxScore
     * @description 
     * The maximum possible score for this item.
     * @servertype Int32
     * @type {number}
     */
    MaxScore: number = 0;

    /**
     * @name Weight
     * @description 
     * If there was a weight involved in scoring this is the weight as a decimal - e.g. 45% is 0.45.
     * @servertype Double
     * @type {number}
     */
    Weight: number = 0;

    /**
     * @name ScoreValueReset
     * @description 
     * When false (which is typical) the score impact is a relative value of how much the score went up or down based on the reason.
     *  When true the score impact is a fixed value the score was reset to the value overriding any other impact that preceded it.
     * @servertype Boolean
     * @type {boolean}
     */
    ScoreValueReset: boolean = false;

    /**
     * @name Properties
     * @description 
     * Properties regarding the score calculation.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name ReportCompilerLibraryEntryScoreCategory Enum
 * @readonly
 * @enum {number}
 */
export enum ReportCompilerLibraryEntryScoreCategory {

    /**
     * Unknown
     */
    Unknown = 1,

    /**
     * Library
     */
    Library = 11,

    /**
     * Match
     */
    Match = 21,

    /**
     * Preference
     */
    Preference = 31,

    /**
     * Total
     */
    Total = 999999

}



/**
 * @name ReportCompilerLibraryEntryScoreType Enum
 * @readonly
 * @enum {number}
 */
export enum ReportCompilerLibraryEntryScoreType {

    /**
     * Unknown
     */
    Unknown = 1,

    /**
     * Item
     */
    Item = 11,

    /**
     * Category Subtotal
     */
    CategorySubtotal = 21,

    /**
     * Reset
     */
    Reset = 91,

    /**
     * Total
     */
    Total = 999999

}



/**
 * @name ReportCompilerLibraryEntryScoreImpactReason Enum
 * @readonly
 * @enum {number}
 */
export enum ReportCompilerLibraryEntryScoreImpactReason {

    /**
     * Unknown
     */
    Unknown = 0,

    /**
     * Other
     */
    Other = 1,

    /**
     * Source Library
     */
    SourceLibrary = 20,

    /**
     * Source Library Personal
     */
    SourceLibraryPersonal = 21,

    /**
     * Source Library Organization
     */
    SourceLibraryOrganization = 22,

    /**
     * Source Library Industry
     */
    SourceLibraryIndustry = 23,

    /**
     * Match
     */
    Match = 30,

    /**
     * Match Exact
     */
    MatchExact = 31,

    /**
     * Match Partial
     */
    MatchPartial = 32,

    /**
     * Match Always
     */
    MatchAlways = 33,

    /**
     * Match Search Found In Tags
     */
    MatchSearchFoundInTags = 34,

    /**
     * Match Search Found In Library
     */
    MatchSearchFoundInLibrary = 35,

    /**
     * Preference
     */
    Preference = 40,

    /**
     * Explicit Personal Preference
     */
    ExplicitPersonalPreference = 41,

    /**
     * Inferred Personal Preference
     */
    InferredPersonalPreference = 42,

    /**
     * Inferred Organization Preference
     */
    InferredOrganizationPreference = 43,

    /**
     * Inferred Industry Preference
     */
    InferredIndustryPreference = 44,

    /**
     * Observed Personal Past Use
     */
    ObservedPersonalPastUse = 48,

    /**
     * Library Content Feedback Sentiment
     */
    LibraryContentFeedbackSentiment = 49,

    /**
     * Love
     */
    Love = 1100,

    /**
     * Like
     */
    Like = 1200,

    /**
     * Dislike
     */
    Dislike = 1300,

    /**
     * Block
     */
    Block = 1400

}



/**
 * @name Subject Matter Structural Subject/Element/Damage
 * @description
 * This contains structural subject matter subject/element/damage information.
 */
export class ReportCompilerSubjectMatterBaseForStructuralSubjectElementDamageModel {

    /**
     * @name SubjectMatterSubjectElementDamageId
     * @description 
     * The id of the structural subject matter subject/element/damage object.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SubjectMatterSubjectElementDamageId: number = 0;

    /**
     * @name SubjectMatterSubjectElementDamageLocalId
     * @description 
     * The local id of the structural subject matter subject/element/damage object.
     * @servertype String
     * @type {string}
     */
    SubjectMatterSubjectElementDamageLocalId: string = "";

    /**
     * @name SubjectMatterAreaLocalId
     * @description 
     * The local id of the area this object belongs to.
     * @servertype String
     * @type {string}
     */
    SubjectMatterAreaLocalId: string = "";

    /**
     * @name SubjectMatterStructureLocalId
     * @description 
     * The local id of the structure this object belongs to.
     * @servertype String
     * @type {string}
     */
    SubjectMatterStructureLocalId: string = "";

    /**
     * @name SubjectMatterPropertyLocalId
     * @description 
     * The local id of the property this object belongs to.
     * @servertype String
     * @type {string}
     */
    SubjectMatterPropertyLocalId: string = "";

    /**
     * @name CaseId
     * @description 
     * The id of the case this belongs to.
     * @servertype Int64
     * @type {number}
     */
    CaseId: number = 0;

    /**
     * @name DisplayOrder
     * @description 
     * The display order for this object.
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Subject
     * @servertype String
     * @type {string}
     */
    Subject: string = "";

    /**
     * @name Element
     * @servertype String
     * @type {string}
     */
    Element: string = "";

    /**
     * @name Damages
     * @servertype string array
     * @type {string[]}
     */
    Damages: string[] = [];

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description 
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Subject Matter Structural Property
 * @description
 * This contains structural subject matter property information.
 */
export class ReportCompilerSubjectMatterStructuralPropertyModel {

    /**
     * @name SubjectMatterPropertyId
     * @description 
     * The id of the structural subject matter property object.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SubjectMatterPropertyId: number = 0;

    /**
     * @name SubjectMatterPropertyLocalId
     * @description 
     * The local id of the structural subject matter property object.
     * @servertype String
     * @type {string}
     */
    SubjectMatterPropertyLocalId: string = "";

    /**
     * @name CaseId
     * @description 
     * The id of the case this belongs to.
     * @servertype Int64
     * @type {number}
     */
    CaseId: number = 0;

    /**
     * @name DisplayOrder
     * @description 
     * The display order for this object.
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Description
     * @description 
     * The description for this subject matter property object.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name PropertyType
     * @description 
     * The property type for this subject matter object.
     * @servertype String
     * @type {string}
     */
    PropertyType: string = "";

    /**
     * @name Structures
     * @servertype ReportCompilerSubjectMatterStructuralStructureModel array
     * @type {ReportCompilerSubjectMatterStructuralStructureModel[]}
     */
    Structures: ReportCompilerSubjectMatterStructuralStructureModel[] = [];

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description 
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Subject Matter Structural Structure
 * @description
 * This contains structural subject matter structure information.
 */
export class ReportCompilerSubjectMatterStructuralStructureModel {

    /**
     * @name SubjectMatterStructureId
     * @description 
     * The id of the structural subject matter structure object.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SubjectMatterStructureId: number = 0;

    /**
     * @name SubjectMatterStructureLocalId
     * @description 
     * The local id of the structural subject matter structure object.
     * @servertype String
     * @type {string}
     */
    SubjectMatterStructureLocalId: string = "";

    /**
     * @name SubjectMatterPropertyLocalId
     * @description 
     * The local id of the property this structure belongs to.
     * @servertype String
     * @type {string}
     */
    SubjectMatterPropertyLocalId: string = "";

    /**
     * @name CaseId
     * @description 
     * The id of the case this belongs to.
     * @servertype Int64
     * @type {number}
     */
    CaseId: number = 0;

    /**
     * @name DisplayOrder
     * @description 
     * The display order for this object.
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name TrueFrontDirection
     * @servertype String
     * @type {string}
     */
    TrueFrontDirection: string = "";

    /**
     * @name ReportFrontDirection
     * @servertype String
     * @type {string}
     */
    ReportFrontDirection: string = "";

    /**
     * @name ConstructedApproximateDate
     * @description 
     * The approximate date the structure was constructed.
     * @servertype String
     * @type {string}
     */
    ConstructedApproximateDate: string = "";

    /**
     * @name OwnedSinceApproximateDate
     * @description 
     * The approximate date the structure was owned by the current owner.
     * @servertype String
     * @type {string}
     */
    OwnedSinceApproximateDate: string = "";

    /**
     * @name Renovations
     * @description 
     * A list of renovation events.
     * @servertype ReportCompilerSubjectMatterEventModel array
     * @type {ReportCompilerSubjectMatterEventModel[]}
     */
    Renovations: ReportCompilerSubjectMatterEventModel[] = [];

    /**
     * @name Additions
     * @description 
     * A list of addition events.
     * @servertype ReportCompilerSubjectMatterEventModel array
     * @type {ReportCompilerSubjectMatterEventModel[]}
     */
    Additions: ReportCompilerSubjectMatterEventModel[] = [];

    /**
     * @name PrecedingDamages
     * @description 
     * A list of preceding damage events.
     * @servertype ReportCompilerSubjectMatterEventModel array
     * @type {ReportCompilerSubjectMatterEventModel[]}
     */
    PrecedingDamages: ReportCompilerSubjectMatterEventModel[] = [];

    /**
     * @name Areas
     * @servertype ReportCompilerSubjectMatterStructuralAreaModel array
     * @type {ReportCompilerSubjectMatterStructuralAreaModel[]}
     */
    Areas: ReportCompilerSubjectMatterStructuralAreaModel[] = [];

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description 
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Subject Matter Event
 * @description
 * This contains a description and time of an event related to subject matter.
 */
export class ReportCompilerSubjectMatterEventModel {

    /**
     * @name Description
     * @description 
     * The description for this event.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ApproximateDate
     * @description 
     * The approximate date of the event.
     * @servertype String
     * @type {string}
     */
    ApproximateDate: string = "";

}



/**
 * @name Subject Matter Structural Area
 * @description
 * This contains structural subject matter area information.
 */
export class ReportCompilerSubjectMatterStructuralAreaModel {

    /**
     * @name SubjectMatterAreaId
     * @description 
     * The id of the structural subject matter area object.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SubjectMatterAreaId: number = 0;

    /**
     * @name SubjectMatterAreaLocalId
     * @description 
     * The local id of the structural subject matter area object.
     * @servertype String
     * @type {string}
     */
    SubjectMatterAreaLocalId: string = "";

    /**
     * @name SubjectMatterStructureLocalId
     * @description 
     * The local id of the structure this area belongs to.
     * @servertype String
     * @type {string}
     */
    SubjectMatterStructureLocalId: string = "";

    /**
     * @name SubjectMatterPropertyLocalId
     * @description 
     * The local id of the property this area belongs to.
     * @servertype String
     * @type {string}
     */
    SubjectMatterPropertyLocalId: string = "";

    /**
     * @name CaseId
     * @description 
     * The id of the case this belongs to.
     * @servertype Int64
     * @type {number}
     */
    CaseId: number = 0;

    /**
     * @name DisplayOrder
     * @description 
     * The display order for this object.
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Stories
     * @servertype String
     * @type {string}
     */
    Stories: string = "";

    /**
     * @name OverallFraming
     * @servertype String
     * @type {string}
     */
    OverallFraming: string = "";

    /**
     * @name OverallFoundation
     * @servertype String
     * @type {string}
     */
    OverallFoundation: string = "";

    /**
     * @name RoofFraming
     * @servertype string array
     * @type {string[]}
     */
    RoofFraming: string[] = [];

    /**
     * @name WallFraming
     * @servertype string array
     * @type {string[]}
     */
    WallFraming: string[] = [];

    /**
     * @name FloorFraming
     * @servertype string array
     * @type {string[]}
     */
    FloorFraming: string[] = [];

    /**
     * @name Foundation
     * @servertype string array
     * @type {string[]}
     */
    Foundation: string[] = [];

    /**
     * @name Roofing
     * @servertype string array
     * @type {string[]}
     */
    Roofing: string[] = [];

    /**
     * @name ExteriorSheathing
     * @servertype string array
     * @type {string[]}
     */
    ExteriorSheathing: string[] = [];

    /**
     * @name ExteriorInsulation
     * @servertype string array
     * @type {string[]}
     */
    ExteriorInsulation: string[] = [];

    /**
     * @name ExteriorRainscreen
     * @servertype string array
     * @type {string[]}
     */
    ExteriorRainscreen: string[] = [];

    /**
     * @name ExteriorCladding
     * @servertype string array
     * @type {string[]}
     */
    ExteriorCladding: string[] = [];

    /**
     * @name GutterLocation
     * @servertype string array
     * @type {string[]}
     */
    GutterLocation: string[] = [];

    /**
     * @name GutterSize
     * @servertype string array
     * @type {string[]}
     */
    GutterSize: string[] = [];

    /**
     * @name DownspoutLocation
     * @servertype string array
     * @type {string[]}
     */
    DownspoutLocation: string[] = [];

    /**
     * @name DownspoutSize
     * @servertype string array
     * @type {string[]}
     */
    DownspoutSize: string[] = [];

    /**
     * @name DownspoutTermination
     * @servertype string array
     * @type {string[]}
     */
    DownspoutTermination: string[] = [];

    /**
     * @name FloorCovering
     * @servertype string array
     * @type {string[]}
     */
    FloorCovering: string[] = [];

    /**
     * @name InteriorCladding
     * @servertype string array
     * @type {string[]}
     */
    InteriorCladding: string[] = [];

    /**
     * @name ReportedCauses
     * @servertype string array
     * @type {string[]}
     */
    ReportedCauses: string[] = [];

    /**
     * @name FoundCauses
     * @servertype string array
     * @type {string[]}
     */
    FoundCauses: string[] = [];

    /**
     * @name Tags
     * @description 
     * A list of tags for this subject matter object.
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name InteriorDamages
     * @servertype ReportCompilerSubjectMatterStructuralInteriorSubjectElementDamageModel array
     * @type {ReportCompilerSubjectMatterStructuralInteriorSubjectElementDamageModel[]}
     */
    InteriorDamages: ReportCompilerSubjectMatterStructuralInteriorSubjectElementDamageModel[] = [];

    /**
     * @name ExteriorDamages
     * @servertype ReportCompilerSubjectMatterStructuralExteriorSubjectElementDamageModel array
     * @type {ReportCompilerSubjectMatterStructuralExteriorSubjectElementDamageModel[]}
     */
    ExteriorDamages: ReportCompilerSubjectMatterStructuralExteriorSubjectElementDamageModel[] = [];

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description 
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Subject Matter Structural Interior Subject/Element/Damage
 * @description
 * This contains structural subject matter interior subject/element/damage information.
 */
export class ReportCompilerSubjectMatterStructuralInteriorSubjectElementDamageModel extends ReportCompilerSubjectMatterBaseForStructuralSubjectElementDamageModel {

}



/**
 * @name Subject Matter Structural Exterior Subject/Element/Damage
 * @description
 * This contains structural subject matter exterior subject/element/damage information.
 */
export class ReportCompilerSubjectMatterStructuralExteriorSubjectElementDamageModel extends ReportCompilerSubjectMatterBaseForStructuralSubjectElementDamageModel {

}



/**
 * @name Subject Matter Other
 * @description
 * This contains other subject matter information.
 */
export class ReportCompilerSubjectMatterOtherModel {

    /**
     * @name SubjectMatterId
     * @description 
     * The id of the other subject matter object.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SubjectMatterId: number = 0;

    /**
     * @name CaseId
     * @description 
     * The id of the case this belongs to.
     * @servertype Int64
     * @type {number}
     */
    CaseId: number = 0;

    /**
     * @name DisplayOrder
     * @description 
     * The display order for this object.
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Description
     * @description 
     * The description for this subject matter object.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name LibrarySelectionRuleValue01
     * @description 
     * Library selection rule value 1.
     * @servertype String
     * @type {string}
     */
    LibrarySelectionRuleValue01: string = "";

    /**
     * @name LibrarySelectionRuleValue02
     * @description 
     * Library selection rule value 2.
     * @servertype String
     * @type {string}
     */
    LibrarySelectionRuleValue02: string = "";

    /**
     * @name LibrarySelectionRuleValue03
     * @description 
     * Library selection rule value 3.
     * @servertype String
     * @type {string}
     */
    LibrarySelectionRuleValue03: string = "";

    /**
     * @name LibrarySelectionRuleValue04
     * @description 
     * Library selection rule value 4.
     * @servertype String
     * @type {string}
     */
    LibrarySelectionRuleValue04: string = "";

    /**
     * @name LibrarySelectionRuleValue05
     * @description 
     * Library selection rule value 5.
     * @servertype String
     * @type {string}
     */
    LibrarySelectionRuleValue05: string = "";

    /**
     * @name LibrarySelectionRuleValue06
     * @description 
     * Library selection rule value 6.
     * @servertype String
     * @type {string}
     */
    LibrarySelectionRuleValue06: string = "";

    /**
     * @name LibrarySelectionRuleValue07
     * @description 
     * Library selection rule value 7.
     * @servertype String
     * @type {string}
     */
    LibrarySelectionRuleValue07: string = "";

    /**
     * @name LibrarySelectionRuleValue08
     * @description 
     * Library selection rule value 8.
     * @servertype String
     * @type {string}
     */
    LibrarySelectionRuleValue08: string = "";

    /**
     * @name LibrarySelectionRuleValue09
     * @description 
     * Library selection rule value 9.
     * @servertype String
     * @type {string}
     */
    LibrarySelectionRuleValue09: string = "";

    /**
     * @name LibrarySelectionRuleValue10
     * @description 
     * Library selection rule value 10.
     * @servertype String
     * @type {string}
     */
    LibrarySelectionRuleValue10: string = "";

    /**
     * @name Tags
     * @description 
     * A list of tags for this subject matter object.
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name SubjectMatter
     * @description 
     * The other properties for this subject matter object.
     * @servertype System.Object
     * @type {any}
     */
    SubjectMatter: any = {};

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description 
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Report Section Text
 * @description
 * This contains text for a report section.
 */
export class ReportCompilerReportSectionTextModel {

    /**
     * @name ReportSectionTextId
     * @description 
     * The id of the report section text.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportSectionTextId: number = 0;

    /**
     * @name ReportSectionConfigurationId
     * @description 
     * The id of the report section this text is part of.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportSectionConfigurationId: number = 0;

    /**
     * @name CaseId
     * @description 
     * The id of the case this belongs to.
     * @servertype Int64
     * @type {number}
     */
    CaseId: number = 0;

    /**
     * @name AssetId
     * @description 
     * The asset id of the asset library text entry.
     * @servertype Int64
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name Selected
     * @description 
     * In the context of a case this is true if the library text was selected to be included in the report.
     * @servertype Boolean
     * @type {boolean}
     */
    Selected: boolean = false;

    /**
     * @name AutoSelect
     * @description 
     * When true the library entry is configured to be automatically selected when suggested.  This is true when the library entry
     * priority is greater than zero.
     * @servertype Boolean
     * @type {boolean}
     */
    AutoSelect: boolean = false;

    /**
     * @name LocalOnlyText
     * @description 
     * If this text was either added to the case without being added to the library or if text that was from the library was edited
     * but only for this case without updating the library then this is set to true.  When this is true if the AssetId is 0 then
     * the text was added locally.  When an AssetId is supplied then the text was edited locally.
     * @servertype Boolean
     * @type {boolean}
     */
    LocalOnlyText: boolean = false;

    /**
     * @name DisplayOrder
     * @description 
     * In scenarios where not stored as part of a sorted collection of library text this value holds the order to be applied.
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name InternalDisplayOrder
     * @description 
     * The order to display the library text in when using a display ordered interface.  Note that scoring will typically take precedence
     * over display order and display order may only apply to items that have the same score.
     * @servertype Int32
     * @type {number}
     */
    InternalDisplayOrder: number = 0;

    /**
     * @name ExternalTextId
     * @description 
     * The external id of the library text entry.
     * @servertype String
     * @type {string}
     */
    ExternalTextId: string = "";

    /**
     * @name Title
     * @description 
     * The title of the library text entry.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name FriendlyName
     * @description 
     * The friendly name of the library text entry.
     * @servertype String
     * @type {string}
     */
    FriendlyName: string = "";

    /**
     * @name FileType
     * @servertype String
     * @type {string}
     */
    FileType: string = "";

    /**
     * @name SizeBytes
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SizeBytes: number = 0;

    /**
     * @name SizeOther
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SizeOther: number = 0;

    /**
     * @name SizeInformation
     * @servertype String
     * @type {string}
     */
    SizeInformation: string = "";

    /**
     * @name ContentCategory
     * @description 
     * The content category provides the ability to mark library entries as referring to a specific document type which can then
     * be referenced in other parts of a report.  For example, knowing that one of the documents reviewed was a weather document
     * can allow another part of the report to reference that document.
     * @servertype String
     * @type {string}
     */
    ContentCategory: string = "";

    /**
     * @name Visibility
     * @servertype String
     * @type {string}
     */
    Visibility: string = "";

    /**
     * @name AuthorLongName
     * @description 
     * The long name of the author of this library text.
     * @servertype String
     * @type {string}
     */
    AuthorLongName: string = "";

    /**
     * @name AuthorShortName
     * @description 
     * The long name of the author of this library text.
     * @servertype String
     * @type {string}
     */
    AuthorShortName: string = "";

    /**
     * @name AuthorContactInformation
     * @description 
     * Contact information for the author of this library text.
     * @servertype String
     * @type {string}
     */
    AuthorContactInformation: string = "";

    /**
     * @name Copyright
     * @description 
     * The copyright for this library text.
     * @servertype String
     * @type {string}
     */
    Copyright: string = "";

    /**
     * @name License
     * @description 
     * The license that applies to this library text.  This is typically only applicable to library text from industry libraries.
     * @servertype String
     * @type {string}
     */
    License: string = "";

    /**
     * @name LicenseUrl
     * @description 
     * The url for this license.  This is often empty when not a custom license.
     * @servertype String
     * @type {string}
     */
    LicenseUrl: string = "";

    /**
     * @name LicenseAnonymous
     * @description 
     * True indicates the licensor has exercised their right to be anonymous from any attribution.
     * @servertype Boolean
     * @type {boolean}
     */
    LicenseAnonymous: boolean = false;

    /**
     * @name Tags
     * @description 
     * A list of tags that are part of this library entry.
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name Property01
     * @description 
     * A selection property used for this library text entry.  This value here and meaning depends on the library group this entry
     * is part of.
     * @servertype String
     * @type {string}
     */
    Property01: string = "";

    /**
     * @name Value01
     * @description 
     * A selection value used for this library text entry.  The corresponding property gives context to this value and depends on
     * the library group this entry is part of.
     * @servertype String
     * @type {string}
     */
    Value01: string = "";

    /**
     * @name Property02
     * @description 
     * A selection property used for this library text entry.  This value here and meaning depends on the library group this entry
     * is part of.
     * @servertype String
     * @type {string}
     */
    Property02: string = "";

    /**
     * @name Value02
     * @description 
     * A selection value used for this library text entry.  The corresponding property gives context to this value and depends on
     * the library group this entry is part of.
     * @servertype String
     * @type {string}
     */
    Value02: string = "";

    /**
     * @name Property03
     * @description 
     * A selection property used for this library text entry.  This value here and meaning depends on the library group this entry
     * is part of.
     * @servertype String
     * @type {string}
     */
    Property03: string = "";

    /**
     * @name Value03
     * @description 
     * A selection value used for this library text entry.  The corresponding property gives context to this value and depends on
     * the library group this entry is part of.
     * @servertype String
     * @type {string}
     */
    Value03: string = "";

    /**
     * @name Property04
     * @description 
     * A selection property used for this library text entry.  This value here and meaning depends on the library group this entry
     * is part of.
     * @servertype String
     * @type {string}
     */
    Property04: string = "";

    /**
     * @name Value04
     * @description 
     * A selection value used for this library text entry.  The corresponding property gives context to this value and depends on
     * the library group this entry is part of.
     * @servertype String
     * @type {string}
     */
    Value04: string = "";

    /**
     * @name Property05
     * @description 
     * A selection property used for this library text entry.  This value here and meaning depends on the library group this entry
     * is part of.
     * @servertype String
     * @type {string}
     */
    Property05: string = "";

    /**
     * @name Value05
     * @description 
     * A selection value used for this library text entry.  The corresponding property gives context to this value and depends on
     * the library group this entry is part of.
     * @servertype String
     * @type {string}
     */
    Value05: string = "";

    /**
     * @name Property06
     * @description 
     * A selection property used for this library text entry.  This value here and meaning depends on the library group this entry
     * is part of.
     * @servertype String
     * @type {string}
     */
    Property06: string = "";

    /**
     * @name Value06
     * @description 
     * A selection value used for this library text entry.  The corresponding property gives context to this value and depends on
     * the library group this entry is part of.
     * @servertype String
     * @type {string}
     */
    Value06: string = "";

    /**
     * @name Property07
     * @description 
     * A selection property used for this library text entry.  This value here and meaning depends on the library group this entry
     * is part of.
     * @servertype String
     * @type {string}
     */
    Property07: string = "";

    /**
     * @name Value07
     * @description 
     * A selection value used for this library text entry.  The corresponding property gives context to this value and depends on
     * the library group this entry is part of.
     * @servertype String
     * @type {string}
     */
    Value07: string = "";

    /**
     * @name Property08
     * @description 
     * A selection property used for this library text entry.  This value here and meaning depends on the library group this entry
     * is part of.
     * @servertype String
     * @type {string}
     */
    Property08: string = "";

    /**
     * @name Value08
     * @description 
     * A selection value used for this library text entry.  The corresponding property gives context to this value and depends on
     * the library group this entry is part of.
     * @servertype String
     * @type {string}
     */
    Value08: string = "";

    /**
     * @name Property09
     * @description 
     * A selection property used for this library text entry.  This value here and meaning depends on the library group this entry
     * is part of.
     * @servertype String
     * @type {string}
     */
    Property09: string = "";

    /**
     * @name Value09
     * @description 
     * A selection value used for this library text entry.  The corresponding property gives context to this value and depends on
     * the library group this entry is part of.
     * @servertype String
     * @type {string}
     */
    Value09: string = "";

    /**
     * @name Property10
     * @description 
     * A selection property used for this library text entry.  This value here and meaning depends on the library group this entry
     * is part of.
     * @servertype String
     * @type {string}
     */
    Property10: string = "";

    /**
     * @name Value10
     * @description 
     * A selection value used for this library text entry.  The corresponding property gives context to this value and depends on
     * the library group this entry is part of.
     * @servertype String
     * @type {string}
     */
    Value10: string = "";

    /**
     * @name FavoriteId
     * @description 
     * If this library text has been marked as a favorite by the current user this is the id of that favorite entry.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FavoriteId: number = 0;

    /**
     * @name FavoriteRank
     * @description 
     * The ranking assigned to this library text due to its favorite status.
     * @servertype Int32
     * @type {number}
     */
    FavoriteRank: number = 0;

    /**
     * @name FavoriteRankMaxNatural
     * @description 
     * The maximum natural ranking for items suggested with this item used to help calculate natural ranking.
     * @servertype Int32
     * @type {number}
     */
    FavoriteRankMaxNatural: number = 0;

    /**
     * @name FavoriteRankOrganization
     * @description 
     * The ranking assigned to this library text due to its favorite status for the organization.
     * @servertype Int32
     * @type {number}
     */
    FavoriteRankOrganization: number = 0;

    /**
     * @name FavoriteRankMaxNaturalOrganization
     * @description 
     * The maximum natural organization ranking for items suggested with this item used to help calculate natural organization ranking.
     * @servertype Int32
     * @type {number}
     */
    FavoriteRankMaxNaturalOrganization: number = 0;

    /**
     * @name FavoriteRankIndustry
     * @description 
     * The ranking assigned to this library text due to its favorite status for the industry.
     * @servertype Int32
     * @type {number}
     */
    FavoriteRankIndustry: number = 0;

    /**
     * @name FavoriteRankMaxNaturalIndustry
     * @description 
     * The maximum natural industry ranking for items suggested with this item used to help calculate natural industry ranking.
     * @servertype Int32
     * @type {number}
     */
    FavoriteRankMaxNaturalIndustry: number = 0;

    /**
     * @name MatchRank
     * @description 
     * The ranking assigned to this library text due to the strength of matching search criteria that was provided.
     * @servertype Int32
     * @type {number}
     */
    MatchRank: number = 0;

    /**
     * @name MatchRankReason
     * @description 
     * The reason the specified match rank was used.
     * @servertype String
     * @type {string}
     */
    MatchRankReason: string = "";

    /**
     * @name Score
     * @description 
     * The score for this library text between -100 and 100 based on a combination of match rank and favorite rank.
     * @servertype Int32
     * @type {number}
     */
    Score: number = 0;

    /**
     * @name ScoreCalculation
     * @description 
     * A description as to how the score was calculated.
     * @servertype String
     * @type {string}
     */
    ScoreCalculation: string = "";

    /**
     * @name ScoreCalculationInformation
     * @description 
     * Meta data explaining how the score was calculated.
     * @servertype ReportCompilerLibraryEntryScoreCalculationModel array
     * @type {ReportCompilerLibraryEntryScoreCalculationModel[]}
     */
    ScoreCalculationInformation: ReportCompilerLibraryEntryScoreCalculationModel[] = [];

    /**
     * @name ReasonSuggested
     * @description 
     * A description as to why this library text was suggested.
     * @servertype String
     * @type {string}
     */
    ReasonSuggested: string = "";

    /**
     * @name ReasonSuggestedInformation
     * @description 
     * Meta data explaining why this library text was suggested.
     * @servertype ReportCompilerLibraryEntryReasonSuggestedModel array
     * @type {ReportCompilerLibraryEntryReasonSuggestedModel[]}
     */
    ReasonSuggestedInformation: ReportCompilerLibraryEntryReasonSuggestedModel[] = [];

    /**
     * @name Approval
     * @description 
     * The approval status of the text.  For non-industry libraries the expected value is N/A.  For industry libraries the expected
     * values are: Rejected (when requested approval was rejected), Automatic (when industry library entries do not require approval),
     * Approved (when industry library text was approved), Requested-x (when industry library text approval was requested where
     * x is a csv of contact id values who requested the approval), CanRequest (not requested yet but available to be requested),
     * Unknown (when unable to determine the approval status typically due to an error).
     * @servertype String
     * @type {string}
     */
    Approval: string = "";

    /**
     * @name Text
     * @description 
     * The text of the library entry.
     * @servertype String
     * @type {string}
     */
    Text: string = "";

    /**
     * @name PreviewText
     * @description 
     * When used in the context of a case this is the text of the library entry with macro resolution based on the current context
     * of the case when last loaded.  This will not reflect real-time changes to the case only the status when the case was last
     * loaded.  Despite that limitation it's still very useful for providing a more user friendly view of what the text will look
     * like inside a report instead of viewing the text with macros.  This value is empty in any non-case context.
     * @servertype String
     * @type {string}
     */
    PreviewText: string = "";

    /**
     * @name TextBookmarkStart
     * @description 
     * A string to use as start bookmark for this text.
     * @servertype String
     * @type {string}
     */
    TextBookmarkStart: string = "";

    /**
     * @name TextBookmarkEnd
     * @description 
     * A string to use as end bookmark for this text.
     * @servertype String
     * @type {string}
     */
    TextBookmarkEnd: string = "";

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description 
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Report Attachment Configuration
 * @description
 * This contains report attachment configuration information.
 */
export class ReportCompilerReportAttachmentConfigurationModel {

    /**
     * @name ReportAttachmentConfigurationId
     * @description 
     * The id of the report attachment configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportAttachmentConfigurationId: number = 0;

    /**
     * @name OwnerResourceType
     * @description 
     * A string to indicate if this is linked to the 'CaseTemplate' or 'Cases' table.
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description 
     * The id of the case template or case this belongs to.
     * @servertype Int64
     * @type {number}
     */
    OwnerResourceId: number = 0;

    /**
     * @name Enabled
     * @description 
     * When true this attachment is enabled for the report.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name DisplayOrder
     * @description 
     * The display order for this report attachment.
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name AttachmentHeading
     * @description 
     * The attachment heading for this report attachment.
     * @servertype String
     * @type {string}
     */
    AttachmentHeading: string = "";

    /**
     * @name AttachmentDescription
     * @description 
     * The attachment description for this report attachment.
     * @servertype String
     * @type {string}
     */
    AttachmentDescription: string = "";

    /**
     * @name AttachmentSectionHeadingType
     * @description 
     * The attachment section heading type for this report attachment.
     * @servertype String
     * @type {string}
     */
    AttachmentSectionHeadingType: string = "";

    /**
     * @name AttachmentRole
     * @description 
     * The attachment role for this attachment.  Used as a fixed value that can be used for macro checking, etc.
     * @servertype String
     * @type {string}
     */
    AttachmentRole: string = "";

    /**
     * @name ReportAttachmentImageLayoutTemplateAssetId
     * @description 
     * The asset id of the report template to use for the report attachment image layouts.  Used to handle things like images that
     * need more than one image per page, image captions, etc.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportAttachmentImageLayoutTemplateAssetId: number = 0;

    /**
     * @name AllowUploadedAttachments
     * @description 
     * When true this attachment can included uploaded files.
     * @servertype Boolean
     * @type {boolean}
     */
    AllowUploadedAttachments: boolean = false;

    /**
     * @name AllowDocumentLibraryAttachments
     * @description 
     * When true this attachment can include files from the document library.
     * @servertype Boolean
     * @type {boolean}
     */
    AllowDocumentLibraryAttachments: boolean = false;

    /**
     * @name UseLibrarySelectionCriteria
     * @description 
     * When true library document retrieval should be done via library selection criteria.
     * @servertype Boolean
     * @type {boolean}
     */
    UseLibrarySelectionCriteria: boolean = false;

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description 
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Report Section Configuration
 * @description
 * This contains report section configuration information.
 */
export class ReportCompilerReportSectionConfigurationModel {

    /**
     * @name ReportSectionConfigurationId
     * @description 
     * The id of the report section configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportSectionConfigurationId: number = 0;

    /**
     * @name OwnerResourceType
     * @description 
     * A string to indicate if this is linked to the 'CaseTemplate' or 'Cases' table.
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description 
     * The id of the case template or case this belongs to.
     * @servertype Int64
     * @type {number}
     */
    OwnerResourceId: number = 0;

    /**
     * @name Enabled
     * @description 
     * When true this section is enabled for the report.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name DisplayOrder
     * @description 
     * The display order for this report section.
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name SectionHeading
     * @description 
     * The section heading for this report section.
     * @servertype String
     * @type {string}
     */
    SectionHeading: string = "";

    /**
     * @name SubsectionHeadingType
     * @description 
     * A subsection heading type that can be used for grouping within a section.
     * @servertype String
     * @type {string}
     */
    SubsectionHeadingType: string = "";

    /**
     * @name SectionRole
     * @description 
     * The section role for this section.  Used as a fixed value that can be used for macro checking, etc. that is less volatile
     * than the section header.
     * @servertype String
     * @type {string}
     */
    SectionRole: string = "";

    /**
     * @name LibraryCategory
     * @description 
     * The library category that identifies library text that is appropriate for inclusion in this section.
     * @servertype String
     * @type {string}
     */
    LibraryCategory: string = "";

    /**
     * @name UseLibraryTextSelectionCriteria
     * @description 
     * When true library text retrieval should be done via library selection criteria.
     * @servertype Boolean
     * @type {boolean}
     */
    UseLibraryTextSelectionCriteria: boolean = false;

    /**
     * @name AutomaticallySuggestLibraryText
     * @description 
     * When true library text should be automatically suggested on a new case.  This is typically false when using text selection
     * criteria for the section.
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticallySuggestLibraryText: boolean = false;

    /**
     * @name AutomaticallySelectLibraryText
     * @description 
     * Flag if library text should be automatically selected upon suggestion.  Possible values include:
     * A = Always
     * N = Never
     * L = Library (If library entry configured for automatic selection.)
     * O = One (If only one suggestion was provided then auto select it.)
     * E = Either (If only one suggestion or library entry configured for automatic selection then auto select it.)
     * @servertype String
     * @type {string}
     */
    AutomaticallySelectLibraryText: string = "";

    /**
     * @name LibraryTextColumnsUseDefaultConfiguration
     * @description 
     * When true the library text columns displayed are the system determined defaults for the section.
     * @servertype Boolean
     * @type {boolean}
     */
    LibraryTextColumnsUseDefaultConfiguration: boolean = false;

    /**
     * @name LibraryTextSort
     * @description 
     * The sort to apply to library text results.  The typical desired value is '-Score' for score in descending order.
     * @servertype String
     * @type {string}
     */
    LibraryTextSort: string = "";

    /**
     * @name LibraryTextColumns
     * @description 
     * A list of library text columns to use when not using the defaults for the section.
     * @servertype ReportCompilerReportSectionConfigurationTextColumnModel array
     * @type {ReportCompilerReportSectionConfigurationTextColumnModel[]}
     */
    LibraryTextColumns: ReportCompilerReportSectionConfigurationTextColumnModel[] = [];

    /**
     * @name ParagraphNumberingStyle
     * @description 
     * The paragraph numbering style to use.  Possible values include:
     * Empty = no numbering
     * 1. or 1) = number
     * A. or A) = upper case letter
     * a. or a) = lower case letter
     * I. or I) = upper case roman numeral
     * i. or i) = lower case roman numeral
     * @servertype String
     * @type {string}
     */
    ParagraphNumberingStyle: string = "";

    /**
     * @name OpeningParagraphs
     * @description 
     * A list of static opening paragraphs to use for this section.
     * @servertype string array
     * @type {string[]}
     */
    OpeningParagraphs: string[] = [];

    /**
     * @name PreviewOpeningParagraphs
     * @description 
     * A preview of static opening paragraphs to use for this section with any macro values replaced.
     * @servertype string array
     * @type {string[]}
     */
    PreviewOpeningParagraphs: string[] = [];

    /**
     * @name OpeningParagraphsUseParagraphNumbering
     * @description 
     * When true opening paragraphs follow the paragraph numbering style selected for the section.
     * @servertype Boolean
     * @type {boolean}
     */
    OpeningParagraphsUseParagraphNumbering: boolean = false;

    /**
     * @name ClosingParagraphs
     * @description 
     * A list of static closing paragraphs to use for this section.
     * @servertype string array
     * @type {string[]}
     */
    ClosingParagraphs: string[] = [];

    /**
     * @name PreviewClosingParagraphs
     * @description 
     * A preview of static closing paragraphs to use for this section with any macro values replaced.
     * @servertype string array
     * @type {string[]}
     */
    PreviewClosingParagraphs: string[] = [];

    /**
     * @name ClosingParagraphsUseParagraphNumbering
     * @description 
     * When true closing paragraphs follow the paragraph numbering style selected for the section.
     * @servertype Boolean
     * @type {boolean}
     */
    ClosingParagraphsUseParagraphNumbering: boolean = false;

    /**
     * @name SectionEnableTriggers
     * @description 
     * A list of triggers that would cause this section to become enabled.
     * @servertype string array
     * @type {string[]}
     */
    SectionEnableTriggers: string[] = [];

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description 
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Report Section Configuration Text Column
 * @description
 * This contains report section configuration text column information.
 */
export class ReportCompilerReportSectionConfigurationTextColumnModel {

    /**
     * @name Enabled
     * @description 
     * When true this column is enabled for display.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name DisplayOrder
     * @description 
     * The display order for this column.
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name HeadingText
     * @description 
     * The heading text for this column.
     * @servertype String
     * @type {string}
     */
    HeadingText: string = "";

    /**
     * @name HeadingIcon
     * @description 
     * The heading icon for this column.
     * @servertype String
     * @type {string}
     */
    HeadingIcon: string = "";

    /**
     * @name PropertyName
     * @description 
     * The property name of the data to display for this column.
     * @servertype String
     * @type {string}
     */
    PropertyName: string = "";

}



/**
 * @name Case Template Contact
 * @description
 * This holds information about the structure for case contacts.
 */
export class ReportCompilerCaseTemplateContactModel {

    /**
     * @name CaseContactRole
     * @description 
     * The role filled by this contact.
     * @servertype String
     * @type {string}
     */
    CaseContactRole: string = "";

    /**
     * @name ContactAddressTypeLink
     * @description 
     * The contact address type that links to this contact role.  When specified the contact is not a customer, directory, group
     * or location type and is instead a contact with this specified address type under the main case contact.
     * @servertype String
     * @type {string}
     */
    ContactAddressTypeLink: string = "";

    /**
     * @name ContactAddressTypeCandidates
     * @description 
     * A list of contact address types that this contact role could be part of.  Each customer contact has only one address type
     * but in some scenarios the contact could be one of several address types.  For example, a property location may often be the
     * same as the property owner.  Instead of having the same contact listed twice we allow contacts of any of the address types
     * in this list to be used.  When a new contact the address type in ContactAddressTypeLink is used.
     * @servertype string array
     * @type {string[]}
     */
    ContactAddressTypeCandidates: string[] = [];

    /**
     * @name CaseContactLink
     * @description 
     * The file contact that links to this contact role.
     * @servertype String
     * @type {string}
     */
    CaseContactLink: string = "";

    /**
     * @name Required
     * @description 
     * When true this is a required contact.
     * @servertype Boolean
     * @type {boolean}
     */
    Required: boolean = false;

    /**
     * @name CanBeCustomer
     * @description 
     * When true this contact can be a customer.
     * @servertype Boolean
     * @type {boolean}
     */
    CanBeCustomer: boolean = false;

    /**
     * @name CanBeCustomerContact
     * @description 
     * When true this contact can be a customer contact.
     * @servertype Boolean
     * @type {boolean}
     */
    CanBeCustomerContact: boolean = false;

    /**
     * @name CanBeDirectory
     * @description 
     * When true this contact can be a directory user.
     * @servertype Boolean
     * @type {boolean}
     */
    CanBeDirectory: boolean = false;

    /**
     * @name CanBeGroup
     * @description 
     * When true this contact can be a group.
     * @servertype Boolean
     * @type {boolean}
     */
    CanBeGroup: boolean = false;

    /**
     * @name CanBeLocation
     * @description 
     * When true this contact can be a location.
     * @servertype Boolean
     * @type {boolean}
     */
    CanBeLocation: boolean = false;

    /**
     * @name DefaultContactType
     * @description 
     * The default contact type for contacts that are newly added.  Possible values include:
     * C = Customer
     * T = Customer Contact
     * D = Directory
     * G = Group
     * L = Location
     * @servertype String
     * @type {string}
     */
    DefaultContactType: string = "";

    /**
     * @name OnCaseCloseSanitizeIfCustomer
     * @description 
     * When true and the contact is a customer it is sanitized when the case is closed.  Note that this will result in loss of data
     * about the customer and should not be used if customer records are reused or need to be retained.
     * @servertype Boolean
     * @type {boolean}
     */
    OnCaseCloseSanitizeIfCustomer: boolean = false;

    /**
     * @name OnCaseCloseSanitizeIfCustomerContact
     * @description 
     * When true and the contact is a customer contact it is sanitized when the case is closed.  Note that this will result in loss
     * of data about the customer contact and should not be used if customer contact records are reused or need to be retained.
     * @servertype Boolean
     * @type {boolean}
     */
    OnCaseCloseSanitizeIfCustomerContact: boolean = false;

    /**
     * @name PromptForContactCaseNumber
     * @description 
     * When true the contact is able to have it's own case number associated with a case.
     * @servertype Boolean
     * @type {boolean}
     */
    PromptForContactCaseNumber: boolean = false;

}



/**
 * @name ReportCompilerCaseContactLinkType Enum
 * @readonly
 * @enum {number}
 */
export enum ReportCompilerCaseContactLinkType {

    /**
     * No case contact link.
     */
    Empty = 0,

    /**
     * This is the main case contact point.  Typically the customer for the case.
     */
    Contact = 1,

    /**
     * This is the case site contact.
     */
    Site = 2,

    /**
     * This is the case opened by contact.  When there is no opened by contact new cases are opened by the main contact.
     */
    OpenedBy = 3,

    /**
     * This is the case owned by contact.  When there is no owned by contact new cases are owned by the opener.
     */
    OwnedBy = 4,

    /**
     * This is the case assigned to contact.  When there is no assigned to contact new cases are assigned to the owner.
     */
    AssignedTo = 5,

    /**
     * This is the case supervisor.  When there is no supervisor contact new cases are assigned to the owner's supervisor.
     */
    Supervisor = 6

}



/**
 * @name Case Customization
 * @description
 * This contains case customization settings for cases for a given case template.
 */
export class ReportCompilerCaseCustomizationModel {

    /**
     * @name ReportTemplateCanChange
     * @description 
     * If true the report template can be changed.
     * @servertype Boolean
     * @type {boolean}
     */
    ReportTemplateCanChange: boolean = false;

    /**
     * @name ReportAttachmentCoverPageTemplateCanChange
     * @description 
     * If true the report attachment cover page template can be changed.
     * @servertype Boolean
     * @type {boolean}
     */
    ReportAttachmentCoverPageTemplateCanChange: boolean = false;

    /**
     * @name AttachmentNumberingStyleCanChange
     * @description 
     * If true the report attachment numbering style can be changed.
     * @servertype Boolean
     * @type {boolean}
     */
    AttachmentNumberingStyleCanChange: boolean = false;

    /**
     * @name ReportSectionCanChangeOptions
     * @description 
     * If true the report section options for the case can be modified.
     * @servertype Boolean
     * @type {boolean}
     */
    ReportSectionCanChangeOptions: boolean = false;

    /**
     * @name ReportSectionCanAdd
     * @description 
     * If true new report sections can be added.
     * @servertype Boolean
     * @type {boolean}
     */
    ReportSectionCanAdd: boolean = false;

    /**
     * @name ReportSectionCanReorder
     * @description 
     * If true the report sections can be reordered.
     * @servertype Boolean
     * @type {boolean}
     */
    ReportSectionCanReorder: boolean = false;

    /**
     * @name ReportAttachmentCanChangeOptions
     * @description 
     * If true the report attachment options for the case can be modified.
     * @servertype Boolean
     * @type {boolean}
     */
    ReportAttachmentCanChangeOptions: boolean = false;

    /**
     * @name ReportAttachmentCanAdd
     * @description 
     * If true new report attachments can be added.
     * @servertype Boolean
     * @type {boolean}
     */
    ReportAttachmentCanAdd: boolean = false;

    /**
     * @name ReportAttachmentCanReorder
     * @description 
     * If true the report attachments can be reordered.
     * @servertype Boolean
     * @type {boolean}
     */
    ReportAttachmentCanReorder: boolean = false;

}



/**
 * @name PDF Options
 * @description
 * This contains options for PDF report output.
 */
export class ReportCompilerPdfOptionsModel {

    /**
     * @name BookmarkLevel
     * @description 
     * Bookmark level to use when creating PDF.  Possible values include:
     * 0 = None
     * 1 = Report and Attachments
     * 2 = Report Contents and Attachments
     * 3 = Report Contents and Attachment Contents
     * @servertype Int32
     * @type {number}
     */
    BookmarkLevel: number = 0;

    /**
     * @name SecurityEnforcedByTemplate
     * @description 
     * When true PDF security is enforced by the template and cannot be changed.
     * @servertype Boolean
     * @type {boolean}
     */
    SecurityEnforcedByTemplate: boolean = false;

    /**
     * @name ApplyDigitalSignature
     * @description 
     * When true a digital signature will be applied to the final report PDF document.
     * @servertype Boolean
     * @type {boolean}
     */
    ApplyDigitalSignature: boolean = false;

    /**
     * @name UseUserCertificateForDigitalSignature
     * @description 
     * When true a user certificate will be used for the digital signature applied to the final report PDF document.
     * @servertype Boolean
     * @type {boolean}
     */
    UseUserCertificateForDigitalSignature: boolean = false;

    /**
     * @name DigitalSignatureAssetId
     * @description 
     * The asset id of the pdf digital signature to use.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DigitalSignatureAssetId: number = 0;

    /**
     * @name OpenPassword
     * @description 
     * The password required for opening the final PDF report.
     * @servertype String
     * @type {string}
     */
    OpenPassword: string = "";

    /**
     * @name PermissionsPassword
     * @description 
     * The password required for editing restricted parts of the final PDF report.
     * @servertype String
     * @type {string}
     */
    PermissionsPassword: string = "";

    /**
     * @name EncryptionLevel
     * @description 
     * The level of encryption to use for the final PDF report.  Valid choices are 128 or 256.
     * @servertype Int32
     * @type {number}
     */
    EncryptionLevel: number = 0;

    /**
     * @name PermitAccessibilityExtractContent
     * @description 
     * When true accessibility features are allowed to extract content from the final PDF report.
     * @servertype Boolean
     * @type {boolean}
     */
    PermitAccessibilityExtractContent: boolean = false;

    /**
     * @name PermitAnnotations
     * @description 
     * When true annotations are allowed in the final PDF report.
     * @servertype Boolean
     * @type {boolean}
     */
    PermitAnnotations: boolean = false;

    /**
     * @name PermitAssembleDocument
     * @description 
     * When true manipulating the document is allowed in the final PDF report.
     * @servertype Boolean
     * @type {boolean}
     */
    PermitAssembleDocument: boolean = false;

    /**
     * @name PermitExtractContent
     * @description 
     * When true extracting content is allowed in the final PDF report.
     * @servertype Boolean
     * @type {boolean}
     */
    PermitExtractContent: boolean = false;

    /**
     * @name PermitModifyDocument
     * @description 
     * When true modifying is allowed in the final PDF report.
     * @servertype Boolean
     * @type {boolean}
     */
    PermitModifyDocument: boolean = false;

    /**
     * @name PermitFormsFill
     * @description 
     * When true forms can be filled in the final PDF report.
     * @servertype Boolean
     * @type {boolean}
     */
    PermitFormsFill: boolean = false;

    /**
     * @name PermitFullQualityPrint
     * @description 
     * When true full quality printing is allowed with the final PDF report.
     * @servertype Boolean
     * @type {boolean}
     */
    PermitFullQualityPrint: boolean = false;

    /**
     * @name PermitPrint
     * @description 
     * When true standard quality printing is allowed with the final PDF report.
     * @servertype Boolean
     * @type {boolean}
     */
    PermitPrint: boolean = false;

}



/**
 * @name ReportCompilerConstants
 */
export module ReportCompilerConstants {
    export var SanitizeCaseRemoveSubjectMatter: string = "SanitizeCaseRemoveSubjectMatter";
    export var SanitizeCaseRemoveReportSections: string = "SanitizeCaseRemoveReportSections";
    export var SanitizeCaseRemoveReportAttachments: string = "SanitizeCaseRemoveReportAttachments";
    export var SanitizeCaseLearnFavorites: string = "SanitizeCaseLearnFavorites";
    export var SupportCaseFeatureWarningWhenReportSectionEmpty: string = "WarningWhenReportSectionEmpty";
    export var SupportCaseFeatureRespectCaseReviewResultPermissions: string = "RespectCaseReviewResultPermissions";
    export var SubjectMatterTypeLibrarySelectionRules: string = "LibrarySelectionRules";
    export var SubjectMatterTypeStructuralProperty: string = "StructuralProperty";
    export var LibraryTypeOther: string = "Other";
    export var LibraryTypeStructural: string = "Structural";
    export var ModuleLibraryPersonal: string = "rc-library-personal";
    export var ModuleLibraryIndustry: string = "rc-library-industry";
    export var ModuleLibraryOrganization: string = "rc-library-organization";
    export var ModuleLibraryInsights: string = "rc-library-insights";
    export var ModuleLibraryExport: string = "rc-library-export";
    export var ModuleManagementInsights: string = "rc-management-insights";
    export var ModuleReportParser: string = "rc-report-parser";
    export var ModuleReportFinalPdf: string = "rc-report-final-pdf";
    export var ModuleWorkflow: string = "rc-workflow";
    export var ScoreMatchExact: string = "100";
    export var ScoreMatchPartial: string = "50";
    export var ScoreMatchAlways: string = "25";
    export var ScoreMatchSearchFoundInTag: string = "10";
    export var ScoreMatchSearchFoundInLibrary: string = "5";
    export var ScoreFavLove: string = "100";
    export var ScoreFavLike: string = "50";
    export var ScoreFavDislike: string = "0";
    export var ScoreFavBlock: string = "-100";
    export var ScoreFavNaturalDefault: string = "5";
    export var ScoreFavNaturalMax: string = "50";
}



/**
 * @name Case Options
 * @description
 * This contains options for case and case template.
 */
export class ReportCompilerCaseOptionsModel {

    /**
     * @name CaseOptionsId
     * @description 
     * The id of the case options object.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseOptionsId: number = 0;

    /**
     * @name OwnerResourceType
     * @description 
     * A string to indicate if this is linked to the 'CaseTemplate' or 'Cases' table.
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description 
     * The id of the case template or case this belongs to.
     * @servertype Int64
     * @type {number}
     */
    OwnerResourceId: number = 0;

    /**
     * @name ReportTemplateAssetId
     * @description 
     * The asset id of the report template to use for the report.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportTemplateAssetId: number = 0;

    /**
     * @name ReportAttachmentCoverPageTemplateAssetId
     * @description 
     * The asset id of the report template to use for the report attachment cover page.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportAttachmentCoverPageTemplateAssetId: number = 0;

    /**
     * @name AttachmentNumberingStyle
     * @description 
     * The attachment numbering style to use.  Possible values include:
     * Empty = no numbering
     * 1 = number
     * A = upper case letter
     * a = lower case letter
     * I = upper case roman numeral
     * i = lower case roman numeral
     * @servertype String
     * @type {string}
     */
    AttachmentNumberingStyle: string = "";

    /**
     * @name PdfOptions
     * @description 
     * PDF options for report output.
     * @servertype IB.Web.API.Models.v5.ReportCompilerPdfOptionsModel
     * @type {ReportCompilerPdfOptionsModel}
     */
    PdfOptions: ReportCompilerPdfOptionsModel = null;

    /**
     * @name TemplateTypeId
     * @description 
     * The template type id of the template type for this case template.  This is a case template value only.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TemplateTypeId: number = 0;

    /**
     * @name CaseCustomization
     * @description 
     * Case customization for cases for this case template.  This is a case template value only.
     * @servertype IB.Web.API.Models.v5.ReportCompilerCaseCustomizationModel
     * @type {ReportCompilerCaseCustomizationModel}
     */
    CaseCustomization: ReportCompilerCaseCustomizationModel = null;

    /**
     * @name CaseContacts
     * @description 
     * List of contacts for cases for this case template.  This is a case template value only.
     * @servertype ReportCompilerCaseTemplateContactModel array
     * @type {ReportCompilerCaseTemplateContactModel[]}
     */
    CaseContacts: ReportCompilerCaseTemplateContactModel[] = [];

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description 
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Template Type Configuration
 * @description
 * This contains information about template type configuration.
 */
export class ReportCompilerTemplateTypeConfigurationModel {

    /**
     * @name TemplateTypeId
     * @description 
     * The id of the template type.
     * @servertype Int64
     * @type {number}
     */
    TemplateTypeId: number = 0;

    /**
     * @name TemplateType
     * @description 
     * The name of the template type.
     * @servertype String
     * @type {string}
     */
    TemplateType: string = "";

    /**
     * @name Enabled
     * @description 
     * Flag if this template type is enabled.  Note there always needs to be at least one enabled template type and that template
     * types that are referenced by active case templates cannot be disabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name LibraryGroupId
     * @description 
     * The id of the library group used for this template type.  Note that multiple template types can use the same library group.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    LibraryGroupId: number = 0;

    /**
     * @name SubjectMatterType
     * @description 
     * The subject matter type for this template type.  This is a system defined value that is used for front-end and back-end processing.
     * @servertype String
     * @type {string}
     */
    SubjectMatterType: string = "";

    /**
     * @name SubjectMatterLabel
     * @description 
     * The label to use for the subject matter.  For example, structural subject matter is often labeled 'Property' but can be customized
     * here.
     * @servertype String
     * @type {string}
     */
    SubjectMatterLabel: string = "";

    /**
     * @name SubjectMatterMinimumItemCount
     * @description 
     * The minimum number of subject matter items to allow.  The minimum is 1.
     * @servertype Int32
     * @type {number}
     */
    SubjectMatterMinimumItemCount: number = 0;

    /**
     * @name SubjectMatterMaximumItemCount
     * @description 
     * The maximum number of subject matter items to allow.  The minimum is 1.
     * @servertype Int32
     * @type {number}
     */
    SubjectMatterMaximumItemCount: number = 0;

    /**
     * @name IncludeTags
     * @description 
     * When true, subject matter includes tags.
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeTags: boolean = false;

    /**
     * @name SubjectMatterPickListIds
     * @description 
     * A list of pick list ids that are used for the subject matter for this template.  These can be shared with other library groups,
     * template types, etc.   Note that the actual list is stored with a prefix of 'RC.' to avoid collision with any system defined
     * pick lists.
     * @servertype string array
     * @type {string[]}
     */
    SubjectMatterPickListIds: string[] = [];

    /**
     * @name SubjectMatterAttributeSetId
     * @description 
     * The attribute set id used for definition of the data that is part of the subject matter for this template type.  Note that
     * the subject matter itself is not stored with this attribute set this simply defines the data model.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SubjectMatterAttributeSetId: number = 0;

    /**
     * @name SubjectMatterFormId
     * @description 
     * The form id used for input/view for subject matter of this template type.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SubjectMatterFormId: number = 0;

    /**
     * @name SubjectMatterAssetId
     * @description 
     * The asset id used for input/view for subject matter of this template type.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SubjectMatterAssetId: number = 0;

    /**
     * @name SubjectMatterProperties
     * @description 
     * Properties related to the subject matter.  The format of this object may differ based on subject matter type.
     * @servertype System.Object
     * @type {any}
     */
    SubjectMatterProperties: any = {};

    /**
     * @name LearnImageClassificationLastTrainingDateTime
     * @description 
     * The date and time when image classification training was last performed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LearnImageClassificationLastTrainingDateTime: Date = null;

    /**
     * @name LearnImageClassificationLastTrainingResultCode
     * @description 
     * The result code from most recent image classification training.
     * @servertype Int32
     * @type {number}
     */
    LearnImageClassificationLastTrainingResultCode: number = 0;

    /**
     * @name LearnImageClassificationLastTrainingResultMessage
     * @description 
     * The result message from most recent image classification training.
     * @servertype String
     * @type {string}
     */
    LearnImageClassificationLastTrainingResultMessage: string = "";

    /**
     * @name LearnImageClassificationLastTrainingExampleCount
     * @description 
     * When image classification training was last performed this was the count of examples for each classification.
     * @servertype ReportCompilerImageClassificationExampleModel array
     * @type {ReportCompilerImageClassificationExampleModel[]}
     */
    LearnImageClassificationLastTrainingExampleCount: ReportCompilerImageClassificationExampleModel[] = [];

    /**
     * @name Private
     * @description 
     * Flag if this item is private.
     * @servertype Boolean
     * @type {boolean}
     */
    Private: boolean = false;

    /**
     * @name Comments
     * @description 
     * Comments for this template type.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description 
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Image Classification Example Data
 * @description
 * This contains information about image classification examples.
 */
export class ReportCompilerImageClassificationExampleModel {

    /**
     * @name Classification
     * @description 
     * The name of the classification.
     * @servertype String
     * @type {string}
     */
    Classification: string = "";

    /**
     * @name ExampleCount
     * @description 
     * The count of the number of example images for this classification.
     * @servertype Int64
     * @type {number}
     */
    ExampleCount: number = 0;

}



/**
 * @name Template Type Structural Subject Matter Properties
 * @description
 * This contains properties unique to structural subject matter.
 */
export class ReportCompilerTemplateTypeStructuralSubjectMatterPropertiesModel {

    /**
     * @name IncludeConstructedApproximateDate
     * @description 
     * When true prompt for constructed approximate date.
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeConstructedApproximateDate: boolean = false;

    /**
     * @name IncludeOwnedSinceApproximateDate
     * @description 
     * When true prompt for owned since approximate date.
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeOwnedSinceApproximateDate: boolean = false;

    /**
     * @name IncludeRenovations
     * @description 
     * When true prompt for renovations.
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeRenovations: boolean = false;

    /**
     * @name IncludeAdditions
     * @description 
     * When true prompt for additions.
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeAdditions: boolean = false;

    /**
     * @name IncludePrecedingDamages
     * @description 
     * When true prompt for preceding damages.
     * @servertype Boolean
     * @type {boolean}
     */
    IncludePrecedingDamages: boolean = false;

    /**
     * @name PropertyTypePickListId
     * @description 
     * The pick list id for the property type pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    PropertyTypePickListId: string = "";

    /**
     * @name TrueFrontDirectionPickListId
     * @description 
     * The pick list id for the true front direction pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    TrueFrontDirectionPickListId: string = "";

    /**
     * @name ReportFrontDirectionPickListId
     * @description 
     * The pick list id for the report front direction pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    ReportFrontDirectionPickListId: string = "";

    /**
     * @name StoriesPickListId
     * @description 
     * The pick list id for the stories pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    StoriesPickListId: string = "";

    /**
     * @name OverallFramingPickListId
     * @description 
     * The pick list id for the overall framing pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    OverallFramingPickListId: string = "";

    /**
     * @name OverallFoundationPickListId
     * @description 
     * The pick list id for the overall foundation pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    OverallFoundationPickListId: string = "";

    /**
     * @name RoofFramingPickListId
     * @description 
     * The pick list id for the roof framing pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    RoofFramingPickListId: string = "";

    /**
     * @name WallFramingPickListId
     * @description 
     * The pick list id for the wall framing pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    WallFramingPickListId: string = "";

    /**
     * @name FloorFramingPickListId
     * @description 
     * The pick list id for the floor framing pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    FloorFramingPickListId: string = "";

    /**
     * @name FoundationPickListId
     * @description 
     * The pick list id for the foundation pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    FoundationPickListId: string = "";

    /**
     * @name RoofingPickListId
     * @description 
     * The pick list id for the roofing pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    RoofingPickListId: string = "";

    /**
     * @name ExteriorSheathingPickListId
     * @description 
     * The pick list id for the exterior sheathing pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    ExteriorSheathingPickListId: string = "";

    /**
     * @name ExteriorInsulationPickListId
     * @description 
     * The pick list id for the exterior insulation pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    ExteriorInsulationPickListId: string = "";

    /**
     * @name ExteriorRainscreenPickListId
     * @description 
     * The pick list id for the exterior rain-screen pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    ExteriorRainscreenPickListId: string = "";

    /**
     * @name ExteriorCladdingPickListId
     * @description 
     * The pick list id for the exterior cladding pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    ExteriorCladdingPickListId: string = "";

    /**
     * @name GutterLocationPickListId
     * @description 
     * The pick list id for the gutter location pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    GutterLocationPickListId: string = "";

    /**
     * @name GutterSizePickListId
     * @description 
     * The pick list id for the gutter size pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    GutterSizePickListId: string = "";

    /**
     * @name DownspoutLocationPickListId
     * @description 
     * The pick list id for the downspout location pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    DownspoutLocationPickListId: string = "";

    /**
     * @name DownspoutSizePickListId
     * @description 
     * The pick list id for the downspout size pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    DownspoutSizePickListId: string = "";

    /**
     * @name DownspoutTerminationPickListId
     * @description 
     * The pick list id for the downspout termination pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    DownspoutTerminationPickListId: string = "";

    /**
     * @name FloorCoveringPickListId
     * @description 
     * The pick list id for the floor covering pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    FloorCoveringPickListId: string = "";

    /**
     * @name InteriorCladdingPickListId
     * @description 
     * The pick list id for the interior cladding pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    InteriorCladdingPickListId: string = "";

    /**
     * @name CausePickListId
     * @description 
     * The pick list id for the cause pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    CausePickListId: string = "";

    /**
     * @name ExteriorSubjectPickListId
     * @description 
     * The pick list id for the exterior subject pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    ExteriorSubjectPickListId: string = "";

    /**
     * @name ExteriorElementPickListId
     * @description 
     * The pick list id for the exterior element pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    ExteriorElementPickListId: string = "";

    /**
     * @name ExteriorDamagePickListId
     * @description 
     * The pick list id for the exterior damage pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    ExteriorDamagePickListId: string = "";

    /**
     * @name InteriorSubjectPickListId
     * @description 
     * The pick list id for the interior subject pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    InteriorSubjectPickListId: string = "";

    /**
     * @name InteriorElementPickListId
     * @description 
     * The pick list id for the interior element pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    InteriorElementPickListId: string = "";

    /**
     * @name InteriorDamagePickListId
     * @description 
     * The pick list id for the interior damage pick list.  If not specified this information is not requested.
     * @servertype String
     * @type {string}
     */
    InteriorDamagePickListId: string = "";

    /**
     * @name Initialized
     * @description 
     * When true the structural subject matter properties have been initialized.  When not initialized the system may decide what
     * values to assign as default values.
     * @servertype Boolean
     * @type {boolean}
     */
    Initialized: boolean = false;

}



/**
 * @name Library Group Configuration
 * @description
 * This contains information about library group configuration.
 */
export class ReportCompilerLibraryGroupConfigurationModel {

    /**
     * @name LibraryGroupId
     * @description 
     * The id of the library group.
     * @servertype Int64
     * @type {number}
     */
    LibraryGroupId: number = 0;

    /**
     * @name LibraryGroup
     * @description 
     * The name of the library group.
     * @servertype String
     * @type {string}
     */
    LibraryGroup: string = "";

    /**
     * @name Enabled
     * @description 
     * Flag if this library group is enabled.  Note there always needs to be at least one enabled library group and that library
     * groups that are referenced by active template types cannot be disabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name LibraryCategoryPickListId
     * @description 
     * The pick list id of library categories for this library group.  This can be shared with multiple library groups that have
     * the same categories.  Note that the actual list is stored with a prefix of 'RC.' to avoid collision with any system defined
     * pick lists.
     * @servertype String
     * @type {string}
     */
    LibraryCategoryPickListId: string = "";

    /**
     * @name IndustryLibraryContentAllowed
     * @description 
     * When true, access to industry library content is supported.
     * @servertype Boolean
     * @type {boolean}
     */
    IndustryLibraryContentAllowed: boolean = false;

    /**
     * @name IndustryLibraryType
     * @description 
     * The industry library type to use for this library group.
     * @servertype String
     * @type {string}
     */
    IndustryLibraryType: string = "";

    /**
     * @name IndustryLibraryContentRequiresApproval
     * @description 
     * When true, industry library content must be approved for use.
     * @servertype Boolean
     * @type {boolean}
     */
    IndustryLibraryContentRequiresApproval: boolean = false;

    /**
     * @name IndustryLibraryContentCanSeeAndRequestApproval
     * @description 
     * When true, industry library content can be requested for approval.
     * @servertype Boolean
     * @type {boolean}
     */
    IndustryLibraryContentCanSeeAndRequestApproval: boolean = false;

    /**
     * @name IndustryLibraryContentAllowedLicenses
     * @description 
     * A list of licenses that are considered acceptable for industry library content use.
     * @servertype string array
     * @type {string[]}
     */
    IndustryLibraryContentAllowedLicenses: string[] = [];

    /**
     * @name IndustryLibraryContentSubmissionAllowed
     * @description 
     * When true, content can be submitted to industry library.
     * @servertype Boolean
     * @type {boolean}
     */
    IndustryLibraryContentSubmissionAllowed: boolean = false;

    /**
     * @name IndustryLibraryContentSubmissionRequiresApproval
     * @description 
     * When true, content submitted to the industry library must be approved first.
     * @servertype Boolean
     * @type {boolean}
     */
    IndustryLibraryContentSubmissionRequiresApproval: boolean = false;

    /**
     * @name IndustryLibraryContentSubmissionAllowedLicenses
     * @description 
     * A list of licenses that can be used for content submitted to industry library.
     * @servertype string array
     * @type {string[]}
     */
    IndustryLibraryContentSubmissionAllowedLicenses: string[] = [];

    /**
     * @name IndustryLibraryContentSubmissionDefaultLicense
     * @description 
     * The default license to use for content submitted to industry library.
     * @servertype String
     * @type {string}
     */
    IndustryLibraryContentSubmissionDefaultLicense: string = "";

    /**
     * @name LearnLibraryContentReviewSentimentLastTrainingDateTime
     * @description 
     * The date and time when library content review sentiment training was last performed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LearnLibraryContentReviewSentimentLastTrainingDateTime: Date = null;

    /**
     * @name LearnLibraryContentReviewSentimentLastTrainingResultCode
     * @description 
     * The result code from most recent library content review sentiment training.
     * @servertype Int32
     * @type {number}
     */
    LearnLibraryContentReviewSentimentLastTrainingResultCode: number = 0;

    /**
     * @name LearnLibraryContentReviewSentimentLastTrainingResultMessage
     * @description 
     * The result message from most recent library content review sentiment training.
     * @servertype String
     * @type {string}
     */
    LearnLibraryContentReviewSentimentLastTrainingResultMessage: string = "";

    /**
     * @name LearnLibraryContentReviewSentimentLastTrainingPositiveExampleCount
     * @description 
     * When library content review sentiment training was last performed this was the count of positive examples.
     * @servertype Int64
     * @type {number}
     */
    LearnLibraryContentReviewSentimentLastTrainingPositiveExampleCount: number = 0;

    /**
     * @name LearnLibraryContentReviewSentimentLastTrainingNegativeExampleCount
     * @description 
     * When library content review sentiment training was last performed this was the count of negative examples.
     * @servertype Int64
     * @type {number}
     */
    LearnLibraryContentReviewSentimentLastTrainingNegativeExampleCount: number = 0;

    /**
     * @name LearnLibraryContentPersonalPreferences
     * @description 
     * When true, the system can learn personal preferences for library content.  That information can be used for future content
     * scoring.
     * @servertype Boolean
     * @type {boolean}
     */
    LearnLibraryContentPersonalPreferences: boolean = false;

    /**
     * @name LearnLibraryContentPersonalPreferencesUseThreshold
     * @description 
     * When learning personal preferences this is the number of times library content would have to be used before that preference
     * could be considered useful information.  Zero means never.
     * @servertype Int32
     * @type {number}
     */
    LearnLibraryContentPersonalPreferencesUseThreshold: number = 0;

    /**
     * @name LearnLibraryContentOrganizationPreferences
     * @description 
     * When true, the system can learn organization preferences for library content.  That information can be used for future content
     * scoring.
     * @servertype Boolean
     * @type {boolean}
     */
    LearnLibraryContentOrganizationPreferences: boolean = false;

    /**
     * @name LearnLibraryContentOrganizationPreferencesUseThreshold
     * @description 
     * When learning organization preferences this is the number of times library content would have to be used before that preference
     * could be considered useful information.  Zero means never.
     * @servertype Int32
     * @type {number}
     */
    LearnLibraryContentOrganizationPreferencesUseThreshold: number = 0;

    /**
     * @name LearnLibraryContentIndustryPreferences
     * @description 
     * When true, the system can learn industry preferences for industry library content.  That information can be used for future
     * content scoring.
     * @servertype Boolean
     * @type {boolean}
     */
    LearnLibraryContentIndustryPreferences: boolean = false;

    /**
     * @name LearnLibraryContentIndustryPreferencesUseThreshold
     * @description 
     * When learning industry preferences this is the number of times library content would have to be used before that preference
     * could be considered useful information.  Zero means never.
     * @servertype Int32
     * @type {number}
     */
    LearnLibraryContentIndustryPreferencesUseThreshold: number = 0;

    /**
     * @name LearnLibraryContentSelectionRulesPersonal
     * @description 
     * When true, the system can learn possible missing library content selection rules based on usage of personal library content.
     *  That information can be used for future content suggestions.
     * @servertype Boolean
     * @type {boolean}
     */
    LearnLibraryContentSelectionRulesPersonal: boolean = false;

    /**
     * @name LearnLibraryContentSelectionRulesOrganization
     * @description 
     * When true, the system can learn possible missing library content selection rules based on usage of organization library content.
     *  That information can be used for future content suggestions.
     * @servertype Boolean
     * @type {boolean}
     */
    LearnLibraryContentSelectionRulesOrganization: boolean = false;

    /**
     * @name LearnLibraryContentSelectionRulesIndustry
     * @description 
     * When true, the system can learn possible missing library content selection rules based on usage of industry library content.
     *  That information can be used for future content suggestions.
     * @servertype Boolean
     * @type {boolean}
     */
    LearnLibraryContentSelectionRulesIndustry: boolean = false;

    /**
     * @name LearnLibraryContentSelectionRulesNarrowSuggestionThreshold
     * @description 
     * The threshold of observed instances of a possible missing library content selection rule that has overlap with other explicitly
     * set selection rules that will trigger including the content with other suggestions.  Zero means never.
     * @servertype Int32
     * @type {number}
     */
    LearnLibraryContentSelectionRulesNarrowSuggestionThreshold: number = 0;

    /**
     * @name LearnLibraryContentSelectionRulesWideSuggestionThreshold
     * @description 
     * The threshold of observed instances of a possible missing library content selection rule that has no overlap with other explicitly
     * set selection rules that will trigger including the content with other suggestions.  Zero means never.
     * @servertype Int32
     * @type {number}
     */
    LearnLibraryContentSelectionRulesWideSuggestionThreshold: number = 0;

    /**
     * @name ScoreWeightCategoryLibraryVisibility
     * @description 
     * The relative weight (0-100) to give category of library visibility when scoring suggestions.
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightCategoryLibraryVisibility: number = 0;

    /**
     * @name ScoreWeightCategorySelectionRules
     * @description 
     * The relative weight (0-100) to give category of selection rules when scoring suggestions.
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightCategorySelectionRules: number = 0;

    /**
     * @name ScoreWeightCategoryPreferences
     * @description 
     * The relative weight (0-100) to give category of preferences when scoring suggestions.
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightCategoryPreferences: number = 0;

    /**
     * @name ScoreWeightLibraryPersonal
     * @description 
     * The relative weight (0-100) to give personal library content when scoring suggestions.
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightLibraryPersonal: number = 0;

    /**
     * @name ScoreWeightLibraryOrganization
     * @description 
     * The relative weight (0-100) to give organization library content when scoring suggestions.
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightLibraryOrganization: number = 0;

    /**
     * @name ScoreWeightLibraryIndustry
     * @description 
     * The relative weight (0-100) to give industry library content when scoring suggestions.
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightLibraryIndustry: number = 0;

    /**
     * @name ScoreWeightSelectionRulesExactMatch
     * @description 
     * The relative weight (0-100) to give library content when selection rules were an exact match.
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightSelectionRulesExactMatch: number = 0;

    /**
     * @name ScoreWeightSelectionRulesPartialMatch
     * @description 
     * The relative weight (0-100) to give library content when selection rules were a partial match.
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightSelectionRulesPartialMatch: number = 0;

    /**
     * @name ScoreWeightSelectionRulesAlwaysMatch
     * @description 
     * The relative weight (0-100) to give library content when selection rules are set to always match.
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightSelectionRulesAlwaysMatch: number = 0;

    /**
     * @name ScoreWeightExplicitPersonalPreference
     * @description 
     * The relative weight (0-100) to give explicit personal preferences when scoring suggestions.  Explicit preferences are based
     * on users indicating they like or dislike content, etc.
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightExplicitPersonalPreference: number = 0;

    /**
     * @name ScoreWeightInferredPersonalPreference
     * @description 
     * The relative weight (0-100) to give inferred personal preferences when scoring suggestions.  Inferred preferences are based
     * on how much a user uses content.
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightInferredPersonalPreference: number = 0;

    /**
     * @name ScoreWeightInferredOrganizationPreference
     * @description 
     * The relative weight (0-100) to give inferred organization preferences when scoring suggestions.  Inferred preferences are
     * based on how much an organization uses content.
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightInferredOrganizationPreference: number = 0;

    /**
     * @name ScoreWeightInferredIndustryPreference
     * @description 
     * The relative weight (0-100) to give inferred industry preferences when scoring suggestions.  Inferred preferences are based
     * on how much an industry uses content.
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightInferredIndustryPreference: number = 0;

    /**
     * @name ScoreWeightLibraryContentFeedbackSentiment
     * @description 
     * The relative weight (0-100) to give library content feedback sentiment when scoring suggestions.
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightLibraryContentFeedbackSentiment: number = 0;

    /**
     * @name TextIncludeTags
     * @description 
     * When true, library group text entries include tags.
     * @servertype Boolean
     * @type {boolean}
     */
    TextIncludeTags: boolean = false;

    /**
     * @name TextIncludeSelectionRules
     * @description 
     * When true, library group text entries include selection rules.  When false, tags will be used for selection rules (if included).
     * @servertype Boolean
     * @type {boolean}
     */
    TextIncludeSelectionRules: boolean = false;

    /**
     * @name TextIncludeVersions
     * @description 
     * When true, library group text entries include versions.
     * @servertype Boolean
     * @type {boolean}
     */
    TextIncludeVersions: boolean = false;

    /**
     * @name TextIncludeFeedback
     * @description 
     * When true, library group text entries include feedback.
     * @servertype Boolean
     * @type {boolean}
     */
    TextIncludeFeedback: boolean = false;

    /**
     * @name TextIncludeReview
     * @description 
     * When true, library group text entries include review.
     * @servertype Boolean
     * @type {boolean}
     */
    TextIncludeReview: boolean = false;

    /**
     * @name TextIncludeApproval
     * @description 
     * When true, library group text entries include approval.
     * @servertype Boolean
     * @type {boolean}
     */
    TextIncludeApproval: boolean = false;

    /**
     * @name TextIncludeAuthor
     * @description 
     * When true, library group text entries include author information.
     * @servertype Boolean
     * @type {boolean}
     */
    TextIncludeAuthor: boolean = false;

    /**
     * @name TextIncludeComments
     * @description 
     * When true, library group text entries include comments.
     * @servertype Boolean
     * @type {boolean}
     */
    TextIncludeComments: boolean = false;

    /**
     * @name TextRequireApprovalForPrivate
     * @description 
     * When true and approval is included, private library group text entries must be approved before use.
     * @servertype Boolean
     * @type {boolean}
     */
    TextRequireApprovalForPrivate: boolean = false;

    /**
     * @name TextRequireApprovalForPublic
     * @description 
     * When true and approval is included, public library group text entries must be approved before use.
     * @servertype Boolean
     * @type {boolean}
     */
    TextRequireApprovalForPublic: boolean = false;

    /**
     * @name DocumentIncludeTags
     * @description 
     * When true, library group documents include tags.
     * @servertype Boolean
     * @type {boolean}
     */
    DocumentIncludeTags: boolean = false;

    /**
     * @name DocumentIncludeSelectionRules
     * @description 
     * When true, library group documents include selection rules.  When false, tags will be used for selection rules (if included).
     * @servertype Boolean
     * @type {boolean}
     */
    DocumentIncludeSelectionRules: boolean = false;

    /**
     * @name DocumentIncludeVersions
     * @description 
     * When true, library group documents include versions.
     * @servertype Boolean
     * @type {boolean}
     */
    DocumentIncludeVersions: boolean = false;

    /**
     * @name DocumentIncludeFeedback
     * @description 
     * When true, library group documents include feedback.
     * @servertype Boolean
     * @type {boolean}
     */
    DocumentIncludeFeedback: boolean = false;

    /**
     * @name DocumentIncludeReview
     * @description 
     * When true, library group documents include review.
     * @servertype Boolean
     * @type {boolean}
     */
    DocumentIncludeReview: boolean = false;

    /**
     * @name DocumentIncludeApproval
     * @description 
     * When true, library group documents include approval.
     * @servertype Boolean
     * @type {boolean}
     */
    DocumentIncludeApproval: boolean = false;

    /**
     * @name DocumentIncludeAuthor
     * @description 
     * When true, library group documents include author information.
     * @servertype Boolean
     * @type {boolean}
     */
    DocumentIncludeAuthor: boolean = false;

    /**
     * @name DocumentIncludeComments
     * @description 
     * When true, library group documents include comments.
     * @servertype Boolean
     * @type {boolean}
     */
    DocumentIncludeComments: boolean = false;

    /**
     * @name DocumentRequireApprovalForPrivate
     * @description 
     * When true and approval is included, private library group documents must be approved before use.
     * @servertype Boolean
     * @type {boolean}
     */
    DocumentRequireApprovalForPrivate: boolean = false;

    /**
     * @name DocumentRequireApprovalForPublic
     * @description 
     * When true and approval is included, public library group documents must be approved before use.
     * @servertype Boolean
     * @type {boolean}
     */
    DocumentRequireApprovalForPublic: boolean = false;

    /**
     * @name Property01
     * @description 
     * A library item selection property for this library group.  If empty this property is not used.
     * @servertype String
     * @type {string}
     */
    Property01: string = "";

    /**
     * @name Property02
     * @description 
     * A library item selection property for this library group.  If empty this property is not used.
     * @servertype String
     * @type {string}
     */
    Property02: string = "";

    /**
     * @name Property03
     * @description 
     * A library item selection property for this library group.  If empty this property is not used.
     * @servertype String
     * @type {string}
     */
    Property03: string = "";

    /**
     * @name Property04
     * @description 
     * A library item selection property for this library group.  If empty this property is not used.
     * @servertype String
     * @type {string}
     */
    Property04: string = "";

    /**
     * @name Property05
     * @description 
     * A library item selection property for this library group.  If empty this property is not used.
     * @servertype String
     * @type {string}
     */
    Property05: string = "";

    /**
     * @name Property06
     * @description 
     * A library item selection property for this library group.  If empty this property is not used.
     * @servertype String
     * @type {string}
     */
    Property06: string = "";

    /**
     * @name Property07
     * @description 
     * A library item selection property for this library group.  If empty this property is not used.
     * @servertype String
     * @type {string}
     */
    Property07: string = "";

    /**
     * @name Property08
     * @description 
     * A library item selection property for this library group.  If empty this property is not used.
     * @servertype String
     * @type {string}
     */
    Property08: string = "";

    /**
     * @name Property09
     * @description 
     * A library item selection property for this library group.  If empty this property is not used.
     * @servertype String
     * @type {string}
     */
    Property09: string = "";

    /**
     * @name Property10
     * @description 
     * A library item selection property for this library group.  If empty this property is not used.
     * @servertype String
     * @type {string}
     */
    Property10: string = "";

    /**
     * @name Property01PickListId
     * @description 
     * If a pick list is used for this property this is the pick list id to use for the property.  This can be shared with other
     * library groups, selection properties, template types, etc.  This can also include multiple pick lists for scenarios that
     * require that.  When multiple pick lists are used the pick list description will be used as grouping for the values from each
     * pick list.  Properties with no associated pick list will be used for free form data entry.  Note that the actual list is
     * stored with a prefix of 'RC.' to avoid collision with any system defined pick lists.
     * @servertype string array
     * @type {string[]}
     */
    Property01PickListId: string[] = [];

    /**
     * @name Property02PickListId
     * @description 
     * If a pick list is used for this property this is the pick list id to use for the property.  This can be shared with other
     * library groups, selection properties, template types, etc.  This can also include multiple pick lists for scenarios that
     * require that.  When multiple pick lists are used the pick list description will be used as grouping for the values from each
     * pick list.  Properties with no associated pick list will be used for free form data entry.  Note that the actual list is
     * stored with a prefix of 'RC.' to avoid collision with any system defined pick lists.
     * @servertype string array
     * @type {string[]}
     */
    Property02PickListId: string[] = [];

    /**
     * @name Property03PickListId
     * @description 
     * If a pick list is used for this property this is the pick list id to use for the property.  This can be shared with other
     * library groups, selection properties, template types, etc.  This can also include multiple pick lists for scenarios that
     * require that.  When multiple pick lists are used the pick list description will be used as grouping for the values from each
     * pick list.  Properties with no associated pick list will be used for free form data entry.  Note that the actual list is
     * stored with a prefix of 'RC.' to avoid collision with any system defined pick lists.
     * @servertype string array
     * @type {string[]}
     */
    Property03PickListId: string[] = [];

    /**
     * @name Property04PickListId
     * @description 
     * If a pick list is used for this property this is the pick list id to use for the property.  This can be shared with other
     * library groups, selection properties, template types, etc.  This can also include multiple pick lists for scenarios that
     * require that.  When multiple pick lists are used the pick list description will be used as grouping for the values from each
     * pick list.  Properties with no associated pick list will be used for free form data entry.  Note that the actual list is
     * stored with a prefix of 'RC.' to avoid collision with any system defined pick lists.
     * @servertype string array
     * @type {string[]}
     */
    Property04PickListId: string[] = [];

    /**
     * @name Property05PickListId
     * @description 
     * If a pick list is used for this property this is the pick list id to use for the property.  This can be shared with other
     * library groups, selection properties, template types, etc.  This can also include multiple pick lists for scenarios that
     * require that.  When multiple pick lists are used the pick list description will be used as grouping for the values from each
     * pick list.  Properties with no associated pick list will be used for free form data entry.  Note that the actual list is
     * stored with a prefix of 'RC.' to avoid collision with any system defined pick lists.
     * @servertype string array
     * @type {string[]}
     */
    Property05PickListId: string[] = [];

    /**
     * @name Property06PickListId
     * @description 
     * If a pick list is used for this property this is the pick list id to use for the property.  This can be shared with other
     * library groups, selection properties, template types, etc.  This can also include multiple pick lists for scenarios that
     * require that.  When multiple pick lists are used the pick list description will be used as grouping for the values from each
     * pick list.  Properties with no associated pick list will be used for free form data entry.  Note that the actual list is
     * stored with a prefix of 'RC.' to avoid collision with any system defined pick lists.
     * @servertype string array
     * @type {string[]}
     */
    Property06PickListId: string[] = [];

    /**
     * @name Property07PickListId
     * @description 
     * If a pick list is used for this property this is the pick list id to use for the property.  This can be shared with other
     * library groups, selection properties, template types, etc.  This can also include multiple pick lists for scenarios that
     * require that.  When multiple pick lists are used the pick list description will be used as grouping for the values from each
     * pick list.  Properties with no associated pick list will be used for free form data entry.  Note that the actual list is
     * stored with a prefix of 'RC.' to avoid collision with any system defined pick lists.
     * @servertype string array
     * @type {string[]}
     */
    Property07PickListId: string[] = [];

    /**
     * @name Property08PickListId
     * @description 
     * If a pick list is used for this property this is the pick list id to use for the property.  This can be shared with other
     * library groups, selection properties, template types, etc.  This can also include multiple pick lists for scenarios that
     * require that.  When multiple pick lists are used the pick list description will be used as grouping for the values from each
     * pick list.  Properties with no associated pick list will be used for free form data entry.  Note that the actual list is
     * stored with a prefix of 'RC.' to avoid collision with any system defined pick lists.
     * @servertype string array
     * @type {string[]}
     */
    Property08PickListId: string[] = [];

    /**
     * @name Property09PickListId
     * @description 
     * If a pick list is used for this property this is the pick list id to use for the property.  This can be shared with other
     * library groups, selection properties, template types, etc.  This can also include multiple pick lists for scenarios that
     * require that.  When multiple pick lists are used the pick list description will be used as grouping for the values from each
     * pick list.  Properties with no associated pick list will be used for free form data entry.  Note that the actual list is
     * stored with a prefix of 'RC.' to avoid collision with any system defined pick lists.
     * @servertype string array
     * @type {string[]}
     */
    Property09PickListId: string[] = [];

    /**
     * @name Property10PickListId
     * @description 
     * If a pick list is used for this property this is the pick list id to use for the property.  This can be shared with other
     * library groups, selection properties, template types, etc.  This can also include multiple pick lists for scenarios that
     * require that.  When multiple pick lists are used the pick list description will be used as grouping for the values from each
     * pick list.  Properties with no associated pick list will be used for free form data entry.  Note that the actual list is
     * stored with a prefix of 'RC.' to avoid collision with any system defined pick lists.
     * @servertype string array
     * @type {string[]}
     */
    Property10PickListId: string[] = [];

    /**
     * @name Private
     * @description 
     * Flag if this item is private.
     * @servertype Boolean
     * @type {boolean}
     */
    Private: boolean = false;

    /**
     * @name Comments
     * @description 
     * Comments for this library group.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description 
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Library Review Document Result
 * @description
 * This contains information about the results of uploading, parsing, and processing of a library review document.
 */
export class ReportCompilerLibraryReviewResultModel {

    /**
     * @name OriginalFileName
     * @description 
     * The original file name of the document that was uploaded.
     * @servertype String
     * @type {string}
     */
    OriginalFileName: string = "";

    /**
     * @name MimeFileName
     * @description 
     * The mime file name of the document that was uploaded.
     * @servertype String
     * @type {string}
     */
    MimeFileName: string = "";

    /**
     * @name FinalFileName
     * @description 
     * The final file name of the document that was uploaded.
     * @servertype String
     * @type {string}
     */
    FinalFileName: string = "";

    /**
     * @name FinalFileFullPath
     * @description 
     * The final file name and path of the document that was uploaded.
     * @servertype String
     * @type {string}
     */
    FinalFileFullPath: string = "";

    /**
     * @name Extension
     * @description 
     * The extension of the document that was uploaded.
     * @servertype String
     * @type {string}
     */
    Extension: string = "";

    /**
     * @name ContentType
     * @description 
     * The content type of the document that was uploaded.
     * @servertype String
     * @type {string}
     */
    ContentType: string = "";

    /**
     * @name ParagraphCountParsedRaw
     * @description 
     * The number of raw paragraphs parsed from the document.  This includes control paragraphs and does not represent the number
     * of library entries in the document.
     * @servertype Int32
     * @type {number}
     */
    ParagraphCountParsedRaw: number = 0;

    /**
     * @name ParagraphCountSkipped
     * @description 
     * The number of raw paragraphs skipped as not needed for library entry parsing.
     * @servertype Int32
     * @type {number}
     */
    ParagraphCountSkipped: number = 0;

    /**
     * @name ParagraphCountMetaData
     * @description 
     * The number of raw paragraphs that contained meta-data used for library entry parsing.
     * @servertype Int32
     * @type {number}
     */
    ParagraphCountMetaData: number = 0;

    /**
     * @name ParagraphCountParsed
     * @description 
     * The number of library entries parsed from the document.
     * @servertype Int32
     * @type {number}
     */
    ParagraphCountParsed: number = 0;

    /**
     * @name ParagraphCountAdded
     * @description 
     * The number of library entries that were added due to being new.
     * @servertype Int32
     * @type {number}
     */
    ParagraphCountAdded: number = 0;

    /**
     * @name ParagraphCountUpdated
     * @description 
     * The number of library entries that were updated due to being different from the current library entry.
     * @servertype Int32
     * @type {number}
     */
    ParagraphCountUpdated: number = 0;

    /**
     * @name ParagraphCountDeleted
     * @description 
     * The number of library entries that were deleted due to having no title, tags, text, etc. defined.
     * @servertype Int32
     * @type {number}
     */
    ParagraphCountDeleted: number = 0;

    /**
     * @name ParagraphCountNotChanged
     * @description 
     * The number of library entries that were not changed because they match the current library entry.
     * @servertype Int32
     * @type {number}
     */
    ParagraphCountNotChanged: number = 0;

    /**
     * @name ParagraphCountWarning
     * @description 
     * The number of errors encountered during parsing, reading, or updating the library.
     * @servertype Int32
     * @type {number}
     */
    ParagraphCountWarning: number = 0;

    /**
     * @name ParagraphCountError
     * @description 
     * The number of errors encountered during parsing, reading, or updating the library.
     * @servertype Int32
     * @type {number}
     */
    ParagraphCountError: number = 0;

    /**
     * @name LibraryAssetsAdded
     * @description 
     * A list of library asset id values that were added to the library.
     * @servertype long array
     * @type {number[]}
     */
    LibraryAssetsAdded: number[] = [];

    /**
     * @name LibraryAssetsUpdated
     * @description 
     * A list of library asset id values that were updated in the library.
     * @servertype long array
     * @type {number[]}
     */
    LibraryAssetsUpdated: number[] = [];

    /**
     * @name LibraryAssetsDeleted
     * @description 
     * A list of library asset id values that were deleted from the library.
     * @servertype long array
     * @type {number[]}
     */
    LibraryAssetsDeleted: number[] = [];

    /**
     * @name LibraryAssetsNotChanged
     * @description 
     * A list of library asset id values that were in the document but had not changed from the current library entry.
     * @servertype long array
     * @type {number[]}
     */
    LibraryAssetsNotChanged: number[] = [];

    /**
     * @name LibraryAssetsWarnings
     * @description 
     * A list of library asset id values that encountered warnings during the process.
     * @servertype long array
     * @type {number[]}
     */
    LibraryAssetsWarnings: number[] = [];

    /**
     * @name LibraryAssetsErrors
     * @description 
     * A list of library asset id values that encountered errors during the process.
     * @servertype long array
     * @type {number[]}
     */
    LibraryAssetsErrors: number[] = [];

    /**
     * @name Notices
     * @description 
     * A list notices other than warnings or errors that occurred during the process.  This could be informational messages including
     * details of operations or proposed operations.
     * @servertype ReportCompilerLibraryReviewNoticeModel array
     * @type {ReportCompilerLibraryReviewNoticeModel[]}
     */
    Notices: ReportCompilerLibraryReviewNoticeModel[] = [];

    /**
     * @name Warnings
     * @description 
     * A list warnings that occurred during the process.
     * @servertype ReportCompilerLibraryReviewNoticeModel array
     * @type {ReportCompilerLibraryReviewNoticeModel[]}
     */
    Warnings: ReportCompilerLibraryReviewNoticeModel[] = [];

    /**
     * @name Errors
     * @description 
     * A list errors that occurred during the process.
     * @servertype ReportCompilerLibraryReviewNoticeModel array
     * @type {ReportCompilerLibraryReviewNoticeModel[]}
     */
    Errors: ReportCompilerLibraryReviewNoticeModel[] = [];

    /**
     * @name DryRun
     * @description 
     * When true the import is a dry-run showing what actions would be taken if processed in a non-dry-run scenario.  A dry-run
     * enables reviewing the actions that will be taken before they are performed.
     * @servertype Boolean
     * @type {boolean}
     */
    DryRun: boolean = false;

}



/**
 * @name Library Review Document Notice
 * @description
 * This contains information about a notice regarding a library review document.
 */
export class ReportCompilerLibraryReviewNoticeModel {

    /**
     * @name AssetId
     * @description 
     * The asset id of the library entry.  If null there was an error that was not related to a library entry.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name OperationType
     * @description 
     * The operation type for this library entry.  A = Add; E = Edit; D = Delete; R = Read; O = Other; U = Unknown
     * @servertype String
     * @type {string}
     */
    OperationType: string = "";

    /**
     * @name ResultCode
     * @description 
     * The result code related to this operation.
     * @servertype IB.Core.StandardResultCode Enum
     * @type {m.StandardResultCode}
     */
    ResultCode: m.StandardResultCode = null;

    /**
     * @name Message
     * @description 
     * The message (if any) related to this operation.
     * @servertype String
     * @type {string}
     */
    Message: string = "";

    /**
     * @name CurrentLibraryMetaData
     * @description 
     * When possible (e.g. not a library read error) this is the current contents of the library entry's meta data.
     * @servertype String
     * @type {string}
     */
    CurrentLibraryMetaData: string = "";

    /**
     * @name CurrentLibraryText
     * @description 
     * When possible (e.g. not a library read error) this is the current contents of the library entry.
     * @servertype String
     * @type {string}
     */
    CurrentLibraryText: string = "";

    /**
     * @name RequestedLibraryMetaData
     * @description 
     * The requested library meta data for this library entry as parsed from the document.
     * @servertype String
     * @type {string}
     */
    RequestedLibraryMetaData: string = "";

    /**
     * @name RequestedLibraryText
     * @description 
     * The requested library text for this library entry as parsed from the document.
     * @servertype String
     * @type {string}
     */
    RequestedLibraryText: string = "";

    /**
     * @name Details
     * @description 
     * Details about operation (if any).
     * @servertype String
     * @type {string}
     */
    Details: string = "";

}



/**
 * @name Library Coverage
 * @description
 * This contains information about library coverage and is used to provide a list of library selection combinations with their
 * coverage counts.
 */
export class ReportCompilerLibraryCoverageSummaryModel {

    /**
     * @name Item
     * @description 
     * The name of the item this coverage information pertains to.
     * @servertype String
     * @type {string}
     */
    Item: string = "";

    /**
     * @name Count
     * @description 
     * The number of library text entries matching this item or if looking for items with no coverage this is the number of child
     * items below this item with no coverage.
     * @servertype Int32
     * @type {number}
     */
    Count: number = 0;

    /**
     * @name Children
     * @description 
     * The number of library text entries matching any selection property/value combinations.
     * @servertype ReportCompilerLibraryCoverageSummaryModel array
     * @type {ReportCompilerLibraryCoverageSummaryModel[]}
     */
    Children: ReportCompilerLibraryCoverageSummaryModel[] = [];

}



/**
 * @name Library Coverage
 * @description
 * This contains information about library coverage and is used to provide a list of library selection combinations that do
 * not have any library text associated with them which represent gaps in the library since if these values are selected in
 * a case no library text exists to be suggested for them.
 */
export class ReportCompilerLibraryCoverageModel {

    /**
     * @name LibraryGroupId
     * @description 
     * The id of the library group this coverage information pertains to.
     * @servertype Int64
     * @type {number}
     */
    LibraryGroupId: number = 0;

    /**
     * @name LibraryGroup
     * @description 
     * The name of the library group this coverage information pertains to.
     * @servertype String
     * @type {string}
     */
    LibraryGroup: string = "";

    /**
     * @name Property01
     * @description 
     * A selection property.  The property depends on the library group.
     * @servertype String
     * @type {string}
     */
    Property01: string = "";

    /**
     * @name Value01
     * @description 
     * A selection value.  The corresponding property gives context to this value and depends on the library group.
     * @servertype String
     * @type {string}
     */
    Value01: string = "";

    /**
     * @name Property02
     * @description 
     * A selection property.  The property depends on the library group.
     * @servertype String
     * @type {string}
     */
    Property02: string = "";

    /**
     * @name Value02
     * @description 
     * A selection value.  The corresponding property gives context to this value and depends on the library group.
     * @servertype String
     * @type {string}
     */
    Value02: string = "";

    /**
     * @name Property03
     * @description 
     * A selection property.  The property depends on the library group.
     * @servertype String
     * @type {string}
     */
    Property03: string = "";

    /**
     * @name Value03
     * @description 
     * A selection value.  The corresponding property gives context to this value and depends on the library group.
     * @servertype String
     * @type {string}
     */
    Value03: string = "";

    /**
     * @name Property04
     * @description 
     * A selection property.  The property depends on the library group.
     * @servertype String
     * @type {string}
     */
    Property04: string = "";

    /**
     * @name Value04
     * @description 
     * A selection value.  The corresponding property gives context to this value and depends on the library group.
     * @servertype String
     * @type {string}
     */
    Value04: string = "";

    /**
     * @name Property05
     * @description 
     * A selection property.  The property depends on the library group.
     * @servertype String
     * @type {string}
     */
    Property05: string = "";

    /**
     * @name Value05
     * @description 
     * A selection value.  The corresponding property gives context to this value and depends on the library group.
     * @servertype String
     * @type {string}
     */
    Value05: string = "";

    /**
     * @name Property06
     * @description 
     * A selection property.  The property depends on the library group.
     * @servertype String
     * @type {string}
     */
    Property06: string = "";

    /**
     * @name Value06
     * @description 
     * A selection value.  The corresponding property gives context to this value and depends on the library group.
     * @servertype String
     * @type {string}
     */
    Value06: string = "";

    /**
     * @name Property07
     * @description 
     * A selection property.  The property depends on the library group.
     * @servertype String
     * @type {string}
     */
    Property07: string = "";

    /**
     * @name Value07
     * @description 
     * A selection value.  The corresponding property gives context to this value and depends on the library group.
     * @servertype String
     * @type {string}
     */
    Value07: string = "";

    /**
     * @name Property08
     * @description 
     * A selection property.  The property depends on the library group.
     * @servertype String
     * @type {string}
     */
    Property08: string = "";

    /**
     * @name Value08
     * @description 
     * A selection value.  The corresponding property gives context to this value and depends on the library group.
     * @servertype String
     * @type {string}
     */
    Value08: string = "";

    /**
     * @name Property09
     * @description 
     * A selection property.  The property depends on the library group.
     * @servertype String
     * @type {string}
     */
    Property09: string = "";

    /**
     * @name Value09
     * @description 
     * A selection value.  The corresponding property gives context to this value and depends on the library group.
     * @servertype String
     * @type {string}
     */
    Value09: string = "";

    /**
     * @name Property10
     * @description 
     * A selection property.  The property depends on the library group.
     * @servertype String
     * @type {string}
     */
    Property10: string = "";

    /**
     * @name Value10
     * @description 
     * A selection value.  The corresponding property gives context to this value and depends on the library group.
     * @servertype String
     * @type {string}
     */
    Value10: string = "";

    /**
     * @name FullMatchCount
     * @description 
     * The number of library text entries matching all selection property/value combinations.
     * @servertype Int32
     * @type {number}
     */
    FullMatchCount: number = 0;

    /**
     * @name FullOrPartialMatchCount
     * @description 
     * The number of library text entries matching any selection property/value combinations.
     * @servertype Int32
     * @type {number}
     */
    FullOrPartialMatchCount: number = 0;

    /**
     * @name AsOf
     * @description 
     * The as-of date and time of the library coverage calculation.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AsOf: Date = null;

}



/**
 * @name Library Stats
 * @description
 * This contains information about library statistics with the number of library text entries broken down by group, category,
 * person who added the text, and visibility of the text.
 */
export class ReportCompilerLibraryStatsModel {

    /**
     * @name LibraryGroupId
     * @description 
     * The library group id.
     * @servertype String
     * @type {string}
     */
    LibraryGroupId: string = "";

    /**
     * @name LibraryGroup
     * @description 
     * The library group.
     * @servertype String
     * @type {string}
     */
    LibraryGroup: string = "";

    /**
     * @name LibraryCategory
     * @description 
     * The library category.
     * @servertype String
     * @type {string}
     */
    LibraryCategory: string = "";

    /**
     * @name AddedByContactId
     * @description 
     * The id of the user who added the library text.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AddedByContactId: number = null;

    /**
     * @name AddedByContactName
     * @description 
     * The name of the user who added the library text.
     * @servertype String
     * @type {string}
     */
    AddedByContactName: string = "";

    /**
     * @name Visibility
     * @description 
     * The visibility for this library text.  Possible values are: Organization or Personal.
     * @servertype String
     * @type {string}
     */
    Visibility: string = "";

    /**
     * @name Count
     * @description 
     * A count of the number of library text entries that match the specified group, category, added by user, and visibility.
     * @servertype Int32
     * @type {number}
     */
    Count: number = 0;

    /**
     * @name Validated
     * @description 
     * A flag indicating the stats have been validated.
     * @servertype Boolean
     * @type {boolean}
     */
    Validated: boolean = false;

}



/**
 * @name ReportCompilerReportParserResultModel
 */
export class ReportCompilerReportParserResultModel {

    /**
     * @name ReportId
     * @servertype String
     * @type {string}
     */
    ReportId: string = "";

    /**
     * @name ReportFileName
     * @servertype String
     * @type {string}
     */
    ReportFileName: string = "";

    /**
     * @name LibraryGroupId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    LibraryGroupId: number = 0;

    /**
     * @name ShareWithOrganization
     * @servertype Boolean
     * @type {boolean}
     */
    ShareWithOrganization: boolean = false;

    /**
     * @name Tags
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name TemplateTypeId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TemplateTypeId: number = 0;

    /**
     * @name SubjectMatters
     * @servertype ReportCompilerReportParserResultSubjectMatterModel array
     * @type {ReportCompilerReportParserResultSubjectMatterModel[]}
     */
    SubjectMatters: ReportCompilerReportParserResultSubjectMatterModel[] = [];

    /**
     * @name PendingCount
     * @servertype Int32
     * @type {number}
     */
    PendingCount: number = 0;

    /**
     * @name AcceptedCount
     * @servertype Int32
     * @type {number}
     */
    AcceptedCount: number = 0;

    /**
     * @name MergedCount
     * @servertype Int32
     * @type {number}
     */
    MergedCount: number = 0;

    /**
     * @name DeletedAndSkippedCount
     * @servertype Int32
     * @type {number}
     */
    DeletedAndSkippedCount: number = 0;

    /**
     * @name DeletedCount
     * @servertype Int32
     * @type {number}
     */
    DeletedCount: number = 0;

}



/**
 * @name ReportCompilerReportParserResultSubjectMatterModel
 */
export class ReportCompilerReportParserResultSubjectMatterModel {

    /**
     * @name SubjectMatterId
     * @servertype String
     * @type {string}
     */
    SubjectMatterId: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name SubjectMatter
     * @servertype object array
     * @type {any[]}
     */
    SubjectMatter: any[] = [];

}



/**
 * @name ReportCompilerReportParserResultAssetModel
 */
export class ReportCompilerReportParserResultAssetModel {

    /**
     * @name ParagraphNumber
     * @servertype Int64
     * @type {number}
     */
    ParagraphNumber: number = 0;

    /**
     * @name ParagraphType
     * @servertype IB.Web.API.Models.v5.ReportCompilerReportParserParagraphType Enum
     * @type {ReportCompilerReportParserParagraphType}
     */
    ParagraphType: ReportCompilerReportParserParagraphType = null;

    /**
     * @name AssetId
     * @servertype Int64
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name AssetText
     * @servertype String
     * @type {string}
     */
    AssetText: string = "";

    /**
     * @name AssetCategory
     * @servertype String
     * @type {string}
     */
    AssetCategory: string = "";

    /**
     * @name IsCategoryHeading
     * @servertype Boolean
     * @type {boolean}
     */
    IsCategoryHeading: boolean = false;

    /**
     * @name SubjectMatterId
     * @servertype String
     * @type {string}
     */
    SubjectMatterId: string = "";

    /**
     * @name SourceParagraphNumbers
     * @servertype long array
     * @type {number[]}
     */
    SourceParagraphNumbers: number[] = [];

    /**
     * @name ExistingAssetMatches
     * @servertype ReportCompilerReportParserResultAssetMatchModel array
     * @type {ReportCompilerReportParserResultAssetMatchModel[]}
     */
    ExistingAssetMatches: ReportCompilerReportParserResultAssetMatchModel[] = [];

}



/**
 * @name ReportCompilerReportParserParagraphType Enum
 * @readonly
 * @enum {number}
 */
export enum ReportCompilerReportParserParagraphType {

    /**
     * Pending
     */
    Pending = 0,

    /**
     * Accepted
     */
    Accepted = 1,

    /**
     * Merged
     */
    Merged = 2,

    /**
     * Deleted And Skipped
     */
    DeletedAndSkipped = 3,

    /**
     * Deleted
     */
    Deleted = 4

}



/**
 * @name ReportCompilerReportParserResultAssetMatchModel
 */
export class ReportCompilerReportParserResultAssetMatchModel {

    /**
     * @name MatchAssetId
     * @servertype Int64
     * @type {number}
     */
    MatchAssetId: number = 0;

    /**
     * @name MatchAssetVisibility
     * @servertype String
     * @type {string}
     */
    MatchAssetVisibility: string = "";

    /**
     * @name MatchAssetHasWildcards
     * @servertype Boolean
     * @type {boolean}
     */
    MatchAssetHasWildcards: boolean = false;

    /**
     * @name MatchPercent
     * @servertype Double
     * @type {number}
     */
    MatchPercent: number = 0;

}



/**
 * @name ReportCompilerReportParserParagraphMetaDataEditViewModel
 */
export class ReportCompilerReportParserParagraphMetaDataEditViewModel {

    /**
     * @name ReportId
     * @servertype String
     * @type {string}
     */
    ReportId: string = "";

    /**
     * @name ParagraphNumber
     * @servertype Int32
     * @type {number}
     */
    ParagraphNumber: number = 0;

    /**
     * @name EndingParagraphNumber
     * @servertype Int32
     * @type {number}
     */
    EndingParagraphNumber: number = 0;

    /**
     * @name MetaDataType
     * @servertype String
     * @type {string}
     */
    MetaDataType: string = "";

    /**
     * @name MetaDataValue
     * @servertype String
     * @type {string}
     */
    MetaDataValue: string = "";

}



