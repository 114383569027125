// !!! This is generated code... DO NOT modify this file !!!


/**
 * @name IConstantInformation
 */
export class IConstantInformation {

    /**
     * @name Value
     * @servertype String
     * @type {string}
     */
    Value: string = "";

    /**
     * @name NativeDescription
     * @servertype String
     * @type {string}
     */
    NativeDescription: string = "";

    /**
     * @name Language
     * @servertype String
     * @type {string}
     */
    Language: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

}



/**
 * @name ConstantInformation
 */
export class ConstantInformation {

    /**
     * @name Value
     * @servertype String
     * @type {string}
     */
    Value: string = "";

    /**
     * @name NativeDescription
     * @servertype String
     * @type {string}
     */
    NativeDescription: string = "";

    /**
     * @name Language
     * @servertype String
     * @type {string}
     */
    Language: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

}



/**
 * @name StandardResultCode Enum
 * @readonly
 * @enum {number}
 */
export enum StandardResultCode {

    /**
     * Success
     */
    Success = 0,

    /**
     * Partial Success
     */
    PartialSuccess = 1,

    /**
     * Pending
     */
    Pending = 51,

    /**
     * Waiting
     */
    Waiting = 52,

    /**
     * Processing
     */
    Processing = 53,

    /**
     * Unknown Error
     */
    UnknownError = 90,

    /**
     * Data Store Information Missing
     */
    DataStoreInformationMissing = 99,

    /**
     * Subsystem Specific Error
     */
    SubsystemSpecificError = 100,

    /**
     * Required Value Empty
     */
    RequiredValueEmpty = 1001,

    /**
     * Invalid Value
     */
    InvalidValue = 1011,

    /**
     * Invalid Value Too Short
     */
    InvalidValueTooShort = 1012,

    /**
     * Invalid Value Too Long
     */
    InvalidValueTooLong = 1013,

    /**
     * Invalid Value Too Low
     */
    InvalidValueTooLow = 1014,

    /**
     * Invalid Value Too High
     */
    InvalidValueTooHigh = 1015,

    /**
     * Invalid Format
     */
    InvalidFormat = 1051,

    /**
     * Validation Failure
     */
    ValidationFailure = 1061,

    /**
     * Prerequisite Failure
     */
    PrerequisiteFailure = 1071,

    /**
     * Not Found
     */
    NotFound = 1101,

    /**
     * Too Many Found
     */
    TooManyFound = 1151,

    /**
     * Duplicate Found
     */
    DuplicateFound = 1201,

    /**
     * Invalid Foreign Key Reference
     */
    InvalidForeignKeyReference = 1211,

    /**
     * Dependency
     */
    Dependency = 1301,

    /**
     * Out Of Identity Space
     */
    OutOfIdentitySpace = 1401,

    /**
     * Insufficient Permission
     */
    InsufficientPermission = 1501,

    /**
     * Invalid User Name Password Combination
     */
    InvalidUserNamePasswordCombination = 1511,

    /**
     * Invalid Authentication Token
     */
    InvalidAuthenticationToken = 1512,

    /**
     * Invalid Authentication Key
     */
    InvalidAuthenticationKey = 1513,

    /**
     * Unknown Third Party Authentication
     */
    UnknownThirdPartyAuthentication = 1519,

    /**
     * Password Expired
     */
    PasswordExpired = 1520,

    /**
     * Access Blocked
     */
    AccessBlocked = 1521,

    /**
     * Access Rejected
     */
    AccessRejected = 1522,

    /**
     * Access Requires Multi Factor Authentication
     */
    AccessRequiresMultiFactorAuthentication = 1531,

    /**
     * Access Requires Multi Factor Authentication For New Public Ip Address
     */
    AccessRequiresMultiFactorAuthenticationForNewPublicIpAddress = 1532,

    /**
     * Access Requires Multi Factor Authentication For New Device Id
     */
    AccessRequiresMultiFactorAuthenticationForNewDeviceId = 1533,

    /**
     * None Affected
     */
    NoneAffected = 1601,

    /**
     * Changed By Another User
     */
    ChangedByAnotherUser = 1611,

    /**
     * Requested Action Not Supported
     */
    RequestedActionNotSupported = 1701,

    /**
     * Requested Action Already Completed
     */
    RequestedActionAlreadyCompleted = 1711,

    /**
     * Database Not Configured Missing Database Id
     */
    DatabaseNotConfiguredMissingDatabaseId = 2001,

    /**
     * Encryption Key Not Found
     */
    EncryptionKeyNotFound = 2101,

    /**
     * Encryption Key Missing Values
     */
    EncryptionKeyMissingValues = 2201,

    /**
     * Encryption Not Configured
     */
    EncryptionNotConfigured = 2301,

    /**
     * Partner System Offline
     */
    PartnerSystemOffline = 3101,

    /**
     * Partner System Timeout
     */
    PartnerSystemTimeout = 3111,

    /**
     * Partner System Error
     */
    PartnerSystemError = 3121,

    /**
     * Rejected
     */
    Rejected = 4101,

    /**
     * Timeout
     */
    Timeout = 4201,

    /**
     * Cancelled
     */
    Cancelled = 4301,

    /**
     * Exception Timeout
     */
    ExceptionTimeout = 2147483645,

    /**
     * Exception Updating Data
     */
    ExceptionUpdatingData = 2147483646,

    /**
     * Exception
     */
    Exception = 2147483647,

    /**
     * Data Store Error
     */
    DataStoreError = -1

}



/**
 * @name SqlComparisonOperator Enum
 * @readonly
 * @enum {number}
 */
export enum SqlComparisonOperator {

    /**
     * Equals
     */
    Equals = 0,

    /**
     * Not Equals
     */
    NotEquals = 1,

    /**
     * Less Than
     */
    LessThan = 2,

    /**
     * Less Than Or Equal To
     */
    LessThanOrEqualTo = 3,

    /**
     * Greater Than
     */
    GreaterThan = 4,

    /**
     * Greater Than Or Equal To
     */
    GreaterThanOrEqualTo = 5,

    /**
     * Starts With
     */
    StartsWith = 6,

    /**
     * Not Starts With
     */
    NotStartsWith = 7,

    /**
     * Ends With
     */
    EndsWith = 8,

    /**
     * Not Ends With
     */
    NotEndsWith = 9,

    /**
     * Contains
     */
    Contains = 10,

    /**
     * Not Contains
     */
    NotContains = 11,

    /**
     * In
     */
    In = 12,

    /**
     * Not In
     */
    NotIn = 13,

    /**
     * Between
     */
    Between = 14,

    /**
     * Not Between
     */
    NotBetween = 15

}



/**
 * @name SqlBooleanOperator Enum
 * @readonly
 * @enum {number}
 */
export enum SqlBooleanOperator {

    /**
     * And
     */
    And = 0,

    /**
     * Or
     */
    Or = 1

}



/**
 * @name VoucherStatus Enum
 * @readonly
 * @enum {number}
 */
export enum VoucherStatus {

    /**
     * Not Found
     */
    NotFound = 0,

    /**
     * Hold
     */
    Hold = 1,

    /**
     * Pending
     */
    Pending = 2,

    /**
     * Reserved
     */
    Reserved = 3,

    /**
     * Active
     */
    Active = 4,

    /**
     * Inactive
     */
    Inactive = 5,

    /**
     * Expired
     */
    Expired = 6,

    /**
     * Lapsed
     */
    Lapsed = 7,

    /**
     * Suspended
     */
    Suspended = 8,

    /**
     * Used
     */
    Used = 9,

    /**
     * Unknown
     */
    Unknown = 10

}



/**
 * @name SettingInputType Enum
 * @readonly
 * @enum {number}
 */
export enum SettingInputType {

    /**
     * Single Line Text
     */
    SingleLineText = 0,

    /**
     * Multi Line Text
     */
    MultiLineText = 1,

    /**
     * Numeric Integer
     */
    NumericInteger = 2,

    /**
     * Numeric Double
     */
    NumericDouble = 3,

    /**
     * Date Time
     */
    DateTime = 4,

    /**
     * Yes No
     */
    YesNo = 5,

    /**
     * Boolean
     */
    Boolean = 6,

    /**
     * File Selection
     */
    FileSelection = 7,

    /**
     * File Selection Image
     */
    FileSelectionImage = 8,

    /**
     * Asset Id
     */
    AssetId = 9,

    /**
     * Case Template Id
     */
    CaseTemplateId = 10,

    /**
     * Notification Group Id
     */
    NotificationGroupId = 11,

    /**
     * Invoice Message Id
     */
    InvoiceMessageId = 12,

    /**
     * Item Id
     */
    ItemId = 13,

    /**
     * Group Id
     */
    GroupId = 14,

    /**
     * Role Id
     */
    RoleId = 15,

    /**
     * Role Id List
     */
    RoleIdList = 16,

    /**
     * Query Id
     */
    QueryId = 17,

    /**
     * Switch Id Primary
     */
    SwitchIdPrimary = 18,

    /**
     * Switch Id
     */
    SwitchId = 19,

    /**
     * Time Zone Id
     */
    TimeZoneId = 20,

    /**
     * Iso Currency Code
     */
    IsoCurrencyCode = 21,

    /**
     * String List
     */
    StringList = 22,

    /**
     * Integer List
     */
    IntegerList = 23,

    /**
     * Constant List
     */
    ConstantList = 24,

    /**
     * Constant List Multi Select Csv
     */
    ConstantListMultiSelectCsv = 25,

    /**
     * User Input Csv
     */
    UserInputCsv = 26,

    /**
     * Json
     */
    Json = 27

}



/**
 * @name IMetaDataModel
 */
export class IMetaDataModel {

    /**
     * @name AddedDateTime
     * @description 
     * The date and time when this object was added.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AddedDateTime: Date = null;

    /**
     * @name AddedByContactId
     * @description 
     * The id of the contact who added this object.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AddedByContactId: number = null;

    /**
     * @name AddedByContactName
     * @description 
     * The name of the contact who added this object.
     * @servertype String
     * @type {string}
     */
    AddedByContactName: string = "";

    /**
     * @name LastEditedDateTime
     * @description 
     * The date and time when this object was last edited.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastEditedDateTime: Date = null;

    /**
     * @name LastEditedByContactId
     * @description 
     * The id of the contact who last edited this object.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    LastEditedByContactId: number = null;

    /**
     * @name LastEditedByContactName
     * @description 
     * The name of the contact who last edited this object.
     * @servertype String
     * @type {string}
     */
    LastEditedByContactName: string = "";

    /**
     * @name MarkedForDeletionDateTime
     * @description 
     * The date and time when this object was marked for deletion.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    MarkedForDeletionDateTime: Date = null;

    /**
     * @name MarkedForDeletionByContactId
     * @description 
     * The id of the contact who marked this object for deletion.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    MarkedForDeletionByContactId: number = null;

    /**
     * @name MarkedForDeletionByContactName
     * @description 
     * The name of the contact who marked this object for deletion.
     * @servertype String
     * @type {string}
     */
    MarkedForDeletionByContactName: string = "";

    /**
     * @name CurrentRowVersion
     * @description 
     * The current row version for this object.
     * @servertype String
     * @type {string}
     */
    CurrentRowVersion: string = "";

    /**
     * @name ObjectHashCode
     * @description 
     * The current hash code for this object.
     * @servertype String
     * @type {string}
     */
    ObjectHashCode: string = "";

    /**
     * @name DataSourceTableName
     * @description 
     * The name of the data source table for this object.
     * @servertype String
     * @type {string}
     */
    DataSourceTableName: string = "";

    /**
     * @name IncludedDataObjectTypes
     * @description 
     * A list of included data object types provided with this object.  This can be used to decide if certain child objects need
     * to be lazy loaded.
     * @servertype string array
     * @type {string[]}
     */
    IncludedDataObjectTypes: string[] = [];

    /**
     * @name DeletedDataObjects
     * @description 
     * A list of keys and values that represent child objects that were deleted as part of this object.  The key is a string representation
     * of the child object type and the value is the key of the child object that was deleted.
     * @servertype IDeletedDataObject array
     * @type {IDeletedDataObject[]}
     */
    DeletedDataObjects: IDeletedDataObject[] = [];

    /**
     * @name PartitionId
     * @description 
     * A partition identifier for this object.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name ReadOnly
     * @description 
     * When true this object is read-only for the current user and context.
     * @servertype Boolean
     * @type {boolean}
     */
    ReadOnly: boolean = false;

    /**
     * @name Properties
     * @description 
     * Additional meta data properties about this object.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name IDeletedDataObject
 */
export class IDeletedDataObject {

    /**
     * @name ObjectType
     * @description 
     * The object type that was deleted.
     * @servertype String
     * @type {string}
     */
    ObjectType: string = "";

    /**
     * @name ObjectId
     * @description 
     * The object id that was deleted.
     * @servertype System.Object
     * @type {any}
     */
    ObjectId: any = {};

}



/**
 * @name MetaDataModel
 */
export class MetaDataModel {

    /**
     * @name AddedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AddedDateTime: Date = null;

    /**
     * @name AddedByContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AddedByContactId: number = null;

    /**
     * @name AddedByContactName
     * @servertype String
     * @type {string}
     */
    AddedByContactName: string = "";

    /**
     * @name LastEditedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastEditedDateTime: Date = null;

    /**
     * @name LastEditedByContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    LastEditedByContactId: number = null;

    /**
     * @name LastEditedByContactName
     * @servertype String
     * @type {string}
     */
    LastEditedByContactName: string = "";

    /**
     * @name MarkedForDeletionDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    MarkedForDeletionDateTime: Date = null;

    /**
     * @name MarkedForDeletionByContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    MarkedForDeletionByContactId: number = null;

    /**
     * @name MarkedForDeletionByContactName
     * @servertype String
     * @type {string}
     */
    MarkedForDeletionByContactName: string = "";

    /**
     * @name CurrentRowVersion
     * @servertype String
     * @type {string}
     */
    CurrentRowVersion: string = "";

    /**
     * @name ObjectHashCode
     * @servertype String
     * @type {string}
     */
    ObjectHashCode: string = "";

    /**
     * @name DataSourceTableName
     * @servertype String
     * @type {string}
     */
    DataSourceTableName: string = "";

    /**
     * @name IncludedDataObjectTypes
     * @servertype string array
     * @type {string[]}
     */
    IncludedDataObjectTypes: string[] = [];

    /**
     * @name DeletedDataObjects
     * @servertype IDeletedDataObject array
     * @type {IDeletedDataObject[]}
     */
    DeletedDataObjects: IDeletedDataObject[] = [];

    /**
     * @name PartitionId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name ReadOnly
     * @servertype Boolean
     * @type {boolean}
     */
    ReadOnly: boolean = false;

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name IMetaDataLightModel
 */
export class IMetaDataLightModel {

    /**
     * @name CurrentRowVersion
     * @description 
     * The current row version for this object.
     * @servertype String
     * @type {string}
     */
    CurrentRowVersion: string = "";

    /**
     * @name ObjectHashCode
     * @description 
     * The current hash code for this object.
     * @servertype String
     * @type {string}
     */
    ObjectHashCode: string = "";

    /**
     * @name DataSourceTableName
     * @description 
     * The name of the data source table for this object.
     * @servertype String
     * @type {string}
     */
    DataSourceTableName: string = "";

    /**
     * @name PartitionId
     * @description 
     * A partition identifier for this object.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name ReadOnly
     * @description 
     * When true this object is read-only for the current user and context.
     * @servertype Boolean
     * @type {boolean}
     */
    ReadOnly: boolean = false;

    /**
     * @name Properties
     * @description 
     * Additional meta data properties about this object.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name MetaDataLightModel
 */
export class MetaDataLightModel {

    /**
     * @name CurrentRowVersion
     * @servertype String
     * @type {string}
     */
    CurrentRowVersion: string = "";

    /**
     * @name ObjectHashCode
     * @servertype String
     * @type {string}
     */
    ObjectHashCode: string = "";

    /**
     * @name DataSourceTableName
     * @servertype String
     * @type {string}
     */
    DataSourceTableName: string = "";

    /**
     * @name PartitionId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name ReadOnly
     * @servertype Boolean
     * @type {boolean}
     */
    ReadOnly: boolean = false;

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name DeletedDataObject
 */
export class DeletedDataObject {

    /**
     * @name ObjectType
     * @servertype String
     * @type {string}
     */
    ObjectType: string = "";

    /**
     * @name ObjectId
     * @servertype System.Object
     * @type {any}
     */
    ObjectId: any = {};

}



/**
 * @name IProcessStatusModel
 */
export class IProcessStatusModel {

    /**
     * @name ProcessStatusId
     * @servertype Int64
     * @type {number}
     */
    ProcessStatusId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ProcessType
     * @servertype String
     * @type {string}
     */
    ProcessType: string = "";

    /**
     * @name ProcessTypeId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessTypeId: number = 0;

    /**
     * @name ProcessTypeId2
     * @servertype String
     * @type {string}
     */
    ProcessTypeId2: string = "";

    /**
     * @name SessionId
     * @servertype String
     * @type {string}
     */
    SessionId: string = "";

    /**
     * @name StatusIntervalMinutes
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StatusIntervalMinutes: number = 0;

    /**
     * @name ServerName
     * @servertype String
     * @type {string}
     */
    ServerName: string = "";

    /**
     * @name ServerProcessId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ServerProcessId: number = 0;

    /**
     * @name ProcessCount
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessCount: number = 0;

    /**
     * @name ProcessTotal
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessTotal: number = 0;

    /**
     * @name ProcessTime
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessTime: number = 0;

    /**
     * @name StartDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    StartDateTime: Date = null;

    /**
     * @name LastUpdateDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastUpdateDateTime: Date = null;

    /**
     * @name DataProcessedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DataProcessedDateTime: Date = null;

    /**
     * @name FinishDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    FinishDateTime: Date = null;

    /**
     * @name CancelNow
     * @servertype Boolean
     * @type {boolean}
     */
    CancelNow: boolean = false;

    /**
     * @name Status
     * @servertype String
     * @type {string}
     */
    Status: string = "";

    /**
     * @name ProcessMemoryUsedKb
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ProcessMemoryUsedKb: number = 0;

    /**
     * @name ServerMemoryFreeKb
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ServerMemoryFreeKb: number = 0;

    /**
     * @name ServerMemoryTotalKb
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ServerMemoryTotalKb: number = 0;

    /**
     * @name ErrorCount
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ErrorCount: number = 0;

    /**
     * @name ErrorMessage
     * @servertype String
     * @type {string}
     */
    ErrorMessage: string = "";

    /**
     * @name WarningCount
     * @servertype Int64 (nullable)
     * @type {number}
     */
    WarningCount: number = 0;

    /**
     * @name WarningMessage
     * @servertype String
     * @type {string}
     */
    WarningMessage: string = "";

    /**
     * @name OmittedCount
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OmittedCount: number = 0;

    /**
     * @name OmittedMessage
     * @servertype String
     * @type {string}
     */
    OmittedMessage: string = "";

    /**
     * @name AlarmCount
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AlarmCount: number = 0;

    /**
     * @name AlarmMessage
     * @servertype String
     * @type {string}
     */
    AlarmMessage: string = "";

    /**
     * @name Feedback
     * @servertype String
     * @type {string}
     */
    Feedback: string = "";

    /**
     * @name ResultCode
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ResultCode: number = 0;

    /**
     * @name ResultMessage
     * @servertype String
     * @type {string}
     */
    ResultMessage: string = "";

    /**
     * @name TimerCount
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerCount: number = 0;

    /**
     * @name TimerTotalMilliseconds
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerTotalMilliseconds: number = 0;

    /**
     * @name TimerMaximumMilliseconds
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerMaximumMilliseconds: number = 0;

    /**
     * @name TimerMinimumMilliseconds
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerMinimumMilliseconds: number = 0;

    /**
     * @name TimerAverageMilliseconds
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerAverageMilliseconds: number = 0;

    /**
     * @name TimerMedianMilliseconds
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerMedianMilliseconds: number = 0;

    /**
     * @name TimerPercentile90Milliseconds
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerPercentile90Milliseconds: number = 0;

    /**
     * @name TimerStandardDeviationMilliseconds
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerStandardDeviationMilliseconds: number = 0;

    /**
     * @name TimerEventsMilliseconds
     * @servertype String
     * @type {string}
     */
    TimerEventsMilliseconds: string = "";

    /**
     * @name TimerStatsAsOfDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    TimerStatsAsOfDateTime: Date = null;

    /**
     * @name Details
     * @servertype IProcessStatusDetailModel array
     * @type {IProcessStatusDetailModel[]}
     */
    Details: IProcessStatusDetailModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {IMetaDataLightModel}
     */
    MetaData: IMetaDataLightModel = new IMetaDataLightModel();

}



/**
 * @name IProcessStatusDetailModel
 */
export class IProcessStatusDetailModel {

    /**
     * @name ProcessStatusDetailId
     * @servertype Int64
     * @type {number}
     */
    ProcessStatusDetailId: number = 0;

    /**
     * @name ProcessStatusId
     * @servertype Int64
     * @type {number}
     */
    ProcessStatusId: number = 0;

    /**
     * @name StartDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    StartDateTime: Date = null;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Value
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value: number = 0;

    /**
     * @name Information
     * @servertype String
     * @type {string}
     */
    Information: string = "";

    /**
     * @name DisplayOrder
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name DetailGroup
     * @servertype String
     * @type {string}
     */
    DetailGroup: string = "";

    /**
     * @name DetailGroupDisplayOrder
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DetailGroupDisplayOrder: number = 0;

    /**
     * @name ValueIsDenominator
     * @servertype Boolean
     * @type {boolean}
     */
    ValueIsDenominator: boolean = false;

    /**
     * @name LastUpdateDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastUpdateDateTime: Date = null;

    /**
     * @name TimerCount
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerCount: number = 0;

    /**
     * @name TimerTotalMilliseconds
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerTotalMilliseconds: number = 0;

    /**
     * @name TimerMaximumMilliseconds
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerMaximumMilliseconds: number = 0;

    /**
     * @name TimerMinimumMilliseconds
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerMinimumMilliseconds: number = 0;

    /**
     * @name TimerAverageMilliseconds
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerAverageMilliseconds: number = 0;

    /**
     * @name TimerMedianMilliseconds
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerMedianMilliseconds: number = 0;

    /**
     * @name TimerPercentile90Milliseconds
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerPercentile90Milliseconds: number = 0;

    /**
     * @name TimerStandardDeviationMilliseconds
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerStandardDeviationMilliseconds: number = 0;

    /**
     * @name TimerEventsMilliseconds
     * @servertype String
     * @type {string}
     */
    TimerEventsMilliseconds: string = "";

    /**
     * @name TimerStatsAsOfDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    TimerStatsAsOfDateTime: Date = null;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {IMetaDataLightModel}
     */
    MetaData: IMetaDataLightModel = new IMetaDataLightModel();

}



/**
 * @name PartitionBrandModel
 */
export class PartitionBrandModel {

    /**
     * @name BrandId
     * @servertype IB.Data.Model.BrandId Enum
     * @type {BrandId}
     */
    BrandId: BrandId = null;

    /**
     * @name Brand
     * @servertype String
     * @type {string}
     */
    Brand: string = "";

    /**
     * @name BrandTrademark
     * @servertype String
     * @type {string}
     */
    BrandTrademark: string = "";

    /**
     * @name ApplicationNameLong
     * @servertype String
     * @type {string}
     */
    ApplicationNameLong: string = "";

    /**
     * @name ApplicationNameShort
     * @servertype String
     * @type {string}
     */
    ApplicationNameShort: string = "";

    /**
     * @name ShowCopyrightMessage
     * @servertype Boolean
     * @type {boolean}
     */
    ShowCopyrightMessage: boolean = false;

    /**
     * @name CopyrightYears
     * @servertype String
     * @type {string}
     */
    CopyrightYears: string = "";

    /**
     * @name CopyrightCompany
     * @servertype String
     * @type {string}
     */
    CopyrightCompany: string = "";

    /**
     * @name CopyrightCompanyUrl
     * @servertype String
     * @type {string}
     */
    CopyrightCompanyUrl: string = "";

    /**
     * @name CopyrightIncludeLicensors
     * @servertype Boolean
     * @type {boolean}
     */
    CopyrightIncludeLicensors: boolean = false;

    /**
     * @name CopyrightIncludeLicensorsMessage
     * @servertype String
     * @type {string}
     */
    CopyrightIncludeLicensorsMessage: string = "";

    /**
     * @name PatentText
     * @servertype String
     * @type {string}
     */
    PatentText: string = "";

    /**
     * @name ShowPoweredByMessage
     * @servertype Boolean
     * @type {boolean}
     */
    ShowPoweredByMessage: boolean = false;

    /**
     * @name PoweredByName
     * @servertype String
     * @type {string}
     */
    PoweredByName: string = "";

    /**
     * @name PoweredByTrademark
     * @servertype String
     * @type {string}
     */
    PoweredByTrademark: string = "";

    /**
     * @name PoweredByUrl
     * @servertype String
     * @type {string}
     */
    PoweredByUrl: string = "";

    /**
     * @name SupportEmailAddress
     * @servertype String
     * @type {string}
     */
    SupportEmailAddress: string = "";

    /**
     * @name SupportUrlPortal
     * @servertype String
     * @type {string}
     */
    SupportUrlPortal: string = "";

    /**
     * @name SupportUrlIndex
     * @servertype String
     * @type {string}
     */
    SupportUrlIndex: string = "";

    /**
     * @name SupportUrlTopicTemplate
     * @servertype String
     * @type {string}
     */
    SupportUrlTopicTemplate: string = "";

    /**
     * @name SupportUrlAuthenticationQueryStringParameter
     * @servertype String
     * @type {string}
     */
    SupportUrlAuthenticationQueryStringParameter: string = "";

    /**
     * @name SupportUrlAuthenticationTokenType
     * @servertype IB.Data.Model.SupportUrlAuthenticationTokenType Enum
     * @type {SupportUrlAuthenticationTokenType}
     */
    SupportUrlAuthenticationTokenType: SupportUrlAuthenticationTokenType = null;

    /**
     * @name BrandTags
     * @servertype string array
     * @type {string[]}
     */
    BrandTags: string[] = [];

    /**
     * @name PrivateBranded
     * @servertype Boolean
     * @type {boolean}
     */
    PrivateBranded: boolean = false;

    /**
     * @name IconName
     * @servertype String
     * @type {string}
     */
    IconName: string = "";

    /**
     * @name LogoName
     * @servertype String
     * @type {string}
     */
    LogoName: string = "";

}



/**
 * @name BrandId Enum
 * @readonly
 * @enum {number}
 */
export enum BrandId {

    /**
     * Unknown
     */
    Unknown = 0,

    /**
     * Nubill
     */
    Nubill = 1,

    /**
     * IntelliBOSS
     */
    IntelliBOSS = 2,

    /**
     * Report Compiler
     */
    ReportCompiler = 3,

    /**
     * Qupport
     */
    Qupport = 4,

    /**
     * NetWiseCRM
     */
    NetWiseCRM = 5,

    /**
     * Custom
     */
    Custom = 2147483647

}



/**
 * @name SupportUrlAuthenticationTokenType Enum
 * @readonly
 * @enum {number}
 */
export enum SupportUrlAuthenticationTokenType {

    /**
     * None
     */
    None = 0,

    /**
     * Partner Token
     */
    PartnerToken = 1,

    /**
     * Native Token
     */
    NativeToken = 2,

    /**
     * Custom
     */
    Custom = 2147483647

}



/**
 * @name MenuItem
 */
export class MenuItem {

    /**
     * @name Id
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name Label
     * @servertype String
     * @type {string}
     */
    Label: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Icon
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name IconType
     * @servertype String
     * @type {string}
     */
    IconType: string = "";

    /**
     * @name LinkParam
     * @servertype String
     * @type {string}
     */
    LinkParam: string = "";

    /**
     * @name LinkType
     * @servertype String
     * @type {string}
     */
    LinkType: string = "";

    /**
     * @name InputParameters
     * @servertype MenuInputParameter array
     * @type {MenuInputParameter[]}
     */
    InputParameters: MenuInputParameter[] = [];

    /**
     * @name AssetId
     * @servertype Int64
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name FormId
     * @servertype Int64
     * @type {number}
     */
    FormId: number = 0;

    /**
     * @name AllowOpenInNewTab
     * @servertype Boolean
     * @type {boolean}
     */
    AllowOpenInNewTab: boolean = false;

    /**
     * @name AlwaysOpenInNewTab
     * @servertype Boolean
     * @type {boolean}
     */
    AlwaysOpenInNewTab: boolean = false;

    /**
     * @name ValidContactTypes
     * @servertype string array
     * @type {string[]}
     */
    ValidContactTypes: string[] = [];

    /**
     * @name FilterContactTypes
     * @servertype string array
     * @type {string[]}
     */
    FilterContactTypes: string[] = [];

    /**
     * @name FilterControls
     * @servertype string array
     * @type {string[]}
     */
    FilterControls: string[] = [];

    /**
     * @name Modules
     * @servertype IB.Data.Model.System.Modules
     * @type {Modules}
     */
    Modules: Modules = null;

    /**
     * @name Permissions
     * @servertype IB.Data.Model.System.Permissions
     * @type {Permissions}
     */
    Permissions: Permissions = null;

    /**
     * @name SecurityScope
     * @servertype IB.Data.Model.System.SecurityScope
     * @type {SecurityScope}
     */
    SecurityScope: SecurityScope = null;

    /**
     * @name Custom
     * @servertype Boolean
     * @type {boolean}
     */
    Custom: boolean = false;

    /**
     * @name Visible
     * @servertype Boolean
     * @type {boolean}
     */
    Visible: boolean = false;

    /**
     * @name Children
     * @servertype MenuItem array
     * @type {MenuItem[]}
     */
    Children: MenuItem[] = [];

}



/**
 * @name MenuInputParameter
 */
export class MenuInputParameter {

    /**
     * @name Id
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name Key
     * @servertype String
     * @type {string}
     */
    Key: string = "";

    /**
     * @name Value
     * @servertype String
     * @type {string}
     */
    Value: string = "";

    /**
     * @name PassThru
     * @servertype Boolean
     * @type {boolean}
     */
    PassThru: boolean = false;

}



/**
 * @name Modules
 */
export class Modules {

    /**
     * @name Flags
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name AllowOnAnyRequired
     * @servertype Boolean
     * @type {boolean}
     */
    AllowOnAnyRequired: boolean = false;

    /**
     * @name ModuleList
     * @servertype Module array
     * @type {Module[]}
     */
    ModuleList: Module[] = [];

}



/**
 * @name Module
 */
export class Module {

    /**
     * @name Id
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name LicensedModule
     * @servertype String
     * @type {string}
     */
    LicensedModule: string = "";

    /**
     * @name Required
     * @servertype Boolean
     * @type {boolean}
     */
    Required: boolean = false;

}



/**
 * @name Permissions
 */
export class Permissions {

    /**
     * @name Flags
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name AllowOnAnyRequired
     * @servertype Boolean
     * @type {boolean}
     */
    AllowOnAnyRequired: boolean = false;

    /**
     * @name PermissionList
     * @servertype Permission array
     * @type {Permission[]}
     */
    PermissionList: Permission[] = [];

    /**
     * @name RequiredRoleIdsAll
     * @servertype long array
     * @type {number[]}
     */
    RequiredRoleIdsAll: number[] = [];

    /**
     * @name RequiredRoleIdsAny
     * @servertype long array
     * @type {number[]}
     */
    RequiredRoleIdsAny: number[] = [];

}



/**
 * @name Permission
 */
export class Permission {

    /**
     * @name Id
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name PermissionAreaType
     * @servertype String
     * @type {string}
     */
    PermissionAreaType: string = "";

    /**
     * @name PermissionArea
     * @servertype String
     * @type {string}
     */
    PermissionArea: string = "";

    /**
     * @name Required
     * @servertype Boolean
     * @type {boolean}
     */
    Required: boolean = false;

    /**
     * @name Rights
     * @servertype string array
     * @type {string[]}
     */
    Rights: string[] = [];

    /**
     * @name AllRightsRequired
     * @servertype Boolean
     * @type {boolean}
     */
    AllRightsRequired: boolean = false;

}



/**
 * @name SecurityScope
 */
export class SecurityScope {

    /**
     * @name ModulesAll
     * @servertype string array
     * @type {string[]}
     */
    ModulesAll: string[] = [];

    /**
     * @name ModulesAny
     * @servertype string array
     * @type {string[]}
     */
    ModulesAny: string[] = [];

    /**
     * @name FeatureFlagsAll
     * @servertype string array
     * @type {string[]}
     */
    FeatureFlagsAll: string[] = [];

    /**
     * @name FeatureFlagsAny
     * @servertype string array
     * @type {string[]}
     */
    FeatureFlagsAny: string[] = [];

    /**
     * @name ContactTypesAny
     * @servertype string array
     * @type {string[]}
     */
    ContactTypesAny: string[] = [];

    /**
     * @name PermissionsAll
     * @servertype SecurityScopePermission array
     * @type {SecurityScopePermission[]}
     */
    PermissionsAll: SecurityScopePermission[] = [];

    /**
     * @name PermissionsAny
     * @servertype SecurityScopePermission array
     * @type {SecurityScopePermission[]}
     */
    PermissionsAny: SecurityScopePermission[] = [];

    /**
     * @name RoleIdsAll
     * @servertype long array
     * @type {number[]}
     */
    RoleIdsAll: number[] = [];

    /**
     * @name RoleIdsAny
     * @servertype long array
     * @type {number[]}
     */
    RoleIdsAny: number[] = [];

    /**
     * @name GroupIdsAll
     * @servertype long array
     * @type {number[]}
     */
    GroupIdsAll: number[] = [];

    /**
     * @name GroupIdsAny
     * @servertype long array
     * @type {number[]}
     */
    GroupIdsAny: number[] = [];

    /**
     * @name Flags
     * @servertype string array
     * @type {string[]}
     */
    Flags: string[] = [];

}



/**
 * @name SecurityScopePermission
 */
export class SecurityScopePermission {

    /**
     * @name Id
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name SecurityAreaType
     * @servertype String
     * @type {string}
     */
    SecurityAreaType: string = "";

    /**
     * @name SecurityArea
     * @servertype String
     * @type {string}
     */
    SecurityArea: string = "";

    /**
     * @name SecurityRights
     * @servertype string array
     * @type {string[]}
     */
    SecurityRights: string[] = [];

}



export module System {

/**
 * @name TypeCode Enum
 * @readonly
 * @enum {number}
 */
export enum TypeCode {

    /**
     * Empty
     */
    Empty = 0,

    /**
     * Object
     */
    Object = 1,

    /**
     * D B Null
     */
    DBNull = 2,

    /**
     * Boolean
     */
    Boolean = 3,

    /**
     * Char
     */
    Char = 4,

    /**
     * S Byte
     */
    SByte = 5,

    /**
     * Byte
     */
    Byte = 6,

    /**
     * Int 16
     */
    Int16 = 7,

    /**
     * U Int 16
     */
    UInt16 = 8,

    /**
     * Int 32
     */
    Int32 = 9,

    /**
     * U Int 32
     */
    UInt32 = 10,

    /**
     * Int 64
     */
    Int64 = 11,

    /**
     * U Int 64
     */
    UInt64 = 12,

    /**
     * Single
     */
    Single = 13,

    /**
     * Double
     */
    Double = 14,

    /**
     * Decimal
     */
    Decimal = 15,

    /**
     * Date Time
     */
    DateTime = 16,

    /**
     * String
     */
    String = 18

}

}



/**
 * @name Authentication Data
 * @description
 * This holds information about the results of an authentication request.
 */
export class AuthenticationData {

    /**
     * @name IsValid
     * @description 
     * This is set to true when the authentication request was valid.
     * @servertype Boolean
     * @type {boolean}
     */
    IsValid: boolean = false;

    /**
     * @name Method
     * @description 
     * The authentication method that was utilized.  This is typically Token or ApiKey.
     * @servertype String
     * @type {string}
     */
    Method: string = "";

    /**
     * @name ContactId
     * @description 
     * The contact id of the authenticated user.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name PartitionId
     * @description 
     * This partition id of the authenticated user.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name Issued
     * @description 
     * The date and time when the authentication device (token or api-key) was issued.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    Issued: Date = null;

    /**
     * @name Expires
     * @description 
     * The date and time when the authentication device (token or api-key) expires.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    Expires: Date = null;

    /**
     * @name ErrorMessage
     * @description 
     * An error message, if any, about the authentication attempt.
     * @servertype String
     * @type {string}
     */
    ErrorMessage: string = "";

    /**
     * @name Flags
     * @description 
     * Any flags attached to the authentication device (token or api-key).
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Raw
     * @description 
     * The raw representation of the authentication device (token or api-key).
     * @servertype String
     * @type {string}
     */
    Raw: string = "";

    /**
     * @name Formatted
     * @description 
     * The formatted representation of the authentication device (token or api-key).  The format varies based on the contents of
     * the authentication device used.
     * @servertype System.Object
     * @type {any}
     */
    Formatted: any = {};

    /**
     * @name Permissions
     * @description 
     * The permissions associated with the authentication device (token or api-key).
     * @servertype System.Object
     * @type {any}
     */
    Permissions: any = {};

}



/**
 * @name InputControlTypeOption Enum
 * @readonly
 * @enum {number}
 */
export enum InputControlTypeOption {

    /**
     * Auto
     */
    Auto = 0,

    /**
     * Single Line Editor
     */
    SingleLineEditor = 11,

    /**
     * Single Line Editor Auto Complete
     */
    SingleLineEditorAutoComplete = 12,

    /**
     * Multi Line Editor
     */
    MultiLineEditor = 21,

    /**
     * Integer
     */
    Integer = 31,

    /**
     * Float
     */
    Float = 41,

    /**
     * Date Time
     */
    DateTime = 51,

    /**
     * Date
     */
    Date = 52,

    /**
     * Check Box
     */
    CheckBox = 61,

    /**
     * Radio Button
     */
    RadioButton = 71,

    /**
     * Drop Down List
     */
    DropDownList = 81,

    /**
     * Drop Down
     */
    DropDown = 82,

    /**
     * Multi Select Drop Down List
     */
    MultiSelectDropDownList = 83

}



/**
 * @name InputControlWidthOption Enum
 * @readonly
 * @enum {number}
 */
export enum InputControlWidthOption {

    /**
     * Auto
     */
    Auto = 0,

    /**
     * Full
     */
    Full = 1,

    /**
     * X Large
     */
    XLarge = 14,

    /**
     * Large
     */
    Large = 15,

    /**
     * Medium
     */
    Medium = 25,

    /**
     * Small
     */
    Small = 35,

    /**
     * X Small
     */
    XSmall = 36

}



/**
 * @name PickListOptionViewModel
 */
export class PickListOptionViewModel {

    /**
     * @name PickListId
     * @servertype String
     * @type {string}
     */
    PickListId: string = "";

    /**
     * @name DisplayOrder
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name GroupText
     * @servertype String
     * @type {string}
     */
    GroupText: string = "";

    /**
     * @name DisplayText
     * @servertype String
     * @type {string}
     */
    DisplayText: string = "";

    /**
     * @name Value
     * @servertype String
     * @type {string}
     */
    Value: string = "";

    /**
     * @name Icon
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name IconColor
     * @servertype String
     * @type {string}
     */
    IconColor: string = "";

    /**
     * @name TextColor
     * @servertype String
     * @type {string}
     */
    TextColor: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name IsDefaultValue
     * @servertype Boolean
     * @type {boolean}
     */
    IsDefaultValue: boolean = false;

    /**
     * @name IsMutuallyExclusive
     * @servertype Boolean
     * @type {boolean}
     */
    IsMutuallyExclusive: boolean = false;

    /**
     * @name Children
     * @servertype PickListOptionViewModel array
     * @type {PickListOptionViewModel[]}
     */
    Children: PickListOptionViewModel[] = [];

    /**
     * @name PickListValueId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PickListValueId: number = 0;

    /**
     * @name ParentPickListValueId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentPickListValueId: number = 0;

}



/**
 * @name Key Value Pair
 * @description
 * This object contains a key and associated value.
 */
export class KeyValuePairModel<TKey, TValue> {

    /**
     * @name Key
     * @servertype 
     * @type {TKey}
     */
    Key: TKey = null;

    /**
     * @name Value
     * @servertype 
     * @type {TValue}
     */
    Value: TValue = null;

}



/**
 * @name Property Meta Data
 * @description
 * This contains information about a property, data types, default values, validation, generic input helpers, etc.
 */
export class PropertyMetaDataViewModel {

    /**
     * @name PropertyConfigurationContainerId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PropertyConfigurationContainerId: number = 0;

    /**
     * @name PropertyConfigurationId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PropertyConfigurationId: number = 0;

    /**
     * @name PropertyNumber
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PropertyNumber: number = 0;

    /**
     * @name PropertyName
     * @servertype String
     * @type {string}
     */
    PropertyName: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name DataType
     * @servertype System.TypeCode Enum
     * @type {System.TypeCode}
     */
    DataType: System.TypeCode = null;

    /**
     * @name DataTypeInformation
     * @servertype String
     * @type {string}
     */
    DataTypeInformation: string = "";

    /**
     * @name IsNullable
     * @servertype Boolean
     * @type {boolean}
     */
    IsNullable: boolean = false;

    /**
     * @name IsCollection
     * @servertype Boolean
     * @type {boolean}
     */
    IsCollection: boolean = false;

    /**
     * @name FullyQualifiedTypeName
     * @servertype String
     * @type {string}
     */
    FullyQualifiedTypeName: string = "";

    /**
     * @name DataTypeDescription
     * @servertype String
     * @type {string}
     */
    DataTypeDescription: string = "";

    /**
     * @name Properties
     * @servertype PropertyMetaDataViewModel array
     * @type {PropertyMetaDataViewModel[]}
     */
    Properties: PropertyMetaDataViewModel[] = [];

    /**
     * @name IsKeyProperty
     * @servertype Boolean
     * @type {boolean}
     */
    IsKeyProperty: boolean = false;

    /**
     * @name IsDescriptionProperty
     * @servertype Boolean
     * @type {boolean}
     */
    IsDescriptionProperty: boolean = false;

    /**
     * @name IsPublicProperty
     * @servertype Boolean
     * @type {boolean}
     */
    IsPublicProperty: boolean = false;

    /**
     * @name DefaultValue
     * @servertype System.Object
     * @type {any}
     */
    DefaultValue: any = {};

    /**
     * @name DefaultValueTemplate
     * @servertype String
     * @type {string}
     */
    DefaultValueTemplate: string = "";

    /**
     * @name PickList
     * @servertype PickListOptionViewModel array
     * @type {PickListOptionViewModel[]}
     */
    PickList: PickListOptionViewModel[] = [];

    /**
     * @name PickListId
     * @servertype String
     * @type {string}
     */
    PickListId: string = "";

    /**
     * @name PickListFilter
     * @servertype String
     * @type {string}
     */
    PickListFilter: string = "";

    /**
     * @name PickListIncludeOptionForNone
     * @servertype Boolean
     * @type {boolean}
     */
    PickListIncludeOptionForNone: boolean = false;

    /**
     * @name PickListTreatMultipleSelectionsAsMultipleSubmissionsWhenPossible
     * @servertype Boolean
     * @type {boolean}
     */
    PickListTreatMultipleSelectionsAsMultipleSubmissionsWhenPossible: boolean = false;

    /**
     * @name IfPropertyValueEmptyAction
     * @servertype IB.Web.API.Models.PropertyMetaDataAction Enum
     * @type {PropertyMetaDataAction}
     */
    IfPropertyValueEmptyAction: PropertyMetaDataAction = null;

    /**
     * @name IsRequired
     * @servertype Boolean
     * @type {boolean}
     */
    IsRequired: boolean = false;

    /**
     * @name IsReadOnly
     * @servertype Boolean
     * @type {boolean}
     */
    IsReadOnly: boolean = false;

    /**
     * @name IsHidden
     * @servertype Boolean
     * @type {boolean}
     */
    IsHidden: boolean = false;

    /**
     * @name MapFromPropertyName
     * @servertype String
     * @type {string}
     */
    MapFromPropertyName: string = "";

    /**
     * @name MapFromPropertyMatchMode
     * @servertype IB.Web.API.Models.PropertyMetaDataMapMatchMode Enum
     * @type {PropertyMetaDataMapMatchMode}
     */
    MapFromPropertyMatchMode: PropertyMetaDataMapMatchMode = null;

    /**
     * @name MapFromTemplate
     * @servertype String
     * @type {string}
     */
    MapFromTemplate: string = "";

    /**
     * @name MapFromStaticValue
     * @servertype String
     * @type {string}
     */
    MapFromStaticValue: string = "";

    /**
     * @name MappedValueTransformation
     * @servertype String
     * @type {string}
     */
    MappedValueTransformation: string = "";

    /**
     * @name MappedValueTrim
     * @servertype Boolean
     * @type {boolean}
     */
    MappedValueTrim: boolean = false;

    /**
     * @name MappedValueMode
     * @servertype IB.Web.API.Models.PropertyMetaDataMapValueMode Enum
     * @type {PropertyMetaDataMapValueMode}
     */
    MappedValueMode: PropertyMetaDataMapValueMode = null;

    /**
     * @name MappedValueDelimiter
     * @servertype String
     * @type {string}
     */
    MappedValueDelimiter: string = "";

    /**
     * @name InputLabel
     * @servertype String
     * @type {string}
     */
    InputLabel: string = "";

    /**
     * @name InputDescription
     * @servertype String
     * @type {string}
     */
    InputDescription: string = "";

    /**
     * @name InputDisplayGroup
     * @servertype Int32
     * @type {number}
     */
    InputDisplayGroup: number = 0;

    /**
     * @name InputDisplayOrder
     * @servertype Int32
     * @type {number}
     */
    InputDisplayOrder: number = 0;

    /**
     * @name InputControlType
     * @servertype IB.Web.API.Models.InputControlTypeOption Enum
     * @type {InputControlTypeOption}
     */
    InputControlType: InputControlTypeOption = null;

    /**
     * @name InputControlWidth
     * @servertype IB.Web.API.Models.InputControlWidthOption Enum
     * @type {InputControlWidthOption}
     */
    InputControlWidth: InputControlWidthOption = null;

    /**
     * @name InputMask
     * @servertype String
     * @type {string}
     */
    InputMask: string = "";

    /**
     * @name IfValidationFailedAction
     * @servertype IB.Web.API.Models.PropertyMetaDataAction Enum
     * @type {PropertyMetaDataAction}
     */
    IfValidationFailedAction: PropertyMetaDataAction = null;

    /**
     * @name ValidationMinimum
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ValidationMinimum: number = 0;

    /**
     * @name ValidationMaximum
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ValidationMaximum: number = 0;

    /**
     * @name ValidationAllowedValues
     * @servertype object array
     * @type {any[]}
     */
    ValidationAllowedValues: any[] = [];

    /**
     * @name ValidationTemplate
     * @servertype String
     * @type {string}
     */
    ValidationTemplate: string = "";

    /**
     * @name ValidationTransformation
     * @servertype String
     * @type {string}
     */
    ValidationTransformation: string = "";

    /**
     * @name ValidationExpression
     * @servertype String
     * @type {string}
     */
    ValidationExpression: string = "";

    /**
     * @name ValidationExpressionFailedMessage
     * @servertype String
     * @type {string}
     */
    ValidationExpressionFailedMessage: string = "";

    /**
     * @name ValidationOtherDescription
     * @servertype String
     * @type {string}
     */
    ValidationOtherDescription: string = "";

}



/**
 * @name PropertyMetaDataAction Enum
 * @readonly
 * @enum {number}
 */
export enum PropertyMetaDataAction {

    /**
     * Ignore
     */
    Ignore = 0,

    /**
     * Post Warning
     */
    PostWarning = 10,

    /**
     * Post Error
     */
    PostError = 20,

    /**
     * Fail
     */
    Fail = 21

}



/**
 * @name PropertyMetaDataMapMatchMode Enum
 * @readonly
 * @enum {number}
 */
export enum PropertyMetaDataMapMatchMode {

    /**
     * The first matching property is used.
     */
    First = 0,

    /**
     * A single exact matching property is used.
     */
    Exact = 1,

    /**
     * All matching properties are used.
     */
    All = 2

}



/**
 * @name PropertyMetaDataMapValueMode Enum
 * @readonly
 * @enum {number}
 */
export enum PropertyMetaDataMapValueMode {

    /**
     * The property value is replaced.
     */
    Replace = 0,

    /**
     * The property value is appended to using a delimiter.
     */
    Append = 1

}



/**
 * @name RouteSegment
 */
export module RouteSegment {
    export var Customers: string = "customers";
    export var Directory: string = "directory";
    export var Locations: string = "locations";
    export var Vendors: string = "vendors";
    export var Warehouses: string = "warehouses";
    export var Groups: string = "groups";
    export var Contacts: string = "contacts";
    export var ContactTypes: string = "contact-types";
    export var WorkSchedules: string = "work-schedules";
    export var WorkScheduleExceptions: string = "work-schedule-exceptions";
    export var ExternalAuthentications: string = "external-authentications";
    export var Access: string = "access";
    export var Members: string = "members";
    export var SalesOpportunities: string = "sales-opportunities";
    export var Documentation: string = "documentation";
    export var ApiConfig: string = "api-config";
    export var Endpoints: string = "endpoints";
    export var DataModels: string = "data-models";
    export var RawDataModels: string = "raw-data-models";
    export var DataOptions: string = "data-options";
    export var Data: string = "data";
    export var Security: string = "security";
    export var Authenticate: string = "authenticate";
    export var Login: string = "login";
    export var MultiFactorAuthentication: string = "multi-factor-authentication";
    export var TOTP: string = "totp";
    export var Token: string = "token";
    export var LoginAvailable: string = "login-available";
    export var LoginChange: string = "login-change";
    export var LoginRecover: string = "login-recover";
    export var PasswordReset: string = "password-reset";
    export var PasswordChange: string = "password-change";
    export var PasswordView: string = "password-view";
    export var ComputeUserPermissions: string = "compute-user-permissions";
    export var Policy: string = "policy";
    export var Mail: string = "mail";
    export var Servers: string = "servers";
    export var Addresses: string = "addresses";
    export var DataImportDefinitions: string = "data-import-definitions";
    export var Inventory: string = "inventory";
    export var History: string = "history";
    export var InventoryEvents: string = "inventory-events";
    export var InventoryTypes: string = "inventory-types";
    export var InventoryQuantityTypes: string = "inventory-quantity-types";
    export var Versions: string = "versions";
    export var Reports: string = "reports";
    export var Jobs: string = "jobs";
    export var JobTypes: string = "job-types";
    export var TimeZones: string = "time-zones";
    export var Roles: string = "roles";
    export var Encryption: string = "encryption";
    export var Echo: string = "echo";
    export var Cache: string = "cache";
    export var PackageSignups: string = "package-signups";
    export var Actions: string = "actions";
    export var Start: string = "start";
    export var Stop: string = "stop";
    export var Request: string = "request";
    export var Approve: string = "approve";
    export var Accept: string = "accept";
    export var Reject: string = "reject";
    export var Analyze: string = "analyze";
    export var Move: string = "move";
    export var Sign: string = "sign";
    export var Download: string = "download";
    export var Upload: string = "upload";
    export var Export: string = "export";
    export var Import: string = "import";
    export var View: string = "view";
    export var Validate: string = "validate";
    export var Run: string = "run";
    export var Execute: string = "execute";
    export var Preview: string = "preview";
    export var Prepare: string = "prepare";
    export var AddNewGetExisting: string = "add-new-get-existing";
    export var New: string = "new";
    export var AddFromTemplate: string = "add-from-template";
    export var AddFromList: string = "add-from-list";
    export var AutoAdd: string = "auto-add";
    export var BulkAdd: string = "bulk-add";
    export var BatchAdd: string = "batch-add";
    export var RefreshStats: string = "refresh-stats";
    export var Activate: string = "activate";
    export var Suspend: string = "suspend";
    export var Available: string = "available";
    export var Reserve: string = "reserve";
    export var Reset: string = "reset";
    export var Test: string = "test";
    export var Check: string = "check";
    export var Calc: string = "calc";
    export var Count: string = "count";
    export var Purge: string = "purge";
    export var Setup: string = "setup";
    export var Flatten: string = "flatten";
    export var Events: string = "events";
    export var Preferences: string = "preferences";
    export var Internal: string = "internal";
    export var External: string = "external";
    export var Assets: string = "assets";
    export var Tags: string = "tags";
    export var References: string = "references";
    export var ObjectStatus: string = "object-status";
    export var Related: string = "related";
    export var Selections: string = "selections";
    export var Feedback: string = "feedback";
    export var AccessLog: string = "access-log";
    export var AssetAccessLog: string = "asset-access-log";
    export var AssetLicenses: string = "asset-licenses";
    export var SeeAlso: string = "see-also";
    export var Notice: string = "notice";
    export var Files: string = "files";
    export var Draft: string = "draft";
    export var Final: string = "final";
    export var Create: string = "create";
    export var Close: string = "close";
    export var Open: string = "open";
    export var Trace: string = "trace";
    export var MostRecent: string = "most-recent";
    export var ReviewSubmit: string = "review-submit";
    export var ReviewRetract: string = "review-retract";
    export var ReviewResult: string = "review-result";
    export var Library: string = "library";
    export var ReviewDocument: string = "review-document";
    export var Coverage: string = "coverage";
    export var ReportParser: string = "report-parser";
    export var Version: string = "version";
    export var Parents: string = "parents";
    export var Children: string = "children";
    export var List: string = "list";
    export var Status: string = "status";
    export var Stats: string = "stats";
    export var Organization: string = "organization";
    export var Industry: string = "industry";
    export var IndustryLibrary: string = "industry-library";
    export var TaskListTemplates: string = "task-list-templates";
    export var TaskLists: string = "task-lists";
    export var Tasks: string = "tasks";
    export var Subtasks: string = "subtasks";
    export var CaseTemplates: string = "case-templates";
    export var Correspondence: string = "correspondence";
    export var Cases: string = "cases";
    export var Notes: string = "notes";
    export var Attachments: string = "attachments";
    export var Suggestions: string = "suggestions";
    export var Assign: string = "assign";
    export var Remove: string = "remove";
    export var ProcessTemplates: string = "process-templates";
    export var Processes: string = "processes";
    export var RawAttributes: string = "raw-attributes";
    export var Attributes: string = "attributes";
    export var AttributeSets: string = "attribute-sets";
    export var Configurations: string = "configurations";
    export var Forms: string = "forms";
    export var Controls: string = "controls";
    export var Html: string = "html";
    export var HelpLinks: string = "help-links";
    export var Templates: string = "templates";
    export var Sync: string = "sync";
    export var DataStores: string = "data-stores";
    export var PublisherArticleTypes: string = "publisher-article-types";
    export var Subscriptions: string = "subscriptions";
    export var Articles: string = "articles";
    export var PaymentProviders: string = "payment-providers";
    export var PaymentTransactions: string = "payment-transactions";
    export var Payments: string = "payments";
    export var Sales: string = "sales";
    export var Refunds: string = "refunds";
    export var PaymentNotifications: string = "payment-notifications";
    export var SendReceipt: string = "send-receipt";
    export var PaymentMethodCardTypes: string = "payment-method-card-types";
    export var PaymentMethods: string = "payment-methods";
    export var PaymentMethodCreditCards: string = "credit-cards";
    export var SelectionRules: string = "selection-rules";
    export var Triggers: string = "triggers";
    export var Billing: string = "billing";
    export var BillingProfiles: string = "billing-profiles";
    export var BillingReportProfiles: string = "billing-report-profiles";
    export var BillingAccounts: string = "billing-accounts";
    export var BillingTransactions: string = "billing-transactions";
    export var BillingEvents: string = "billing-events";
    export var Packages: string = "packages";
    export var PackageOccurrences: string = "package-occurrences";
    export var Items: string = "items";
    export var Rates: string = "rates";
    export var RateAdjustments: string = "rate-adjustments";
    export var Lists: string = "lists";
    export var Purchases: string = "purchases";
    export var PayPal: string = "pay-pal";
    export var Braintree: string = "braintree";
    export var Adyen: string = "adyen";
    export var IPN: string = "ipn";
    export var Authorization: string = "authorization";
    export var Tax: string = "tax";
    export var Geocodes: string = "geocodes";
    export var GeocodeLookups: string = "geocode-lookups";
    export var Jurisdictions: string = "jurisdictions";
    export var Matrix: string = "matrix";
    export var TransactionDetails: string = "transaction-details";
    export var AuthorizationCodes: string = "authorization-codes";
    export var Associations: string = "associations";
    export var Vouchers: string = "vouchers";
    export var Batches: string = "batches";
    export var Lots: string = "lots";
    export var NextSerialNumber: string = "next-serial-number";
    export var NotificationContacts: string = "notification-contacts";
    export var NotificationMessages: string = "notification-messages";
    export var NotificationOptOuts: string = "notification-opt-outs";
    export var NotificationGroups: string = "notification-groups";
    export var Details: string = "details";
    export var NotificationEvents: string = "notification-events";
    export var Notifications: string = "notifications";
    export var ActionLinks: string = "action-links";
    export var AlarmRules: string = "alarm-rules";
    export var Currency: string = "currency";
    export var Visibility: string = "visibility";
    export var RestrictionRules: string = "restriction-rules";
    export var Aliases: string = "aliases";
    export var Filters: string = "filters";
    export var System: string = "system";
    export var Search: string = "search";
    export var Table: string = "table";
    export var Settings: string = "settings";
    export var AppSettings: string = "app-settings";
    export var Query: string = "query";
    export var Queries: string = "queries";
    export var Translations: string = "translations";
    export var Find: string = "find";
    export var Missing: string = "missing";
    export var Management: string = "management";
    export var Merge: string = "merge";
    export var Duplicate: string = "duplicate";
    export var NotDuplicate: string = "not-duplicate";
    export var PossibleDuplicates: string = "possible-duplicates";
    export var ChildSynopsis: string = "child-synopsis";
    export var Sections: string = "sections";
    export var Text: string = "text";
    export var Documents: string = "documents";
    export var MetaData: string = "meta-data";
    export var Config: string = "config";
    export var Options: string = "options";
    export var OptionsList: string = "options-list";
    export var ObjectPickList: string = "object-pick-list";
    export var CustomPickLists: string = "custom-pick-lists";
    export var PickListSelections: string = "pick-list-selections";
    export var PickListCategories: string = "pick-list-categories";
    export var PickLists: string = "pick-lists";
    export var PickListValues: string = "pick-list-values";
    export var DataSources: string = "data-sources";
    export var FileServers: string = "file-servers";
    export var DataTableSupport: string = "data-table-support";
    export var FreeFormTextParsers: string = "free-form-text-parsers";
    export var ServerResources: string = "server-resources";
    export var Favorites: string = "favorites";
    export var Dashboards: string = "dashboards";
    export var Widgets: string = "widgets";
    export var Bookmarks: string = "bookmarks";
    export var Webhooks: string = "webhooks";
    export var WebhookEvents: string = "webhook-events";
    export var Target: string = "target";
    export var WatchEvents: string = "watch-events";
    export var ApplicationInformation: string = "application-information";
    export var Partitions: string = "partitions";
    export var Services: string = "services";
    export var Domains: string = "domains";
    export var PartitionRoutes: string = "partition-routes";
    export var ApiAccess: string = "api-access";
    export var Clients: string = "clients";
    export var Activity: string = "activity";
    export var NetworkElements: string = "network-elements";
    export var PortGroups: string = "port-groups";
    export var Ports: string = "ports";
    export var Telecom: string = "telecom";
    export var NumberingPlans: string = "numbering-plans";
    export var Standard: string = "standard";
    export var Custom: string = "custom";
    export var LocationProfiles: string = "location-profiles";
    export var LocationGroups: string = "location-groups";
    export var Links: string = "links";
    export var Usage: string = "usage";
    export var ServiceIdentifications: string = "service-identifications";
    export var ImportLogs: string = "import-logs";
    export var DataFeed: string = "data-feed";
    export var CostCenterRating: string = "cost-center-rating";
    export var Rating: string = "rating";
    export var Profiles: string = "profiles";
    export var Step: string = "step";
    export var Steps: string = "steps";
    export var Zones: string = "zones";
    export var SurchargeDiscounts: string = "surcharge-discounts";
    export var Holidays: string = "holidays";
    export var Logs: string = "logs";
    export var ProcessStatus: string = "process-status";
    export var QrCode: string = "qr-code";
    export var ReportCompiler: string = "report-compiler";
    export var LibraryGroups: string = "library-groups";
    export var TemplateTypes: string = "template-types";
    export var IndustryLibraryTypeInformation: string = "industry-library-type-information";
    export var SubjectMatter: string = "subject-matter";
    export var Other: string = "other";
    export var Structural: string = "structural";
    export var IndustryLibraryApproval: string = "industry-library-approval";
    export var AppInit: string = "app-init";
}



