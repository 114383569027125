import * as m from "./ngCoreModels";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { Helper } from "projects/core-lib/src/lib/helpers/helper";
import { SelectItem } from "primeng/api";
import { IconHelper } from "projects/common-lib/src/lib/image/icon/icon-helper";
import { Dictionary } from "./dictionary";


export interface OptInFeatures {
  ObjectExportImportSupport?: boolean;
  ShowComputedPermissions?: boolean;
}


export interface IPickListValueProperties {
  value: string;
  displayText: string;
  icon: string;
  iconColor: string;
}


export interface ObjectPatch {
  objectType?: string;
  objectId?: number;
  action?: "add" | "edit" | "delete";
  propertyName?: string;
  value?: string;
}



export class AssetSelectionRuleOptions {
  showMatchAllOption: boolean = false;
  property01: string = "";
  property02: string = "";
  property03: string = "";
  property04: string = "";
  property05: string = "";
  property06: string = "";
  property07: string = "";
  property08: string = "";
  property09: string = "";
  property10: string = "";
  value01UsePickList: boolean = false;
  value02UsePickList: boolean = false;
  value03UsePickList: boolean = false;
  value04UsePickList: boolean = false;
  value05UsePickList: boolean = false;
  value06UsePickList: boolean = false;
  value07UsePickList: boolean = false;
  value08UsePickList: boolean = false;
  value09UsePickList: boolean = false;
  value10UsePickList: boolean = false;
  value01UsePickListGroups: boolean = false;
  value02UsePickListGroups: boolean = false;
  value03UsePickListGroups: boolean = false;
  value04UsePickListGroups: boolean = false;
  value05UsePickListGroups: boolean = false;
  value06UsePickListGroups: boolean = false;
  value07UsePickListGroups: boolean = false;
  value08UsePickListGroups: boolean = false;
  value09UsePickListGroups: boolean = false;
  value10UsePickListGroups: boolean = false;
  value01PickList: m5core.PickListSelectionViewModel[] = [];
  value02PickList: m5core.PickListSelectionViewModel[] = [];
  value03PickList: m5core.PickListSelectionViewModel[] = [];
  value04PickList: m5core.PickListSelectionViewModel[] = [];
  value05PickList: m5core.PickListSelectionViewModel[] = [];
  value06PickList: m5core.PickListSelectionViewModel[] = [];
  value07PickList: m5core.PickListSelectionViewModel[] = [];
  value08PickList: m5core.PickListSelectionViewModel[] = [];
  value09PickList: m5core.PickListSelectionViewModel[] = [];
  value10PickList: m5core.PickListSelectionViewModel[] = [];
  value01PickListCascadeFrom: number;
  value02PickListCascadeFrom: number;
  value03PickListCascadeFrom: number;
  value04PickListCascadeFrom: number;
  value05PickListCascadeFrom: number;
  value06PickListCascadeFrom: number;
  value07PickListCascadeFrom: number;
  value08PickListCascadeFrom: number;
  value09PickListCascadeFrom: number;
  value10PickListCascadeFrom: number;
  value01PickListWhenCascadeFromHasEmptyValue: m5core.PickListSelectionViewModel[] = [];
  value02PickListWhenCascadeFromHasEmptyValue: m5core.PickListSelectionViewModel[] = [];
  value03PickListWhenCascadeFromHasEmptyValue: m5core.PickListSelectionViewModel[] = [];
  value04PickListWhenCascadeFromHasEmptyValue: m5core.PickListSelectionViewModel[] = [];
  value05PickListWhenCascadeFromHasEmptyValue: m5core.PickListSelectionViewModel[] = [];
  value06PickListWhenCascadeFromHasEmptyValue: m5core.PickListSelectionViewModel[] = [];
  value07PickListWhenCascadeFromHasEmptyValue: m5core.PickListSelectionViewModel[] = [];
  value08PickListWhenCascadeFromHasEmptyValue: m5core.PickListSelectionViewModel[] = [];
  value09PickListWhenCascadeFromHasEmptyValue: m5core.PickListSelectionViewModel[] = [];
  value10PickListWhenCascadeFromHasEmptyValue: m5core.PickListSelectionViewModel[] = [];
  value01PickListNoneLabel: string = "";
  value02PickListNoneLabel: string = "";
  value03PickListNoneLabel: string = "";
  value04PickListNoneLabel: string = "";
  value05PickListNoneLabel: string = "";
  value06PickListNoneLabel: string = "";
  value07PickListNoneLabel: string = "";
  value08PickListNoneLabel: string = "";
  value09PickListNoneLabel: string = "";
  value10PickListNoneLabel: string = "";
}


// We usually pull PickListSelectionViewModel[] from the documentation/data-options api but some are static to our app and defined here
export class StaticPickList {


  public static stringArrayToPickList(items: string[], includeNone: boolean = false): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    if (includeNone) {
      list.push({ Value: "", DisplayText: "<None>" } as m5core.PickListSelectionViewModel);
    }
    if (!items || items.length === 0) {
      return list;
    }
    let order: number = 1;
    items.forEach((item) => {
      const option = new m5core.PickListSelectionViewModel();
      option.DisplayOrder = order;
      option.Value = item;
      option.DisplayText = item;
      list.push(option);
      order++;
    });
    return list;
  }

  public static stringItemsToPickList(...items: string[]): m5core.PickListSelectionViewModel[] {
    // Exclude empty strings
    items = items.filter(x => x);
    // Map to pick list selection objects
    return StaticPickList.stringArrayToPickList(items, false);
  }


  public static dictionaryToPickList(items: { [index: string]: string }, includeNone: boolean = false): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    if (includeNone) {
      list.push({ Value: "", DisplayText: "<None>" } as m5core.PickListSelectionViewModel);
    }
    const keys: string[] = [];
    for (const properties in items) {
      if (items.hasOwnProperty(properties)) {
        keys.push(properties);
      }
    }
    if (!keys || keys.length === 0) {
      return list;
    }
    let order: number = 1;
    keys.forEach((key) => {
      const option = new m5core.PickListSelectionViewModel();
      option.DisplayOrder = order;
      option.Value = key;
      option.DisplayText = items[key];
      list.push(option);
      order++;
    });
    return list;
  }


  public static constantsArrayToPickList(constants: m.ConstantInformation[], includeNone: boolean = false): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    if (includeNone) {
      list.push({ Value: "", DisplayText: "<None>" } as m5core.PickListSelectionViewModel);
    }
    if (!constants || constants.length === 0) {
      return list;
    }
    constants.forEach((item) => {
      let option = new m5core.PickListSelectionViewModel();
      option.Value = item.Value;
      option.DisplayText = item.Description;
      list.push(option);
    });
    return list;
  }

  public static pickListToPrimeSelectItems(pickList: m5core.PickListSelectionViewModel[],
    optionsIncludeNone: boolean = true,
    optionsNoneLabel: string = "<None>",
    optionsValueIsInteger: boolean = false,
    optionsIncludeGroups: boolean = false): SelectItem[] {

    const items: SelectItem[] = [];

    if (optionsIncludeNone && !optionsValueIsInteger && pickList.filter(x => !x.Value).length === 0) {
      items.push({ label: optionsNoneLabel, value: "" });
    } else if (optionsIncludeNone && optionsValueIsInteger && pickList.filter(x => x.Value === "0").length === 0) {
      items.push({ label: optionsNoneLabel, value: "0" });
    }

    let lastGroup: string = "";
    pickList.forEach(pick => {
      if (optionsIncludeGroups) {
        if (pick.GroupText && !Helper.equals(lastGroup, pick.GroupText, true)) {
          let group: SelectItem = { label: pick.GroupText, value: pick.GroupText, disabled: true, styleClass: "primeng-select-item-group" };
          (<any>group).PickListValue = pick;
          (<any>group).GroupHeader = true;
          items.push(group);
          lastGroup = pick.GroupText;
        }
      }
      const native: SelectItem = { label: pick.DisplayText, value: pick.Value };
      if (pick.Icon) {
        native.icon = IconHelper.iconDataFromIconDescription(pick.Icon).calculatedClasses;
      }
      (<any>native).PickListValue = pick;
      items.push(native);
    });

    /*
    export interface SelectItem {
      label?: string;
      value: any;
      styleClass?: string;
      icon?: string;
      title?: string;
      disabled?: boolean;
    }
    */

    return items;

  }


  public static SingleDataOptionOfNone(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "", DisplayText: "<None>" } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static SingleDataOptionOfCurrentValue(value: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: value, DisplayText: value, Description: value, IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static SingleDataOptionOfNoneOrCurrent(value: string, language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "", DisplayText: "<None>" } as m5core.PickListSelectionViewModel);
    list.push({ Value: value, DisplayText: value, Description: value, IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    return list;
  }

  // collection of DataDictionaryColumn objects
  public static DataModelColumns(columns: any[], sortByName: boolean = true, excludeRarelyUsedMetaColumns: boolean = true,
    displayColDescription: boolean = true): m5core.PickListSelectionViewModel[] {

    let list: m5core.PickListSelectionViewModel[] = [];
    if (!columns || columns.length === 0) {
      return list;
    }

    columns.forEach((col: any, index: number, cols: any[]) => {

      if (excludeRarelyUsedMetaColumns &&
        (Helper.equals(col.Name, "MarkedForDeletionDateTime", true) ||
          Helper.equals(col.Name, "MarkedForDeletionByContactId", true) ||
          Helper.equals(col.Name, "CurrentRowVersion", true) ||
          Helper.equals(col.Name, "PartitionId", true))) {
        // These columns are excluded from the list of columns that can be selected
      } else {
        const picker: m5core.PickListSelectionViewModel = new m5core.PickListSelectionViewModel();
        picker.PickListId = ``;
        picker.DisplayOrder = index;

        if (displayColDescription) {
          picker.DisplayText = col.Description;
        } else {
          picker.DisplayText = col.Name;
        }

        picker.Value = col.Name;
        picker.Description = col.Notes;
        list.push(picker);
      }

    });

    if (sortByName) {
      list = Helper.arraySort(list, "DisplayText");
    }

    return list;

  }

  // DataDictionaryColumn.Options
  public static DataModelColumnOptions(options: any[]): m5core.PickListSelectionViewModel[] {

    const list: m5core.PickListSelectionViewModel[] = [];
    if (!options || options.length === 0) {
      return list;
    }

    options.forEach((option: any, index: number, opts: any[]) => {
      const picker: m5core.PickListSelectionViewModel = new m5core.PickListSelectionViewModel();
      picker.PickListId = ``;
      picker.DisplayOrder = index;
      picker.DisplayText = option.Label;
      picker.Value = option.Value;
      picker.Description = option.Description;
      picker.IsDefaultValue = option.Default;
      list.push(picker);
    });

    return list;

  }


  public static FilterBuilderComparisonOperatorString(): m5core.PickListSelectionViewModel[] {
    // Strings start with the same comparison operators as non-strings and then add a few.
    const list: m5core.PickListSelectionViewModel[] = StaticPickList.FilterBuilderComparisonOperatorNonString();
    list.push({ Value: "StartsWith", DisplayText: "Starts With", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "EndsWith", DisplayText: "Ends With", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "Contains", DisplayText: "Contains", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "NotStartsWith", DisplayText: "Doesn't Start With", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "NotEndsWith", DisplayText: "Doesn't End With", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "NotContains", DisplayText: "Doesn't Contain", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    return list;
  }

  public static FilterBuilderComparisonOperatorNonString(): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "Equals", DisplayText: "Equal To", IsDefaultValue: true } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "NotEquals", DisplayText: "Not Equal To", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "LessThan", DisplayText: "Less Than", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "LessThanOrEqualTo", DisplayText: "Less Than or Equal To", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "GreaterThan", DisplayText: "Greater Than", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "GreaterThanOrEqualTo", DisplayText: "Greater Than or Equal To", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "Between", DisplayText: "Between", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "NotBetween", DisplayText: "Not Between", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "In", DisplayText: "In", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "NotIn", DisplayText: "Not In", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    return list;
  }

  public static FilterBooleanOperator(): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "And", DisplayText: "All", IsDefaultValue: true } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "Or", DisplayText: "Any", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    return list;
  }

  public static FilterContactIdOptions(): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "Me", DisplayText: "Me", IsDefaultValue: true } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "NotMe", DisplayText: "Not Me", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "MyGroups", DisplayText: "My Groups", IsDefaultValue: true } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "MeAndMyGroups", DisplayText: "Me and My Groups", IsDefaultValue: true } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "Other", DisplayText: "Other", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    return list;
  }

  public static DayOfWeek(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "S", DisplayText: "Sunday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M", DisplayText: "Monday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "T", DisplayText: "Tuesday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "W", DisplayText: "Wednesday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "R", DisplayText: "Thursday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "F", DisplayText: "Friday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "U", DisplayText: "Saturday" } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static AttributeSetAttributeSetTarget(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "Customer", DisplayText: "Customers", Description: "Customers", IsDefaultValue: true } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Directory", DisplayText: "Users", Description: "Users", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Vendor", DisplayText: "Vendors", Description: "Vendors", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Warehouse", DisplayText: "Warehouses", Description: "Warehouses", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Group", DisplayText: "Groups", Description: "Groups", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    //list.push({ DisplayText: "Agents", value: "Agent" });
    //list.push({ DisplayText: "Service Providers", value: "ServiceProvider" });
    list.push({ Value: "Cases", DisplayText: "Case", Description: "Case", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Asset", DisplayText: "Asset", Description: "Asset", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "AssetSelection", DisplayText: "Asset Selection", Description: "Asset Selection", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "BillingAccount", DisplayText: "Billing Account", Description: "Billing Account", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "BillingEvent", DisplayText: "Billing Event", Description: "Billing Event", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PackageOccurrence", DisplayText: "Package Occurrence", Description: "Package Occurrence", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Association", DisplayText: "Association", Description: "Association", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "TaxMatrix", DisplayText: "Tax Matrix", Description: "Tax Matrix", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Voucher", DisplayText: "Voucher", Description: "Voucher", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PaymentTransaction", DisplayText: "Payment Transaction", Description: "Payment Transaction", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "InventoryType", DisplayText: "Inventory Type", Description: "Inventory Type", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Inventory", DisplayText: "Inventory", Description: "Inventory", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "InventoryEvent", DisplayText: "Inventory Event", Description: "Inventory Event", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static AttributeSetAttributeStaticProperties(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "AttributeSetTarget", DisplayText: "AttributeSetTarget", Description: "AttributeSetTarget", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "AttributeSetTargetExternalId", DisplayText: "AttributeSetTargetExternalId", Description: "AttributeSetTargetExternalId", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "AttributeSetTargetDescription", DisplayText: "AttributeSetTargetDescription", Description: "AttributeSetTargetDescription", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "AttributeSetTargetName", DisplayText: "AttributeSetTargetName", Description: "AttributeSetTargetName", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "AddedDateTime", DisplayText: "AddedDateTime", Description: "AddedDateTime", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "AddedByContactName", DisplayText: "AddedByContactName", Description: "AddedByContactName", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LastEditedDateTime", DisplayText: "LastEditedDateTime", Description: "LastEditedDateTime", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LastEditedByContactName", DisplayText: "LastEditedByContactName", Description: "LastEditedByContactName", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static FormControlOptionMultipleChoice(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "1", DisplayText: "1 column of radio buttons" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "2", DisplayText: "2 columns of radio buttons" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "3", DisplayText: "3 columns of radio buttons" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "H", DisplayText: "Horizontal list of radio buttons" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "D", DisplayText: "Drop down list of options", Description: "", IsDefaultValue: true } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static FormControlOptionText(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "1", DisplayText: "Heading 1" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "2", DisplayText: "Heading 2" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "3", DisplayText: "Heading 3" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "4", DisplayText: "Heading 4" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "5", DisplayText: "Heading 5" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "P", DisplayText: "Paragraph", Description: "", IsDefaultValue: true } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static FormControlOptionButton(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "P", DisplayText: "Primary Color" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "I", DisplayText: "Info Color" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "S", DisplayText: "Success Color" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "W", DisplayText: "Warning Color" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "D", DisplayText: "Danger Color" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "N", DisplayText: "Normal Color", IsDefaultValue: true } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static FormControlOptionAttachment(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "U", DisplayText: "Upload" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M", DisplayText: "Manage" } as m5core.PickListSelectionViewModel);
    return list;
  }

  // Get objects this control may point to
  public static FormControlObjectName(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "", DisplayText: "<None>" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Customer", DisplayText: "Customer" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Directory", DisplayText: "User" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Vendor", DisplayText: "Vendor" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Warehouse", DisplayText: "Warehouse" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Cases", DisplayText: "Case" } as m5core.PickListSelectionViewModel);
    return list;
  }

  // Get objects this control may point to
  public static AssetOwnerResourceType(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "", DisplayText: "<None>" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Me", DisplayText: "Me" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Customers", DisplayText: "Customers" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Directory Users", DisplayText: "Directory Users" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Vendors", DisplayText: "Vendors" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Warehouses", DisplayText: "Warehouses" } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static NumericPriority(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "-1", DisplayText: "Low" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "0", DisplayText: "Normal" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "1", DisplayText: "High" } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static DateRangeOptions(language?: string): m5core.PickListSelectionViewModel[] {

    const list: m5core.PickListSelectionViewModel[] = [];

    list.push({ Value: "THIS WEEK", DisplayText: "This Week" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "THIS MONTH", DisplayText: "This Month" } as m5core.PickListSelectionViewModel);

    list.push({ Value: "YTD", DisplayText: "Year to Date" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "QTD", DisplayText: "Quarter to Date" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "MTD", DisplayText: "Month to Date" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "WTD", DisplayText: "Week to Date" } as m5core.PickListSelectionViewModel);

    list.push({ Value: "TODAY", DisplayText: "Today" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "YESTERDAY", DisplayText: "Yesterday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "2 DAYS AGO", DisplayText: "2 Days Ago" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "3 DAYS AGO", DisplayText: "3 Days Ago" } as m5core.PickListSelectionViewModel);

    list.push({ Value: "LAST WEEK", DisplayText: "Last Week" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LAST MONTH", DisplayText: "Last Month" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LAST QUARTER", DisplayText: "Last Quarter" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LAST YEAR", DisplayText: "Last Year" } as m5core.PickListSelectionViewModel);

    list.push({ Value: "PAST WEEK", DisplayText: "Past Week" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PAST MONTH", DisplayText: "Past Month" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PAST 3 MONTHS", DisplayText: "Past 3 Months" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PAST YEAR", DisplayText: "Past Year" } as m5core.PickListSelectionViewModel);

    list.push({ Value: "M01", DisplayText: "January" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M02", DisplayText: "February" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M03", DisplayText: "March" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M04", DisplayText: "April" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M05", DisplayText: "May" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M06", DisplayText: "June" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M07", DisplayText: "July" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M08", DisplayText: "August" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M09", DisplayText: "September" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M10", DisplayText: "October" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M11", DisplayText: "November" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M12", DisplayText: "December" } as m5core.PickListSelectionViewModel);

    list.push({ Value: "Q1", DisplayText: "1st Quarter" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Q2", DisplayText: "2nd Quarter" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Q3", DisplayText: "3rd Quarter" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Q4", DisplayText: "4th Quarter" } as m5core.PickListSelectionViewModel);
    // list.push({ Value: "WINTER", DisplayText: "Winter" } as m5core.PickListSelectionViewModel);
    // list.push({ Value: "SPRING", DisplayText: "Spring" } as m5core.PickListSelectionViewModel);
    // list.push({ Value: "SUMMER", DisplayText: "Summer" } as m5core.PickListSelectionViewModel);
    // list.push({ Value: "FALL", DisplayText: "Fall" } as m5core.PickListSelectionViewModel);

    list.push({ Value: "LAST MON", DisplayText: "Last Monday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LAST TUE", DisplayText: "Last Tuesday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LAST WED", DisplayText: "Last Wednesday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LAST THU", DisplayText: "Last Thursday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LAST FRI", DisplayText: "Last Friday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LAST SAT", DisplayText: "Last Saturday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LAST SUN", DisplayText: "Last Sunday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LAST 7 DAYS", DisplayText: "Last 7 Days" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LAST 3 DAYS", DisplayText: "Last 3 Days" } as m5core.PickListSelectionViewModel);

    list.push({ Value: "CUSTOM", DisplayText: "Custom" } as m5core.PickListSelectionViewModel);

    return list;

  }

  public static QueryRawDataExportFormats(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "xlsx", DisplayText: "xlsx" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "pdf", DisplayText: "pdf" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "csv", DisplayText: "csv" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "tsv", DisplayText: "tsv" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "psv", DisplayText: "psv" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "xml", DisplayText: "xml" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "json", DisplayText: "json" } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static PaymentProviderName(language?: string): m5core.PickListSelectionViewModel[] {
    let list: m5core.PickListSelectionViewModel[] = [];
    // Static
    list.push({ Value: "Simulator", DisplayText: "Simulator", Description: "Payment Provider Simulator", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PayPal", DisplayText: "PayPal", Description: "PayPal", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "BraintreePayPal", DisplayText: "Braintree PayPal", Description: "Braintree PayPal", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "BraintreeVenmo", DisplayText: "Braintree Venmo", Description: "Braintree Venmo", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Amazon", DisplayText: "Amazon - Pay with Amazon", Description: "Amazon - Pay with Amazon", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "ApplePayChaseOrbitalCommonWallet", DisplayText: "Apple Pay with Chase Orbital Wallet", Description: "Apple Pay with Chase Orbital Wallet", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "ApplePayChaseOrbitalCommonWalletV2", DisplayText: "Apple Pay with Chase Orbital Wallet v2", Description: "Apple Pay with Chase Orbital Wallet v2", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Adyen", DisplayText: "Adyen", Description: "Adyen", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Cielo", DisplayText: "Cielo eCommerce", Description: "Cielo eCommerce", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Nestpay", DisplayText: "Nestpay / Isbank / Payten / Asseco", Description: "Nestpay / Isbank / Payten / Asseco", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "NubillWallet", DisplayText: "Nubill Wallet", Description: "Nubill Wallet", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Custom", DisplayText: "Custom", Description: "Custom Provider", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    // Part of E-Payment Integrator
    list.push({ Value: "Authorize.Net AIM (AuthorizeNet)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "eProcessing Transparent Database Engine (Eprocessing)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Intellipay ExpertLink (Intellipay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "iTransact RediCharge HTML (ITransact)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "NetBilling DirectMode (NetBilling)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Verisign PayFlow Pro (PayFlowPro)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "USA ePay CGI Transaction Gateway (USAePay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Plug 'n Pay (PlugNPay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Planet Payment iPay (PlanetPayment)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "MPCS (MPCS)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "RTWare (RTWare)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "ECX (ECX)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Bank of America (Global Gateway e4) (BankOfAmerica)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Innovative Gateway (PHP) (Innovative)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Merchant Anywhere (Transaction Central Classic) (MerchantAnywhere)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "SkipJack (Skipjack)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Intuit Payment Solutions (IntuitPaymentSolutions)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "3 Delta Systems (3DSI) EC-Linx (3DSI)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "TrustCommerce API (TrustCommerce)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PSIGate HTML (PSIGate)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PayFuse XML (ClearCommerce Engine) (PayFuse)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PayFlow Link (PayFlowLink)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Chase Paymentech Orbital Gateway (Orbital)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LinkPoint (LinkPoint)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Moneris eSelect Plus Canada (Moneris)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "uSight Gateway Post-Auth (USight)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Fast Transact VeloCT (Direct Mode) (FastTransact)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "NetworkMerchants Direct-Post API (NetworkMerchants)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Ogone DirectLink (Ogone)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "TransFirst Transaction Central Classic (formerly PRIGate) (PRIGate)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Merchant Partners (Transaction Engine) (MerchantPartners)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "CyberCash (CyberCash)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "First Data Global Gateway (Linkpoint) (FirstData)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "YourPay (Linkpoint) (YourPay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "ACH Payments AGI (ACHPayments)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Payments Gateway AGI (PaymentsGateway)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Cyber Source SOAP API (CyberSource)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "eWay XML API (Australia) (Eway)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "goEmerchant XML (GoEMerchant)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "TransFirst eLink (TransFirst)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Chase Merchant Services (Linkpoint) (Chase)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Thompson Merchant Services NexCommerce (iTransact mode) (NexCommerce)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "WorldPay Select Junior Invisible (WorldPay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "TransFirst Transaction Central Classic (TransactionCentral)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Sterling SPOT XML API (HTTPS POST) (Sterling)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PayJunction Trinity Gateway (PayJunction)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "SECPay (United Kingdom) API Solution (SECPay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Payment Express PXPost (PaymentExpress)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Elavon/NOVA/My Virtual Merchant (MyVirtualMerchant)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Sage Payment Solutions (Bankcard HTTPS Post protocol) (SagePayments)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "SecurePay (Script API/COM Object Interface) (SecurePay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Moneris eSelect Plus USA (MonerisUSA)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Beanstream Process Transaction API (Beanstream)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Verifi Direct-Post API (Verifi)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "SagePay Direct (Previously Protx) (SagePay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Merchant E-Solutions Payment Gateway (Trident API) (MerchantESolutions)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PayLeap Web Services API (PayLeap)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PayPoint.net (Previously SECPay) API Solution (PayPoint)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Worldpay XML (Direct/Invisible) (WorldPayXML)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "ProPay Merchant Services API (ProPay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Intuit QuickBooks Merchant Services (QBMS) (QBMS)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Heartland POS Gateway (Heartland)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Litle Online Gateway (Litle)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "BrainTree DirectPost (Server-to-Server Orange) Gateway (BrainTree)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "JetPay Gateway (JetPay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "HSBC XML API (ClearCommerce Engine) (HSBC)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "BluePay 2.0 Post (BluePay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Adyen API Payments (Adyen)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Barclay ePDQ (DirectLink) (Barclay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PayTrace Payment Gateway (PayTrace)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "YKC Gateway (YKC)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "GoToBilling Gateway (GoToBilling)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "TransNational Bankcard (TransNationalBankcard)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Netbanx (Netbanx)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "MIT (MIT)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "DataCash (DataCash)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "ACH Federal (ACHFederal)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Global Iris (HSBC) (GlobalIris)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "First Data Global Gateway E4 (FirstDataE4)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "First Atlantic Commerce (FirstAtlantic)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Bluefin (Bluefin)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Payscape (Payscape)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Pay Direct (Link2Gov) (PayDirect)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Authorize.NET CIM (AuthorizeNetCIM)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "5th Dimension Logistics (5thDimension)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "WorldPay US Link Gateway (WorldPayLink)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "3DSI Payment WorkSuite (PaymentWorkSuite)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PSIGate XML (PSIGateXML)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "First Data PayPoint (FirstDataPayPoint)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "ExPay Gateway (ExPay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Payvision Gateway (Payvision)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Converge (formerly MyVirtualMerchant) (Converge)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Payeezy Gateway (formerly First Data E4) (Payeezy)" } as m5core.PickListSelectionViewModel);
    // Fill in missing DisplayTexts and descriptions
    list.forEach((provider, index, providers) => {
      if (!provider.DisplayText || provider.DisplayText === "") {
        provider.DisplayText = provider.Value.replace(/\([^)]*\)/g, '').trim();
      }
      if (!provider.Description || provider.Description === "") {
        provider.Description = provider.DisplayText;
      }
    });
    // Sort
    list = Helper.arraySort(list, "Label");
    return list;
  }


  public static FontAwesomeIconNames(): string[] {

    // Font Awesome Pro v6.2.0
    const standard: string[] = [
      "0", "00", "1", "2", "3", "360-degrees",
      "4", "5", "6", "7", "8", "9",
      "a", "abacus", "accent-grave", "acorn", "address-book", "address-card",
      "air-conditioner", "airplay", "alarm-clock", "alarm-exclamation", "alarm-plus", "alarm-snooze",
      "album-circle-plus", "album-circle-user", "album-collection-circle-plus", "album-collection-circle-user", "album-collection", "album",
      "alicorn", "alien-8bit", "alien", "align-center", "align-justify", "align-left",
      "align-right", "align-slash", "alt", "amp-guitar", "ampersand", "anchor-circle-check",
      "anchor-circle-exclamation", "anchor-circle-xmark", "anchor-lock", "anchor", "angel", "angle-90",
      "angle-down", "angle-left", "angle-right", "angle-up", "angle", "angles-down",
      "angles-left", "angles-right", "angles-up", "ankh", "apartment", "aperture",
      "apostrophe", "apple-core", "apple-whole", "archway", "arrow-down-1-9", "arrow-down-9-1",
      "arrow-down-a-z", "arrow-down-arrow-up", "arrow-down-big-small", "arrow-down-from-dotted-line", "arrow-down-from-line", "arrow-down-left-and-arrow-up-right-to-center",
      "arrow-down-left", "arrow-down-long", "arrow-down-right", "arrow-down-short-wide", "arrow-down-small-big", "arrow-down-square-triangle",
      "arrow-down-to-arc", "arrow-down-to-bracket", "arrow-down-to-dotted-line", "arrow-down-to-line", "arrow-down-to-square", "arrow-down-triangle-square",
      "arrow-down-up-across-line", "arrow-down-up-lock", "arrow-down-wide-short", "arrow-down-z-a", "arrow-down", "arrow-left-from-line",
      "arrow-left-long-to-line", "arrow-left-long", "arrow-left-to-line", "arrow-left", "arrow-pointer", "arrow-right-arrow-left",
      "arrow-right-from-arc", "arrow-right-from-bracket", "arrow-right-from-line", "arrow-right-long-to-line", "arrow-right-long", "arrow-right-to-arc",
      "arrow-right-to-bracket", "arrow-right-to-city", "arrow-right-to-line", "arrow-right", "arrow-rotate-left", "arrow-rotate-right",
      "arrow-trend-down", "arrow-trend-up", "arrow-turn-down-left", "arrow-turn-down-right", "arrow-turn-down", "arrow-turn-up",
      "arrow-up-1-9", "arrow-up-9-1", "arrow-up-a-z", "arrow-up-arrow-down", "arrow-up-big-small", "arrow-up-from-arc",
      "arrow-up-from-bracket", "arrow-up-from-dotted-line", "arrow-up-from-ground-water", "arrow-up-from-line", "arrow-up-from-square", "arrow-up-from-water-pump",
      "arrow-up-left-from-circle", "arrow-up-left", "arrow-up-long", "arrow-up-right-and-arrow-down-left-from-center", "arrow-up-right-dots", "arrow-up-right-from-square",
      "arrow-up-right", "arrow-up-short-wide", "arrow-up-small-big", "arrow-up-square-triangle", "arrow-up-to-dotted-line", "arrow-up-to-line",
      "arrow-up-triangle-square", "arrow-up-wide-short", "arrow-up-z-a", "arrow-up", "arrows-cross", "arrows-down-to-line",
      "arrows-down-to-people", "arrows-from-dotted-line", "arrows-from-line", "arrows-left-right-to-line", "arrows-left-right", "arrows-maximize",
      "arrows-minimize", "arrows-repeat-1", "arrows-repeat", "arrows-retweet", "arrows-rotate", "arrows-spin",
      "arrows-split-up-and-left", "arrows-to-circle", "arrows-to-dot", "arrows-to-dotted-line", "arrows-to-eye", "arrows-to-line",
      "arrows-turn-right", "arrows-turn-to-dots", "arrows-up-down-left-right", "arrows-up-down", "arrows-up-to-line", "asterisk",
      "at", "atom-simple", "atom", "audio-description-slash", "audio-description", "austral-sign",
      "avocado", "award-simple", "award", "axe-battle", "axe", "b",
      "baby-carriage", "baby", "backpack", "backward-fast", "backward-step", "backward",
      "bacon", "bacteria", "bacterium", "badge-check", "badge-dollar", "badge-percent",
      "badge-sheriff", "badge", "badger-honey", "badminton", "bag-shopping", "bagel",
      "bags-shopping", "baguette", "bahai", "baht-sign", "ball-pile", "balloon",
      "balloons", "ballot-check", "ballot", "ban-bug", "ban-parking", "ban-smoking",
      "ban", "banana", "bandage", "bangladeshi-taka-sign", "banjo", "barcode-read",
      "barcode-scan", "barcode", "bars-filter", "bars-progress", "bars-sort", "bars-staggered",
      "bars", "baseball-bat-ball", "baseball", "basket-shopping-simple", "basket-shopping", "basketball-hoop",
      "basketball", "bat", "bath", "battery-bolt", "battery-empty", "battery-exclamation",
      "battery-full", "battery-half", "battery-low", "battery-quarter", "battery-slash", "battery-three-quarters",
      "bed-bunk", "bed-empty", "bed-front", "bed-pulse", "bed", "bee",
      "beer-mug-empty", "beer-mug", "bell-concierge", "bell-exclamation", "bell-on", "bell-plus",
      "bell-school-slash", "bell-school", "bell-slash", "bell", "bells", "bench-tree",
      "bezier-curve", "bicycle", "binary-circle-check", "binary-lock", "binary-slash", "binary",
      "binoculars", "biohazard", "bird", "bitcoin-sign", "blanket-fire", "blanket",
      "blender-phone", "blender", "blinds-open", "blinds-raised", "blinds", "block-brick-fire",
      "block-brick", "block-question", "block-quote", "block", "blog", "blueberries",
      "bold", "bolt-auto", "bolt-lightning", "bolt-slash", "bolt", "bomb",
      "bone-break", "bone", "bong", "book-arrow-right", "book-arrow-up", "book-atlas",
      "book-bible", "book-blank", "book-bookmark", "book-circle-arrow-right", "book-circle-arrow-up", "book-copy",
      "book-font", "book-heart", "book-journal-whills", "book-medical", "book-open-cover", "book-open-reader",
      "book-open", "book-quran", "book-section", "book-skull", "book-sparkles", "book-tanakh",
      "book-user", "book", "bookmark-slash", "bookmark", "books-medical", "books",
      "boombox", "boot-heeled", "boot", "booth-curtain", "border-all", "border-bottom-right",
      "border-bottom", "border-center-h", "border-center-v", "border-inner", "border-left", "border-none",
      "border-outer", "border-right", "border-top-left", "border-top", "bore-hole", "bottle-droplet",
      "bottle-water", "bow-arrow", "bowl-chopsticks-noodles", "bowl-chopsticks", "bowl-food", "bowl-hot",
      "bowl-rice", "bowl-scoop", "bowl-scoops", "bowl-soft-serve", "bowl-spoon", "bowling-ball-pin",
      "bowling-ball", "bowling-pins", "box-archive", "box-ballot", "box-check", "box-circle-check",
      "box-dollar", "box-heart", "box-open-full", "box-open", "box-taped", "box-tissue",
      "box", "boxes-packing", "boxes-stacked", "boxing-glove", "bracket-curly-right", "bracket-curly",
      "bracket-round-right", "bracket-round", "bracket-square-right", "bracket-square", "brackets-curly", "brackets-round",
      "brackets-square", "braille", "brain-arrow-curved-right", "brain-circuit", "brain", "brake-warning",
      "brazilian-real-sign", "bread-loaf", "bread-slice-butter", "bread-slice", "bridge-circle-check", "bridge-circle-exclamation",
      "bridge-circle-xmark", "bridge-lock", "bridge-suspension", "bridge-water", "bridge", "briefcase-arrow-right",
      "briefcase-blank", "briefcase-medical", "briefcase", "brightness-low", "brightness", "bring-forward",
      "bring-front", "broccoli", "broom-ball", "broom", "browser", "browsers",
      "brush", "bucket", "bug-slash", "bug", "bugs", "building-circle-arrow-right",
      "building-circle-check", "building-circle-exclamation", "building-circle-xmark", "building-columns", "building-flag", "building-lock",
      "building-ngo", "building-shield", "building-un", "building-user", "building-wheat", "building",
      "buildings", "bullhorn", "bullseye-arrow", "bullseye-pointer", "bullseye", "burger-cheese",
      "burger-fries", "burger-glass", "burger-lettuce", "burger-soda", "burger", "burrito",
      "burst", "bus-school", "bus-simple", "bus", "business-time", "butter",
      "c", "cabin", "cabinet-filing", "cable-car", "cactus", "cake-candles",
      "cake-slice", "calculator-simple", "calculator", "calendar-arrow-down", "calendar-arrow-up", "calendar-check",
      "calendar-circle-exclamation", "calendar-circle-minus", "calendar-circle-plus", "calendar-circle-user", "calendar-clock", "calendar-day",
      "calendar-days", "calendar-exclamation", "calendar-heart", "calendar-image", "calendar-lines-pen", "calendar-lines",
      "calendar-minus", "calendar-pen", "calendar-plus", "calendar-range", "calendar-star", "calendar-week",
      "calendar-xmark", "calendar", "calendars", "camcorder", "camera-cctv", "camera-movie",
      "camera-polaroid", "camera-retro", "camera-rotate", "camera-security", "camera-slash", "camera-viewfinder",
      "camera-web-slash", "camera-web", "camera", "campfire", "campground", "can-food",
      "candle-holder", "candy-bar", "candy-cane", "candy-corn", "candy", "cannabis",
      "capsules", "car-battery", "car-bolt", "car-building", "car-bump", "car-burst",
      "car-bus", "car-circle-bolt", "car-garage", "car-mirrors", "car-on", "car-rear",
      "car-side-bolt", "car-side", "car-tilt", "car-tunnel", "car-wash", "car-wrench",
      "car", "caravan-simple", "caravan", "card-club", "card-diamond", "card-heart",
      "card-spade", "cards-blank", "cards", "caret-down", "caret-left", "caret-right",
      "caret-up", "carrot", "cars", "cart-arrow-down", "cart-arrow-up", "cart-circle-arrow-down",
      "cart-circle-arrow-up", "cart-circle-check", "cart-circle-exclamation", "cart-circle-plus", "cart-circle-xmark", "cart-flatbed-boxes",
      "cart-flatbed-empty", "cart-flatbed-suitcase", "cart-flatbed", "cart-minus", "cart-plus", "cart-shopping-fast",
      "cart-shopping", "cart-xmark", "cash-register", "cassette-betamax", "cassette-tape", "cassette-vhs",
      "castle", "cat-space", "cat", "cauldron", "cedi-sign", "cent-sign",
      "certificate", "chair-office", "chair", "chalkboard-user", "chalkboard", "champagne-glass",
      "champagne-glasses", "charging-station", "chart-area", "chart-bar", "chart-bullet", "chart-candlestick",
      "chart-column", "chart-gantt", "chart-line-down", "chart-line-up", "chart-line", "chart-mixed",
      "chart-network", "chart-pie-simple", "chart-pie", "chart-pyramid", "chart-radar", "chart-scatter-3d",
      "chart-scatter-bubble", "chart-scatter", "chart-simple-horizontal", "chart-simple", "chart-tree-map", "chart-user",
      "chart-waterfall", "check-double", "check-to-slot", "check", "cheese-swiss", "cheese",
      "cherries", "chess-bishop-piece", "chess-bishop", "chess-board", "chess-clock-flip", "chess-clock",
      "chess-king-piece", "chess-king", "chess-knight-piece", "chess-knight", "chess-pawn-piece", "chess-pawn",
      "chess-queen-piece", "chess-queen", "chess-rook-piece", "chess-rook", "chess", "chestnut",
      "chevron-down", "chevron-left", "chevron-right", "chevron-up", "chevrons-down", "chevrons-left",
      "chevrons-right", "chevrons-up", "child-dress", "child-reaching", "child-rifle", "child",
      "children", "chimney", "chopsticks", "church", "circle-0", "circle-1",
      "circle-2", "circle-3", "circle-4", "circle-5", "circle-6", "circle-7",
      "circle-8", "circle-9", "circle-a", "circle-ampersand", "circle-arrow-down-left", "circle-arrow-down-right",
      "circle-arrow-down", "circle-arrow-left", "circle-arrow-right", "circle-arrow-up-left", "circle-arrow-up-right", "circle-arrow-up",
      "circle-b", "circle-bolt", "circle-book-open", "circle-bookmark", "circle-c", "circle-calendar",
      "circle-camera", "circle-caret-down", "circle-caret-left", "circle-caret-right", "circle-caret-up", "circle-check",
      "circle-chevron-down", "circle-chevron-left", "circle-chevron-right", "circle-chevron-up", "circle-d", "circle-dashed",
      "circle-divide", "circle-dollar-to-slot", "circle-dollar", "circle-dot", "circle-down-left", "circle-down-right",
      "circle-down", "circle-e", "circle-ellipsis-vertical", "circle-ellipsis", "circle-envelope", "circle-exclamation-check",
      "circle-exclamation", "circle-f", "circle-g", "circle-h", "circle-half-stroke", "circle-half",
      "circle-heart", "circle-i", "circle-info", "circle-j", "circle-k", "circle-l",
      "circle-left", "circle-location-arrow", "circle-m", "circle-microphone-lines", "circle-microphone", "circle-minus",
      "circle-n", "circle-nodes", "circle-notch", "circle-o", "circle-p", "circle-parking",
      "circle-pause", "circle-phone-flip", "circle-phone-hangup", "circle-phone", "circle-play", "circle-plus",
      "circle-q", "circle-quarter", "circle-quarters", "circle-question", "circle-r", "circle-radiation",
      "circle-right", "circle-s", "circle-small", "circle-sort-down", "circle-sort-up", "circle-sort",
      "circle-star", "circle-stop", "circle-t", "circle-three-quarters", "circle-trash", "circle-u",
      "circle-up-left", "circle-up-right", "circle-up", "circle-user", "circle-v", "circle-video",
      "circle-w", "circle-waveform-lines", "circle-x", "circle-xmark", "circle-y", "circle-z",
      "circle", "citrus-slice", "citrus", "city", "clapperboard-play", "clapperboard",
      "clarinet", "claw-marks", "clipboard-check", "clipboard-list-check", "clipboard-list", "clipboard-medical",
      "clipboard-prescription", "clipboard-question", "clipboard-user", "clipboard", "clock-desk", "clock-eight-thirty",
      "clock-eight", "clock-eleven-thirty", "clock-eleven", "clock-five-thirty", "clock-five", "clock-four-thirty",
      "clock-nine-thirty", "clock-nine", "clock-one-thirty", "clock-one", "clock-rotate-left", "clock-seven-thirty",
      "clock-seven", "clock-six-thirty", "clock-six", "clock-ten-thirty", "clock-ten", "clock-three-thirty",
      "clock-three", "clock-twelve-thirty", "clock-twelve", "clock-two-thirty", "clock-two", "clock",
      "clone", "closed-captioning-slash", "closed-captioning", "clothes-hanger", "cloud-arrow-down", "cloud-arrow-up",
      "cloud-bolt-moon", "cloud-bolt-sun", "cloud-bolt", "cloud-check", "cloud-drizzle", "cloud-exclamation",
      "cloud-fog", "cloud-hail-mixed", "cloud-hail", "cloud-meatball", "cloud-minus", "cloud-moon-rain",
      "cloud-moon", "cloud-music", "cloud-plus", "cloud-question", "cloud-rain", "cloud-rainbow",
      "cloud-showers-heavy", "cloud-showers-water", "cloud-showers", "cloud-slash", "cloud-sleet", "cloud-snow",
      "cloud-sun-rain", "cloud-sun", "cloud-word", "cloud-xmark", "cloud", "clouds-moon",
      "clouds-sun", "clouds", "clover", "club", "coconut", "code-branch",
      "code-commit", "code-compare", "code-fork", "code-merge", "code-pull-request-closed", "code-pull-request-draft",
      "code-pull-request", "code-simple", "code", "coffee-bean", "coffee-beans", "coffee-pot",
      "coffin-cross", "coffin", "coin-blank", "coin-front", "coin-vertical", "coin",
      "coins", "colon-sign", "colon", "columns-3", "comet", "comma",
      "command", "comment-arrow-down", "comment-arrow-up-right", "comment-arrow-up", "comment-captions", "comment-check",
      "comment-code", "comment-dollar", "comment-dots", "comment-exclamation", "comment-image", "comment-lines",
      "comment-medical", "comment-middle-top", "comment-middle", "comment-minus", "comment-music", "comment-pen",
      "comment-plus", "comment-question", "comment-quote", "comment-slash", "comment-smile", "comment-sms",
      "comment-text", "comment-xmark", "comment", "comments-dollar", "comments-question-check", "comments-question",
      "comments", "compact-disc", "compass-drafting", "compass-slash", "compass", "compress-wide",
      "compress", "computer-classic", "computer-mouse-scrollwheel", "computer-mouse", "computer-speaker", "computer",
      "container-storage", "conveyor-belt-boxes", "conveyor-belt-empty", "conveyor-belt", "cookie-bite", "cookie",
      "copy", "copyright", "corn", "corner", "couch", "cow",
      "cowbell-circle-plus", "cowbell", "crab", "crate-apple", "crate-empty", "credit-card-blank",
      "credit-card-front", "credit-card", "cricket-bat-ball", "croissant", "crop-simple", "crop",
      "cross", "crosshairs-simple", "crosshairs", "crow", "crown", "crutch",
      "crutches", "cruzeiro-sign", "crystal-ball", "cube", "cubes-stacked", "cubes",
      "cucumber", "cup-straw-swoosh", "cup-straw", "cup-togo", "cupcake", "curling-stone",
      "custard", "d", "dagger", "dash", "database", "deer-rudolph",
      "deer", "delete-left", "delete-right", "democrat", "desktop-arrow-down", "desktop",
      "dharmachakra", "diagram-cells", "diagram-lean-canvas", "diagram-nested", "diagram-next", "diagram-predecessor",
      "diagram-previous", "diagram-project", "diagram-sankey", "diagram-subtask", "diagram-successor", "diagram-venn",
      "dial-high", "dial-low", "dial-max", "dial-med-low", "dial-med", "dial-min",
      "dial-off", "dial", "diamond-exclamation", "diamond-turn-right", "diamond", "dice-d10",
      "dice-d12", "dice-d20", "dice-d4", "dice-d6", "dice-d8", "dice-five",
      "dice-four", "dice-one", "dice-six", "dice-three", "dice-two", "dice",
      "diploma", "disc-drive", "disease", "display-arrow-down", "display-code", "display-medical",
      "display-slash", "display", "distribute-spacing-horizontal", "distribute-spacing-vertical", "ditto", "divide",
      "dna", "do-not-enter", "dog-leashed", "dog", "dollar-sign", "dolly-empty",
      "dolly", "dolphin", "dong-sign", "donut", "door-closed", "door-open",
      "dove", "down-from-dotted-line", "down-from-line", "down-left-and-up-right-to-center", "down-left", "down-long",
      "down-right", "down-to-bracket", "down-to-dotted-line", "down-to-line", "down", "download",
      "dragon", "draw-circle", "draw-polygon", "draw-square", "dreidel", "drone-front",
      "drone", "droplet-degree", "droplet-percent", "droplet-slash", "droplet", "drum-steelpan",
      "drum", "drumstick-bite", "drumstick", "dryer-heat", "dryer", "duck",
      "dumbbell", "dumpster-fire", "dumpster", "dungeon", "e", "ear-deaf",
      "ear-listen", "ear-muffs", "ear", "earth-africa", "earth-americas", "earth-asia",
      "earth-europe", "earth-oceania", "eclipse", "egg-fried", "egg", "eggplant",
      "eject", "elephant", "elevator", "ellipsis-stroke-vertical", "ellipsis-stroke", "ellipsis-vertical",
      "ellipsis", "empty-set", "engine-warning", "engine", "envelope-circle-check", "envelope-dot",
      "envelope-open-dollar", "envelope-open-text", "envelope-open", "envelope", "envelopes-bulk", "envelopes",
      "equals", "eraser", "escalator", "ethernet", "euro-sign", "exclamation",
      "expand-wide", "expand", "explosion", "eye-dropper-full", "eye-dropper-half", "eye-dropper",
      "eye-evil", "eye-low-vision", "eye-slash", "eye", "eyes", "f",
      "face-angry-horns", "face-angry", "face-anguished", "face-anxious-sweat", "face-astonished", "face-awesome",
      "face-beam-hand-over-mouth", "face-clouds", "face-confounded", "face-confused", "face-cowboy-hat", "face-diagonal-mouth",
      "face-disappointed", "face-disguise", "face-dizzy", "face-dotted", "face-downcast-sweat", "face-drooling",
      "face-exhaling", "face-explode", "face-expressionless", "face-eyes-xmarks", "face-fearful", "face-flushed",
      "face-frown-open", "face-frown-slight", "face-frown", "face-glasses", "face-grimace", "face-grin-beam-sweat",
      "face-grin-beam", "face-grin-hearts", "face-grin-squint-tears", "face-grin-squint", "face-grin-stars", "face-grin-tears",
      "face-grin-tongue-squint", "face-grin-tongue-wink", "face-grin-tongue", "face-grin-wide", "face-grin-wink", "face-grin",
      "face-hand-over-mouth", "face-hand-peeking", "face-hand-yawn", "face-head-bandage", "face-holding-back-tears", "face-hushed",
      "face-icicles", "face-kiss-beam", "face-kiss-closed-eyes", "face-kiss-wink-heart", "face-kiss", "face-laugh-beam",
      "face-laugh-squint", "face-laugh-wink", "face-laugh", "face-lying", "face-mask", "face-meh-blank",
      "face-meh", "face-melting", "face-monocle", "face-nauseated", "face-nose-steam", "face-party",
      "face-pensive", "face-persevering", "face-pleading", "face-pouting", "face-raised-eyebrow", "face-relieved",
      "face-rolling-eyes", "face-sad-cry", "face-sad-sweat", "face-sad-tear", "face-saluting", "face-scream",
      "face-shush", "face-sleeping", "face-sleepy", "face-smile-beam", "face-smile-halo", "face-smile-hearts",
      "face-smile-horns", "face-smile-plus", "face-smile-relaxed", "face-smile-tear", "face-smile-tongue", "face-smile-upside-down",
      "face-smile-wink", "face-smile", "face-smiling-hands", "face-smirking", "face-spiral-eyes", "face-sunglasses",
      "face-surprise", "face-swear", "face-thermometer", "face-thinking", "face-tired", "face-tissue",
      "face-tongue-money", "face-tongue-sweat", "face-unamused", "face-viewfinder", "face-vomit", "face-weary",
      "face-woozy", "face-worried", "face-zany", "face-zipper", "falafel", "family-dress",
      "family-pants", "family", "fan-table", "fan", "farm", "faucet-drip",
      "faucet", "fax", "feather-pointed", "feather", "fence", "ferris-wheel",
      "ferry", "field-hockey-stick-ball", "file-arrow-down", "file-arrow-up", "file-audio", "file-binary",
      "file-certificate", "file-chart-column", "file-chart-pie", "file-check", "file-circle-check", "file-circle-exclamation",
      "file-circle-info", "file-circle-minus", "file-circle-plus", "file-circle-question", "file-circle-xmark", "file-code",
      "file-contract", "file-csv", "file-dashed-line", "file-excel", "file-exclamation", "file-export",
      "file-heart", "file-image", "file-import", "file-invoice-dollar", "file-invoice", "file-lines",
      "file-lock", "file-magnifying-glass", "file-medical", "file-minus", "file-music", "file-pdf",
      "file-pen", "file-plus-minus", "file-plus", "file-powerpoint", "file-prescription", "file-shield",
      "file-signature", "file-slash", "file-spreadsheet", "file-user", "file-video", "file-waveform",
      "file-word", "file-xmark", "file-zipper", "file", "files-medical", "files",
      "fill-drip", "fill", "film-canister", "film-simple", "film-slash", "film",
      "films", "filter-circle-dollar", "filter-circle-xmark", "filter-list", "filter-slash", "filter",
      "filters", "fingerprint", "fire-burner", "fire-extinguisher", "fire-flame-curved", "fire-flame-simple",
      "fire-flame", "fire-hydrant", "fire-smoke", "fire", "fireplace", "fish-bones",
      "fish-cooked", "fish-fins", "fish", "fishing-rod", "flag-checkered", "flag-pennant",
      "flag-swallowtail", "flag-usa", "flag", "flashlight", "flask-round-poison", "flask-round-potion",
      "flask-vial", "flask", "flatbread-stuffed", "flatbread", "floppy-disk-circle-arrow-right", "floppy-disk-circle-xmark",
      "floppy-disk-pen", "floppy-disk", "floppy-disks", "florin-sign", "flower-daffodil", "flower-tulip",
      "flower", "flute", "flux-capacitor", "flying-disc", "folder-arrow-down", "folder-arrow-up",
      "folder-bookmark", "folder-closed", "folder-gear", "folder-grid", "folder-heart", "folder-image",
      "folder-magnifying-glass", "folder-medical", "folder-minus", "folder-music", "folder-open", "folder-plus",
      "folder-tree", "folder-user", "folder-xmark", "folder", "folders", "fondue-pot",
      "font-case", "font", "football-helmet", "football", "fork-knife", "fork",
      "forklift", "fort", "forward-fast", "forward-step", "forward", "frame",
      "franc-sign", "french-fries", "frog", "function", "futbol", "g",
      "galaxy", "gallery-thumbnails", "game-board-simple", "game-board", "game-console-handheld", "gamepad-modern",
      "gamepad", "garage-car", "garage-open", "garage", "garlic", "gas-pump-slash",
      "gas-pump", "gauge-circle-bolt", "gauge-circle-minus", "gauge-circle-plus", "gauge-high", "gauge-low",
      "gauge-max", "gauge-min", "gauge-simple-high", "gauge-simple-low", "gauge-simple-max", "gauge-simple-min",
      "gauge-simple", "gauge", "gavel", "gear", "gears", "gem",
      "genderless", "ghost", "gif", "gift-card", "gift", "gifts",
      "gingerbread-man", "glass-citrus", "glass-empty", "glass-half", "glass-water-droplet", "glass-water",
      "glass", "glasses-round", "glasses", "globe-snow", "globe-stand", "globe",
      "goal-net", "golf-ball-tee", "golf-club", "golf-flag-hole", "gopuram", "graduation-cap",
      "gramophone", "grapes", "grate-droplet", "grate", "greater-than-equal", "greater-than",
      "grid-2-plus", "grid-2", "grid-4", "grid-5", "grid-dividers", "grid-horizontal",
      "grid", "grill-fire", "grill-hot", "grill", "grip-dots-vertical", "grip-dots",
      "grip-lines-vertical", "grip-lines", "grip-vertical", "grip", "group-arrows-rotate", "guarani-sign",
      "guitar-electric", "guitar", "guitars", "gun-slash", "gun-squirt", "gun",
      "h", "h1", "h2", "h3", "h4", "h5",
      "h6", "hammer-crash", "hammer-war", "hammer", "hamsa", "hand-back-fist",
      "hand-back-point-down", "hand-back-point-left", "hand-back-point-ribbon", "hand-back-point-right", "hand-back-point-up", "hand-dots",
      "hand-fingers-crossed", "hand-fist", "hand-heart", "hand-holding-box", "hand-holding-dollar", "hand-holding-droplet",
      "hand-holding-hand", "hand-holding-heart", "hand-holding-magic", "hand-holding-medical", "hand-holding-seedling", "hand-holding-skull",
      "hand-holding", "hand-horns", "hand-lizard", "hand-love", "hand-middle-finger", "hand-peace",
      "hand-point-down", "hand-point-left", "hand-point-ribbon", "hand-point-right", "hand-point-up", "hand-pointer",
      "hand-scissors", "hand-sparkles", "hand-spock", "hand-wave", "hand", "handcuffs",
      "hands-asl-interpreting", "hands-bound", "hands-bubbles", "hands-clapping", "hands-holding-child", "hands-holding-circle",
      "hands-holding-diamond", "hands-holding-dollar", "hands-holding-heart", "hands-holding", "hands-praying", "hands",
      "handshake-angle", "handshake-simple-slash", "handshake-simple", "handshake-slash", "handshake", "hanukiah",
      "hard-drive", "hashtag-lock", "hashtag", "hat-chef", "hat-cowboy-side", "hat-cowboy",
      "hat-santa", "hat-winter", "hat-witch", "hat-wizard", "head-side-brain", "head-side-cough-slash",
      "head-side-cough", "head-side-goggles", "head-side-headphones", "head-side-heart", "head-side-mask", "head-side-medical",
      "head-side-virus", "head-side", "heading", "headphones-simple", "headphones", "headset",
      "heart-circle-bolt", "heart-circle-check", "heart-circle-exclamation", "heart-circle-minus", "heart-circle-plus", "heart-circle-xmark",
      "heart-crack", "heart-half-stroke", "heart-half", "heart-pulse", "heart", "heat",
      "helicopter-symbol", "helicopter", "helmet-battle", "helmet-safety", "helmet-un", "hexagon-check",
      "hexagon-divide", "hexagon-exclamation", "hexagon-image", "hexagon-minus", "hexagon-plus", "hexagon-vertical-nft-slanted",
      "hexagon-vertical-nft", "hexagon-xmark", "hexagon", "high-definition", "highlighter-line", "highlighter",
      "hill-avalanche", "hill-rockslide", "hippo", "hockey-mask", "hockey-puck", "hockey-stick-puck",
      "hockey-sticks", "holly-berry", "honey-pot", "hood-cloak", "horizontal-rule", "horse-head",
      "horse-saddle", "horse", "hose-reel", "hose", "hospital-user", "hospital",
      "hospitals", "hot-tub-person", "hotdog", "hotel", "hourglass-clock", "hourglass-end",
      "hourglass-half", "hourglass-start", "hourglass", "house-blank", "house-building", "house-chimney-blank",
      "house-chimney-crack", "house-chimney-heart", "house-chimney-medical", "house-chimney-user", "house-chimney-window", "house-chimney",
      "house-circle-check", "house-circle-exclamation", "house-circle-xmark", "house-crack", "house-day", "house-fire",
      "house-flag", "house-flood-water-circle-arrow-right", "house-flood-water", "house-heart", "house-laptop", "house-lock",
      "house-medical-circle-check", "house-medical-circle-exclamation", "house-medical-circle-xmark", "house-medical-flag", "house-medical", "house-night",
      "house-person-leave", "house-person-return", "house-signal", "house-tree", "house-tsunami", "house-turret",
      "house-user", "house-water", "house-window", "house", "hryvnia-sign", "hundred-points",
      "hurricane", "hyphen", "i-cursor", "i", "ice-cream", "ice-skate",
      "icicles", "icons", "id-badge", "id-card-clip", "id-card", "igloo",
      "image-landscape", "image-polaroid-user", "image-polaroid", "image-portrait", "image-slash", "image-user",
      "image", "images-user", "images", "inbox-full", "inbox-in", "inbox-out",
      "inbox", "inboxes", "indent", "indian-rupee-sign", "industry-windows", "industry",
      "infinity", "info", "inhaler", "input-numeric", "input-pipe", "input-text",
      "integral", "intersection", "island-tropical", "italic", "j", "jack-o-lantern",
      "jar-wheat", "jar", "jedi", "jet-fighter-up", "jet-fighter", "joint",
      "joystick", "jug-detergent", "jug", "k", "kaaba", "kazoo",
      "kerning", "key-skeleton-left-right", "key-skeleton", "key", "keyboard-brightness-low", "keyboard-brightness",
      "keyboard-down", "keyboard-left", "keyboard", "keynote", "khanda", "kidneys",
      "kip-sign", "kit-medical", "kitchen-set", "kite", "kiwi-bird", "kiwi-fruit",
      "knife-kitchen", "knife", "l", "lacrosse-stick-ball", "lacrosse-stick", "lambda",
      "lamp-desk", "lamp-floor", "lamp-street", "lamp", "land-mine-on", "landmark-dome",
      "landmark-flag", "landmark", "language", "laptop-arrow-down", "laptop-code", "laptop-file",
      "laptop-medical", "laptop-mobile", "laptop-slash", "laptop", "lari-sign", "lasso-sparkles",
      "lasso", "layer-group", "layer-minus", "layer-plus", "leaf-heart", "leaf-maple",
      "leaf-oak", "leaf", "leafy-green", "left-from-line", "left-long-to-line", "left-long",
      "left-right", "left-to-line", "left", "lemon", "less-than-equal", "less-than",
      "life-ring", "light-ceiling", "light-emergency-on", "light-emergency", "light-switch-off", "light-switch-on",
      "light-switch", "lightbulb-cfl-on", "lightbulb-cfl", "lightbulb-dollar", "lightbulb-exclamation-on", "lightbulb-exclamation",
      "lightbulb-on", "lightbulb-slash", "lightbulb", "lights-holiday", "line-columns", "line-height",
      "lines-leaning", "link-horizontal-slash", "link-horizontal", "link-simple-slash", "link-simple", "link-slash",
      "link", "lips", "lira-sign", "list-check", "list-dropdown", "list-music",
      "list-ol", "list-radio", "list-timeline", "list-tree", "list-ul", "list",
      "litecoin-sign", "loader", "lobster", "location-arrow", "location-check", "location-crosshairs-slash",
      "location-crosshairs", "location-dot-slash", "location-dot", "location-exclamation", "location-minus", "location-pen",
      "location-pin-lock", "location-pin-slash", "location-pin", "location-plus", "location-question", "location-smile",
      "location-xmark", "lock-a", "lock-hashtag", "lock-keyhole-open", "lock-keyhole", "lock-open",
      "lock", "locust", "lollipop", "loveseat", "luchador-mask", "lungs-virus",
      "lungs", "m", "mace", "magnet", "magnifying-glass-arrow-right", "magnifying-glass-chart",
      "magnifying-glass-dollar", "magnifying-glass-location", "magnifying-glass-minus", "magnifying-glass-plus", "magnifying-glass", "mailbox",
      "manat-sign", "mandolin", "mango", "manhole", "map-location-dot", "map-location",
      "map-pin", "map", "marker", "mars-and-venus-burst", "mars-and-venus", "mars-double",
      "mars-stroke-right", "mars-stroke-up", "mars-stroke", "mars", "martini-glass-citrus", "martini-glass-empty",
      "martini-glass", "mask-face", "mask-snorkel", "mask-ventilator", "mask", "masks-theater",
      "mattress-pillow", "maximize", "meat", "medal", "megaphone", "melon-slice",
      "melon", "memo-circle-check", "memo-circle-info", "memo-pad", "memo", "memory",
      "menorah", "mercury", "merge", "message-arrow-down", "message-arrow-up-right", "message-arrow-up",
      "message-bot", "message-captions", "message-check", "message-code", "message-dollar", "message-dots",
      "message-exclamation", "message-image", "message-lines", "message-medical", "message-middle-top", "message-middle",
      "message-minus", "message-music", "message-pen", "message-plus", "message-question", "message-quote",
      "message-slash", "message-smile", "message-sms", "message-text", "message-xmark", "message",
      "messages-dollar", "messages-question", "messages", "meteor", "meter-bolt", "meter-droplet",
      "meter-fire", "meter", "microchip-ai", "microchip", "microphone-lines-slash", "microphone-lines",
      "microphone-slash", "microphone-stand", "microphone", "microscope", "microwave", "mill-sign",
      "minimize", "minus", "mistletoe", "mitten", "mobile-button", "mobile-notch",
      "mobile-retro", "mobile-screen-button", "mobile-screen", "mobile-signal-out", "mobile-signal", "mobile",
      "money-bill-1-wave", "money-bill-1", "money-bill-simple-wave", "money-bill-simple", "money-bill-transfer", "money-bill-trend-up",
      "money-bill-wave", "money-bill-wheat", "money-bill", "money-bills-simple", "money-bills", "money-check-dollar-pen",
      "money-check-dollar", "money-check-pen", "money-check", "money-from-bracket", "money-simple-from-bracket", "monitor-waveform",
      "monkey", "monument", "moon-cloud", "moon-over-sun", "moon-stars", "moon",
      "moped", "mortar-pestle", "mosque", "mosquito-net", "mosquito", "motorcycle",
      "mound", "mountain-city", "mountain-sun", "mountain", "mountains", "mouse-field",
      "mp3-player", "mug-hot", "mug-marshmallows", "mug-saucer", "mug-tea-saucer", "mug-tea",
      "mug", "mushroom", "music-note-slash", "music-note", "music-slash", "music",
      "n", "naira-sign", "narwhal", "nesting-dolls", "network-wired", "neuter",
      "newspaper", "nfc-lock", "nfc-magnifying-glass", "nfc-pen", "nfc-signal", "nfc-slash",
      "nfc-trash", "nfc", "not-equal", "notdef", "note-medical", "note-sticky",
      "note", "notebook", "notes-medical", "notes", "o", "object-exclude",
      "object-group", "object-intersect", "object-subtract", "object-ungroup", "object-union", "objects-align-bottom",
      "objects-align-center-horizontal", "objects-align-center-vertical", "objects-align-left", "objects-align-right", "objects-align-top", "objects-column",
      "octagon-check", "octagon-divide", "octagon-exclamation", "octagon-minus", "octagon-plus", "octagon-xmark",
      "octagon", "oil-can-drip", "oil-can", "oil-temperature", "oil-well", "olive-branch",
      "olive", "om", "omega", "onion", "option", "ornament",
      "otter", "outdent", "outlet", "oven", "overline", "p",
      "page-caret-down", "page-caret-up", "page", "pager", "paint-roller", "paintbrush-fine",
      "paintbrush-pencil", "paintbrush", "palette", "pallet-box", "pallet-boxes", "pallet",
      "pan-food", "pan-frying", "pancakes", "panel-ews", "panel-fire", "panorama",
      "paper-plane-top", "paper-plane", "paperclip-vertical", "paperclip", "parachute-box", "paragraph-left",
      "paragraph", "party-bell", "party-horn", "passport", "paste", "pause",
      "paw-claws", "paw-simple", "paw", "peace", "peach", "peanut",
      "peanuts", "peapod", "pear", "pedestal", "pegasus", "pen-circle",
      "pen-clip-slash", "pen-clip", "pen-fancy-slash", "pen-fancy", "pen-field", "pen-line",
      "pen-nib-slash", "pen-nib", "pen-paintbrush", "pen-ruler", "pen-slash", "pen-swirl",
      "pen-to-square", "pen", "pencil-slash", "pencil", "people-arrows", "people-carry-box",
      "people-dress-simple", "people-dress", "people-group", "people-line", "people-pants-simple", "people-pants",
      "people-pulling", "people-robbery", "people-roof", "people-simple", "people", "pepper-hot",
      "pepper", "percent", "period", "person-arrow-down-to-line", "person-arrow-up-from-line", "person-biking-mountain",
      "person-biking", "person-booth", "person-breastfeeding", "person-burst", "person-cane", "person-carry-box",
      "person-chalkboard", "person-circle-check", "person-circle-exclamation", "person-circle-minus", "person-circle-plus", "person-circle-question",
      "person-circle-xmark", "person-digging", "person-dolly-empty", "person-dolly", "person-dots-from-line", "person-dress-burst",
      "person-dress-simple", "person-dress", "person-drowning", "person-falling-burst", "person-falling", "person-from-portal",
      "person-half-dress", "person-harassing", "person-hiking", "person-military-pointing", "person-military-rifle", "person-military-to-person",
      "person-pinball", "person-praying", "person-pregnant", "person-rays", "person-rifle", "person-running",
      "person-seat-reclined", "person-seat", "person-shelter", "person-sign", "person-simple", "person-skating",
      "person-ski-jumping", "person-ski-lift", "person-skiing-nordic", "person-skiing", "person-sledding", "person-snowboarding",
      "person-snowmobiling", "person-swimming", "person-through-window", "person-to-door", "person-to-portal", "person-walking-arrow-loop-left",
      "person-walking-arrow-right", "person-walking-dashed-line-arrow-right", "person-walking-luggage", "person-walking-with-cane", "person-walking", "person",
      "peseta-sign", "peso-sign", "phone-arrow-down-left", "phone-arrow-up-right", "phone-flip", "phone-hangup",
      "phone-intercom", "phone-missed", "phone-office", "phone-plus", "phone-rotary", "phone-slash",
      "phone-volume", "phone-xmark", "phone", "photo-film-music", "photo-film", "pi",
      "piano-keyboard", "piano", "pickleball", "pie", "pig", "piggy-bank",
      "pills", "pinata", "pinball", "pineapple", "pipe-circle-check", "pipe-collar",
      "pipe-section", "pipe-smoking", "pipe-valve", "pipe", "pizza-slice", "pizza",
      "place-of-worship", "plane-arrival", "plane-circle-check", "plane-circle-exclamation", "plane-circle-xmark", "plane-departure",
      "plane-engines", "plane-lock", "plane-prop", "plane-slash", "plane-tail", "plane-up-slash",
      "plane-up", "plane", "planet-moon", "planet-ringed", "plant-wilt", "plate-utensils",
      "plate-wheat", "play-pause", "play", "plug-circle-bolt", "plug-circle-check", "plug-circle-exclamation",
      "plug-circle-minus", "plug-circle-plus", "plug-circle-xmark", "plug", "plus-large", "plus-minus",
      "plus", "podcast", "podium-star", "podium", "police-box", "poll-people",
      "pompebled", "poo-storm", "poo", "pool-8-ball", "poop", "popcorn",
      "popsicle", "pot-food", "potato", "power-off", "prescription-bottle-medical", "prescription-bottle",
      "prescription", "presentation-screen", "pretzel", "print-magnifying-glass", "print-slash", "print",
      "projector", "pump-medical", "pump-soap", "pump", "pumpkin", "puzzle-piece-simple",
      "puzzle-piece", "puzzle", "q", "qrcode", "question", "quote-left",
      "quote-right", "quotes", "r", "rabbit-running", "rabbit", "racquet",
      "radar", "radiation", "radio-tuner", "radio", "rainbow", "raindrops",
      "ram", "ramp-loading", "ranking-star", "raygun", "receipt", "record-vinyl",
      "rectangle-ad", "rectangle-barcode", "rectangle-code", "rectangle-history-circle-plus", "rectangle-history-circle-user", "rectangle-history",
      "rectangle-list", "rectangle-pro", "rectangle-terminal", "rectangle-vertical-history", "rectangle-vertical", "rectangle-wide",
      "rectangle-xmark", "rectangle", "rectangles-mixed", "recycle", "reel", "refrigerator",
      "registered", "repeat-1", "repeat", "reply-all", "reply-clock", "reply",
      "republican", "restroom-simple", "restroom", "retweet", "rhombus", "ribbon",
      "right-from-bracket", "right-from-line", "right-left", "right-long-to-line", "right-long", "right-to-bracket",
      "right-to-line", "right", "ring-diamond", "ring", "rings-wedding", "road-barrier",
      "road-bridge", "road-circle-check", "road-circle-exclamation", "road-circle-xmark", "road-lock", "road-spikes",
      "road", "robot-astromech", "robot", "rocket-launch", "rocket", "roller-coaster",
      "rotate-exclamation", "rotate-left", "rotate-right", "rotate", "route-highway", "route-interstate",
      "route", "router", "rss", "ruble-sign", "rug", "rugby-ball",
      "ruler-combined", "ruler-horizontal", "ruler-triangle", "ruler-vertical", "ruler", "rupee-sign",
      "rupiah-sign", "rv", "s", "sack-dollar", "sack-xmark", "sack",
      "sailboat", "salad", "salt-shaker", "sandwich", "satellite-dish", "satellite",
      "sausage", "saxophone-fire", "saxophone", "scale-balanced", "scale-unbalanced-flip", "scale-unbalanced",
      "scalpel-line-dashed", "scalpel", "scanner-gun", "scanner-image", "scanner-keyboard", "scanner-touchscreen",
      "scarecrow", "scarf", "school-circle-check", "school-circle-exclamation", "school-circle-xmark", "school-flag",
      "school-lock", "school", "scissors", "screen-users", "screencast", "screwdriver-wrench",
      "screwdriver", "scribble", "scroll-old", "scroll-torah", "scroll", "scrubber",
      "scythe", "sd-card", "sd-cards", "seal-exclamation", "seal-question", "seal",
      "seat-airline", "section", "seedling", "semicolon", "send-back", "send-backward",
      "sensor-cloud", "sensor-fire", "sensor-on", "sensor-triangle-exclamation", "sensor", "server",
      "shapes", "share-all", "share-from-square", "share-nodes", "share", "sheep",
      "sheet-plastic", "shekel-sign", "shelves-empty", "shelves", "shield-cat", "shield-check",
      "shield-cross", "shield-dog", "shield-exclamation", "shield-halved", "shield-heart", "shield-keyhole",
      "shield-minus", "shield-plus", "shield-quartered", "shield-slash", "shield-virus", "shield-xmark",
      "shield", "ship", "shirt-long-sleeve", "shirt-running", "shirt-tank-top", "shirt",
      "shish-kebab", "shoe-prints", "shop-lock", "shop-slash", "shop", "shovel-snow",
      "shovel", "shower-down", "shower", "shredder", "shrimp", "shuffle",
      "shutters", "shuttle-space", "shuttlecock", "sickle", "sidebar-flip", "sidebar",
      "sigma", "sign-hanging", "signal-bars-fair", "signal-bars-good", "signal-bars-slash", "signal-bars-weak",
      "signal-bars", "signal-fair", "signal-good", "signal-slash", "signal-stream-slash", "signal-stream",
      "signal-strong", "signal-weak", "signal", "signature-lock", "signature-slash", "signature",
      "signs-post", "sim-card", "sim-cards", "sink", "siren-on", "siren",
      "sitemap", "skeleton", "ski-boot-ski", "ski-boot", "skull-cow", "skull-crossbones",
      "skull", "slash-back", "slash-forward", "slash", "sleigh", "slider",
      "sliders-simple", "sliders-up", "sliders", "slot-machine", "smog", "smoke",
      "smoking", "snake", "snooze", "snow-blowing", "snowflake", "snowflakes",
      "snowman-head", "snowman", "snowplow", "soap", "socks", "soft-serve",
      "solar-panel", "solar-system", "sort-down", "sort-up", "sort", "spa",
      "space-station-moon-construction", "space-station-moon", "spade", "spaghetti-monster-flying", "sparkles", "speaker",
      "speakers", "spell-check", "spider-black-widow", "spider-web", "spider", "spinner-third",
      "spinner", "split", "splotch", "spoon", "sportsball", "spray-can-sparkles",
      "spray-can", "sprinkler-ceiling", "sprinkler", "square-0", "square-1", "square-2",
      "square-3", "square-4", "square-5", "square-6", "square-7", "square-8",
      "square-9", "square-a-lock", "square-a", "square-ampersand", "square-arrow-down-left", "square-arrow-down-right",
      "square-arrow-down", "square-arrow-left", "square-arrow-right", "square-arrow-up-left", "square-arrow-up-right", "square-arrow-up",
      "square-b", "square-bolt", "square-c", "square-caret-down", "square-caret-left", "square-caret-right",
      "square-caret-up", "square-check", "square-chevron-down", "square-chevron-left", "square-chevron-right", "square-chevron-up",
      "square-code", "square-d", "square-dashed", "square-divide", "square-dollar", "square-down-left",
      "square-down-right", "square-down", "square-e", "square-ellipsis-vertical", "square-ellipsis", "square-envelope",
      "square-exclamation", "square-f", "square-fragile", "square-full", "square-g", "square-h",
      "square-heart", "square-i", "square-info", "square-j", "square-k", "square-kanban",
      "square-l", "square-left", "square-list", "square-m", "square-minus", "square-n",
      "square-nfi", "square-o", "square-p", "square-parking-slash", "square-parking", "square-pen",
      "square-person-confined", "square-phone-flip", "square-phone-hangup", "square-phone", "square-plus", "square-poll-horizontal",
      "square-poll-vertical", "square-q", "square-quarters", "square-question", "square-quote", "square-r",
      "square-right", "square-ring", "square-root-variable", "square-root", "square-rss", "square-s",
      "square-share-nodes", "square-sliders-vertical", "square-sliders", "square-small", "square-star", "square-t",
      "square-terminal", "square-this-way-up", "square-u", "square-up-left", "square-up-right", "square-up",
      "square-user", "square-v", "square-virus", "square-w", "square-x", "square-xmark",
      "square-y", "square-z", "square", "squid", "squirrel", "staff-snake",
      "staff", "stairs", "stamp", "standard-definition", "stapler", "star-and-crescent",
      "star-christmas", "star-exclamation", "star-half-stroke", "star-half", "star-of-david", "star-of-life",
      "star-sharp-half-stroke", "star-sharp-half", "star-sharp", "star-shooting", "star", "starfighter-twin-ion-engine-advanced",
      "starfighter-twin-ion-engine", "starfighter", "stars", "starship-freighter", "starship", "steak",
      "steering-wheel", "sterling-sign", "stethoscope", "stocking", "stomach", "stop",
      "stopwatch-20", "stopwatch", "store-lock", "store-slash", "store", "strawberry",
      "street-view", "stretcher", "strikethrough", "stroopwafel", "subscript", "suitcase-medical",
      "suitcase-rolling", "suitcase", "sun-bright", "sun-cloud", "sun-dust", "sun-haze",
      "sun-plant-wilt", "sun", "sunglasses", "sunrise", "sunset", "superscript",
      "sushi-roll", "sushi", "swatchbook", "sword-laser-alt", "sword-laser", "sword",
      "swords-laser", "swords", "symbols", "synagogue", "syringe", "t",
      "table-cells-large", "table-cells", "table-columns", "table-layout", "table-list", "table-picnic",
      "table-pivot", "table-rows", "table-tennis-paddle-ball", "table-tree", "table", "tablet-button",
      "tablet-rugged", "tablet-screen-button", "tablet-screen", "tablet", "tablets", "tachograph-digital",
      "taco", "tag", "tags", "tally-1", "tally-2", "tally-3",
      "tally-4", "tally", "tamale", "tank-water", "tape", "tarp-droplet",
      "tarp", "taxi-bus", "taxi", "teddy-bear", "teeth-open", "teeth",
      "telescope", "temperature-arrow-down", "temperature-arrow-up", "temperature-empty", "temperature-full", "temperature-half",
      "temperature-high", "temperature-list", "temperature-low", "temperature-quarter", "temperature-snow", "temperature-sun",
      "temperature-three-quarters", "tenge-sign", "tennis-ball", "tent-arrow-down-to-line", "tent-arrow-left-right", "tent-arrow-turn-left",
      "tent-arrows-down", "tent", "tents", "terminal", "text-height", "text-size",
      "text-slash", "text-width", "text", "thermometer", "theta", "thought-bubble",
      "thumbs-down", "thumbs-up", "thumbtack", "tick", "ticket-airline", "ticket-simple",
      "ticket", "tickets-airline", "tilde", "timeline-arrow", "timeline", "timer",
      "tire-flat", "tire-pressure-warning", "tire-rugged", "tire", "toggle-large-off", "toggle-large-on",
      "toggle-off", "toggle-on", "toilet-paper-blank-under", "toilet-paper-blank", "toilet-paper-check", "toilet-paper-slash",
      "toilet-paper-under-slash", "toilet-paper-under", "toilet-paper-xmark", "toilet-paper", "toilet-portable", "toilet",
      "toilets-portable", "tomato", "tombstone-blank", "tombstone", "toolbox", "tooth",
      "toothbrush", "torii-gate", "tornado", "tower-broadcast", "tower-cell", "tower-control",
      "tower-observation", "tractor", "trademark", "traffic-cone", "traffic-light-go", "traffic-light-slow",
      "traffic-light-stop", "traffic-light", "trailer", "train-subway-tunnel", "train-subway", "train-track",
      "train-tram", "train-tunnel", "train", "transformer-bolt", "transgender", "transporter-1",
      "transporter-2", "transporter-3", "transporter-4", "transporter-5", "transporter-6", "transporter-7",
      "transporter-empty", "transporter", "trash-arrow-up", "trash-can-arrow-up", "trash-can-check", "trash-can-clock",
      "trash-can-list", "trash-can-plus", "trash-can-slash", "trash-can-undo", "trash-can-xmark", "trash-can",
      "trash-check", "trash-clock", "trash-list", "trash-plus", "trash-slash", "trash-undo",
      "trash-xmark", "trash", "treasure-chest", "tree-christmas", "tree-city", "tree-deciduous",
      "tree-decorated", "tree-large", "tree-palm", "tree", "trees", "triangle-exclamation",
      "triangle-instrument", "triangle-person-digging", "triangle", "trillium", "trophy-star", "trophy",
      "trowel-bricks", "trowel", "truck-arrow-right", "truck-bolt", "truck-clock", "truck-container-empty",
      "truck-container", "truck-droplet", "truck-fast", "truck-field-un", "truck-field", "truck-flatbed",
      "truck-front", "truck-medical", "truck-monster", "truck-moving", "truck-pickup", "truck-plane",
      "truck-plow", "truck-ramp-box", "truck-ramp-couch", "truck-ramp", "truck-tow", "truck",
      "trumpet", "tty-answer", "tty", "tugrik-sign", "turkey", "turkish-lira-sign",
      "turn-down-left", "turn-down-right", "turn-down", "turn-up", "turntable", "turtle",
      "tv-music", "tv-retro", "tv", "typewriter", "u", "ufo-beam",
      "ufo", "umbrella-beach", "umbrella-simple", "umbrella", "underline", "unicorn",
      "uniform-martial-arts", "union", "universal-access", "unlock-keyhole", "unlock", "up-down-left-right",
      "up-down", "up-from-bracket", "up-from-dotted-line", "up-from-line", "up-left", "up-long",
      "up-right-and-down-left-from-center", "up-right-from-square", "up-right", "up-to-dotted-line", "up-to-line", "up",
      "upload", "usb-drive", "user-alien", "user-astronaut", "user-bounty-hunter", "user-check",
      "user-chef", "user-clock", "user-cowboy", "user-crown", "user-doctor-hair-long", "user-doctor-hair",
      "user-doctor-message", "user-doctor", "user-gear", "user-graduate", "user-group-crown", "user-group",
      "user-hair-buns", "user-hair-long", "user-hair-mullet", "user-hair", "user-headset", "user-helmet-safety",
      "user-injured", "user-large-slash", "user-large", "user-lock", "user-minus", "user-music",
      "user-ninja", "user-nurse-hair-long", "user-nurse-hair", "user-nurse", "user-pen", "user-pilot-tie",
      "user-pilot", "user-plus", "user-police-tie", "user-police", "user-robot-xmarks", "user-robot",
      "user-secret", "user-shakespeare", "user-shield", "user-slash", "user-tag", "user-tie-hair-long",
      "user-tie-hair", "user-tie", "user-unlock", "user-visor", "user-vneck-hair-long", "user-vneck-hair",
      "user-vneck", "user-xmark", "user", "users-between-lines", "users-gear", "users-line",
      "users-medical", "users-rays", "users-rectangle", "users-slash", "users-viewfinder", "users",
      "utensils-slash", "utensils", "utility-pole-double", "utility-pole", "v", "vacuum-robot",
      "vacuum", "value-absolute", "van-shuttle", "vault", "vector-circle", "vector-polygon",
      "vector-square", "vent-damper", "venus-double", "venus-mars", "venus", "vest-patches",
      "vest", "vial-circle-check", "vial-virus", "vial", "vials", "video-arrow-down-left",
      "video-arrow-up-right", "video-plus", "video-slash", "video", "vihara", "violin",
      "virus-covid-slash", "virus-covid", "virus-slash", "virus", "viruses", "voicemail",
      "volcano", "volleyball", "volume-high", "volume-low", "volume-off", "volume-slash",
      "volume-xmark", "volume", "vr-cardboard", "w", "waffle", "wagon-covered",
      "walker", "walkie-talkie", "wallet", "wand-magic-sparkles", "wand-magic", "wand-sparkles",
      "wand", "warehouse-full", "warehouse", "washing-machine", "watch-apple", "watch-calculator",
      "watch-fitness", "watch-smart", "watch", "water-arrow-down", "water-arrow-up", "water-ladder",
      "water", "watermelon-slice", "wave-pulse", "wave-sine", "wave-square", "wave-triangle",
      "waveform-lines", "waveform", "weight-hanging", "weight-scale", "whale", "wheat-awn-circle-exclamation",
      "wheat-awn-slash", "wheat-awn", "wheat-slash", "wheat", "wheelchair-move", "wheelchair",
      "whiskey-glass-ice", "whiskey-glass", "whistle", "wifi-exclamation", "wifi-fair", "wifi-slash",
      "wifi-weak", "wifi", "wind-turbine", "wind-warning", "wind", "window-flip",
      "window-frame-open", "window-frame", "window-maximize", "window-minimize", "window-restore", "window",
      "windsock", "wine-bottle", "wine-glass-crack", "wine-glass-empty", "wine-glass", "won-sign",
      "worm", "wreath", "wrench-simple", "wrench", "x-ray", "x",
      "xmark-large", "xmark-to-slot", "xmark", "xmarks-lines", "y", "yen-sign",
      "yin-yang", "z",];

    const brands: string[] = [
      "42-group (brand)", "500px (brand)", "accessible-icon (brand)", "accusoft (brand)", "adn (brand)", "adversal (brand)",
      "affiliatetheme (brand)", "airbnb (brand)", "algolia (brand)", "alipay (brand)", "amazon-pay (brand)", "amazon (brand)",
      "amilia (brand)", "android (brand)", "angellist (brand)", "angrycreative (brand)", "angular (brand)", "app-store-ios (brand)",
      "app-store (brand)", "apper (brand)", "apple-pay (brand)", "apple (brand)", "artstation (brand)", "asymmetrik (brand)",
      "atlassian (brand)", "audible (brand)", "autoprefixer (brand)", "avianex (brand)", "aviato (brand)", "aws (brand)",
      "bandcamp (brand)", "battle-net (brand)", "behance (brand)", "bilibili (brand)", "bimobject (brand)", "bitbucket (brand)",
      "bitcoin (brand)", "bity (brand)", "black-tie (brand)", "blackberry (brand)", "blogger-b (brand)", "blogger (brand)",
      "bluetooth-b (brand)", "bluetooth (brand)", "bootstrap (brand)", "bots (brand)", "btc (brand)", "buffer (brand)",
      "buromobelexperte (brand)", "buy-n-large (brand)", "buysellads (brand)", "canadian-maple-leaf (brand)", "cc-amazon-pay (brand)", "cc-amex (brand)",
      "cc-apple-pay (brand)", "cc-diners-club (brand)", "cc-discover (brand)", "cc-jcb (brand)", "cc-mastercard (brand)", "cc-paypal (brand)",
      "cc-stripe (brand)", "cc-visa (brand)", "centercode (brand)", "centos (brand)", "chrome (brand)", "chromecast (brand)",
      "cloudflare (brand)", "cloudscale (brand)", "cloudsmith (brand)", "cloudversify (brand)", "cmplid (brand)", "codepen (brand)",
      "codiepie (brand)", "confluence (brand)", "connectdevelop (brand)", "contao (brand)", "cotton-bureau (brand)", "cpanel (brand)",
      "creative-commons-by (brand)", "creative-commons-nc-eu (brand)", "creative-commons-nc-jp (brand)", "creative-commons-nc (brand)", "creative-commons-nd (brand)", "creative-commons-pd-alt (brand)",
      "creative-commons-pd (brand)", "creative-commons-remix (brand)", "creative-commons-sa (brand)", "creative-commons-sampling-plus (brand)", "creative-commons-sampling (brand)", "creative-commons-share (brand)",
      "creative-commons-zero (brand)", "creative-commons (brand)", "critical-role (brand)", "css3-alt (brand)", "css3 (brand)", "cuttlefish (brand)",
      "d-and-d-beyond (brand)", "d-and-d (brand)", "dailymotion (brand)", "dashcube (brand)", "deezer (brand)", "delicious (brand)",
      "deploydog (brand)", "deskpro (brand)", "dev (brand)", "deviantart (brand)", "dhl (brand)", "diaspora (brand)",
      "digg (brand)", "digital-ocean (brand)", "discord (brand)", "discourse (brand)", "dochub (brand)", "docker (brand)",
      "draft2digital (brand)", "dribbble (brand)", "dropbox (brand)", "drupal (brand)", "dyalog (brand)", "earlybirds (brand)",
      "ebay (brand)", "edge-legacy (brand)", "edge (brand)", "elementor (brand)", "ello (brand)", "ember (brand)",
      "empire (brand)", "envira (brand)", "erlang (brand)", "ethereum (brand)", "etsy (brand)", "evernote (brand)",
      "expeditedssl (brand)", "facebook-f (brand)", "facebook-messenger (brand)", "facebook (brand)", "fantasy-flight-games (brand)", "fedex (brand)",
      "fedora (brand)", "figma (brand)", "firefox-browser (brand)", "firefox (brand)", "first-order-alt (brand)", "first-order (brand)",
      "firstdraft (brand)", "flickr (brand)", "flipboard (brand)", "fly (brand)", "font-awesome (brand)", "fonticons-fi (brand)",
      "fonticons (brand)", "fort-awesome-alt (brand)", "fort-awesome (brand)", "forumbee (brand)", "foursquare (brand)", "free-code-camp (brand)",
      "freebsd (brand)", "fulcrum (brand)", "galactic-republic (brand)", "galactic-senate (brand)", "get-pocket (brand)", "gg-circle (brand)",
      "gg (brand)", "git-alt (brand)", "git (brand)", "github-alt (brand)", "github (brand)", "gitkraken (brand)",
      "gitlab (brand)", "gitter (brand)", "glide-g (brand)", "glide (brand)", "gofore (brand)", "golang (brand)",
      "goodreads-g (brand)", "goodreads (brand)", "google-drive (brand)", "google-pay (brand)", "google-play (brand)", "google-plus-g (brand)",
      "google-plus (brand)", "google-wallet (brand)", "google (brand)", "gratipay (brand)", "grav (brand)", "gripfire (brand)",
      "grunt (brand)", "guilded (brand)", "gulp (brand)", "hacker-news (brand)", "hackerrank (brand)", "hashnode (brand)",
      "hips (brand)", "hire-a-helper (brand)", "hive (brand)", "hooli (brand)", "hornbill (brand)", "hotjar (brand)",
      "houzz (brand)", "html5 (brand)", "hubspot (brand)", "ideal (brand)", "imdb (brand)", "instagram (brand)",
      "instalod (brand)", "intercom (brand)", "internet-explorer (brand)", "invision (brand)", "ioxhost (brand)", "itch-io (brand)",
      "itunes-note (brand)", "itunes (brand)", "java (brand)", "jedi-order (brand)", "jenkins (brand)", "jira (brand)",
      "joget (brand)", "joomla (brand)", "js (brand)", "jsfiddle (brand)", "kaggle (brand)", "keybase (brand)",
      "keycdn (brand)", "kickstarter-k (brand)", "kickstarter (brand)", "korvue (brand)", "laravel (brand)", "lastfm (brand)",
      "leanpub (brand)", "less (brand)", "line (brand)", "linkedin-in (brand)", "linkedin (brand)", "linode (brand)",
      "linux (brand)", "lyft (brand)", "magento (brand)", "mailchimp (brand)", "mandalorian (brand)", "markdown (brand)",
      "mastodon (brand)", "maxcdn (brand)", "mdb (brand)", "medapps (brand)", "medium (brand)", "medrt (brand)",
      "meetup (brand)", "megaport (brand)", "mendeley (brand)", "meta (brand)", "microblog (brand)", "microsoft (brand)",
      "mix (brand)", "mixcloud (brand)", "mixer (brand)", "mizuni (brand)", "modx (brand)", "monero (brand)",
      "napster (brand)", "neos (brand)", "nfc-directional (brand)", "nfc-symbol (brand)", "nimblr (brand)", "node-js (brand)",
      "node (brand)", "npm (brand)", "ns8 (brand)", "nutritionix (brand)", "octopus-deploy (brand)", "odnoklassniki (brand)",
      "old-republic (brand)", "opencart (brand)", "openid (brand)", "opera (brand)", "optin-monster (brand)", "orcid (brand)",
      "osi (brand)", "padlet (brand)", "page4 (brand)", "pagelines (brand)", "palfed (brand)", "patreon (brand)",
      "paypal (brand)", "perbyte (brand)", "periscope (brand)", "phabricator (brand)", "phoenix-framework (brand)", "phoenix-squadron (brand)",
      "php (brand)", "pied-piper-alt (brand)", "pied-piper-hat (brand)", "pied-piper-pp (brand)", "pied-piper (brand)", "pinterest-p (brand)",
      "pinterest (brand)", "pix (brand)", "playstation (brand)", "product-hunt (brand)", "pushed (brand)", "python (brand)",
      "qq (brand)", "quinscape (brand)", "quora (brand)", "r-project (brand)", "raspberry-pi (brand)", "ravelry (brand)",
      "react (brand)", "reacteurope (brand)", "readme (brand)", "rebel (brand)", "red-river (brand)", "reddit-alien (brand)",
      "reddit (brand)", "redhat (brand)", "renren (brand)", "replyd (brand)", "researchgate (brand)", "resolving (brand)",
      "rev (brand)", "rocketchat (brand)", "rockrms (brand)", "rust (brand)", "safari (brand)", "salesforce (brand)",
      "sass (brand)", "schlix (brand)", "screenpal (brand)", "scribd (brand)", "searchengin (brand)", "sellcast (brand)",
      "sellsy (brand)", "servicestack (brand)", "shirtsinbulk (brand)", "shopify (brand)", "shopware (brand)", "simplybuilt (brand)",
      "sistrix (brand)", "sith (brand)", "sitrox (brand)", "sketch (brand)", "skyatlas (brand)", "skype (brand)",
      "slack (brand)", "slideshare (brand)", "snapchat (brand)", "soundcloud (brand)", "sourcetree (brand)", "space-awesome (brand)",
      "speakap (brand)", "speaker-deck (brand)", "spotify (brand)", "square-behance (brand)", "square-dribbble (brand)", "square-facebook (brand)",
      "square-font-awesome-stroke (brand)", "square-font-awesome (brand)", "square-git (brand)", "square-github (brand)", "square-gitlab (brand)", "square-google-plus (brand)",
      "square-hacker-news (brand)", "square-instagram (brand)", "square-js (brand)", "square-lastfm (brand)", "square-odnoklassniki (brand)", "square-pied-piper (brand)",
      "square-pinterest (brand)", "square-reddit (brand)", "square-snapchat (brand)", "square-steam (brand)", "square-tumblr (brand)", "square-twitter (brand)",
      "square-viadeo (brand)", "square-vimeo (brand)", "square-whatsapp (brand)", "square-xing (brand)", "square-youtube (brand)", "squarespace (brand)",
      "stack-exchange (brand)", "stack-overflow (brand)", "stackpath (brand)", "staylinked (brand)", "steam-symbol (brand)", "steam (brand)",
      "sticker-mule (brand)", "strava (brand)", "stripe-s (brand)", "stripe (brand)", "studiovinari (brand)", "stumbleupon-circle (brand)",
      "stumbleupon (brand)", "superpowers (brand)", "supple (brand)", "suse (brand)", "swift (brand)", "symfony (brand)",
      "teamspeak (brand)", "telegram (brand)", "tencent-weibo (brand)", "the-red-yeti (brand)", "themeco (brand)", "themeisle (brand)",
      "think-peaks (brand)", "tiktok (brand)", "trade-federation (brand)", "trello (brand)", "tumblr (brand)", "twitch (brand)",
      "twitter (brand)", "typo3 (brand)", "uber (brand)", "ubuntu (brand)", "uikit (brand)", "umbraco (brand)",
      "uncharted (brand)", "uniregistry (brand)", "unity (brand)", "unsplash (brand)", "untappd (brand)", "ups (brand)",
      "usb (brand)", "usps (brand)", "ussunnah (brand)", "vaadin (brand)", "viacoin (brand)", "viadeo (brand)",
      "viber (brand)", "vimeo-v (brand)", "vimeo (brand)", "vine (brand)", "vk (brand)", "vnv (brand)",
      "vuejs (brand)", "watchman-monitoring (brand)", "waze (brand)", "weebly (brand)", "weibo (brand)", "weixin (brand)",
      "whatsapp (brand)", "whmcs (brand)", "wikipedia-w (brand)", "windows (brand)", "wirsindhandwerk (brand)", "wix (brand)",
      "wizards-of-the-coast (brand)", "wodu (brand)", "wolf-pack-battalion (brand)", "wordpress-simple (brand)", "wordpress (brand)", "wpbeginner (brand)",
      "wpexplorer (brand)", "wpforms (brand)", "wpressr (brand)", "xbox (brand)", "xing (brand)", "y-combinator (brand)",
      "yahoo (brand)", "yammer (brand)", "yandex-international (brand)", "yandex (brand)", "yarn (brand)", "yelp (brand)",
      "yoast (brand)", "youtube (brand)", "zhihu (brand)",];

    const icons: string[] = [];
    standard.forEach(icon => {
      icons.push(icon, `${icon} (solid)`, `${icon} (light)`, `${icon} (duotone)`);
    });
    icons.push(...brands);
    icons.sort();

    return icons;

  }






  public static ReportCompilerAttachmentNumberingStyle(language?: string): m5core.PickListSelectionViewModel[] {
    var list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "1", DisplayText: "1, 2, 3, etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "A", DisplayText: "A, B, C, etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "a", DisplayText: "a, b, c, etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "I", DisplayText: "I, II, III, etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "i", DisplayText: "i, ii, iii, etc." } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static ReportCompilerParagraphNumberingStyle(language?: string): m5core.PickListSelectionViewModel[] {
    var list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "1.", DisplayText: "1. 2. 3. etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "1)", DisplayText: "1) 2) 3) etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "A.", DisplayText: "A. B. C. etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "A)", DisplayText: "A) B) C) etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "a.", DisplayText: "a. b. c. etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "a)", DisplayText: "a) b) c) etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "I.", DisplayText: "I. II. III. etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "I)", DisplayText: "I) II) III) etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "i.", DisplayText: "i. ii. iii. etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "i)", DisplayText: "i) ii) iii) etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "*", DisplayText: "Solid Bullet" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "o", DisplayText: "Outline Bullet" } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static ReportCompilerAutomaticallySelectLibraryText(language?: string): m5core.PickListSelectionViewModel[] {
    var list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "A", DisplayText: "Always" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "N", DisplayText: "Never" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "L", DisplayText: "When library entry configured for automatic selection" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "O", DisplayText: "When only one item suggested" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "E", DisplayText: "When library entry configured for automatic selection or only one item suggested" } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static ReportCompilerAttachmentSectionHeadingType(language?: string): m5core.PickListSelectionViewModel[] {
    var list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "ExternalAssetId", DisplayText: "Document Id" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Title", DisplayText: "Document Title" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "FriendlyName", DisplayText: "Document File Name" } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static ReportCompilerAssetReportTemplateAssetScope(language?: string): m5core.PickListSelectionViewModel[] {
    var list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "ReportTemplate", DisplayText: "Report Template" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "AttachmentCoverPageTemplate", DisplayText: "Attachment Cover Page Template" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "AttachmentImageLayoutTemplate", DisplayText: "Attachment Image Layout Template" } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static ReportCompilerReportSectionConfigurationTextColumnPropertyName(language?: string): m5core.PickListSelectionViewModel[] {
    var list: m5core.PickListSelectionViewModel[] = [];
    for (var i = 1; i <= 10; i++) {
      list.push({ Value: `Value${Helper.pad(i, 2, "0")}`, DisplayText: `Value${Helper.pad(i, 2, "0")}` } as m5core.PickListSelectionViewModel);
    }
    list.push({ Value: "Tags", DisplayText: "Tags" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "ExternalTextId", DisplayText: "ExternalTextId" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Title", DisplayText: "Title" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Score", DisplayText: "Score" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Text", DisplayText: "Text" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PreviewText", DisplayText: "PreviewText" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "AuthorLongName", DisplayText: "AuthorLongName" } as m5core.PickListSelectionViewModel);
    return list;
  }

}
