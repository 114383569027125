import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { Helper, Log } from "projects/core-lib/src/lib/helpers/helper";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { IconHelper } from 'projects/common-lib/src/lib/image/icon/icon-helper';
import { ApiDocsService } from './api-docs.service';
import { ApiModuleReportCompiler } from '../api/Api.Module.ReportCompiler';
import { ApiModuleCore } from '../api/Api.Module.Core';
import { ApiModuleWeb } from '../api/Api.Module.Web';
import { Api } from '../api/Api';
import { ApiModuleSecurity } from '../api/Api.Module.Security';
import { ApiOperationType, ApiProperties, ApiEndpoint } from '../api/ApiModels';
import { ApiModulePayment } from '../api/Api.Module.Payment';
import { ApiModuleTelecom } from '../api/Api.Module.Telecom';
import { ApiModuleUsage } from '../api/Api.Module.Usage';
import { Action } from 'projects/common-lib/src/lib/ux-models';
import { MenuItem } from 'primeng/api';
import { SecurityService } from './security.service';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { map, takeUntil, withLatestFrom, timeout } from 'rxjs/operators';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class MenuService extends BaseService {


  public nativeConfiguredMenu: m.MenuItem[] = [];
  public nativeDefaultMenu: m.MenuItem[] = [];
  public primeMenu: MenuItem[] = [];
  protected primeMenuSubject = new BehaviorSubject<MenuItem[]>([]);
  public primeMenuFeed(): Observable<MenuItem[]> {
    return this.primeMenuSubject.asObservable();
  }

  protected observeUser: Observable<m5sec.AuthenticatedUserViewModel> = null;
  protected observeAppInfo: Observable<m5core.ApplicationInformationModel> = null;


  constructor(
    protected appService: AppService,
    protected securityService: SecurityService,
    protected docService: ApiDocsService) {

    super();

    // Services don't call ngOnInit automatically like components do.
    this.ngOnInit();

  }


  ngOnInit(): void {

    super.ngOnInit();

    this.observeUser = this.appService.userFeed().pipe(takeUntil(this.ngUnsubscribe));
    this.observeAppInfo = this.appService.appInfoFeed().pipe(takeUntil(this.ngUnsubscribe));

    try {
      // Do our first menu rendering
      this.updateMenu(false, "initial menu config");
    } catch (ex) {
      Log.errorMessage(ex);
    }

  }


  ngOnDestroy() {
    super.ngOnDestroy();
    this.primeMenuSubject.complete();
  }


  /**
   * Updates menu and emits for primeMenuFeed subscribers.
   * @param iconOnly
   * @param triggeredBy
   * @returns
   */
  updateMenu(iconOnly: boolean, triggeredBy: string = "menu update"): Observable<MenuItem[]> {

    // We do not want to use a default menu for kiosk, customer, or app until we know there is not
    // a configured menu coming with our app info object.  For ngib and api docs applications just
    // use the default since those menus cannot be edited by users.
    if (this.appService.config.type === "ngib" || this.appService.config.type === "api-docs") {
      if (!this.nativeDefaultMenu || this.nativeDefaultMenu.length === 0) {
        this.nativeDefaultMenu = this.buildDefaultAppMenu();
      }
      if (!this.nativeConfiguredMenu || this.nativeConfiguredMenu.length === 0) {
        this.nativeConfiguredMenu = this.nativeDefaultMenu;
      }
    }

    // Our menu rendering is going to depend on user and app info objects but if those observables
    // don't get values within the timeout we want we provide an option to render the default menu
    // instead of having no menu via this setTimeout() call.  If a configured menu is available
    // then the default menu will not be utilized.
    setTimeout(() => this.updateMenuUsingDefault(iconOnly), 5_000);

    // When either user or app info observable emits a value then we refresh our menu and publish through our menu subject
    combineLatest([this.observeUser, this.observeAppInfo])
      .pipe(takeUntil(this.ngUnsubscribe))
      // TODO pipe timeout not working as expected
      // ,timeout({
      //   first: 3_000,
      //   each: undefined,
      //   with: () => [this.appService.userOrDefault, this.appService.appInfoOrDefault]})
      .subscribe(results => {

        const user: m5sec.AuthenticatedUserViewModel = results[0];
        const appInfo: m5core.ApplicationInformationModel = results[1];

        // Both ngib and api docs menus are configured above using default.  If we're not ngib or
        // api docs and have a customized menu then use that; otherwise, we'll use the default menu.
        if (this.appService.config.type !== "ngib" && this.appService.config.type !== "api-docs") {
          if (!appInfo?.Settings?.Menu || appInfo.Settings.Menu.length === 0) {
            this.nativeDefaultMenu = this.buildDefaultAppMenu();
            this.nativeConfiguredMenu = this.buildDefaultAppMenu();
          } else {
            this.nativeConfiguredMenu = appInfo.Settings.Menu;
          }
        }

        // We should not get this far without this.nativeConfiguredMenu but if it happens then use the default.
        if (!this.nativeConfiguredMenu || this.nativeConfiguredMenu.length === 0) {
          this.nativeConfiguredMenu = this.buildDefaultAppMenu();
        }

        // Convert the menu to UI format.
        // Make note of any expanded menu items
        const expanded: string[] = this.getExpandedPrimeMenuIds(this.primeMenu);

        // Map our menu object to the prime menu object array since we're using prime for our menu rendering
        this.primeMenu = [...this.toPrimeMenu(this.nativeConfiguredMenu, user, appInfo, null, iconOnly)];
        Log.debug("ui", "Menu", `Using configured menu with ${this.nativeConfiguredMenu.length} menu items.  Menu render triggered by '${triggeredBy}'.`);

        // Restore any previously expanded menu items
        this.setExpandedPrimeMenuIds(this.primeMenu, expanded);
        this.primeMenuSubject.next(this.primeMenu);

      });

    return this.primeMenuFeed();

  }



  /**
   * Updates menu using app default menu and emits for primeMenuFeed subscribers.
   * This should only be called in a timeout when updateMenu fails to render a
   * menu due not app info and/or user objects not loading.  The reason is that
   * we prefer a default menu to no menu at all.
   * @param iconOnly
   * @param triggeredBy
   * @returns
   */
  protected updateMenuUsingDefault(iconOnly: boolean): Observable<MenuItem[]> {

    // If we have a configured menu then we're done since this method only
    // needs to take action after a timeout with no configured menu available.
    if (this.primeMenu && this.primeMenu.length > 0) {
      return this.primeMenuFeed();
    }

    // Get our default menu
    this.nativeDefaultMenu = this.buildDefaultAppMenu();

    // Convert the menu to UI format.
    // Make note of any expanded menu items
    const expanded: string[] = this.getExpandedPrimeMenuIds(this.primeMenu);

    // Map our menu object to the prime menu object array since we're using prime for our menu rendering.
    // We use default user and app info objects as inputs when those objects do not exist.
    this.primeMenu = [...this.toPrimeMenu(this.nativeDefaultMenu, this.appService.userOrDefault, this.appService.appInfoOrDefault, null, iconOnly)];
    Log.debug("ui", "Menu", `Using configured menu with ${this.nativeDefaultMenu.length} menu items.  Menu render triggered by timeout event using the default app menu.`);

    // Restore any previously expanded menu items
    this.setExpandedPrimeMenuIds(this.primeMenu, expanded);

    this.primeMenuSubject.next(this.primeMenu);
    return this.primeMenuFeed();

  }





  public buildDefaultAppMenu(): m.MenuItem[] {
    // Get the default menu to the best we know it based on app config settings
    if (this.appService.config.type === "ngib") {
      return this.buildDefaultIbNgWelcomeAppMenu();
    } else if (this.appService.config.type === "api-docs") {
      return this.buildDefaultApiDocsAppMenu();
    } else if (this.appService.isBrandReportCompiler) {
      return this.buildDefaultReportCompilerAppMenu();
    } else {
      // TODO other brand menus
      // Have one default menu when given brand doesn't have a default menu defined
      return this.buildDefaultStandardAppMenu();
    }
  }


  public buildDefaultReportCompilerAppMenu(): m.MenuItem[] {

    const menu: m.MenuItem[] = [];

    menu.push(this.buildMenuItem("Dashboard", "/dashboard", "tachometer-alt (light)", Constants.ContactType.Directory));
    menu.push(this.buildMenuItem("{{CasePlural}}", "/rc/cases", "folder-open (light)", Constants.ContactType.Directory, Constants.AccessArea.Cases));
    menu.push(this.buildMenuItem("Customers", "/customers", `${IconHelper.iconDescriptionFromContactType(Constants.ContactType.Customer, false, true)}`, Constants.ContactType.Directory, Constants.AccessArea.Customer));

    const library = this.buildMenuItem("Library", "", "book (light)", Constants.ContactType.Directory);
    library.Children.push(this.buildMenuItem("Text", "/rc/library/text", "book (light)", Constants.ContactType.Directory, Constants.AccessArea.LibraryPersonalText));
    library.Children.push(this.buildMenuItem("Documents", "/rc/library/documents", "file-alt (light)", Constants.ContactType.Directory, Constants.AccessArea.LibraryPersonalDocument));
    library.Children.push(this.buildMenuItem("Library Insights", "/rc/library/insights", "chart-line (light)", Constants.ContactType.Directory, Constants.AccessArea.LibraryInsights, "PA"));
    library.Children.slice(-1)[0].Modules.ModuleList.push({ Id: "rc-library-insights", LicensedModule: "rc-library-insights", Required: true });
    library.Children.push(this.buildMenuItem("Report Parser", "/rc/library/report-parser", "file-import (light)", Constants.ContactType.Directory, Constants.AccessArea.ReportParser, "PA"));
    library.Children.slice(-1)[0].Modules.ModuleList.push({ Id: "rc-report-parser", LicensedModule: "rc-report-parser", Required: true });
    menu.push(library);

    // This is sub-menu under settings
    const company = this.buildMenuItem("Company", "", "users-class (light)", Constants.ContactType.Directory);
    company.Children.push(this.buildMenuItem("Management Insights", "/reports", "file-alt (light)", Constants.ContactType.Directory, Constants.AccessArea.Reports, "PA"));
    company.Children.slice(-1)[0].Modules.ModuleList.push({ Id: "rc-management-insights", LicensedModule: "rc-management-insights", Required: true });
    company.Children.push(this.buildMenuItem("Users", "/directory", `${IconHelper.iconDescriptionFromContactType(Constants.ContactType.Directory, false, true)}`, Constants.ContactType.Directory, Constants.AccessArea.Directory));
    company.Children.push(this.buildMenuItem("Groups", "/groups", `${IconHelper.iconDescriptionFromContactType(Constants.ContactType.Group, false, true)}`, Constants.ContactType.Directory, Constants.AccessArea.Group));
    company.Children.slice(-1)[0].Modules.ModuleList.push({ Id: "user-groups", LicensedModule: "user-groups", Required: true });
    company.Children.push(this.buildMenuItem("Locations", "/locations", `${IconHelper.iconDescriptionFromContactType(Constants.ContactType.Location, false, true)}`, Constants.ContactType.Directory, Constants.AccessArea.Location));
    // TMI company.Children.push(this.buildMenuItem("Roles", "/security/roles", "shield (light)", Constants.ContactType.Directory));

    // This is sub-menu under settings
    const templates = this.buildMenuItem("Templates", "", "cogs (light)", Constants.ContactType.Directory);
    templates.Children.push(this.buildMenuItem("Pick Lists", "/rc/config/pick-lists", "list-alt (light)", Constants.ContactType.Directory, Constants.AccessArea.PickList));
    templates.Children.push(this.buildMenuItem("{{Case}} Templates", "/rc/case-templates", "folders (light)", Constants.ContactType.Directory, Constants.AccessArea.CaseTemplate));
    templates.Children.push(this.buildMenuItem("Library Groups", "/rc/config/library-groups", "books (light)", Constants.ContactType.Directory, Constants.AccessArea.LibraryGroupConfiguration));
    templates.Children.push(this.buildMenuItem("Template Types", "/rc/config/template-types", "folder-plus (light)", Constants.ContactType.Directory, Constants.AccessArea.TemplateTypeConfiguration));
    templates.Children.push(this.buildMenuItem("Template Documents", "/rc/config/template-documents", "file-word (light)", Constants.ContactType.Directory, Constants.AccessArea.TemplateDocument));

    // This is sub-menu under settings
    const advanced = this.buildMenuItem("Advanced", "", "cogs (solid)", Constants.ContactType.Directory);
    advanced.Children.push(this.buildMenuItem("Quick Start", "/rc/config/quick-start", "bolt (light)", Constants.ContactType.Directory, Constants.AccessArea.Directory));
    advanced.Children.push(this.buildMenuItem("System Settings", "/rc/config/settings", "cog (light)", Constants.ContactType.Directory, Constants.AccessArea.Setting));
    advanced.Children.push(this.buildMenuItem("Security Policy", "/security/policies", "shield-check (light)", Constants.ContactType.Directory, Constants.AccessArea.SecurityPolicy));
    advanced.Children.push(this.buildMenuItem("Messages", "/notification/standard-messages", "envelope-open-text (light)", Constants.ContactType.Directory, Constants.AccessArea.NotificationMessage));

    const settings = this.buildMenuItem("Settings", "", "cog (light)", Constants.ContactType.Directory);
    settings.Children.push(company);
    settings.Children.push(templates);
    settings.Children.push(advanced);
    menu.push(settings);

    return menu;

  }


  public buildDefaultStandardAppMenu(): m.MenuItem[] {

    const menu: m.MenuItem[] = [];

    menu.push(this.buildMenuItem("Dashboard", "/dashboard", "tachometer-alt (light)", Constants.ContactType.Directory));
    menu.push(this.buildMenuItem("Reports", "/reports", "file-alt (light)", Constants.ContactType.Directory, Constants.AccessArea.Reports, "PA"));
    menu.push(this.buildMenuItem("Articles", "/articles", "list-alt (light)"));
    menu.push(this.buildMenuItem("{{CasePlural}}", "/cases", "folder-open (light)", Constants.ContactType.Directory, Constants.AccessArea.Cases));
    menu.push(this.buildMenuItem("Customers", "/customers", `${IconHelper.iconDescriptionFromContactType(Constants.ContactType.Customer, false, true)}`, Constants.ContactType.Directory, Constants.AccessArea.Customer));
    menu.push(this.buildMenuItem("Sales Opportunities", "/sales/opportunities", "funnel-dollar (duotone)", Constants.ContactType.Directory, Constants.AccessArea.SalesOpportunity));

    const notification = this.buildMenuItem("Notifications", "", "envelope (light)", Constants.ContactType.Directory);
    notification.Children.push(this.buildMenuItem("Send Notifications", "/notification/events/add", "envelope-open (light)", Constants.ContactType.Directory, Constants.AccessArea.NotificationEvent));
    notification.Children.push(this.buildMenuItem("Events", "/notification/events", "mail-bulk (light)", Constants.ContactType.Directory, Constants.AccessArea.NotificationEvent));
    menu.push(notification);

    const inventory = this.buildMenuItem("Inventory", "", "boxes-alt (light)", Constants.ContactType.Directory);
    inventory.Modules.ModuleList.push({ Id: "inventory", LicensedModule: "inventory", Required: true });
    inventory.Children.push(this.buildMenuItem("Inventory", "/inventory", "boxes-alt (light)", Constants.ContactType.Directory, Constants.AccessArea.Inventory));
    inventory.Children.slice(-1)[0].Modules.ModuleList.push({ Id: "inventory", LicensedModule: "inventory", Required: true });
    inventory.Children.push(this.buildMenuItem("Inventory Types", "/inventory/types", "boxes-alt (solid)", Constants.ContactType.Directory, Constants.AccessArea.InventoryType));
    inventory.Children.slice(-1)[0].Modules.ModuleList.push({ Id: "inventory", LicensedModule: "inventory", Required: true });
    inventory.Children.push(this.buildMenuItem("Version Release Management", "/inventory/types/release-management", "parachute-box (light)", Constants.ContactType.Directory, Constants.AccessArea.InventoryTypeVersion));
    inventory.Children.slice(-1)[0].Modules.ModuleList.push({ Id: "inventory", LicensedModule: "inventory", Required: true });
    inventory.Children.push(this.buildMenuItem("Version Planning", "/inventory/types/version-planning", "folder-open (light)", Constants.ContactType.Directory, Constants.AccessArea.InventoryTypeVersion));
    inventory.Children.slice(-1)[0].Modules.ModuleList.push({ Id: "inventory", LicensedModule: "inventory", Required: true });
    inventory.Children.push(this.buildMenuItem("Inventory Quantity Types", "/inventory/quantity-types", "pallet (light)", Constants.ContactType.Directory, Constants.AccessArea.InventoryQuantityType));
    inventory.Children.slice(-1)[0].Modules.ModuleList.push({ Id: "inventory", LicensedModule: "inventory", Required: true });
    inventory.Children.push(this.buildMenuItem("Vendors", "/vendors", `${IconHelper.iconDescriptionFromContactType(Constants.ContactType.Vendor, false, true)}`, Constants.ContactType.Directory, Constants.AccessArea.Vendor));
    inventory.Children.slice(-1)[0].Modules.ModuleList.push({ Id: "inventory", LicensedModule: "inventory", Required: true });
    inventory.Children.push(this.buildMenuItem("Warehouses", "/warehouses", `${IconHelper.iconDescriptionFromContactType(Constants.ContactType.Warehouse, false, true)}`, Constants.ContactType.Directory, Constants.AccessArea.Warehouse));
    inventory.Children.slice(-1)[0].Modules.ModuleList.push({ Id: "inventory", LicensedModule: "inventory", Required: true });
    menu.push(inventory);

    // This is sub-menu under settings
    const company = this.buildMenuItem("Company", "", "users-class (light)", Constants.ContactType.Directory);
    company.Children.push(this.buildMenuItem("Users", "/directory", `${IconHelper.iconDescriptionFromContactType(Constants.ContactType.Directory, false, true)}`, Constants.ContactType.Directory, Constants.AccessArea.Directory));
    company.Children.push(this.buildMenuItem("Groups", "/groups", `${IconHelper.iconDescriptionFromContactType(Constants.ContactType.Group, false, true)}`, Constants.ContactType.Directory, Constants.AccessArea.Group));
    company.Children.push(this.buildMenuItem("Locations", "/locations", `${IconHelper.iconDescriptionFromContactType(Constants.ContactType.Location, false, true)}`, Constants.ContactType.Directory, Constants.AccessArea.Location));
    company.Children.push(this.buildMenuItem("Roles", "/security/roles", "shield (light)", Constants.ContactType.Directory, Constants.AccessArea.Role));
    company.Children.push(this.buildMenuItem("Security Policies", "/security/policies", "shield-check (light)", Constants.ContactType.Directory, Constants.AccessArea.SecurityPolicy));

    // This is sub-menu under settings
    const data = this.buildMenuItem("Data", "", "table (light)", Constants.ContactType.Directory);
    data.Children.push(this.buildMenuItem("Assets", "/asset", "atom-alt (light)", Constants.ContactType.Directory, Constants.AccessArea.Asset));
    data.Children.push(this.buildMenuItem("Filters", "/query/filters", "filter (light)", Constants.ContactType.Directory, Constants.AccessArea.Filter));
    data.Children.push(this.buildMenuItem("Queries", "/query", "database (light)", Constants.ContactType.Directory, Constants.AccessArea.Query));
    data.Children.push(this.buildMenuItem("Data Sources", "/query/data-sources", "database (solid)", Constants.ContactType.Directory, Constants.AccessArea.DataSource));
    data.Children.push(this.buildMenuItem("Attributes", "/attribute/sets", "table (light)", Constants.ContactType.Directory, Constants.AccessArea.AttributeSet));

    // This is a sub-menu that is optionally included based on brand
    const tax = this.buildMenuItem("Tax", "", "percent (light)", Constants.ContactType.Directory);
    tax.Modules.ModuleList.push({ Id: "tax", LicensedModule: "tax", Required: true });
    tax.Children.push(this.buildMenuItem("Configurations", "/tax/configurations", "sliders-h (light)", Constants.ContactType.Directory, Constants.AccessArea.TaxConfiguration));
    tax.Children.slice(-1)[0].Modules.ModuleList.push({ Id: "tax", LicensedModule: "tax", Required: true });
    tax.Children.push(this.buildMenuItem("Geocodes", "/tax/geocodes", "globe (light)", Constants.ContactType.Directory, Constants.AccessArea.TaxGeocode));
    tax.Children.slice(-1)[0].Modules.ModuleList.push({ Id: "tax", LicensedModule: "tax", Required: true });
    tax.Children.push(this.buildMenuItem("Geocode Lookup", "/tax/geocode-lookups", "atlas (light)", Constants.ContactType.Directory, Constants.AccessArea.TaxGeocodeLookup));
    tax.Children.slice(-1)[0].Modules.ModuleList.push({ Id: "tax", LicensedModule: "tax", Required: true });
    tax.Children.push(this.buildMenuItem("Matrix", "/tax/matrix", "percent (light)", Constants.ContactType.Directory, Constants.AccessArea.TaxMatrix));
    tax.Children.slice(-1)[0].Modules.ModuleList.push({ Id: "tax", LicensedModule: "tax", Required: true });

    // This is a sub-menu that is optionally included based on brand
    const pay = this.buildMenuItem("Payment", "", "money-bill (light)", Constants.ContactType.Directory);
    pay.Children.push(this.buildMenuItem("Providers", "/payment/providers", "university (light)", Constants.ContactType.Directory, Constants.AccessArea.PaymentProvider));
    pay.Children.push(this.buildMenuItem("Card Types", "/payment/card-types", "credit-card (light)", Constants.ContactType.Directory, Constants.AccessArea.PaymentMethodCardType));

    // This is sub-menu under settings
    const notifSetup = this.buildMenuItem("Notifications", "", "envelope (light)", Constants.ContactType.Directory);
    notifSetup.Children.push(this.buildMenuItem("Events", "/notification/events", "mail-bulk (light)", Constants.ContactType.Directory, Constants.AccessArea.NotificationEvent));
    notifSetup.Children.push(this.buildMenuItem("Groups", "/notification/groups", "book-user (light)", Constants.ContactType.Directory, Constants.AccessArea.NotificationGroup));
    notifSetup.Children.push(this.buildMenuItem("Messages (Standard)", "/notification/standard-messages", "envelope-open-text (light)", Constants.ContactType.Directory, Constants.AccessArea.NotificationMessage));
    notifSetup.Children.push(this.buildMenuItem("Messages (Advanced)", "/notification/messages", "envelope-open-text (solid)", Constants.ContactType.Directory, Constants.AccessArea.NotificationMessage));
    notifSetup.Children.push(this.buildMenuItem("Contacts", "/notification/contacts", "file-user (light)", Constants.ContactType.Directory, Constants.AccessArea.NotificationContact));

    // This is sub-menu under settings
    const advanced = this.buildMenuItem("Advanced", "", "cogs (light)", Constants.ContactType.Directory);
    advanced.Children.push(this.buildMenuItem("System Settings", "/settings/app", "cog (light)", Constants.ContactType.Directory, Constants.AccessArea.Setting));
    advanced.Children.push(this.buildMenuItem("{{Case}} Templates", "/case-templates", "folders (light)", Constants.ContactType.Directory, Constants.AccessArea.CaseTemplate));
    advanced.Children.push(this.buildMenuItem("Process Templates", "/system/process-templates", "folders (solid)", Constants.ContactType.Directory, Constants.AccessArea.ProcessTemplate));
    advanced.Children.push(this.buildMenuItem("Processes", "/system/processes", "folders (solid)", Constants.ContactType.Directory, Constants.AccessArea.Process));
    advanced.Children.push(this.buildMenuItem("Time Zones", "/settings/time-zones", "clock (light)", Constants.ContactType.Directory, Constants.AccessArea.TimeZone));
    advanced.Children.push(this.buildMenuItem("Currency", "/settings/currency", "usd-square (light)", Constants.ContactType.Directory, Constants.AccessArea.Currency));
    advanced.Children.push(this.buildMenuItem("Translations", "/translations/raw", "language (light)", Constants.ContactType.Directory, Constants.AccessArea.Translation));
    advanced.Children.push(this.buildMenuItem("Forms", "/forms", "line-columns (light)", Constants.ContactType.Directory, Constants.AccessArea.Form));
    advanced.Children.push(this.buildMenuItem("Favorites", "/web/favorites", "thumbs-up (light)", Constants.ContactType.Directory, Constants.AccessArea.Favorite));
    advanced.Children.push(this.buildMenuItem("Webhooks", "/web/webhooks", "share-square (light)", Constants.ContactType.Directory, Constants.AccessArea.Webhook));
    // Nubill and IntelliBOSS get tax menu
    if (this.appService.isBrandNubill || this.appService.isBrandIntelliBOSS) {
      advanced.Children.push(pay);
      advanced.Children.push(tax);
      advanced.Children.push(this.buildMenuItem("Numbering Plans", "/telecom/numbering-plans", "phone (light)", Constants.ContactType.Directory, Constants.AccessArea.TelecomNumberingPlan));
      advanced.Children.slice(-1)[0].Modules.ModuleList.push({ Id: "telecom", LicensedModule: "telecom", Required: true });
    }

    const settings = this.buildMenuItem("Settings", "", "cog (light)", Constants.ContactType.Directory);
    settings.Children.push(this.buildMenuItem("Pick Lists", "/pick-lists", "list-alt (light)", Constants.ContactType.Directory, Constants.AccessArea.PickList));
    settings.Children.push(company);
    settings.Children.push(data);
    settings.Children.push(notifSetup);
    settings.Children.push(advanced);
    menu.push(settings);

    return menu;

  }


  public buildDefaultIbNgWelcomeAppMenu(): m.MenuItem[] {

    const menu: m.MenuItem[] = [];

    menu.push(this.buildMenuItem("Home", "/", "home", Constants.ContactType.Directory));

    const gallery = this.buildMenuItem("Gallery", "/gallery", "cogs", Constants.ContactType.Directory);
    gallery.Children.push(this.buildMenuItem("Input (Standard)", "/gallery/input", "keyboard", Constants.ContactType.Directory));
    gallery.Children.push(this.buildMenuItem("Input (Advanced)", "/gallery/input-advanced", "keyboard (solid)", Constants.ContactType.Directory));
    gallery.Children.push(this.buildMenuItem("Image", "/gallery/image", "image", Constants.ContactType.Directory));
    gallery.Children.push(this.buildMenuItem("UX", "/gallery/ux", "browser", Constants.ContactType.Directory));
    gallery.Children.push(this.buildMenuItem("Data", "/gallery/data", "database", Constants.ContactType.Directory));
    gallery.Children.push(this.buildMenuItem("Route", "/gallery/data/sample/tax-geocode", "table", Constants.ContactType.Directory));
    gallery.Children.push(this.buildMenuItem("File", "/gallery/file", "file", Constants.ContactType.Directory));
    menu.push(gallery);

    const test = this.buildMenuItem("Test", "/test", "vial", Constants.ContactType.Directory);
    test.Children.push(this.buildMenuItem("API Service", "/test/api-service", "exchange", Constants.ContactType.Directory));
    menu.push(test);

    return menu;

  }


  public buildDefaultApiDocsAppMenu(): m.MenuItem[] {

    if (this.appService.config.brand === "reportcompiler") {
      return this.getMenuApiMenuBrandReportCompiler();
    } else if (this.appService.config.brand === "wallet") {
      return this.getMenuApiMenuBrandWallet();
    } else {
      return this.getMenuApiMenuBrandOther();
    }

  }





  public getMenuApiMenuBrandOther(): m.MenuItem[] {

    let menu: m.MenuItem[] = [];


    menu = this.addSectionIfChildrenExist(menu, this.getMenuApiMenuOverviewSection());


    menu = this.addSectionIfChildrenExist(menu, this.getMenuApiMenuSecuritySection());


    const users = this.buildMenuItem("Directory", "", `${IconHelper.iconDescriptionFromContactType(Constants.ContactType.Directory, false, true)}`);
    users.Children = this.getMenuApiMenuItems(Api.Directory());
    users.Children = users.Children.concat(this.getMenuApiMenuItems(Api.Group()));
    menu = this.addSectionIfChildrenExist(menu, users);

    const customers = this.buildMenuItem("Customers", "", `${IconHelper.iconDescriptionFromContactType(Constants.ContactType.Customer, false, true)}`);
    customers.Children = this.getMenuApiMenuItems(Api.Customer());
    customers.Children = customers.Children.concat(this.getMenuApiMenuItems(ApiModulePayment.CustomerPaymentMethodCreditCard()));
    menu = this.addSectionIfChildrenExist(menu, customers);

    const contacts = this.buildMenuItem("Contact", "", `${IconHelper.iconDescriptionFromContactType(Constants.ContactType.Contact, false, true)}`);
    contacts.Children = this.getMenuApiMenuItems(Api.ContactChildContact());
    contacts.Children = contacts.Children.concat(this.getMenuApiMenuItems(Api.ContactPreferences()));
    contacts.Children = contacts.Children.concat(this.getMenuApiMenuItems(Api.ContactInventory()));
    contacts.Children = contacts.Children.concat(this.getMenuApiMenuItems(ApiModulePayment.PaymentMethod()));
    contacts.Children = contacts.Children.concat(this.getMenuApiMenuItems(Api.ContactRole()));
    contacts.Children = contacts.Children.concat(this.getMenuApiMenuItems(Api.ContactWorkSchedule()));
    contacts.Children = contacts.Children.concat(this.getMenuApiMenuItems(Api.ContactWorkScheduleException()));
    contacts.Children = contacts.Children.concat(this.getMenuApiMenuItems(Api.ContactExternalAuthentication()));
    contacts.Children = contacts.Children.concat(this.getMenuApiMenuItems(Api.ContactAssetAccessLog()));
    menu = this.addSectionIfChildrenExist(menu, contacts);


    menu = this.addSectionIfChildrenExist(menu, this.getMenuApiMenuCrmSection());


    menu = this.addSectionIfChildrenExist(menu, this.getMenuApiMenuInventorySection());


    // Case with sub-menus
    let cases = this.buildMenuItem("Case", "", "folder-open (light)");

    const casesProper = this.buildMenuItem("Case", "", "folder-open (light)");
    casesProper.Children = this.getMenuApiMenuItems(Api.Case());
    cases = this.addSectionIfChildrenExist(cases, casesProper);

    const casesTemplate = this.buildMenuItem("Template", "", "folders (light)");
    casesTemplate.Children = this.getMenuApiMenuItems(Api.CaseTemplate());
    cases = this.addSectionIfChildrenExist(cases, casesTemplate);

    const tasks = this.buildMenuItem("Task", "", "check-square (light)");
    tasks.Children = this.getMenuApiMenuItems(Api.TaskList());
    tasks.Children = tasks.Children.concat(this.getMenuApiMenuItems(Api.Task()));
    tasks.Children = tasks.Children.concat(this.getMenuApiMenuItems(Api.TaskSubtask()));
    cases = this.addSectionIfChildrenExist(cases, tasks);

    const taskTemplates = this.buildMenuItem("Task Template", "", "check-square (solid)");
    taskTemplates.Children = this.getMenuApiMenuItems(Api.TaskListTemplate());
    taskTemplates.Children = taskTemplates.Children.concat(this.getMenuApiMenuItems(Api.TaskTemplate()));
    taskTemplates.Children = taskTemplates.Children.concat(this.getMenuApiMenuItems(Api.TaskSubtaskTemplate()));
    cases = this.addSectionIfChildrenExist(cases, taskTemplates);

    menu = this.addSectionIfChildrenExist(menu, cases);


    if (this.appService.config.brand === "all" || this.appService.config.brand === "reportcompiler") {
      // RC File with sub-menus
      menu = this.addSectionIfChildrenExist(menu, this.getMenuApiMenuReportCompilerCaseSection());
      // RC Library with sub-menus
      menu = this.addSectionIfChildrenExist(menu, this.getMenuApiMenuReportCompilerLibrarySection());
    }


    menu = this.addSectionIfChildrenExist(menu, this.getMenuApiMenuNotificationSection());


    // billing with sub-menus
    let billing = this.buildMenuItem("Billing", "", "file-invoice (light)");

    const billingProfile = this.buildMenuItem("Billing Profile", "", "file-invoice (duotone)");
    billingProfile.Children = this.getMenuApiMenuItems(Api.BillingProfile());
    billingProfile.Children = billingProfile.Children.concat(this.getMenuApiMenuItems(Api.BillingReportProfile()));
    billingProfile.Children = billingProfile.Children.concat(this.getMenuApiMenuItems(Api.BillingReport()));
    billing = this.addSectionIfChildrenExist(billing, billingProfile);

    const billingAccount = this.buildMenuItem("Billing Account", "", "file-invoice (light)");
    billingAccount.Children = this.getMenuApiMenuItems(Api.BillingAccount());
    billing = this.addSectionIfChildrenExist(billing, billingAccount);

    const billingTransaction = this.buildMenuItem("Billing Transaction", "", "file-invoice-dollar (light)");
    billingTransaction.Children = this.getMenuApiMenuItems(Api.BillingTransaction());
    billingTransaction.Children = billingTransaction.Children.concat(this.getMenuApiMenuItems(Api.BillingTransactionDetail()));
    billing = this.addSectionIfChildrenExist(billing, billingTransaction);

    const billingEvent = this.buildMenuItem("Billing Event", "", "calendar-alt (light)");
    billingEvent.Children = this.getMenuApiMenuItems(Api.BillingEvent());
    billingEvent.Children = billingEvent.Children.concat(this.getMenuApiMenuItems(Api.BillingEventBulkAdd()));
    billing = this.addSectionIfChildrenExist(billing, billingEvent);

    const pkg = this.buildMenuItem("Package", "", "box-check (light)");
    pkg.Children = this.getMenuApiMenuItems(Api.Package());
    pkg.Children = pkg.Children.concat(this.getMenuApiMenuItems(Api.PackageItem()));
    pkg.Children = pkg.Children.concat(this.getMenuApiMenuItems(Api.PackageRateAdjustment()));
    pkg.Children = pkg.Children.concat(this.getMenuApiMenuItems(Api.PackageAssociation()));
    pkg.Children = pkg.Children.concat(this.getMenuApiMenuItems(Api.PackageInventory()));
    billing = this.addSectionIfChildrenExist(billing, pkg);

    const item = this.buildMenuItem("Item", "", "box (light)");
    item.Children = this.getMenuApiMenuItems(Api.Item());
    item.Children = item.Children.concat(this.getMenuApiMenuItems(Api.ItemRate()));
    item.Children = item.Children.concat(this.getMenuApiMenuItems(Api.ItemList()));
    billing = this.addSectionIfChildrenExist(billing, item);

    const po = this.buildMenuItem("Package Occurrence", "", "box-full (light)");
    po.Children = this.getMenuApiMenuItems(Api.PackageOccurrence());
    po.Children = po.Children.concat(this.getMenuApiMenuItems(Api.PackageOccurrenceItem()));
    po.Children = po.Children.concat(this.getMenuApiMenuItems(Api.PackageOccurrenceRateAdjustment()));
    billing = this.addSectionIfChildrenExist(billing, po);

    const billingPurchase = this.buildMenuItem("Purchase", "", "wallet (light)");
    billingPurchase.Children = this.getMenuApiMenuItems(Api.Purchase());
    billingPurchase.Children = billingPurchase.Children.concat(this.getMenuApiMenuItems(Api.PurchaseStatus()));
    billing = this.addSectionIfChildrenExist(billing, billingPurchase);

    menu = this.addSectionIfChildrenExist(menu, billing);




    // tax with sub-menus
    let tax = this.buildMenuItem("Tax", "", "percent (light)");

    const config = this.buildMenuItem("Configuration", "", "sliders-h (light)");
    config.Children = this.getMenuApiMenuItems(Api.TaxConfiguration());
    tax = this.addSectionIfChildrenExist(tax, config);

    const geocode = this.buildMenuItem("Geocode", "", "globe (light)");
    geocode.Children = this.getMenuApiMenuItems(Api.TaxGeocode());
    tax = this.addSectionIfChildrenExist(tax, geocode);

    const geocodeLookup = this.buildMenuItem("Geocode Lookup", "", "atlas (light)");
    geocodeLookup.Children = this.getMenuApiMenuItems(Api.TaxGeocodeLookup());
    tax = this.addSectionIfChildrenExist(tax, geocodeLookup);

    const jurisdiction = this.buildMenuItem("Jurisdiction", "", "globe (light)");
    jurisdiction.Children = this.getMenuApiMenuItems(Api.TaxJurisdiction());
    tax = this.addSectionIfChildrenExist(tax, jurisdiction);

    const matrix = this.buildMenuItem("Matrix", "", "percent (light)");
    matrix.Children = this.getMenuApiMenuItems(Api.TaxMatrix());
    tax = this.addSectionIfChildrenExist(tax, matrix);

    const detail = this.buildMenuItem("Transaction Detail", "", "money-bill (light)");
    detail.Children = this.getMenuApiMenuItems(Api.TaxTransactionDetail());
    tax = this.addSectionIfChildrenExist(tax, detail);

    const actions = this.buildMenuItem("Actions", "", "badge-percent (light)");
    actions.Children = this.getMenuApiMenuItems(Api.TaxCalculate());
    tax = this.addSectionIfChildrenExist(tax, actions);

    menu = this.addSectionIfChildrenExist(menu, tax);




    // voucher with sub-menus
    let voucherMenu = this.buildMenuItem("Voucher", "", "ticket (light)");

    const voucherBatch = this.buildMenuItem("Batch", "", "ticket (solid)");
    voucherBatch.Children = this.getMenuApiMenuItems(Api.VoucherBatch());
    voucherBatch.Children = voucherBatch.Children.concat(this.getMenuApiMenuItems(Api.VoucherBatchRefreshStatsAll()));
    voucherBatch.Children = voucherBatch.Children.concat(this.getMenuApiMenuItems(Api.VoucherBatchRefreshStats()));
    voucherBatch.Children = voucherBatch.Children.concat(this.getMenuApiMenuItems(Api.VoucherBatchSuspend()));
    voucherBatch.Children = voucherBatch.Children.concat(this.getMenuApiMenuItems(Api.VoucherBatchAvailableVoucherCount()));
    voucherBatch.Children = voucherBatch.Children.concat(this.getMenuApiMenuItems(Api.VoucherBatchNextSerialNumber()));
    voucherBatch.Children = voucherBatch.Children.concat(this.getMenuApiMenuItems(Api.VoucherBatchVisibility()));
    voucherBatch.Children = voucherBatch.Children.concat(this.getMenuApiMenuItems(Api.VoucherBatchRestrictionRule()));
    voucherMenu = this.addSectionIfChildrenExist(voucherMenu, voucherBatch);

    const voucherLot = this.buildMenuItem("Lot", "", "ticket");
    voucherLot.Children = this.getMenuApiMenuItems(Api.VoucherLot());
    voucherLot.Children = voucherLot.Children.concat(this.getMenuApiMenuItems(Api.VoucherLotActivate()));
    voucherLot.Children = voucherLot.Children.concat(this.getMenuApiMenuItems(Api.VoucherLotSuspend()));
    voucherMenu = this.addSectionIfChildrenExist(voucherMenu, voucherLot);

    const voucher = this.buildMenuItem("Voucher", "", "ticket (duotone)");
    voucher.Children = this.getMenuApiMenuItems(Api.Voucher());
    voucher.Children = voucher.Children.concat(this.getMenuApiMenuItems(Api.VoucherAddFromList()));
    voucher.Children = voucher.Children.concat(this.getMenuApiMenuItems(Api.VoucherReserve()));
    voucher.Children = voucher.Children.concat(this.getMenuApiMenuItems(Api.VoucherSuspend()));
    voucher.Children = voucher.Children.concat(this.getMenuApiMenuItems(Api.VoucherReset()));
    voucher.Children = voucher.Children.concat(this.getMenuApiMenuItems(Api.VoucherFind()));
    voucher.Children = voucher.Children.concat(this.getMenuApiMenuItems(Api.VoucherCheck()));
    voucherMenu = this.addSectionIfChildrenExist(voucherMenu, voucher);

    menu = this.addSectionIfChildrenExist(menu, voucherMenu);


    // Payment with sub-menus
    let payment = this.buildMenuItem("Payment", "", "credit-card (light)");

    const paymentProvider = this.buildMenuItem("Payment Provider", "", "university (light)");
    paymentProvider.Children = this.getMenuApiMenuItems(ApiModulePayment.PaymentProvider());
    paymentProvider.Children = paymentProvider.Children.concat(this.getMenuApiMenuItems(ApiModulePayment.PaymentProviderSupportedCardType()));
    paymentProvider.Children = paymentProvider.Children.concat(this.getMenuApiMenuItems(ApiModulePayment.PaymentProviderSelectionRule()));
    paymentProvider.Children = paymentProvider.Children.concat(this.getMenuApiMenuItems(ApiModulePayment.PaymentProviderTrigger()));
    payment = this.addSectionIfChildrenExist(payment, paymentProvider);

    const paymentMethodCardType = this.buildMenuItem("Payment Method Card Type", "", "credit-card-front (light)");
    paymentMethodCardType.Children = this.getMenuApiMenuItems(ApiModulePayment.PaymentMethodCardType());
    payment = this.addSectionIfChildrenExist(payment, paymentMethodCardType);

    const paymentTransaction = this.buildMenuItem("Payment Transaction", "", "credit-card (light)");
    paymentTransaction.Children = this.getMenuApiMenuItems(ApiModulePayment.PaymentTransaction());
    paymentTransaction.Children = paymentTransaction.Children.concat(this.getMenuApiMenuItems(ApiModulePayment.PaymentTransactionGetByIdType()));
    paymentTransaction.Children = paymentTransaction.Children.concat(this.getMenuApiMenuItems(ApiModulePayment.PaymentTransactionAdd()));
    paymentTransaction.Children = paymentTransaction.Children.concat(this.getMenuApiMenuItems(ApiModulePayment.PaymentTransactionSale()));
    paymentTransaction.Children = paymentTransaction.Children.concat(this.getMenuApiMenuItems(ApiModulePayment.PaymentTransactionRefund()));
    paymentTransaction.Children = paymentTransaction.Children.concat(this.getMenuApiMenuItems(ApiModulePayment.PaymentTransactionDelete()));
    paymentTransaction.Children = paymentTransaction.Children.concat(this.getMenuApiMenuItems(ApiModulePayment.PaymentTransactionReceipt()));
    payment = this.addSectionIfChildrenExist(payment, paymentTransaction);

    const paymentNotification = this.buildMenuItem("Payment Notification", "", "comments-dollar (light)");
    paymentNotification.Children = this.getMenuApiMenuItems(ApiModulePayment.PaymentNotification());
    paymentNotification.Children = paymentNotification.Children.concat(this.getMenuApiMenuItems(ApiModulePayment.PaymentNotificationPayPalIPN()));
    paymentNotification.Children = paymentNotification.Children.concat(this.getMenuApiMenuItems(ApiModulePayment.PaymentNotificationAdyenNotificationWebhookTarget()));
    payment = this.addSectionIfChildrenExist(payment, paymentNotification);

    const braintree = this.buildMenuItem("Braintree", "", "cc-paypal (brand)");
    braintree.Children = this.getMenuApiMenuItems(ApiModulePayment.BraintreeClientToken());
    payment = this.addSectionIfChildrenExist(payment, braintree);

    const paypal = this.buildMenuItem("PayPal", "", "paypal (brand)");
    paypal.Children = this.getMenuApiMenuItems(ApiModulePayment.PayPalAuthorization());
    paypal.Children = paypal.Children.concat(this.getMenuApiMenuItems(ApiModulePayment.PayPalAuthorizationLegacyRoute()));
    paypal.Children = paypal.Children.concat(this.getMenuApiMenuItems(ApiModulePayment.PayPalIPNLegacyRoute()));
    payment = this.addSectionIfChildrenExist(payment, paypal);

    menu = this.addSectionIfChildrenExist(menu, payment);



    // Association with sub-menus
    let associationMenu = this.buildMenuItem("Association", "", "handshake (light)");

    const association = this.buildMenuItem("Association", "", "handshake (light)");
    association.Children = this.getMenuApiMenuItems(Api.Association());
    associationMenu = this.addSectionIfChildrenExist(associationMenu, association);

    const authorizationCode = this.buildMenuItem("Authorization Code", "", "handshake (light)");
    authorizationCode.Children = this.getMenuApiMenuItems(Api.AuthorizationCode());
    authorizationCode.Children = authorizationCode.Children.concat(this.getMenuApiMenuItems(Api.AuthorizationCodeBulkAdd()));
    authorizationCode.Children = authorizationCode.Children.concat(this.getMenuApiMenuItems(Api.AuthorizationCodeGroup()));
    authorizationCode.Children = authorizationCode.Children.concat(this.getMenuApiMenuItems(Api.AuthorizationCodeCheck()));
    associationMenu = this.addSectionIfChildrenExist(associationMenu, authorizationCode);

    menu = this.addSectionIfChildrenExist(menu, associationMenu);



    // Telecom with sub-menus
    let telecom = this.buildMenuItem("Telecom", "", "phone (light)");
    const numberingPlan = this.buildMenuItem("Numbering Plan", "", "globe (light)");
    numberingPlan.Children = this.getMenuApiMenuItems(ApiModuleTelecom.NumberingPlan());
    telecom = this.addSectionIfChildrenExist(telecom, numberingPlan);
    const locationProfile = this.buildMenuItem("Location Profile", "", "globe-stand (light)");
    locationProfile.Children = this.getMenuApiMenuItems(ApiModuleTelecom.LocationProfile());
    telecom = this.addSectionIfChildrenExist(telecom, locationProfile);
    const location = this.buildMenuItem("Location", "", "atlas (light)");
    location.Children = this.getMenuApiMenuItems(ApiModuleTelecom.LocationStandard());
    location.Children = location.Children.concat(this.getMenuApiMenuItems(ApiModuleTelecom.LocationCustom()));
    telecom = this.addSectionIfChildrenExist(telecom, location);
    const locationGroup = this.buildMenuItem("Location Group", "", "globe-americas (light)");
    locationGroup.Children = this.getMenuApiMenuItems(ApiModuleTelecom.LocationGroupList());
    locationGroup.Children = locationGroup.Children.concat(this.getMenuApiMenuItems(ApiModuleTelecom.LocationGroupLink()));
    telecom = this.addSectionIfChildrenExist(telecom, locationGroup);
    menu = this.addSectionIfChildrenExist(menu, telecom);


    // Usage with sub-menus
    let usage = this.buildMenuItem("Usage", "", "stream (light)");
    const serviceIdentification = this.buildMenuItem("Service Identification", "", "stream (light)");
    serviceIdentification.Children = this.getMenuApiMenuItems(ApiModuleUsage.ServiceIdentification());
    usage = this.addSectionIfChildrenExist(usage, serviceIdentification);
    const usageDataSource = this.buildMenuItem("Data Sources", "", "server (light)");
    usageDataSource.Children = this.getMenuApiMenuItems(ApiModuleUsage.UsageDataSource());
    usage = this.addSectionIfChildrenExist(usage, usageDataSource);
    const usageImportLog = this.buildMenuItem("Import Log", "", "stream (light)");
    usageImportLog.Children = this.getMenuApiMenuItems(ApiModuleUsage.UsageImportLog());
    usage = this.addSectionIfChildrenExist(usage, usageImportLog);
    const usageDataFeed = this.buildMenuItem("Data Feed", "", "stream (light)");
    usageDataFeed.Children = this.getMenuApiMenuItems(ApiModuleUsage.UsageDataFeed());
    usageDataFeed.Children = usageDataFeed.Children.concat(this.getMenuApiMenuItems(ApiModuleUsage.UsageDataFeedBulkAdd()));
    usage = this.addSectionIfChildrenExist(usage, usageDataFeed);
    const costCenterRating = this.buildMenuItem("Cost Center Rating", "", "coins (light)");
    costCenterRating.Children = this.getMenuApiMenuItems(ApiModuleUsage.UsageCostCenterRating());
    usage = this.addSectionIfChildrenExist(usage, costCenterRating);
    const network = this.buildMenuItem("Network", "", "network-wired (light)");
    network.Children = this.getMenuApiMenuItems(ApiModuleUsage.NetworkElement());
    network.Children = network.Children.concat(this.getMenuApiMenuItems(ApiModuleUsage.NetworkPortGroup()));
    network.Children = network.Children.concat(this.getMenuApiMenuItems(ApiModuleUsage.NetworkPort()));
    usage = this.addSectionIfChildrenExist(usage, network);
    const activity = this.buildMenuItem("Usage", "", "table (light)");
    activity.Children = this.getMenuApiMenuItems(Api.Activity());
    usage = this.addSectionIfChildrenExist(usage, activity);
    menu = this.addSectionIfChildrenExist(menu, usage);


    // Rating with sub-menus
    let rating = this.buildMenuItem("Rating", "", "coin (light)");
    const ratingProfile = this.buildMenuItem("Rating Profile", "", "coin (light)");
    ratingProfile.Children = this.getMenuApiMenuItems(ApiModuleUsage.RatingProfile());
    ratingProfile.Children = ratingProfile.Children.concat(this.getMenuApiMenuItems(ApiModuleUsage.RatingGroup()));
    ratingProfile.Children = ratingProfile.Children.concat(this.getMenuApiMenuItems(ApiModuleUsage.RatingGroupAutoAdd()));
    ratingProfile.Children = ratingProfile.Children.concat(this.getMenuApiMenuItems(ApiModuleUsage.RatingGroupPurge()));
    ratingProfile.Children = ratingProfile.Children.concat(this.getMenuApiMenuItems(ApiModuleUsage.RatingStep()));
    ratingProfile.Children = ratingProfile.Children.concat(this.getMenuApiMenuItems(ApiModuleUsage.RatingStepFlatten()));
    ratingProfile.Children = ratingProfile.Children.concat(this.getMenuApiMenuItems(ApiModuleUsage.RatingRate()));
    ratingProfile.Children = ratingProfile.Children.concat(this.getMenuApiMenuItems(ApiModuleUsage.RatingRatePurge()));
    rating = this.addSectionIfChildrenExist(rating, ratingProfile);
    const ratingSurcharge = this.buildMenuItem("Rating Surcharge/Discount", "", "badge-percent (light)");
    ratingSurcharge.Children = this.getMenuApiMenuItems(ApiModuleUsage.RatingSurchargeDiscount());
    ratingSurcharge.Children = ratingSurcharge.Children.concat(this.getMenuApiMenuItems(ApiModuleUsage.RatingSurchargeDiscountPurge()));
    rating = this.addSectionIfChildrenExist(rating, ratingSurcharge);
    const ratingZone = this.buildMenuItem("Rating Zone", "", "globe-asia (light)");
    ratingZone.Children = this.getMenuApiMenuItems(ApiModuleUsage.RatingZone());
    ratingZone.Children = ratingZone.Children.concat(this.getMenuApiMenuItems(ApiModuleUsage.RatingZoneSetup()));
    ratingZone.Children = ratingZone.Children.concat(this.getMenuApiMenuItems(ApiModuleUsage.RatingZonePurge()));
    rating = this.addSectionIfChildrenExist(rating, ratingZone);
    const ratingHoliday = this.buildMenuItem("Rating Holiday", "", "calendar-alt (light)");
    ratingHoliday.Children = this.getMenuApiMenuItems(ApiModuleUsage.RatingHoliday());
    ratingHoliday.Children = ratingHoliday.Children.concat(this.getMenuApiMenuItems(ApiModuleUsage.RatingHolidayPurge()));
    rating = this.addSectionIfChildrenExist(rating, ratingHoliday);
    menu = this.addSectionIfChildrenExist(menu, rating);



    menu = this.addSectionIfChildrenExist(menu, this.getMenuApiMenuDataSection());


    menu = this.addSectionIfChildrenExist(menu, this.getMenuApiMenuWebSection());


    menu = this.addSectionIfChildrenExist(menu, this.getMenuApiMenuSystemSection());


    return menu;

  }

  public getMenuApiMenuBrandWallet(): m.MenuItem[] {

    let menu: m.MenuItem[] = [];


    menu = this.addSectionIfChildrenExist(menu, this.getMenuApiMenuOverviewSection());

    let security = this.buildMenuItem("Security", "", "lock (light)");
    // Hide login, change long, change pw as not yet needed
    //security.Children = this.getMenuApiMenuItems(Api.WalletSecurityLogin());
    security.Children = security.Children.concat(this.getMenuApiMenuItems(Api.WalletSecurityAuthenticate()));
    //security.Children = security.Children.concat(this.getMenuApiMenuItems(Api.WalletSecurityChangeLogin()));
    //security.Children = security.Children.concat(this.getMenuApiMenuItems(Api.WalletSecurityChangePassword()));

    // Hide role and api access as not yet needed since we can add them manually for the few users involved

    //let role = { text: "Role", url: "javascript:void(0)", icon: "cube", showWhen: "true", visibility: "", collapsed: true, children: [], activeMenu: [] };
    //role.Children = this.getMenuApiMenuItems(Api.WalletRole()).concat(this.getMenuApiMenuItems(Api.WalletRoleDetail()));
    //security = this.addSectionIfChildrenExist(security, role);

    //let apiAccess = { text: "API Access", url: "javascript:void(0)", icon: "cube", showWhen: "true", visibility: "", collapsed: true, children: [], activeMenu: [] };
    //apiAccess.Children = this.getMenuApiMenuItems(Api.WalletApiAccess());
    //apiAccess.Children = apiAccess.Children.concat(this.getMenuApiMenuItems(Api.WalletApiAccessClient()));
    //security = this.addSectionIfChildrenExist(security, apiAccess);

    const encryption = this.buildMenuItem("Encryption", "", "cube (light)");
    encryption.Children = this.getMenuApiMenuItems(Api.WalletEncryptionKeyGet());
    encryption.Children = encryption.Children.concat(this.getMenuApiMenuItems(Api.WalletEncryptionEcho()));
    security = this.addSectionIfChildrenExist(security, encryption);

    menu = this.addSectionIfChildrenExist(menu, security);


    //let contacts = { text: "Contacts", url: "javascript:void(0)", icon: "group", showWhen: "true", visibility: "", collapsed: true, children: [], activeMenu: [] };
    //contacts.Children = this.getMenuApiMenuItems(Api.WalletContact());
    //// Hide ext auth and access as not yet needed
    ////let contactExtAuth = { text: "External Authentication", url: "javascript:void(0)", icon: "cube", showWhen: "true", visibility: "", collapsed: true, children: [], activeMenu: [] };
    ////contactExtAuth.Children = this.getMenuApiMenuItems(Api.WalletContactExternalAuthentication());
    ////contacts = this.addSectionIfChildrenExist(contacts, contactExtAuth);
    ////let contactAccess = { text: "Access", url: "javascript:void(0)", icon: "cube", showWhen: "true", visibility: "", collapsed: true, children: [], activeMenu: [] };
    ////contactAccess.Children = this.getMenuApiMenuItems(Api.WalletContactAccess());
    ////contacts = this.addSectionIfChildrenExist(contacts, contactAccess);
    //let contactRole = { text: "Roles", url: "javascript:void(0)", icon: "cube", showWhen: "true", visibility: "", collapsed: true, children: [], activeMenu: [] };
    //contactRole.Children = this.getMenuApiMenuItems(Api.WalletContactRole());
    //contacts = this.addSectionIfChildrenExist(contacts, contactRole);
    //menu = this.addSectionIfChildrenExist(menu, contacts);

    let payment = this.buildMenuItem("Payments", "", "credit-card (light)");
    const provider = this.buildMenuItem("Provider", "", "cube (light)");
    provider.Children = this.getMenuApiMenuItems(Api.WalletPaymentProvider());
    // Hide trigger as not yet needed
    //provider.Children = provider.Children.concat(this.getMenuApiMenuItems(Api.WalletPaymentProviderTrigger()));
    payment = this.addSectionIfChildrenExist(payment, provider);
    const method = this.buildMenuItem("Method", "", "cube (light)");
    method.Children = this.getMenuApiMenuItems(Api.WalletPaymentMethod());
    payment = this.addSectionIfChildrenExist(payment, method);
    const transaction = this.buildMenuItem("Transaction", "", "cube (light)");
    transaction.Children = this.getMenuApiMenuItems(Api.WalletPaymentTransactionSale());
    transaction.Children = transaction.Children.concat(this.getMenuApiMenuItems(Api.WalletPaymentTransactionRefund()));
    transaction.Children = transaction.Children.concat(this.getMenuApiMenuItems(Api.WalletPaymentTransaction()));
    transaction.Children = transaction.Children.concat(this.getMenuApiMenuItems(Api.WalletPaymentTransactionTrace()));
    transaction.Children = transaction.Children.concat(this.getMenuApiMenuItems(Api.WalletPaymentNotification()));
    transaction.Children = transaction.Children.concat(this.getMenuApiMenuItems(Api.WalletPaymentNotificationAdyenNotificationWebhookTarget()));
    payment = this.addSectionIfChildrenExist(payment, transaction);
    menu = this.addSectionIfChildrenExist(menu, payment);


    const proxy = this.buildMenuItem("Proxy", "", "globe (light)");
    proxy.Children = this.getMenuApiMenuItems(Api.WalletProxyCreditCardAdd());
    proxy.Children = proxy.Children.concat(this.getMenuApiMenuItems(Api.WalletProxyPurchase()));
    menu = this.addSectionIfChildrenExist(menu, proxy);


    return menu;

  }

  public getMenuApiMenuBrandReportCompiler(): m.MenuItem[] {

    let menu: m.MenuItem[] = [];

    menu = this.addSectionIfChildrenExist(menu, this.getMenuApiMenuOverviewSection());

    menu = this.addSectionIfChildrenExist(menu, this.getMenuApiMenuSecuritySection());

    const users = this.buildMenuItem("Directory", "", IconHelper.iconDescriptionFromContactType(Constants.ContactType.Directory, false, true));
    users.Children = this.getMenuApiMenuItems(Api.Directory());
    menu = this.addSectionIfChildrenExist(menu, users);

    const customers = this.buildMenuItem("Customers", "", IconHelper.iconDescriptionFromContactType(Constants.ContactType.Customer, false, true));
    customers.Children = this.getMenuApiMenuItems(Api.Customer());
    menu = this.addSectionIfChildrenExist(menu, customers);

    const contacts = this.buildMenuItem("Contacts", "", IconHelper.iconDescriptionFromContactType(Constants.ContactType.Contact, false, true));
    contacts.Children = this.getMenuApiMenuItems(Api.ContactChildContact());
    contacts.Children = contacts.Children.concat(this.getMenuApiMenuItems(Api.ContactRole()));
    contacts.Children = contacts.Children.concat(this.getMenuApiMenuItems(Api.ContactWorkSchedule()));
    contacts.Children = contacts.Children.concat(this.getMenuApiMenuItems(Api.ContactWorkScheduleException()));
    contacts.Children = contacts.Children.concat(this.getMenuApiMenuItems(Api.ContactExternalAuthentication()));
    contacts.Children = contacts.Children.concat(this.getMenuApiMenuItems(Api.ContactAssetAccessLog()));
    menu = this.addSectionIfChildrenExist(menu, contacts);

    // RC Case with sub-menus
    menu = this.addSectionIfChildrenExist(menu, this.getMenuApiMenuReportCompilerCaseSection());

    // RC Library with sub-menus
    menu = this.addSectionIfChildrenExist(menu, this.getMenuApiMenuReportCompilerLibrarySection());

    menu = this.addSectionIfChildrenExist(menu, this.getMenuApiMenuNotificationSection());

    menu = this.addSectionIfChildrenExist(menu, this.getMenuApiMenuDataSection());

    menu = this.addSectionIfChildrenExist(menu, this.getMenuApiMenuWebSection());

    menu = this.addSectionIfChildrenExist(menu, this.getMenuApiMenuSystemSection());

    // RC Config with sub-menus
    menu = this.addSectionIfChildrenExist(menu, this.getMenuApiMenuReportCompilerConfigurationSection());

    return menu;

  }

  public getMenuApiMenuOverviewSection(): m.MenuItem {

    // Sub menu under overview
    const request = this.buildMenuItem("Request Formats", "", "exchange (light)", Constants.ContactType.Directory);
    if (this.docService.isValidApiEndpoint("Doc.Overview.RequestFormats", ApiOperationType.Get, "Overview")) {
      request.Children.push(this.buildMenuItem("Overview", "/overview/request-formats", "exchange (solid)", Constants.ContactType.Directory));
    }
    if (this.docService.isValidApiEndpoint("Doc.Overview.RequestFormats", ApiOperationType.Get, "Methods")) {
      request.Children.push(this.buildMenuItem("Methods", "/overview/request-formats/methods", "bars (light)", Constants.ContactType.Directory));
    }
    if (this.docService.isValidApiEndpoint("Doc.Overview.RequestFormats", ApiOperationType.Get, "Lists")) {
      request.Children.push(this.buildMenuItem("Lists", "/overview/request-formats/lists", "list-alt (light)", Constants.ContactType.Directory));
    }

    // Sub menu under overview
    const policy = this.buildMenuItem("Policies", "", "file-alt (light)", Constants.ContactType.Directory);
    if (this.docService.isValidApiEndpoint("Doc.Overview.Policies", ApiOperationType.Get, "TermsOfUse")) {
      policy.Children.push(this.buildMenuItem("Terms of Use", "/overview/terms-of-use", "file-certificate (light)", Constants.ContactType.Directory));
    }
    if (this.docService.isValidApiEndpoint("Doc.Overview.Policies", ApiOperationType.Get, "ApiPolicy")) {
      policy.Children.push(this.buildMenuItem("API Use Policy", "/overview/api-use-policy", "file-user (light)", Constants.ContactType.Directory));
    }
    if (this.docService.isValidApiEndpoint("Doc.Overview.Policies", ApiOperationType.Get, "ApiLicense")) {
      policy.Children.push(this.buildMenuItem("API License Agreement", "/overview/api-license-agreement", "file-contract (light)", Constants.ContactType.Directory));
    }

    // Sub menu under overview
    const dataModel = this.buildMenuItem("Data Model", "", "database (light)", Constants.ContactType.Directory);
    if (this.docService.isValidApiEndpoint("Doc.Overview.DataModel", ApiOperationType.Get, "Overview")) {
      dataModel.Children.push(this.buildMenuItem("Overview", "/overview/data-model", "database (solid)", Constants.ContactType.Directory));
    }
    if (this.docService.isValidApiEndpoint("Doc.Overview.DataModel", ApiOperationType.Get, "Categories")) {
      dataModel.Children.push(this.buildMenuItem("Categories", "/overview/data-model/categories", "server (light)", Constants.ContactType.Directory));
    }
    if (this.docService.isValidApiEndpoint("Doc.Overview.DataModel", ApiOperationType.Get, "Tables")) {
      dataModel.Children.push(this.buildMenuItem("Tables", "/overview/data-model/tables", "table (light)", Constants.ContactType.Directory));
    }
    if (this.docService.isValidApiEndpoint("Doc.Overview.DataModel", ApiOperationType.Get, "Views")) {
      dataModel.Children.push(this.buildMenuItem("Views", "/overview/data-model/views", "table (solid)", Constants.ContactType.Directory));
    }
    if (this.docService.isValidApiEndpoint("Doc.Overview.DataModel", ApiOperationType.Get, "TablesAndViews")) {
      dataModel.Children.push(this.buildMenuItem("Tables & Views", "/overview/data-model/tables-and-views", "table (light)", Constants.ContactType.Directory));
    }

    const overview = this.buildMenuItem("Overview", "", "bullseye", Constants.ContactType.Directory);
    // We need at least one item in the menu so always allow intro
    overview.Children.push(this.buildMenuItem("Introduction", "/overview/introduction", "file (light)", Constants.ContactType.Directory));
    if (this.docService.isValidApiEndpoint("Doc.Overview.Credentials")) {
      overview.Children.push(this.buildMenuItem("Credentials", "/overview/credentials", "lock (light)", Constants.ContactType.Directory));
    }
    if (this.docService.isValidApiEndpoint("Doc.Overview.RequestFormats")) {
      overview.Children.push(request);
    }
    if (this.docService.isValidApiEndpoint("Doc.Overview.ResponseFormats")) {
      overview.Children.push(this.buildMenuItem("Response Formats", "/overview/response-formats", "exchange-alt (light)", Constants.ContactType.Directory));
    }
    if (this.docService.isValidApiEndpoint("Doc.Overview.DataFormats")) {
      overview.Children.push(this.buildMenuItem("Data Formats", "/overview/data-formats", "file-spreadsheet (light)", Constants.ContactType.Directory));
    }
    if (this.docService.isValidApiEndpoint("Doc.Overview.ResultCodes")) {
      overview.Children.push(this.buildMenuItem("Result Codes", "/overview/result-codes", "sort-numeric-up (light)", Constants.ContactType.Directory));
    }
    if (this.docService.isValidApiEndpoint("Doc.Overview.Versions")) {
      overview.Children.push(this.buildMenuItem("Versions", "/overview/versions", "list-ol (light)", Constants.ContactType.Directory));
    }
    if (this.docService.isValidApiEndpoint("Doc.Overview.RateLimits")) {
      overview.Children.push(this.buildMenuItem("Rate Limits", "/overview/rate-limits", "chart-line (light)", Constants.ContactType.Directory));
    }
    if (this.docService.isValidApiEndpoint("Doc.Overview.Notes")) {
      overview.Children.push(this.buildMenuItem("Notes", "/overview/notes", "sticky-note (light)", Constants.ContactType.Directory));
    }
    if (this.docService.isValidApiEndpoint("Doc.Overview.Endpoints")) {
      overview.Children.push(this.buildMenuItem("Endpoints", "/overview/endpoints", "cloud (light)", Constants.ContactType.Directory));
    }
    if (this.docService.isValidApiEndpoint("Doc.Overview.Policies")) {
      overview.Children.push(policy);
    }
    if (this.docService.isValidApiEndpoint("Doc.Overview.DataModel")) {
      overview.Children.push(dataModel);
    }

    return overview;

  }

  public getMenuApiMenuSecuritySection(): m.MenuItem {

    let security = this.buildMenuItem("Security", "", "lock (light)");
    security.Children = this.getMenuApiMenuItems(ApiModuleSecurity.SecurityLogin());
    security.Children = security.Children.concat(this.getMenuApiMenuItems(ApiModuleSecurity.SecurityAuthenticate()));
    security.Children = security.Children.concat(this.getMenuApiMenuItems(ApiModuleSecurity.SecurityLoginNameAvailable()));
    security.Children = security.Children.concat(this.getMenuApiMenuItems(ApiModuleSecurity.SecurityLoginRecover()));
    security.Children = security.Children.concat(this.getMenuApiMenuItems(ApiModuleSecurity.SecurityLoginChange()));
    security.Children = security.Children.concat(this.getMenuApiMenuItems(ApiModuleSecurity.SecurityPasswordReset()));
    security.Children = security.Children.concat(this.getMenuApiMenuItems(ApiModuleSecurity.SecurityPasswordChange()));

    const role = this.buildMenuItem("Role", "", "shield (light)");
    role.Children = this.getMenuApiMenuItems(ApiModuleSecurity.Role()).concat(this.getMenuApiMenuItems(ApiModuleSecurity.RoleDetail()));
    security = this.addSectionIfChildrenExist(security, role);

    const policy = this.buildMenuItem("Security Policy", "", "shield-check (light)");
    policy.Children = this.getMenuApiMenuItems(ApiModuleSecurity.SecurityPolicy());
    security = this.addSectionIfChildrenExist(security, policy);

    const apiAccess = this.buildMenuItem("API Access", "", "cube (light)");
    apiAccess.Children = this.getMenuApiMenuItems(Api.ApiAccess());
    apiAccess.Children = apiAccess.Children.concat(this.getMenuApiMenuItems(Api.ApiAccessClient()));
    security = this.addSectionIfChildrenExist(security, apiAccess);

    return security;

  }

  public getMenuApiMenuCrmSection(): m.MenuItem {

    let crm = this.buildMenuItem("CRM", "", "address-book (duotone)");

    const salesOpportunities = this.buildMenuItem("Sales Opportunities", "", "funnel-dollar (duotone)");
    salesOpportunities.Children = this.getMenuApiMenuItems(Api.SalesOpportunity());
    crm = this.addSectionIfChildrenExist(crm, salesOpportunities);

    return crm;

  }

  public getMenuApiMenuInventorySection(): m.MenuItem {

    let inventory = this.buildMenuItem("Inventory", "", "boxes-alt (light)");

    const vendors = this.buildMenuItem("Vendor", "", `${IconHelper.iconDescriptionFromContactType(Constants.ContactType.Vendor, false, true)}`);
    vendors.Children = this.getMenuApiMenuItems(Api.Vendor());
    inventory = this.addSectionIfChildrenExist(inventory, vendors);

    const warehouses = this.buildMenuItem("Warehouse", "", `${IconHelper.iconDescriptionFromContactType(Constants.ContactType.Warehouse, false, true)}`);
    warehouses.Children = this.getMenuApiMenuItems(Api.Warehouse());
    inventory = this.addSectionIfChildrenExist(inventory, warehouses);

    const inventoryTypes = this.buildMenuItem("Inventory Type", "", "boxes-alt (solid)");
    inventoryTypes.Children = this.getMenuApiMenuItems(Api.InventoryType());
    inventoryTypes.Children = inventoryTypes.Children.concat(this.getMenuApiMenuItems(Api.InventoryTypeVersion()));
    inventoryTypes.Children = inventoryTypes.Children.concat(this.getMenuApiMenuItems(Api.InventoryTypeVersionBatchAdd()));
    inventoryTypes.Children = inventoryTypes.Children.concat(this.getMenuApiMenuItems(Api.InventoryTypeVersionAssignCase()));
    inventoryTypes.Children = inventoryTypes.Children.concat(this.getMenuApiMenuItems(Api.InventoryTypeVersionRemoveCase()));
    inventoryTypes.Children = inventoryTypes.Children.concat(this.getMenuApiMenuItems(Api.InventoryTypeVersionCreateReleaseNotes()));
    inventory = this.addSectionIfChildrenExist(inventory, inventoryTypes);

    const inventoryQuantityTypeMenu = this.buildMenuItem("Inventory Quantity Type", "", "pallet (light)");
    inventoryQuantityTypeMenu.Children = this.getMenuApiMenuItems(Api.InventoryQuantityType());
    inventory = this.addSectionIfChildrenExist(inventory, inventoryQuantityTypeMenu);

    const inventoryMenu = this.buildMenuItem("Inventory", "", "boxes-alt (light)");
    inventoryMenu.Children = this.getMenuApiMenuItems(Api.Inventory());
    inventory = this.addSectionIfChildrenExist(inventory, inventoryMenu);

    const inventoryHistoryMenu = this.buildMenuItem("Inventory History", "", "boxes-alt");
    inventoryHistoryMenu.Children = this.getMenuApiMenuItems(Api.InventoryHistory());
    inventory = this.addSectionIfChildrenExist(inventory, inventoryHistoryMenu);

    const inventoryLocationMenu = this.buildMenuItem("Inventory Location", "", `${IconHelper.iconDescriptionFromContactType(Constants.ContactType.Location, false, true)}`);
    inventoryLocationMenu.Children = this.getMenuApiMenuItems(Api.InventoryLocation());
    inventory = this.addSectionIfChildrenExist(inventory, inventoryLocationMenu);

    const inventoryEvents = this.buildMenuItem("Inventory Event", "", "calendar-alt (light)");
    inventoryEvents.Children = this.getMenuApiMenuItems(Api.InventoryEvent());
    inventoryEvents.Children = inventoryEvents.Children.concat(this.getMenuApiMenuItems(Api.InventoryEventBulkAdd()));
    inventory = this.addSectionIfChildrenExist(inventory, inventoryEvents);

    return inventory;

  }

  public getMenuApiMenuNotificationSection(): m.MenuItem {

    // Notification with sub-menus
    let notification = this.buildMenuItem("Notification", "", "envelope (light)");

    const notificationContact = this.buildMenuItem("Contact", "", "file-user (light)");
    notificationContact.Children = this.getMenuApiMenuItems(Api.NotificationContact());
    notification = this.addSectionIfChildrenExist(notification, notificationContact);

    //const notificationMessage = { text: "Messages", url: "javascript:void(0)", icon: "envelope-o", showWhen: "true", visibility: "", collapsed: true, children: [], activeMenu: [] };
    //notificationMessage.Children = this.getMenuApiMenuItems(Api.NotificationMessage);
    //notification = this.addSectionIfChildrenExist(notification, notificationMessage);

    const notificationOptOut = this.buildMenuItem("Opt Out", "", "envelope-open-text (light)");
    notificationOptOut.Children = this.getMenuApiMenuItems(Api.NotificationOptOut());
    notification = this.addSectionIfChildrenExist(notification, notificationOptOut);

    const notificationGroup = this.buildMenuItem("Group", "", "book-user (light)");
    notificationGroup.Children = this.getMenuApiMenuItems(Api.NotificationGroup());
    notificationGroup.Children = notificationGroup.Children.concat(this.getMenuApiMenuItems(Api.NotificationGroupDetail()));
    notification = this.addSectionIfChildrenExist(notification, notificationGroup);

    const notificationEvent = this.buildMenuItem("Event", "", "mail-bulk (light)");
    notificationEvent.Children = this.getMenuApiMenuItems(Api.NotificationEvent());
    notificationEvent.Children = notificationEvent.Children.concat(this.getMenuApiMenuItems(Api.Notification()));
    notification = this.addSectionIfChildrenExist(notification, notificationEvent);

    const alarmRule = this.buildMenuItem("Alarm Rule", "", "bell (light)");
    alarmRule.Children = this.getMenuApiMenuItems(Api.AlarmRule());
    alarmRule.Children = alarmRule.Children.concat(this.getMenuApiMenuItems(Api.AlarmRuleNotification()));
    notification = this.addSectionIfChildrenExist(notification, alarmRule);

    const mail = this.buildMenuItem("Mail", "", "envelope (solid)");
    mail.Children = this.getMenuApiMenuItems(Api.MailServer());
    mail.Children = mail.Children.concat(this.getMenuApiMenuItems(Api.MailAddressAction()));
    notification = this.addSectionIfChildrenExist(notification, mail);

    return notification;

  }

  public getMenuApiMenuDataSection(): m.MenuItem {

    // Data with sub-menus
    let data = this.buildMenuItem("Data", "", "database (light)");

    const asset = this.buildMenuItem("Asset", "", "atom (light)");
    asset.Children = this.getMenuApiMenuItems(Api.Asset());
    asset.Children = asset.Children.concat(this.getMenuApiMenuItems(Api.AssetCopyAttachments()));
    // hide from menu until we get this working.... asset.Children = asset.Children.concat(this.getMenuApiMenuItems(Api.AssetAddFromUpload));
    asset.Children = asset.Children.concat(this.getMenuApiMenuItems(Api.AssetActionValidate()));
    asset.Children = asset.Children.concat(this.getMenuApiMenuItems(Api.AssetActionAnalyze()));
    asset.Children = asset.Children.concat(this.getMenuApiMenuItems(Api.AssetActionDownload()));
    asset.Children = asset.Children.concat(this.getMenuApiMenuItems(Api.AssetActionView()));
    asset.Children = asset.Children.concat(this.getMenuApiMenuItems(Api.AssetShareActionDownload()));
    asset.Children = asset.Children.concat(this.getMenuApiMenuItems(Api.AssetShareActionView()));
    asset.Children = asset.Children.concat(this.getMenuApiMenuItems(Api.AssetFile()));
    asset.Children = asset.Children.concat(this.getMenuApiMenuItems(Api.AssetFileActionDownload()));
    asset.Children = asset.Children.concat(this.getMenuApiMenuItems(Api.AssetFileActionView()));
    // hide from menu until we get this working.... asset.Children = asset.Children.concat(this.getMenuApiMenuItems(Api.AssetFileActionUpload()));
    asset.Children = asset.Children.concat(this.getMenuApiMenuItems(Api.AssetSelection()));
    asset.Children = asset.Children.concat(this.getMenuApiMenuItems(Api.AssetRelated()));
    asset.Children = asset.Children.concat(this.getMenuApiMenuItems(Api.AssetReference()));
    asset.Children = asset.Children.concat(this.getMenuApiMenuItems(Api.AssetVisibility()));
    asset.Children = asset.Children.concat(this.getMenuApiMenuItems(Api.AssetAccessLog()));
    asset.Children = asset.Children.concat(this.getMenuApiMenuItems(Api.AssetFeedback()));
    data = this.addSectionIfChildrenExist(data, asset);

    const attribute = this.buildMenuItem("Attribute", "", "cube (light)");
    attribute.Children = this.getMenuApiMenuItems(Api.AttributeSet());
    attribute.Children = attribute.Children.concat(this.getMenuApiMenuItems(Api.AttributeSetConfiguration()));
    attribute.Children = attribute.Children.concat(this.getMenuApiMenuItems(Api.AttributeSetRawAttribute()));
    attribute.Children = attribute.Children.concat(this.getMenuApiMenuItems(Api.AttributeSetAttribute()));
    attribute.Children = attribute.Children.concat(this.getMenuApiMenuItems(Api.AttributeRawAttribute()));
    data = this.addSectionIfChildrenExist(data, attribute);

    const query = this.buildMenuItem("Query", "", "question (light)");
    query.Children = this.getMenuApiMenuItems(Api.Query());
    query.Children = query.Children.concat(this.getMenuApiMenuItems(Api.QueryRun()));
    data = this.addSectionIfChildrenExist(data, query);

    const dataSource = this.buildMenuItem("Data Source", "", "database (light)");
    dataSource.Children = this.getMenuApiMenuItems(Api.DataSource());
    data = this.addSectionIfChildrenExist(data, dataSource);

    const filter = this.buildMenuItem("Filter", "", "filter (light)");
    filter.Children = this.getMenuApiMenuItems(Api.Filter());
    filter.Children = filter.Children.concat(this.getMenuApiMenuItems(Api.FilterTest()));
    data = this.addSectionIfChildrenExist(data, filter);

    const dataImport = this.buildMenuItem("Data Import Definition", "", "file-import (light)");
    dataImport.Children = this.getMenuApiMenuItems(Api.DataImportDefinition());
    data = this.addSectionIfChildrenExist(data, dataImport);

    const cache = this.buildMenuItem("Cache", "", "cube (light)");
    cache.Children = this.getMenuApiMenuItems(Api.CachePackageSignup());
    data = this.addSectionIfChildrenExist(data, cache);

    const visibility = this.buildMenuItem("Visibility", "", "eye (light)");
    visibility.Children = this.getMenuApiMenuItems(Api.Visibility());
    data = this.addSectionIfChildrenExist(data, visibility);

    const alias = this.buildMenuItem("Alias", "", "arrows-h (light)");
    alias.Children = this.getMenuApiMenuItems(Api.Alias());
    data = this.addSectionIfChildrenExist(data, alias);

    const reference = this.buildMenuItem("Reference", "", "link (light)");
    reference.Children = this.getMenuApiMenuItems(Api.Reference());
    data = this.addSectionIfChildrenExist(data, reference);

    const objectStatus = this.buildMenuItem("Object Status", "", "clipboard-list-check (light)");
    objectStatus.Children = this.getMenuApiMenuItems(Api.ObjectStatus());
    data = this.addSectionIfChildrenExist(data, objectStatus);

    const messageExchange = this.buildMenuItem("Encryption", "", "key (light)");
    messageExchange.Children = this.getMenuApiMenuItems(Api.Encryption());
    messageExchange.Children = messageExchange.Children.concat(this.getMenuApiMenuItems(Api.EncryptionEcho()));
    data = this.addSectionIfChildrenExist(data, messageExchange);

    return data;

  }

  public getMenuApiMenuWebSection(): m.MenuItem {

    // Web with sub-menus
    let web = this.buildMenuItem("Web", "", "laptop (light)");

    const form = this.buildMenuItem("Form", "", "wpforms (brand)");
    form.Children = this.getMenuApiMenuItems(ApiModuleWeb.Form());
    form.Children = form.Children.concat(this.getMenuApiMenuItems(ApiModuleWeb.FormControlGroup()));
    form.Children = form.Children.concat(this.getMenuApiMenuItems(ApiModuleWeb.FormControl()));
    web = this.addSectionIfChildrenExist(web, form);

    const favorite = this.buildMenuItem("Favorite", "", "thumbs-up (light)");
    favorite.Children = this.getMenuApiMenuItems(ApiModuleWeb.Favorite());
    favorite.Children = favorite.Children.concat(this.getMenuApiMenuItems(ApiModuleWeb.FavoriteForContact()));
    web = this.addSectionIfChildrenExist(web, favorite);

    const webhook = this.buildMenuItem("Webhook", "", "share (light)");
    webhook.Children = this.getMenuApiMenuItems(ApiModuleWeb.Webhook());
    webhook.Children = webhook.Children.concat(this.getMenuApiMenuItems(ApiModuleWeb.WebhookTarget()));
    webhook.Children = webhook.Children.concat(this.getMenuApiMenuItems(ApiModuleWeb.WebhookEvent()));
    web = this.addSectionIfChildrenExist(web, webhook);

    const search = this.buildMenuItem("Search", "", "search (light)");
    search.Children = this.getMenuApiMenuItems(ApiModuleWeb.SearchConfiguration());
    search.Children = search.Children.concat(this.getMenuApiMenuItems(ApiModuleWeb.SearchResult()));
    web = this.addSectionIfChildrenExist(web, search);

    return web;

  }

  public getMenuApiMenuSystemSection(): m.MenuItem {

    // System with sub-menus
    let system = this.buildMenuItem("System", "", "cog (light)");

    const settings = this.buildMenuItem("Settings", "", "cog (light)");
    settings.Children = this.getMenuApiMenuItems(ApiModuleCore.SystemSettingsApp());
    settings.Children = settings.Children.concat(this.getMenuApiMenuItems(Api.SystemSettings()));
    settings.Children = settings.Children.concat(this.getMenuApiMenuItems(Api.SystemSettingOne()));
    system = this.addSectionIfChildrenExist(system, settings);

    const list = this.buildMenuItem("Lists", "", "list-alt (light)");
    list.Children = this.getMenuApiMenuItems(ApiModuleCore.CustomPickList());
    list.Children = list.Children.concat(this.getMenuApiMenuItems(ApiModuleCore.InputPickList()));
    list.Children = list.Children.concat(this.getMenuApiMenuItems(ApiModuleCore.PickListCategory()));
    list.Children = list.Children.concat(this.getMenuApiMenuItems(ApiModuleCore.PickList()));
    list.Children = list.Children.concat(this.getMenuApiMenuItems(ApiModuleCore.PickListValue()));
    //// HIDDEN list.Children.push({ text: "Query", url: "/system/query", icon: "", showWhen: "true", visibility: "", collapsed: true, children: [], activeMenu: [] });
    system = this.addSectionIfChildrenExist(system, list);

    const currency = this.buildMenuItem("Currency", "", "usd-square (light)");
    currency.Children = this.getMenuApiMenuItems(Api.Currency());
    system = this.addSectionIfChildrenExist(system, currency);

    const timeZone = this.buildMenuItem("Time Zone", "", "clock (light)");
    timeZone.Children = this.getMenuApiMenuItems(Api.TimeZone());
    system = this.addSectionIfChildrenExist(system, timeZone);

    const fileServer = this.buildMenuItem("File Server", "", "server (light)");
    fileServer.Children = this.getMenuApiMenuItems(Api.FileServer());
    system = this.addSectionIfChildrenExist(system, fileServer);

    const job = this.buildMenuItem("Job", "", "tasks-alt (light)");
    job.Children = this.getMenuApiMenuItems(Api.JobType());
    job.Children = job.Children.concat(this.getMenuApiMenuItems(Api.Job()));
    job.Children = job.Children.concat(this.getMenuApiMenuItems(Api.JobActionStart()));
    job.Children = job.Children.concat(this.getMenuApiMenuItems(Api.JobActionStop()));
    //var typedJob = { text: "Job Types", url: "javascript:void(0)", icon: "cube", showWhen: "true", visibility: "", collapsed: true, children: [], activeMenu: [] };
    //typedJob.Children = this.getMenuApiMenuItems(Api.JobDirectorySync());
    //typedJob.Children = typedJob.Children.concat(this.getMenuApiMenuItems(Api.JobNotificationEngine()));
    //typedJob.Children = typedJob.Children.concat(this.getMenuApiMenuItems(Api.JobAlarmRule()));
    //job.Children.push(typedJob);
    system = this.addSectionIfChildrenExist(system, job);

    const process = this.buildMenuItem("Process Template", "", "tasks-alt (solid)");
    process.Children = this.getMenuApiMenuItems(Api.ProcessTemplate());
    process.Children = process.Children.concat(this.getMenuApiMenuItems(Api.ProcessTemplateStep()));
    process.Children = process.Children.concat(this.getMenuApiMenuItems(Api.Process()));
    process.Children = process.Children.concat(this.getMenuApiMenuItems(Api.ProcessApproval()));
    process.Children = process.Children.concat(this.getMenuApiMenuItems(Api.ProcessStep()));
    system = this.addSectionIfChildrenExist(system, process);

    const watchEvent = this.buildMenuItem("Watch Event", "", "eye (solid)");
    watchEvent.Children = this.getMenuApiMenuItems(Api.WatchEvent());
    watchEvent.Children = process.Children.concat(this.getMenuApiMenuItems(Api.WatchEventSystemAdd()));
    watchEvent.Children = process.Children.concat(this.getMenuApiMenuItems(Api.WatchEventSystemMostRecent()));
    system = this.addSectionIfChildrenExist(system, watchEvent);

    const partition = this.buildMenuItem("Partition", "", "border-all (light)");
    partition.Children = this.getMenuApiMenuItems(ApiModuleCore.ApplicationInformation());
    partition.Children = partition.Children.concat(this.getMenuApiMenuItems(ApiModuleCore.Partition()));
    partition.Children = partition.Children.concat(this.getMenuApiMenuItems(ApiModuleCore.PartitionService()));
    partition.Children = partition.Children.concat(this.getMenuApiMenuItems(ApiModuleCore.PartitionDomain()));
    system = this.addSectionIfChildrenExist(system, partition);

    const sync = this.buildMenuItem("Sync", "", "sync-alt (light)");
    sync.Children = this.getMenuApiMenuItems(Api.SyncDataStore());
    sync.Children = sync.Children.concat(this.getMenuApiMenuItems(Api.SyncPublisherArticleType()));
    sync.Children = sync.Children.concat(this.getMenuApiMenuItems(Api.SyncSubscription()));
    sync.Children = sync.Children.concat(this.getMenuApiMenuItems(Api.SyncArticle()));
    system = this.addSectionIfChildrenExist(system, sync);

    const management = this.buildMenuItem("Management", "", "tasks-alt (light)");
    management.Children = this.getMenuApiMenuItems(Api.ContactDuplicateManagementPossibleDuplicates());
    management.Children = management.Children.concat(this.getMenuApiMenuItems(Api.ContactDuplicateManagementPossibleDuplicateChildSynopsis()));
    management.Children = management.Children.concat(this.getMenuApiMenuItems(Api.ContactDuplicateManagementNotDuplicate()));
    management.Children = management.Children.concat(this.getMenuApiMenuItems(Api.ContactDuplicateManagementMerge()));
    system = this.addSectionIfChildrenExist(system, management);

    //var other = { text: "Other", url: "javascript:void(0)", icon: "cube", showWhen: "true", visibility: "", collapsed: true, children: [], activeMenu: [] };
    //system.Children.push(other);

    return system;

  }


  public getMenuApiMenuReportCompilerCaseSection(): m.MenuItem {

    let cases = this.buildMenuItem("Cases", "", "folder-open (light)");

    const casesProper = this.buildMenuItem("Case", "", "folder-open (light)");
    casesProper.Children = this.getMenuApiMenuItems(Api.Case());
    casesProper.Children = casesProper.Children.concat(this.getMenuApiMenuItems(Api.TaskList()));
    casesProper.Children = casesProper.Children.concat(this.getMenuApiMenuItems(Api.Task()));
    casesProper.Children = casesProper.Children.concat(this.getMenuApiMenuItems(ApiModuleReportCompiler.ReportCompilerCaseOptions()));
    casesProper.Children = casesProper.Children.concat(this.getMenuApiMenuItems(ApiModuleReportCompiler.ReportCompilerCaseReportSections()));
    casesProper.Children = casesProper.Children.concat(this.getMenuApiMenuItems(ApiModuleReportCompiler.ReportCompilerCaseReportSectionText()));
    casesProper.Children = casesProper.Children.concat(this.getMenuApiMenuItems(ApiModuleReportCompiler.ReportCompilerCaseReportSectionTextSuggestion()));
    casesProper.Children = casesProper.Children.concat(this.getMenuApiMenuItems(ApiModuleReportCompiler.ReportCompilerCaseReportAttachments()));
    casesProper.Children = casesProper.Children.concat(this.getMenuApiMenuItems(ApiModuleReportCompiler.ReportCompilerCaseReportAttachmentDocuments()));
    casesProper.Children = casesProper.Children.concat(this.getMenuApiMenuItems(ApiModuleReportCompiler.ReportCompilerReportCreateDraft()));
    casesProper.Children = casesProper.Children.concat(this.getMenuApiMenuItems(ApiModuleReportCompiler.ReportCompilerReportMarkAsDraft()));
    casesProper.Children = casesProper.Children.concat(this.getMenuApiMenuItems(ApiModuleReportCompiler.ReportCompilerReportMarkAsFinal()));
    casesProper.Children = casesProper.Children.concat(this.getMenuApiMenuItems(ApiModuleReportCompiler.ReportCompilerReportCreateFinal()));
    cases = this.addSectionIfChildrenExist(cases, casesProper);

    const casesTemplate = this.buildMenuItem("Template", "", "folders (light)");
    casesTemplate.Children = this.getMenuApiMenuItems(Api.CaseTemplate());
    casesTemplate.Children = casesTemplate.Children.concat(this.getMenuApiMenuItems(Api.TaskListTemplate()));
    casesTemplate.Children = casesTemplate.Children.concat(this.getMenuApiMenuItems(Api.TaskTemplate()));
    casesTemplate.Children = casesTemplate.Children.concat(this.getMenuApiMenuItems(ApiModuleReportCompiler.ReportCompilerCaseTemplateOptions()));
    casesTemplate.Children = casesTemplate.Children.concat(this.getMenuApiMenuItems(ApiModuleReportCompiler.ReportCompilerCaseTemplateReportSections()));
    casesTemplate.Children = casesTemplate.Children.concat(this.getMenuApiMenuItems(ApiModuleReportCompiler.ReportCompilerCaseTemplateReportAttachments()));
    cases = this.addSectionIfChildrenExist(cases, casesTemplate);

    return cases;

  }

  public getMenuApiMenuReportCompilerLibrarySection(): m.MenuItem {
    const library = this.buildMenuItem("Library", "", "book (light)");
    library.Children = this.getMenuApiMenuItems(ApiModuleReportCompiler.ReportCompilerLibraryStats());
    library.Children = library.Children.concat(this.getMenuApiMenuItems(ApiModuleReportCompiler.ReportCompilerLibraryCoverage()));
    library.Children = library.Children.concat(this.getMenuApiMenuItems(ApiModuleReportCompiler.ReportCompilerLibraryReview()));
    //library.Children = library.Children.concat(this.getMenuApiMenuItems(ApiModuleReportCompiler.ReportCompilerSelectionsReview()));
    return library;
  }

  public getMenuApiMenuReportCompilerConfigurationSection(): m.MenuItem {
    const library = this.buildMenuItem("Configuration", "", "cog (light)");
    library.Children = this.getMenuApiMenuItems(ApiModuleReportCompiler.ReportCompilerTemplateTypeConfiguration());
    library.Children = library.Children.concat(this.getMenuApiMenuItems(ApiModuleReportCompiler.ReportCompilerLibraryGroupConfiguration()));
    library.Children = library.Children.concat(this.getMenuApiMenuItems(ApiModuleReportCompiler.ReportCompilerLibraryGroupContentMove()));
    library.Children = library.Children.concat(this.getMenuApiMenuItems(ApiModuleReportCompiler.ReportCompilerIndustryLibraryTypeInformation()));
    return library;
  }



  /**
  Since a menu section may have all endpoints hidden the children in the section may be empty to this helper method
  checks for that and only adds the section to our menu if it indeed has children.
  Accept MenuItem or MenuItem[] for our source menu so this can work at top level which is collection.
  Return any since we can't assign either type to the target.
  */
  protected addSectionIfChildrenExist = (menu: m.MenuItem | m.MenuItem[], section: m.MenuItem): any => {
    if (!section) {
      return menu;
    }
    if (!section.Children || section.Children.length === 0) {
      return menu;
    }
    if (Helper.isArray(menu)) {
      (<m.MenuItem[]>menu).push(section);
    } else {
      (<m.MenuItem>menu).Children.push(section);
    }
    return menu;
  }


  protected getMenuApiMenuItems(api: ApiProperties): m.MenuItem[] {

    const menu: m.MenuItem[] = [];

    // We may not have AuthorizationCode but do have AuthorizationCode.Add so don't return so quickly
    //if (!this.docService.isValidApiEndpoint(api.id)) {
    //    return menu;
    //}

    api.endpoints.forEach((endpoint: ApiEndpoint, index: number, array: ApiEndpoint[]) => {
      if (!this.docService.isValidApiEndpoint(api.id, endpoint.type, endpoint.id)) {
        // Skip this endpoint and continue on with the next one in the list
        return;
      }
      const type = ApiOperationType[endpoint.type]; // String representation of the enum
      let urlPart = type.toLowerCase(); // String representation of the enum
      if (endpoint.id) {
        urlPart += `-${endpoint.id.toLowerCase()}`;
      }
      let url = api.documentation.documentationUrlBase;
      let description = api.documentation.objectDescription;
      if (endpoint.type === ApiOperationType.List) {
        description = api.documentation.objectDescriptionPlural;
      }
      let label = `${type} ${description}`;
      // See if our endpoint has some documentation to override some of these settings
      if (endpoint.documentation) {
        url = Helper.getFirstDefinedString(endpoint.documentation.documentationUrlBase, api.documentation.documentationUrlBase);
        description = Helper.getFirstDefinedString(endpoint.documentation.objectDescription, api.documentation.objectDescription);
        if (endpoint.type === ApiOperationType.List) {
          description = Helper.getFirstDefinedString(endpoint.documentation.objectDescriptionPlural, api.documentation.objectDescriptionPlural);
        }
        label = Helper.getFirstDefinedString(endpoint.documentation.menuText, `${type} ${description}`);
      }
      menu.push(this.buildMenuItem(label, `/endpoints/${url}${urlPart}`, ""));
    });

    return menu;

  }






  public buildMenuItem(label: string, url: string, icon: string, requiredContactType: string = "", permissionArea: string = "", permissionAreaType: string = "TB"): m.MenuItem {
    const menu = new m.MenuItem();
    menu.Id = Helper.createBase36Guid();
    menu.Label = label;
    if (!url) {
      menu.LinkType = "MenuGroup";
    } else if (Helper.startsWith(url, "/")) {
      menu.LinkType = "Route";
    } else {
      menu.LinkType = "Url";
    }
    menu.LinkParam = url;
    menu.Icon = icon;
    menu.IconType = "Icon";
    menu.Visible = true;
    menu.Modules = new m.Modules();
    menu.Permissions = new m.Permissions();
    if (requiredContactType) {
      menu.ValidContactTypes.push(requiredContactType);
    }
    if (permissionArea) {
      const perm = new m.Permission();
      perm.Id = Helper.createBase36Guid();
      perm.PermissionAreaType = permissionAreaType;
      perm.PermissionArea = permissionArea;
      perm.Required = true;
      perm.Rights.push(Constants.Permission.Read);
      menu.Permissions.PermissionList.push(perm);
    }
    return menu;
  }


  /**
   * Find a menu item in a menu collection based on an id.  If not found returns null.
   * If found returns an object that has the following properties:
   * #1 "menu" - the menu collection the item is found in.  This may be different than the menu collection submitted when the
   * the menu item was found in a child menu collection.  This is returned in case there is action that needs to be done in
   * the context of that collection (e.g. move up, add after, etc.).
   * #2 "item" - the menu item that was found.
   * #3 "index" - the index inside "menu" where "item" resides.
   * @param menu
   * @param id
   */
  public findMenuItem(menu: m.MenuItem[], id: string): { menu: m.MenuItem[], item: m.MenuItem, index: number } {
    if (!menu || menu.length === 0) {
      return null;
    }
    for (let i = 0; i < menu.length; i++) {
      // See if this item is the one we want
      if (menu[i].Id === id) {
        return { menu: menu, item: menu[i], index: i };
      }
      // If we have children then do recursive call to see if one of them is the menu item we want
      if (menu[i].Children && menu[i].Children.length > 0) {
        const match = this.findMenuItem(menu[i].Children, id);
        if (match) {
          return match;
        }
      }
    }
    // No joy
    return null;
  }


  /**
   * Find a menu item in a menu collection based on an id.  If not found returns null.
   * If found returns an object that has the following properties:
   * #1 "menu" - the menu collection the item is found in.  This may be different than the menu collection submitted when the
   * the menu item was found in a child menu collection.  This is returned in case there is action that needs to be done in
   * the context of that collection (e.g. move up, add after, etc.).
   * #2 "item" - the menu item that was found.
   * #3 "index" - the index inside "menu" where "item" resides.
   * @param menu
   * @param id
   */
  public findPrimeMenuItem(menu: MenuItem[], id: string): { menu: MenuItem[], item: MenuItem, index: number } {
    if (!menu || menu.length === 0) {
      return null;
    }
    for (let i = 0; i < menu.length; i++) {
      // See if this item is the one we want
      if (menu[i].id === id) {
        return { menu: menu, item: menu[i], index: i };
      }
      // If we have children then do recursive call to see if one of them is the menu item we want
      if (menu[i].items && menu[i].items.length > 0) {
        const match = this.findPrimeMenuItem(<MenuItem[]>menu[i].items, id);
        if (match) {
          return match;
        }
      }
    }
    // No joy
    return null;
  }


  public toPrimeMenu(menu: m.MenuItem[], user: m5sec.AuthenticatedUserViewModel, appInfo: m5core.ApplicationInformationModel, globalAction: (event?: any) => any = null, iconOnly: boolean = false): MenuItem[] {
    const items: MenuItem[] = [];
    if (!menu || menu.length === 0) {
      return items;
    }
    menu.forEach(one => {
      const prime = this.toPrimeMenuItem(one, user, appInfo, globalAction, iconOnly);
      // Prime menu item could be null if menu does not pass check for modules, permissions, etc.
      if (prime) {
        if (prime.separator) {
          if (items.length === 0) {
            // We don't add a separator as first menu item
          } else if (items.slice(-1)[0].separator) {
            // We don't add a separator right after another separator
          } else {
            items.push(prime);
          }
        } else {
          items.push(prime);
        }
      }
    });
    return items;
  }

  public toPrimeMenuItem(menu: m.MenuItem, user: m5sec.AuthenticatedUserViewModel, appInfo: m5core.ApplicationInformationModel, globalAction: (event?: any) => any = null, iconOnly: boolean = false): MenuItem {

    // See if we pass any module requirements
    const moduleResult = this.securityService.checkModules(menu.Modules, `Menu item '${menu.Label}'`, appInfo);
    if (!moduleResult.passed) {
      return null;
    }

    // See if we pass any contact type restrictions
    if (menu.ValidContactTypes && menu.ValidContactTypes.length > 0 && user) {
      if (!menu.ValidContactTypes.includes(user.ParentContactType)) {
        Log.debug("ui", "Menu", `Menu item '${menu.Label}' rejected because it does not support contact type '${user.ParentContactType}'.`);
        return null;
      }
    }

    // See if there are any permission restrictions
    const permissionResult = this.securityService.checkPermissions(menu.Permissions, `Menu item '${menu.Label}'`, user);
    if (!permissionResult.passed) {
      return null;
    }

    const item: MenuItem = {};
    item.id = menu.Id;
    // Handle any macros we might have in our menus
    let label = this.appService.labelMacroSubstitution(menu.Label);
    let description = this.appService.labelMacroSubstitution(menu.Description);
    if (!iconOnly) {
      // When collapsed we may want to only show icons so don't map the description
      item.label = label;
    }
    item.icon = IconHelper.parseIcon(menu.Icon).calculatedClasses;
    if (iconOnly) {
      // When collapsed we want the menu item label as title
      item.title = label;
    } else {
      item.title = description;
    }
    // When we're in menu editor mode we fire a method to set which menu item we're editing
    if (globalAction) {
      item.command = globalAction;
    } else {
      if (Helper.startsWith(menu.LinkParam, "http", true)) {
        item.url = menu.LinkParam;
      } else if (menu.LinkParam) {
        item.routerLink = [menu.LinkParam];
      }
    }
    if (Helper.equals(menu.LinkType, "separator", true) || Helper.equals(menu.LinkType, "divider", true)) {
      item.separator = true;
    }
    item.visible = menu.Visible;

    // TODO parse contact types and permissions to make visible or not
    if (menu.Children && menu.Children.length > 0) {
      item.items = [];
      menu.Children.forEach(child => {
        const prime = this.toPrimeMenuItem(child, user, appInfo, globalAction, iconOnly);
        // Prime menu item could be null if menu does not pass check for modules, permissions, etc.
        if (prime) {
          (<MenuItem[]>item.items).push(prime);
        }
      });
    }

    return item;

  }

  public getExpandedPrimeMenuIds(menu: MenuItem[]): string[] {
    const expanded: string[] = [];
    if (!menu || menu.length === 0) {
      return expanded;
    }
    menu.forEach(one => {
      if (one.expanded) {
        expanded.push(one.id);
      }
      // Check descendants for a couple of levels
      if (one.items && one.items.length > 0) {
        (<MenuItem[]>one.items).forEach(child => {
          if (child.expanded) {
            expanded.push(child.id);
          }
          // Check grandchildren
          if (child.items && child.items.length > 0) {
            (<MenuItem[]>child.items).forEach(grandchild => {
              if (grandchild.expanded) {
                expanded.push(grandchild.id);
              }
            });
          }
        });
      }
    });
    return expanded;
  }

  public setExpandedPrimeMenuIds(menu: MenuItem[], expanded: string[]): void {
    if (!menu || menu.length === 0 || !expanded || expanded.length === 0) {
      return;
    }
    menu.forEach(one => {
      if (expanded.includes(one.id)) {
        one.expanded = true;
      }
      // Check descendants for a couple of levels
      if (one.items && one.items.length > 0) {
        (<MenuItem[]>one.items).forEach(child => {
          if (expanded.includes(child.id)) {
            child.expanded = true;
          }
          // Check grandchildren
          if (child.items && child.items.length > 0) {
            (<MenuItem[]>child.items).forEach(grandchild => {
              if (expanded.includes(grandchild.id)) {
                grandchild.expanded = true;
              }
            });
          }
        });
      }
    });
    return;
  }

  public fromActionListToPrimeMenu(menu: Action[], globalAction: (event?: any) => any = null): MenuItem[] {
    const items: MenuItem[] = [];
    if (!menu || menu.length === 0) {
      return items;
    }
    menu.forEach(one => {
      items.push(this.fromActionToPrimeMenuItem(one, globalAction));
    });
    return items;
  }

  public fromActionToPrimeMenuItem(action: Action, globalAction: (event?: any) => any = null): MenuItem {
    const item: MenuItem = {};
    item.id = action.actionId;
    item.label = action.label;
    item.icon = IconHelper.parseIcon(action.icon).calculatedClasses;
    item.title = action.description;
    // When we're in menu editor mode we fire a method to set which menu item we're editing
    if (globalAction) {
      item.command = globalAction;
    } else {
      item.command = action.action;
    }
    if (!action.label && !action.icon) {
      item.separator = true;
    }
    return item;
  }




  get showPartitionZeroBadge(): boolean {
    // Some components are only for partition zero but in an on premise installation
    // we are frequently (maybe always) in partition zero so this moniker has no added value.  We
    // can generally determine that by the config setting if we're showing a partition
    // zero warning flag in the header.
    if (this.appService.config.showPartitionZeroWarning) {
      return true;
    }
    if (this.appService.config.partitionNotices) {
      const match = this.appService.config.partitionNotices.find(x => x.partitionId === 0);
      if (match) {
        return true;
      }
    }
    return false;
  }


  public getAppComponentLinksFiltered(brandId?: m.BrandId): AppComponentLink[] {

    let links: AppComponentLink[] = this.getAppComponentLinksAll();

    if (this.showPartitionZeroBadge) {
      // Some components are only for partition zero but in an on premise installation
      // we are frequently in partition zero so keep P0 only links depending on this
      // config setting.
      if (!this.appService.isUserPartitionZeroDirectoryUser) {
        // We're showing a P0 badge so we're probably not an on premise installation
        // and we're not a P0 directory user so exclude P0 only links.
        Log.debug("ui", "SiteMap", `Excluding P0 only links: ${Helper.buildCsvString(links.filter(x => x.partitionZeroOnly).map(x => x.label))}`);
        links = links.filter(x => !x.partitionZeroOnly);
      }
    } else {
      // Generally speaking a SaaS install will have P0 warning enabled so if it isn't then this is probably an
      // on premise installation and, therefore, any SaaS components are not appropriate to be included.
      Log.debug("ui", "SiteMap", `Excluding SaaS only links: ${Helper.buildCsvString(links.filter(x => x.saasOnly).map(x => x.label))}`);
      links = links.filter(x => !x.saasOnly);
    }

    // Remove any links that have valid modules and we don't have any of them
    let removed = links.filter(x => x.validModules && x.validModules.length > 0 && !x.validModules.some(m => this.appService.hasModule(m)));
    if (removed && removed.length > 0) {
      Log.debug("ui", "SiteMap", `Excluding missing module links: ${Helper.buildCsvString(removed.map(x => `${x.label} (${Helper.buildCsvString(x.validModules)})`))}`);
    }
    links = links.filter(x => !x.validModules || x.validModules.length === 0 || x.validModules.some(m => this.appService.hasModule(m)));

    // If we were given a brand then only keep links for that brand or that are valid for that brand
    if (brandId) {
      // Check valid brands
      removed = links.filter(x => x.brandId !== brandId && x.validBrandIds && x.validBrandIds.length > 0 && !x.validBrandIds.some(b => b === brandId));
      if (removed && removed.length > 0) {
        Log.debug("ui", "SiteMap", `Excluding invalid brand links: ${Helper.buildCsvString(removed.map(x => `${x.label} (not ${x.brandId} or ${Helper.buildCsvString(x.validBrandIds.map(b => m.BrandId[b]))})`))}`);
      }
      links = links.filter(x => x.brandId === brandId || !x.validBrandIds || x.validBrandIds.length === 0 || x.validBrandIds.some(b => b === brandId));
      // Check excluded brands
      removed = links.filter(x => x.excludedBrandIds && x.excludedBrandIds.length > 0 && x.excludedBrandIds.some(b => b === brandId));
      if (removed && removed.length > 0) {
        Log.debug("ui", "SiteMap", `Excluding excluded brand links: ${Helper.buildCsvString(removed.map(x => `${x.label} (${Helper.buildCsvString(x.excludedBrandIds.map(b => m.BrandId[b]))})`))}`);
      }
      links = links.filter(x => !x.excludedBrandIds || x.excludedBrandIds.length === 0 || !x.excludedBrandIds.some(b => b === brandId));
      // Now in a brand filtered scenario we want to clear brand label since that label may show in
      // the UI and is N/A in this scenario because we know the context of the brand being requested.
      links.forEach((link: AppComponentLink) => {
        link.brand = "";
      });
    }

    // Remove any links that we don't have permissions for
    removed = links.filter(x => x.validPermission?.permissionArea && !this.appService.hasPermission(x.validPermission.permissionArea, "R"));
    if (removed && removed.length > 0) {
      Log.debug("ui", "SiteMap", `Excluding missing permission links: ${Helper.buildCsvString(removed.map(x => `${x.label} (${x.validPermission.permissionArea})`))}`);
    }
    links = links.filter(x => !x.validPermission?.permissionArea || this.appService.hasPermission(x.validPermission.permissionArea, "R"));

    // Sort by label
    links.sort((a, b) => a.label.localeCompare(b.label));

    return links;

  }

  public getAppComponentLinksAll(): AppComponentLink[] {

    const links: AppComponentLink[] = [];

    links.push({ label: "Dashboard", path: "/dashboard", categories: ["Reporting"] }); // valid everywhere
    links.push({ label: "Reports", path: "/reports", categories: ["Reporting"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.Reports, permissionAreaType: "PA" } }); // valid everywhere but RC has it's own label

    // Contact
    links.push({ label: "Profile", path: "/profile", categories: ["Company", "Security"] }); // valid everywhere
    links.push({ label: "{{DirectoryPlural}}", path: "/directory", categories: ["Company"], validPermission: { permissionArea: Constants.AccessArea.Directory, permissionAreaType: "TB" } }); // valid everywhere
    links.push({ label: "Users", path: "/directory", categories: ["Company"], validPermission: { permissionArea: Constants.AccessArea.Directory, permissionAreaType: "TB" } }); // valid everywhere
    links.push({ label: "{{GroupPlural}}", path: "/groups", categories: ["Company", "Security"], validPermission: { permissionArea: Constants.AccessArea.Group, permissionAreaType: "TB" } }); // valid everywhere
    links.push({ label: "{{LocationPlural}}", path: "/locations", categories: ["Company"], validPermission: { permissionArea: Constants.AccessArea.Location, permissionAreaType: "TB" } }); // valid everywhere
    links.push({ label: "{{CustomerPlural}}", path: "/customers", categories: ["CRM", "Billing"], validPermission: { permissionArea: Constants.AccessArea.Customer, permissionAreaType: "TB" } });

    // CRM
    links.push({ label: "Sales Opportunities", path: "/sales/opportunities", categories: ["CRM"], validModules: ["crm"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.SalesOpportunity, permissionAreaType: "TB" } });
    links.push({ label: "{{CasePlural}}", path: "/cases", categories: ["{{CasePlural}}"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.Cases, permissionAreaType: "TB" } }); // RC has it's own flavor
    links.push({ label: "{{Case}} Templates", categories: ["{{CasePlural}}"], path: "/case-templates", excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.CaseTemplate, permissionAreaType: "TB" } }); // RC has it's own flavor

    // Notification
    links.push({ label: "Notifications - Send", path: "/notification/events/add", categories: ["Notifications"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.NotificationEvent, permissionAreaType: "TB" } });
    links.push({ label: "Notifications - Events", path: "/notification/events", categories: ["Notifications"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.NotificationEvent, permissionAreaType: "TB" } });
    links.push({ label: "Notifications - Groups", path: "/notification/groups", categories: ["Notifications"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.NotificationGroup, permissionAreaType: "TB" } });
    links.push({ label: "Notifications - Messages (Standard)", path: "/notification/standard-messages", categories: ["Notifications"], validPermission: { permissionArea: Constants.AccessArea.NotificationMessage, permissionAreaType: "TB" } }); // valid everywhere
    links.push({ label: "Notifications - Messages (Advanced)", path: "/notification/messages", categories: ["Notifications"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.NotificationMessage, permissionAreaType: "TB" } });
    links.push({ label: "Notifications - Contacts", path: "/notification/contacts", categories: ["Notifications"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.NotificationContact, permissionAreaType: "TB" } });
    links.push({ label: "Mail Servers", path: "/notification/mail-servers", categories: ["Notifications"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.MailServer, permissionAreaType: "TB" } });
    links.push({ label: "Mail Address Actions", path: "/notification/mail-address-actions", categories: ["Notifications"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.MailAddressAction, permissionAreaType: "TB" } });
    links.push({ label: "Alarm Rules", path: "/alarm-rules/", categories: ["Notifications"], validPermission: { permissionArea: Constants.AccessArea.AlarmRule, permissionAreaType: "TB" } });

    // Inventory
    links.push({ label: "Inventory", path: "/inventory", categories: ["Inventory"], validModules: ["inventory"], validPermission: { permissionArea: Constants.AccessArea.Inventory, permissionAreaType: "TB" } });
    links.push({ label: "Inventory Types", path: "/inventory/types", categories: ["Inventory"], validModules: ["inventory"], validPermission: { permissionArea: Constants.AccessArea.InventoryType, permissionAreaType: "TB" } });
    links.push({ label: "Inventory Type Version Release Management", path: "/inventory/types/release-management", categories: ["Inventory"], validModules: ["inventory"], validPermission: { permissionArea: Constants.AccessArea.InventoryTypeVersion, permissionAreaType: "TB" } });
    links.push({ label: "Inventory Type Version Planning", path: "/inventory/types/version-planning", categories: ["Inventory"], validModules: ["inventory"], validPermission: { permissionArea: Constants.AccessArea.InventoryTypeVersion, permissionAreaType: "TB" } });
    links.push({ label: "Inventory Quantity Types", path: "/inventory/quantity-types", categories: ["Inventory"], validModules: ["inventory"], validPermission: { permissionArea: Constants.AccessArea.InventoryQuantityType, permissionAreaType: "TB" } });
    links.push({ label: "Vendors", path: "/vendors", categories: ["Inventory"], validModules: ["inventory"], validPermission: { permissionArea: Constants.AccessArea.Vendor, permissionAreaType: "TB" } });
    links.push({ label: "Warehouses", path: "/warehouses", categories: ["Inventory"], validModules: ["inventory"], validPermission: { permissionArea: Constants.AccessArea.Warehouse, permissionAreaType: "TB" } });

    // Security
    links.push({ label: "Roles", path: "/security/roles", categories: ["Security"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.Role, permissionAreaType: "TB" } });
    links.push({ label: "Security Policies", path: "/security/policies", categories: ["Security"], validPermission: { permissionArea: Constants.AccessArea.SecurityPolicy, permissionAreaType: "TB" } }); // valid everywhere

    // Data
    links.push({ label: "Assets", path: "/asset", categories: ["Data", "Content", "Apps"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.Asset, permissionAreaType: "TB" } });
    links.push({ label: "Asset Packages", path: "/asset/packages", categories: ["Data", "Content", "Apps"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.Asset, permissionAreaType: "TB" } });
    links.push({ label: "Articles", path: "/articles", categories: ["Data", "Content"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.Asset, permissionAreaType: "TB" } });
    links.push({ label: "Filters", path: "/query/filters", categories: ["Data"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.Filter, permissionAreaType: "TB" } });
    links.push({ label: "Queries", path: "/query", categories: ["Data", "Reporting"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.Query, permissionAreaType: "TB" } });
    links.push({ label: "Data Sources", path: "/query/data-sources", categories: ["Data"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.DataSource, permissionAreaType: "TB" } });
    links.push({ label: "Attributes", path: "/attribute/sets", categories: ["Data", "Apps"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.AttributeSet, permissionAreaType: "TB" } });
    links.push({ label: "Data Import Definitions", path: "/data/import-definitions", categories: ["Data"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.DataImportDefinition, permissionAreaType: "TB" } });
    links.push({ label: "Logs", path: "/logs", categories: ["Data"], validPermission: { permissionArea: Constants.AccessArea.Log, permissionAreaType: "TB" } }); // valid everywhere
    links.push({ label: "Forms", path: "/forms", categories: ["Apps"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.Form, permissionAreaType: "TB" } });
    links.push({ label: "Usage Data Feed", path: "/usage/data-feed", categories: ["Data"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.UsageDataFeed, permissionAreaType: "TB" } });

    // Tax
    links.push({ label: "Tax Configurations", path: "/tax/configurations", categories: ["Tax", "Billing"], validModules: ["tax"], validPermission: { permissionArea: Constants.AccessArea.TaxConfiguration, permissionAreaType: "TB" } });
    links.push({ label: "Tax Geocodes", path: "/tax/geocodes", categories: ["Tax", "Billing"], validModules: ["tax"], validPermission: { permissionArea: Constants.AccessArea.TaxGeocode, permissionAreaType: "TB" } });
    links.push({ label: "Tax Geocode Lookup", path: "/tax/geocode-lookups", categories: ["Tax", "Billing"], validModules: ["tax"], validPermission: { permissionArea: Constants.AccessArea.TaxGeocodeLookup, permissionAreaType: "TB" } });
    links.push({ label: "Tax Matrix", path: "/tax/matrix", categories: ["Tax", "Billing"], validModules: ["tax"], validPermission: { permissionArea: Constants.AccessArea.TaxMatrix, permissionAreaType: "TB" } });

    // Billing
    links.push({ label: "Billing Profiles", path: "/billing/profiles", categories: ["Billing"], validModules: ["billing"], validPermission: { permissionArea: Constants.AccessArea.BillingProfile, permissionAreaType: "TB" } });
    links.push({ label: "Billing Report Profiles", path: "/billing/report-profiles", categories: ["Billing"], validModules: ["billing"], validPermission: { permissionArea: Constants.AccessArea.BillingReportProfile, permissionAreaType: "TB" } });
    links.push({ label: "Items", path: "/items", categories: ["Billing"], validModules: ["billing"], validPermission: { permissionArea: Constants.AccessArea.Item, permissionAreaType: "TB" } });
    links.push({ label: "Packages", path: "/packages", categories: ["Billing"], validModules: ["billing"], validPermission: { permissionArea: Constants.AccessArea.Package, permissionAreaType: "TB" } });
    links.push({ label: "Voucher Batches", path: "/voucher/batches", categories: ["Voucher"], validPermission: { permissionArea: Constants.AccessArea.VoucherBatch, permissionAreaType: "TB" } });

    // Payment
    links.push({ label: "Payment Providers", path: "/payment/providers", categories: ["Billing", "Payment"], validModules: ["payment"], validPermission: { permissionArea: Constants.AccessArea.PaymentProvider, permissionAreaType: "TB" } });
    links.push({ label: "Payment Card Types", path: "/payment/card-types", categories: ["Billing", "Payment"], validModules: ["payment"], validPermission: { permissionArea: Constants.AccessArea.PaymentMethodCardType, permissionAreaType: "TB" } });

    // Site
    links.push({ label: "Search", path: "/search", categories: ["Site"], excludedBrandIds: [m.BrandId.ReportCompiler] });
    links.push({ label: "Site Map", path: "/system/site-map", categories: ["Site"] }); // valid everywhere
    links.push({ label: "Search Configuration", path: "/web/search/configurations", categories: ["Site", "Settings"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.SearchConfiguration, permissionAreaType: "TB" } });

    // Settings
    links.push({ label: "My App Settings", path: "/settings/my-app-settings", categories: ["Settings"] });
    links.push({ label: "System Settings", path: "/settings/app", categories: ["Settings"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.Setting, permissionAreaType: "TB" } });
    links.push({ label: "System Settings (Advanced)", path: "/settings/system", categories: ["Settings"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.Setting, permissionAreaType: "TB" } });
    links.push({ label: "Time Zones", path: "/settings/time-zones", categories: ["Settings"], partitionZeroOnly: true, excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.TimeZone, permissionAreaType: "TB" } });
    links.push({ label: "Currency", path: "/settings/currency", categories: ["Settings"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.Currency, permissionAreaType: "TB" } });
    links.push({ label: "Partitions", path: "/partitions", categories: ["Settings"], partitionZeroOnly: true, validPermission: { permissionArea: Constants.AccessArea.Partition, permissionAreaType: "TB" } });
    links.push({ label: "Jobs", path: "/jobs", validPermission: { permissionArea: Constants.AccessArea.Job, permissionAreaType: "TB" } });
    links.push({ label: "Server Resource Alarm Rules", path: "/system/server-resources/alarm-rules", categories: ["Settings"], partitionZeroOnly: true, excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.AlarmRule, permissionAreaType: "TB" } });
    links.push({ label: "Data Table Support", path: "/system/data-table-support", categories: ["Settings"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.DataTableSupport, permissionAreaType: "TB" } });
    links.push({ label: "Process Templates", path: "/system/process-templates", categories: ["Settings"], validPermission: { permissionArea: Constants.AccessArea.ProcessTemplate, permissionAreaType: "TB" } }); // valid everywhere
    links.push({ label: "Processes", path: "/system/processes", categories: ["Settings"], validPermission: { permissionArea: Constants.AccessArea.Process, permissionAreaType: "TB" } }); // valid everywhere
    links.push({ label: "Help Links", path: "/system/help-links", categories: ["Settings"], partitionZeroOnly: true, validPermission: { permissionArea: Constants.AccessArea.HelpLink, permissionAreaType: "TB" } }); // valid everywhere
    links.push({ label: "Translations (Raw)", path: "/translations/raw", categories: ["Settings"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.Translation, permissionAreaType: "TB" } });
    links.push({ label: "Favorites", path: "/web/favorites", categories: ["Settings"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.Favorite, permissionAreaType: "TB" } });
    links.push({ label: "Webhooks", path: "/web/webhooks", categories: ["Settings"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.Webhook, permissionAreaType: "TB" } });
    links.push({ label: "Pick Lists", path: "/pick-lists", categories: ["Settings"], excludedBrandIds: [m.BrandId.ReportCompiler], validPermission: { permissionArea: Constants.AccessArea.PickList, permissionAreaType: "TB" } });
    links.push({ label: "Subscription Options", path: "/subscriptions/options", categories: ["Apps", "Settings"], partitionZeroOnly: true, saasOnly: true, validPermission: { permissionArea: Constants.AccessArea.SubscriptionConfig, permissionAreaType: "TB" } });
    links.push({ label: "File Servers", path: "/system/file-servers", categories: ["Settings"], validPermission: { permissionArea: Constants.AccessArea.FileServer, permissionAreaType: "TB" } }); //valid everywhere
    links.push({ label: "Quick Start - User", path: "/profile/quick-start", categories: ["Settings"], validPermission: { permissionArea: Constants.AccessArea.FileServer, permissionAreaType: "TB" } }); //valid everywhere


    // Telecom
    links.push({ label: "Numbering Plans", path: "/telecom/numbering-plans", categories: ["Settings", "Telecom"], validModules: ["telecom"], validPermission: { permissionArea: Constants.AccessArea.TelecomNumberingPlan, permissionAreaType: "TB" } });
    links.push({ label: "Location Profiles", path: "/telecom/location-profiles", categories: ["Telecom"], validModules: ["telecom"], validPermission: { permissionArea: Constants.AccessArea.TelecomLocationProfile, permissionAreaType: "TB" } });
    links.push({ label: "Standard Locations", path: "/telecom/standard-locations", categories: ["Telecom"], validModules: ["telecom"], validPermission: { permissionArea: Constants.AccessArea.TelecomLocationStandard, permissionAreaType: "TB" } });
    links.push({ label: "Custom Locations", path: "/telecom/custom-locations", categories: ["Telecom"], validModules: ["telecom"], validPermission: { permissionArea: Constants.AccessArea.TelecomLocationCustom, permissionAreaType: "TB" } });

    // Usage
    links.push({ label: "Usage Import Logs", path: "/usage/import-log", categories: ["Usage"], validModules: ["usage"], validPermission: { permissionArea: Constants.AccessArea.UsageImportLog, permissionAreaType: "TB" } });
    links.push({ label: "Usage Data Sources", path: "/usage/data-sources", categories: ["Usage"], validModules: ["usage"], validPermission: { permissionArea: Constants.AccessArea.UsageDataSource, permissionAreaType: "TB" } });
    links.push({ label: "Service Identification", path: "/usage/service-identifications", categories: ["Usage"], validModules: ["usage"], validPermission: { permissionArea: Constants.AccessArea.UsageServiceIdentification, permissionAreaType: "TB" } });
    links.push({ label: "Rating Profiles", path: "/usage/rating-profiles", categories: ["Usage"], validModules: ["usage"], validPermission: { permissionArea: Constants.AccessArea.RatingProfile, permissionAreaType: "TB" } });
    links.push({ label: "Network Elements", path: "/network/network-element", categories: ["Usage"], validModules: ["usage"], validPermission: { permissionArea: Constants.AccessArea.NetworkElement, permissionAreaType: "TB" } });

    // // Qupport
    // if (this.appService.isBrandQupport) {
    //   links.push({ label: "Articles", path: "/articles", categories: ["Content"], brand: "Qupport", brandId: m.BrandId.Qupport, validBrandIds: [m.BrandId.Qupport] });
    // }

    // Report Compiler
    if (this.appService.isBrandReportCompiler) {
      links.push({ label: "{{CasePlural}}", path: "/rc/cases", categories: ["{{CasePlural}}"], brand: "Report Compiler", brandId: m.BrandId.ReportCompiler, validPermission: { permissionArea: Constants.AccessArea.Cases, permissionAreaType: "TB" } });
      links.push({ label: "Library Text", path: "/rc/library/text", categories: ["Library"], brand: "Report Compiler", brandId: m.BrandId.ReportCompiler, validPermission: { permissionArea: Constants.AccessArea.LibraryPersonalText, permissionAreaType: "TB" } });
      links.push({ label: "Library Documents", path: "/rc/library/documents", categories: ["Library"], brand: "Report Compiler", brandId: m.BrandId.ReportCompiler, validPermission: { permissionArea: Constants.AccessArea.LibraryPersonalDocument, permissionAreaType: "TB" } });
      links.push({ label: "Industry Library Text", path: "/rc/library/industry/text", categories: ["Library"], brand: "Report Compiler", brandId: m.BrandId.ReportCompiler, validPermission: { permissionArea: Constants.AccessArea.LibraryIndustryText, permissionAreaType: "TB" } });
      links.push({ label: "Industry Library Documents", path: "/rc/library/industry/documents", categories: ["Library"], brand: "Report Compiler", brandId: m.BrandId.ReportCompiler, validPermission: { permissionArea: Constants.AccessArea.LibraryIndustryDocument, permissionAreaType: "TB" } });
      links.push({ label: "Library Insights", path: "/rc/library/insights", categories: ["Library"], brand: "Report Compiler", brandId: m.BrandId.ReportCompiler, validPermission: { permissionArea: Constants.AccessArea.LibraryInsights, permissionAreaType: "PA" } });
      links.push({ label: "Report Parser", path: "/rc/library/report-parser", categories: ["Library"], brand: "Report Compiler", brandId: m.BrandId.ReportCompiler, validPermission: { permissionArea: Constants.AccessArea.ReportParser, permissionAreaType: "PA" } });
      links.push({ label: "{{Case}} Templates", path: "/rc/case-templates", categories: ["{{CasePlural}}"], brand: "Report Compiler", brandId: m.BrandId.ReportCompiler, validPermission: { permissionArea: Constants.AccessArea.CaseTemplate, permissionAreaType: "TB" } });
      links.push({ label: "System Settings", path: "/rc/config/settings", categories: ["Settings"], brand: "Report Compiler", brandId: m.BrandId.ReportCompiler, validPermission: { permissionArea: Constants.AccessArea.Setting, permissionAreaType: "TB" } });
      links.push({ label: "Library Groups", path: "/rc/config/library-groups", categories: ["Settings", "Library"], brand: "Report Compiler", brandId: m.BrandId.ReportCompiler, validPermission: { permissionArea: Constants.AccessArea.LibraryGroupConfiguration, permissionAreaType: "TB" } });
      links.push({ label: "Template Types", path: "/rc/config/template-types", categories: ["Settings"], brand: "Report Compiler", brandId: m.BrandId.ReportCompiler, validPermission: { permissionArea: Constants.AccessArea.TemplateTypeConfiguration, permissionAreaType: "TB" } });
      links.push({ label: "Template Documents", path: "/rc/config/template-documents", categories: ["Settings"], brand: "Report Compiler", brandId: m.BrandId.ReportCompiler, validPermission: { permissionArea: Constants.AccessArea.TemplateDocument, permissionAreaType: "TB" } });
      links.push({ label: "Industry Library Information", path: "/rc/config/industry-library-information", categories: ["Settings", "Library"], brand: "Report Compiler", brandId: m.BrandId.ReportCompiler, partitionZeroOnly: true, saasOnly: true, validPermission: { permissionArea: Constants.AccessArea.IndustryLibraryTypeInformation, permissionAreaType: "TB" } });
      links.push({ label: "Pick Lists", path: "/rc/config/pick-lists", categories: ["Settings"], brand: "Report Compiler", brandId: m.BrandId.ReportCompiler, validPermission: { permissionArea: Constants.AccessArea.PickList, permissionAreaType: "TB" } });
      links.push({ label: "Quick Start - Organization", path: "/rc/config/quick-start", categories: ["Settings"], brand: "Report Compiler", brandId: m.BrandId.ReportCompiler, validPermission: { permissionArea: Constants.AccessArea.Directory, permissionAreaType: "TB" } });
      links.push({ label: "Management Insights", path: "/reports", categories: ["Reporting"], brand: "Report Compiler", brandId: m.BrandId.ReportCompiler, validPermission: { permissionArea: Constants.AccessArea.Reports, permissionAreaType: "PA" } });
    }

    // Now handle any macros in the sitemap data
    links.forEach(link => {
      link.label = this.appService.labelMacroSubstitution(link.label);
      if (link.categories && link.categories.length > 0) {
        link.categories = link.categories.map(x => this.appService.labelMacroSubstitution(x));
      }
    });

    // Sort by label
    links.sort((a, b) => a.label.localeCompare(b.label));

    return links;

  }

}


export interface AppComponentLink {
  /*
   * A label describing the component.
   */
  label: string;
  /*
   * The path for the component.
   */
  path: string;
  /*
   * One or more categories for this component link to use
   * when showing components by category.
   */
  categories?: string[];
  /*
   * The brand to display for this component if the component is brand specific.
   */
  brand?: string;
  /*
   * The BrandId for this component if the component is brand specific.
   */
  brandId?: m.BrandId;
  /*
   * A list of valid brands for this component for components shared across brands.
   * Note that a component may be valid for a brand but not desired to have a link.
   * Also note that not every brand may be listed by default and that an empty list
   * indicates the component is valid for all brands.
   */
  validBrandIds?: m.BrandId[];
  /*
   * A list of excluded brands for this component.  This is the inverse of validBrandIds
   * and may be easier to reference when a component is almost always valid.
   */
  excludedBrandIds?: m.BrandId[];
  /*
   * A list of valid modules for this component.  An empty list indicates the component
   * is valid for any module or not tied to a module.  If any of the modules in the list
   * is present then the component is valid.
   */
  validModules?: string[];
  /**
   * A permission area that is required for the component to be valid for current user.
   */
  validPermission?: { permissionArea: string, permissionAreaType: string };
  /*
   * Flag if this component is only valid for partition zero users.
   */
  partitionZeroOnly?: boolean;
  /*
   * Flag if this component is for SaaS scenarios.
   */
  saasOnly?: boolean;
}
